.form-select,
.select-custom {
	.select__control {
		min-width: 5.714285714285714em;
		width: 100%;

		&:hover {
			border-color: $primary-color;
		}

		&.select__control--menu-is-open {
			border-color: $primary-color !important;
		}

		&.select__control--is-focused {
			border-color: $primary-color !important;
		}
	}

	.select__menu {

		.select__menu-list {
			.select__option--is-selected {
				background-color: $primary-color !important;

				&:hover {
					background-color: $primary-color-darken !important;
					color: $white !important;
				}
			}

			.select__option--is-focused {
				background-color: #f2f2f2;

				&:hover {
					color: $primary-color;
				}
			}

		}
	}
}