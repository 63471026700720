.btn-one {
	min-width: rem(114px);
	text-transform: uppercase;
	font-weight: 700;
	margin: 5px;
}

.two-button,
.three-button {
	text-align: right;
}

.four-button {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
}

@media screen and (max-width: 768px) {
	.btn-one {
		flex: 1;
	}

	.three-button {
		display: flex;
		flex-wrap: wrap;
		text-align: right;
	}

	.four-button {
		.two-button {
			display: flex;
			flex-wrap: wrap;
			flex: 1;
		}
	}
}

@media screen and (max-width: 475px) {
	.two-button {
		display: flex;
		flex-wrap: wrap;
	}
}