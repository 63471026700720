.list-component-upgrade-css {
    .table-list {
        .table-responsive {
            max-height: none;
            overflow-y: auto;
        }
        .table {
            thead {
                th {
                    vertical-align: middle;
                    .box-opt {
                        .items-opt {
                            max-height: none;
                        }
                    }
                    .drop-box-filter {
                        max-height: calc(100vh - 50px);
                        overflow-y: auto;
                    }
                    .th-filter {
                        flex-direction: row;
                        & > span {
                            max-width: calc(100% - 20px);
                            white-space: initial;
                            overflow: initial;
                            &:first-child {
                                padding-right: rem(15px);
                                position: relative;
                            }
                            &:before {
                                position: absolute;
                                top: 50%;
                                right: rem(3px);
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
            }
            th {
                padding: rem(8px);
            }
            td {
                padding: rem(8px);
            }
        }
        .drop-box-arr {
            > .popup-action {
                > .form-group, .operation-select-filter {
                    display: none!important; // have class d-flex in same tag
                }
            }
            .btn-add-form {
                display: none;
            }
            .form-group {
                > .popup-action {
                    > .popup-action {
                        width: 100%;
                    }
                }
            }
        }
        .th-col-opt {
            .drop-col-opt {
                max-height: none;
            }
        }
        .sort-control {
            display: flex;
            flex-direction: column;
            margin-right: 5px;

            & > span {
                line-height: 5px;
                &:first-child {
                    padding-top: 5px;
                    padding-bottom: 0;
                }
                &:last-child {
                    padding-top: 0;
                    padding-bottom: 5px;
                    .icon.icon-desc-sort {
                        transform: rotate(180deg) translateX(0.5px);
                    }
                }
            }
        }
    }
}