.form-pass {
    .title {
        .hint {
            font-weight: bold;
            color: #2FBF71;
        }
    }

    .form-input {
        margin-bottom: rem(10px);

        .error {
            border-color: $danger-color;
        }
    }

    .input-group input[type="text"]+span::before {
        content: "\e95c";
    }

    .ReactPasswordStrength {
        &:active {
            border-color: $border-color-primary !important;
        }

        &:focus {
            border-color: $border-color-primary !important;
        }

        .ReactPasswordStrength-input {
            border: none;
            padding: 0 !important;
            line-height: 0 !important;
            font-size: rem(12px) !important;

            &:active {
                border-color: $border-color-primary !important;
            }

            &:focus {
                border-color: $border-color-primary !important;
            }
        }

        .ReactPasswordStrength-strength-desc {
            font-size: rem(12px) !important;
        }

        .ReactPasswordStrength-strength-bar {
            top: 6px !important;
            margin: 0 -0.75rem !important;
            width: calc(100% + 1.571em) !important;
        }
    }

    .react-password-strength-input {
        border-color: $border-color-primary !important;
    }
}