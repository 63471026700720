.unfreeze-membership {
  .collapse-saas {
    border: none;
    box-shadow: none !important;

    .content.open {
      overflow: visible;
    }

    .content {
      transition: max-height 0.4s ease-in-out;
    }
  }

  .section-title-bar {
    button.collapsible {
      background-color: transparent;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1em;
      border-bottom: 1px solid #f2f2f2;
    }
  }

  .text-title {
    font-size: 1em;
    margin-top: 1em;
    text-transform: uppercase;
  }

  .unfreeze-summary-info {
    width: 80%;
  }

  .unfreeze-payment-summary-table {
    thead {
      background-color: #f2f2f2 !important;

      tr {
        th {
          color: $base-color;
        }
      }
    }

    .no-data {
      justify-content: center;
      padding: 0.7142857142857143em 0;
    }
  }

  .list-btn-action {
    text-align: right;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 0 1rem 1rem;
    }

    button {
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}
