.look-up {
  .lookup-action {
    .btn-add-new {
      &:hover {
        background-color: transparent;
        color: $primary-color !important;
        border-color: $primary-color;
      }
    }
  }
}

.add-lookup {
  .lookup-main {
    padding: 0.714em 1.4285714285714286em;
    box-shadow: 0 0 0.2142857143rem var(--gray-color-3);
    .lookup-control {
      display: flex;
      flex-wrap: wrap;
      margin: 1rem 0;

      .root-lookup {
        font-weight: 600;
      }

      .form-group {
        flex: 1;
        margin-right: 3.571em;
      }

      .form-group:last-child {
        margin-right: 0;
      }
    }

    .lookup-list-item {
      background: white;
      overflow: auto;

      .box-btn-filter {
        z-index: 1;
        background: white;
        margin-top: 1rem;
        padding-left: 2rem;
        position: absolute;

        .btn {
          font-size: 0.786em;
        }
      }

      .look-up-control-list {
        padding: 2.5rem 2rem 0 2rem;
      }

      .lookup-control {
        border-bottom: 0.071em solid $gray-color-3;

        .form-group {
          margin-bottom: 1.5rem;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      .lookup-action {
        .btn {
          padding-top: 0.357em;
          padding-bottom: 0.357em;
        }
      }
    }

    .btn-holder {
      margin-top: 1rem;
      text-align: right;

      .btn {
        width: 7.143em;
        margin-left: 0.714em;
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }
}

@media (max-width: 768px) {
  .add-lookup {
    .lookup-control {
      display: block;

      .form-group {
        width: 100%;
      }
    }
  }
}
