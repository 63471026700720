.form-checkbox {
    .checkbox-container {
        display: block;
        position: relative;
        height: 1.7857142857rem;
        padding-left: 2.543em;
        margin: 0 1.429em 0.714em 0;
        cursor: pointer;
        font-size: 0.9285714285714286em;
        line-height: 1.7857142857142858em;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:hover {
            .checkmark {
                background-color: $gray-color-2;

                &.disabled {
                    background-color: $gray-color-2;
                }
            }
        }
    }

    /* Hide the browser's default checkbox */
    .checkbox-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        width: 1.7857142857rem;
        height: 1.7857142857rem;
        background-color: #fff;
        border-radius: 0.1428571429rem;
        border: 0.071em solid $gray-color-3;

        /* Style the checkmark/indicator */
        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 0.6428571428rem;
            top: 0.2857142857rem;
            font-size: 1.2142857142rem;
            width: 0.357em;
            height: 0.714em;
            border: solid $primary-color;
            border-width: 0 0.143em 0.143em 0;
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        &.disabled {
            background-color: $gray-color-2;
            border: 0.071em solid $gray-color-3;

            &:after {
                border: solid $gray-color-4;
                border-width: 0 0.143em 0.143em 0;
            }
        }
    }

    /* On mouse-over, add a grey background color */
    .checkbox-container:hover input~.checkmark {
        background-color: #fff;
    }

    /* Show the checkmark when checked */
    .checkbox-container input:checked~.checkmark:after {
        display: block;
    }
}