.new-member-summary {
	margin: 0;
	display: flex;

	.debt-summary-wrapper {
		border-radius: rem(3px);
		padding: rem(10px) rem(10px);
		border: rem(1px) solid $gray-color-1;
		background-color: white;

		.debt-title {
			font-weight: 600;
			text-transform: uppercase;
			font-size: rem(20px);
			text-align: center;
			padding: rem(10px) 0;
		}

		.money-value {
			margin: 0;
			text-align: center;
			text-transform: uppercase;
			padding: rem(10px) 0 rem(20px) 0;
			white-space: nowrap;

			.title-value {
				margin: 0;
			}

			.value {
				font-size: rem(28px);
				font-weight: 700;

				sup {
					font-size: 65%;
				}
			}
		}

		.content-wrapper {
			background-color: $gray-color-2;
			padding: rem(10px);
			border-radius: rem(3px);
			border: rem(1px) solid $gray-color-1;
			margin-bottom: rem(10px);

			dl {
				justify-content: unset;

				.debt-chase {
					min-width: rem(172px);
					padding-left: rem(15px);
					padding-right: rem(5px);
				}

				.original-state {
					min-width: rem(190px);
					padding-left: rem(15px);
				}

				.debt-value {
					padding: 0;
				}

				dd {
					text-align: left;
				}
			}

			&.money-wrapper {
				padding: 0;
				background-color: unset;
				padding: 0;
				border-radius: unset;
				border: none;
				margin-bottom: 0;
			}

			.title {
				text-transform: uppercase;
				margin: 0;
			}

			.debt-state {
				text-transform: uppercase;
				font-size: rem(18px);
				font-weight: 700;
			}

			.debit-content {
				display: flex;
				align-items: center;

				.image-content {
					border: rem(1px) solid white;
					border-radius: 50%;
					background-color: white;
					width: rem(100px);
					height: rem(100px);
					padding: rem(10px);
					margin-right: rem(10px);
				}

				img {
					position: relative;
					top: rem(5px);
					width: 100%;
					height: 100%;
				}

				.text-content {
					margin-bottom: rem(10px);

					.text-value {
						margin: 0;
						color: black;
						line-height: 1.3;
						font-size: rem(13px);
					}

					.value {
						color: var(--primary-color);
						font-size: rem(18px);
						font-weight: 600;
					}
				}

			}

			.last-bank-reject {
				display: block;

				dd {
					text-align: left;
				}
			}
		}

		.button-wrapper {
			display: flex;

			button {
				flex: 1;
				font-weight: 600;
				text-transform: uppercase;

				&.btn-view-debt {
					color: var(--base-color);
					background-color: $gray-color-1;
					border: rem(1px) solid $gray-color-1;
				}

				&:first-child {
					margin-right: rem(5px);
				}

				&:nth-child(2) {
					margin-left: rem(5px);
				}
			}
		}
	}

	.summary-wrapper {
		margin: 0;
		padding-left: rem(15px);
		padding-right: 0;
		height: fit-content;
		overflow-x: hidden;

		.summary-content {
			height: fit-content;
			border: rem(1px) solid $gray-color-1;
			border-radius: rem(3px);
			margin: 0 0 rem(10px) 0;
			padding: rem(10px) 0;
			background-color: white;

			&:last-child {
				margin: 0;
			}

			.membership-detail-wrapper,
			.personal-detail-wrapper,
			.activity-wrapper {
				padding: 0 rem(10px);
			}

			.future-event {
				padding: 0 rem(10px);
				margin-top: rem(5px);
			}

			.freeze-event-wrapper {
				display: flex;
				justify-content: space-between;
				background: #f2f2f2;
				border-radius: rem(3px);
				padding: rem(10px);
				padding-right: 0;

				.no-mg {
					margin: 0;
				}

				.feature-event {
					display: flex;
					align-items: center;

					.text-holder {
						margin-left: rem(10px);

						.title {
							text-transform: uppercase;
							margin: 0;
							font-size: rem(12px);
							color: $primary-color;
						}

						.freeze-description {
							font-size: rem(12px);
						}
					}

					.calendar-img {
						width: rem(32px);
						height: rem(32px);
					}
				}

				.summary-header {
					.list-action {
						.remove-freeze {
							padding: 0;
						}
					}
				}
			}

			.action {
				width: rem(20px);
				height: rem(20px);
				padding: 0;

				.button-action {
					width: 100%;
					height: 100%;
					border-radius: rem(3px);
					border: rem(1px) solid var(--primary-color);
					background: var(--primary-color);
					opacity: 0.2;
					padding-right: rem(20px) !important;
					padding-bottom: rem(3px);

					&::after {
						display: none;
					}
				}

				.triangle {
					width: 0;
					height: 0;
					border-left: rem(4px) solid transparent;
					border-right: rem(4px) solid transparent;
					position: absolute;
					border-top: rem(4px) solid var(--primary-color);
					top: rem(8px);
					left: rem(7px);
				}
			}

			.summary-header {
				margin-bottom: rem(2px);
				display: flex;
				justify-content: space-between;

				p {
					color: var(--base-color) !important;
					line-height: 2;
				}
			}

			.list-action {
				display: flex;
				justify-content: flex-end;

				.list-action__items {
					span {
						text-transform: capitalize;
					}
				}
			}

			.summary-detail {
				align-items: center;
				margin: 0;

				.access-right {
					.right-wrapper {
						border: rem(1px) solid var(--primary-color);
						border-radius: rem(3px);
						padding: rem(5px) rem(10px);
						margin: rem(5px) 0;
						min-width: rem(150px);

						&:first-child {
							margin-bottom: 0;
						}

						p {
							margin: 0;
						}

						.right-name {
							color: var(--primary-color);
							font-size: rem(16px);
							font-weight: 700;
						}
					}
				}

				.detail {
					width: 100%;
					margin-left: rem(20px);
					padding: 0;

					.col-5 {
						padding-right: 0;
					}

					dl {
						&.row {
							margin: 0;
						}

						line-height: 1.5 !important;
						justify-content: unset !important;

						dd {
							text-align: left !important;
						}
					}
				}
			}

			.activity-summary {
				height: 100%;
				display: flex;
				flex-direction: column;
			}

			.activity-detail {
				border: rem(1px) solid $gray-color-1;
				border-radius: rem(3px);
				flex-grow: 2;
				margin-bottom: 0;
				align-items: stretch;
				align-content: stretch;
				display: flex;
				flex-direction: column;

				.member-ship-card {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin: rem(5px) rem(10px);

					p {
						text-transform: uppercase;
						margin: 0;
						line-height: 1.1;
					}

					.card-number {
						color: var(--primary-color);
						font-size: rem(20px);
						font-weight: 700;
					}
				}

				.activist {
					background-color: $gray-color-2;
					height: 100%;
					display: flex;
					flex-direction: column;

					.visit,
					.access {
						margin: 0;
						padding-top: rem(10px);
						padding-bottom: rem(5px);
						border-bottom: rem(1px) solid $gray-color-1;
						flex-grow: 2;
						display: flex;
						align-items: center;
					}

					.access:last-child {
						border-bottom: 0;
					}

					.visit {
						.text-content {
							margin-bottom: rem(-10px);
						}
					}

					.text-content {
						display: flex;
						align-items: center;
						padding: 0 rem(10px);

						.no {
							color: var(--primary-color);
							font-size: rem(40px);
							font-weight: 700;
							line-height: 0.8;
							padding-right: rem(5px);
							position: relative;
							top: rem(-8px);
							min-width: rem(50px);
							text-align: right;

							&.no-assgigned {
								top: unset;
							}
						}

						.text {
							line-height: 1.4;

							.main-text {
								font-size: rem(15px);
								text-transform: uppercase;
								color: black;

								&.member-card {
									max-width: rem(160px);
								}
							}
						}
					}

					.description {
						padding: 0;
						font-size: rem(15px);
						line-height: 1.3;

						.status {
							font-size: rem(19px);
							font-weight: 700;
							color: var(--primary-color);
						}
					}
				}
			}

			.personal-detail-summary {
				height: 100%;
				display: flex;
				flex-direction: column;
			}

			.personal-detail {
				border: rem(1px) solid $gray-color-1;
				border-radius: rem(3px);
				flex-grow: 2;
				margin-bottom: 0;
				align-items: stretch;
				align-content: stretch;
				display: flex;
				flex-direction: column;

				.content {
					border-bottom: rem(1px) solid $gray-color-1;
					margin-left: 0;
					margin-right: 0;
					padding: rem(10px) 0;
					flex-grow: 1;
					align-items: center;

					&:last-child {
						border-bottom: unset;
					}
				}

				p {
					font-weight: 700;
					margin-bottom: 0.1rem;
				}

				.text {
					line-height: 1.1;
					word-break: break-word;
				}
			}

			.contact-detail {
				border: rem(1px) solid $gray-color-1;
				border-radius: rem(3px);

				.content {
					border-bottom: rem(1px) solid $gray-color-1;
					margin-left: 0;
					margin-right: 0;

					&:last-child {
						border-bottom: unset;
					}

					.detail-data {
						display: flex;
						align-items: center;
						border-right: rem(1px) solid $gray-color-1;
						padding: rem(5px) 0;

						&:last-child {
							border-right: unset
						}

						.number {
							color: var(--primary-color);
							font-size: rem(40px);
							font-weight: 700;
							min-width: rem(55px);
							text-align: right;
							margin-right: rem(10px);
						}

						.text-content {
							line-height: 1.3;
						}

						.title {
							font-size: rem(15px);
							text-transform: uppercase;
							font-weight: 700;
						}
					}
				}
			}


			&.member-account-wrapper {
				border: rem(1px) solid var(--primary-color);
				background-color: var(--primary-color);
				padding: 0 rem(10px);

				.action {

					.button-action {
						border: rem(1px) solid white;
						background: white;
						opacity: 1;
					}

					.triangle {
						border-top: rem(4px) solid var(--primary-color);
					}
				}

				.member-account-summary {
					display: flex;
					width: 100%;

					.current-balance {
						color: white;
						border-right: rem(1px) solid $gray-color-1;
						padding: rem(40px) 0;
						min-width: rem(150px);
						text-align: center;
						align-items: center;
						margin: 0;

						p {
							margin: 0;
							padding: 0;
							line-height: 1;
							text-transform: uppercase;
						}

						.current-value {
							font-weight: 700;
							padding: 0;
							line-height: 1;

							.number-pay-now {
								font-size: rem(24px);
							}
						}
					}

					.charge-content {
						color: white;
						border-right: rem(1px) solid $gray-color-1;
						padding: rem(25px) rem(10px);
						width: rem(170px);

						&.has-current-balance {
							padding: rem(15px);
							width: auto;
						}

						.content-wrapper:first-child {
							margin-bottom: rem(15px);
						}

						.charge-title {
							text-transform: uppercase;
						}

						.charge-value {
							font-weight: 700;
							width: 100%;
							margin-right: rem(90px);

							span {
								font-weight: 400;
							}
						}
					}

					.overview-content {
						width: 100%;

						.list-action {
							padding-top: rem(10px);
							width: 100%;
							display: flex;
							justify-content: flex-end;

							.list-action__items {

								span {
									color: white;
									text-transform: capitalize;
								}

								a {
									color: white;
								}
							}

							.show-box {
								top: 3.2em;
							}
						}

						.contact-right {
							color: white;
							display: flex;
							align-items: center;

							.start-date {
								text-align: right;
								width: rem(100px);
								margin: 0 rem(20px);

								.title {
									line-height: 1.1;
								}

								.value {
									font-weight: 700;
									font-size: rem(16px);
								}
							}

							.date-mcp {
								margin: rem(15px);

								.contract-month {
									display: flex;
									align-items: flex-end;
									border-bottom: rem(1px) solid $gray-color-1;
									padding-bottom: rem(10px);
									width: rem(130px);

									.no {
										text-align: right;
										font-size: rem(40px);
										font-weight: 700;
										line-height: 0.8;
										position: relative;
										top: 0;
										min-width: rem(50px);
										margin-right: rem(5px);
									}

									.contract-text {
										line-height: 1.2;
									}
								}

								.date-mcp-content {
									text-align: center;

									.date {
										line-height: 1.2;
										font-size: rem(14px);
										font-weight: 700;
									}
								}
							}

							.progress-wrapper {
								position: relative;
								width: 60%;

								.debt-start-date,
								.last-visit {
									display: inline-grid;
									text-align: center;
									width: auto;
									position: absolute;
								}

								.debt-start-date {
									top: rem(15px);

									.debt-start-date-title {
										line-height: 1;
									}

									.debt-start-date-value {
										line-height: 1.1;
										font-weight: 700;
									}

									&::before {
										content: '';
										position: absolute;
										width: rem(12px);
										height: rem(12px);
										border-radius: 50%;
										top: rem(-18px);
										left: 42%;
										background-color: white;
									}

									&::after {
										content: '';
										transform: rotate(90deg);
										position: absolute;
										width: rem(15px);
										height: rem(1px);
										top: rem(-6px);
										left: 41.2%;
										background-color: white;

									}
								}

								.last-visit {
									top: rem(-52px);
									padding: rem(5px) rem(10px);

									&::after {
										content: '';
										position: absolute;
										top: 0;
										bottom: 0;
										left: 0;
										right: 0;
										background-color: white;
										opacity: 0.2;
										border-radius: rem(3px);
									}

									.last-visit-title {
										line-height: 1.1;
									}

									.last-visit-value {
										line-height: 1.1;
										font-weight: 700;
									}

									&::before {
										content: '';
										position: absolute;
										width: 0;
										height: 0;
										border-left: 0.6rem solid transparent;
										border-right: 0.6rem solid transparent;
										position: absolute;
										border-top: 0.6rem solid white;
										opacity: 0.2;
										bottom: rem(-8.5px);
										left: 40%;
									}
								}
							}

							.progress {
								background-color: transparent;
								height: rem(6px);

								&::after {
									content: '';
									position: absolute;
									top: 0;
									bottom: 0;
									left: 0;
									right: 0;
									width: 100%;
									background-color: white;
									opacity: 0.4;
									border-radius: rem(5px);
								}
							}

							.progress-bar {
								background-color: white;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.new-member-summary {

		.debt-summary-wrapper {
			padding: rem(5px);

			.debt-title {
				font-size: rem(18px);
				padding: rem(5px) 0;
			}

			.title-value {
				font-size: rem(12px);
			}

			.money-value {
				margin: 0;
				padding: rem(10px) 0;

				.value {
					font-size: rem(24px);
				}
			}

			.content-wrapper {
				margin-bottom: rem(5px);
				padding: rem(5px) rem(10px);

				dl {

					.debt-chase {
						min-width: rem(160px);
					}

					.original-state {
						min-width: rem(170px);
					}
				}

				.title {
					font-size: rem(11px);
				}

				.debt-state {
					line-height: 1.2;
				}

				.debit-content {

					.image-content {
						width: rem(80px);
						height: rem(80px);
					}

					.text-content {

						.text-value {
							font-size: rem(11px);
						}

						.value {
							font-size: rem(14px);
							line-height: 1.3;
						}
					}

				}
			}

			.button-wrapper {
				flex-wrap: wrap;

				button {
					font-size: rem(12px);
					margin: 0 rem(-1px);
					padding-left: rem(5px);
					padding-right: rem(5px);
				}
			}
		}

		.list-action {

			.list-action__items {
				line-height: 1;

				span,
				a {
					line-height: 1;

					&::before {
						top: 0 !important;
					}
				}
			}

			.show-box {
				right: rem(-10px) !important;
			}
		}

		.summary-wrapper {

			padding-left: rem(5px);

			.summary-content {
				margin: 0 0 rem(5px);
				padding: rem(5px) 0;

				.future-event {
					padding: 0 rem(5px);
				}

				.action {
					.button-action {
						padding-right: rem(18px) !important;
					}

					.triangle {
						width: 0;
						height: 0;
						top: rem(7px);
						left: rem(6px);
					}
				}

				.membership-detail-wrapper,
				.personal-detail-wrapper,
				.activity-wrapper {
					padding: 0 rem(5px);
				}

				.summary-detail {

					.access-right {
						.right-wrapper {
							min-width: unset;

							p {
								font-size: rem(11px);
								line-height: 1.3;
							}

							.right-name {
								font-size: rem(18px);
							}
						}
					}

					.detail {
						margin-left: rem(5px);
					}
				}

				.activity-detail {

					.member-ship-card {
						margin: rem(3px) rem(10px);

						p {
							font-size: rem(11px);
						}

						.card-number {
							font-size: rem(18px);
						}
					}

					.activist {

						.visit,
						.access {
							padding-top: rem(4px);
							padding-bottom: rem(4px);
						}

						.text-content {
							.no {
								font-size: rem(24px);
								min-width: rem(35px);
							}

							.text {

								.main-text {
									font-size: rem(11px);
									line-height: 1;
								}
							}

							.sub-text {
								font-size: rem(11px);
							}
						}

						.description {
							font-size: rem(12px);

							.status {
								font-size: rem(14px);
								line-height: 1;
							}
						}
					}
				}

				.personal-detail {
					.content {
						padding: rem(3px) 0;
					}

					p {
						font-size: rem(11px);
					}

					.text {
						font-size: rem(11px);
					}
				}

				.contact-detail {

					.content {

						.detail-data {

							padding: rem(4px) 0;

							.number {
								font-size: rem(24px);
								min-width: rem(35px);
							}

							.title {
								font-size: rem(12px);
							}

							.value {
								font-size: rem(11px);
							}
						}
					}
				}


				&.member-account-wrapper {
					padding-left: rem(5px);

					.member-account-summary {

						.current-balance {
							padding: rem(35px) 0;
							min-width: rem(150px);
							text-transform: uppercase;

							p {
								font-size: rem(12px);
							}

							.current-value {

								.number-pay-now {
									font-size: rem(18px);
								}
							}
						}

						.charge-content {

							.content-wrapper:first-child {
								margin-bottom: rem(10px);
							}

							.charge-title {
								font-size: rem(12px);
							}

							.charge-value {
								font-size: rem(12px);
							}
						}

						.overview-content {
							padding: 0;

							.list-action {
								padding: 0;
							}

							.contact-right {
								padding-top: rem(10px);

								.start-date {
									margin: 0 rem(10px);
									width: rem(70px);

									.title {
										font-size: rem(10px);
										line-height: 1;
									}

									.value {
										font-size: rem(13px);
									}
								}

								.date-mcp {
									margin: rem(5px);

									.contract-month {
										width: rem(90px);

										.no {
											font-size: rem(24px);
											min-width: rem(30px);
										}

										.contract-text {
											font-size: rem(10px);
											line-height: 1;
										}
									}

									.date-mcp-content {

										.text {
											font-size: rem(10px);
										}

										.date {
											font-size: rem(13px);
										}
									}
								}

								.progress-wrapper {
									font-size: rem(12px);

									.debt-start-date {
										top: rem(10px);

										.debt-start-date-title {
											font-size: rem(10px);
										}

										.debt-start-date-value {
											font-size: rem(12px);
										}

										&::before {
											width: rem(8px);
											height: rem(8px);
											top: rem(-12px);
											left: 46%;
										}

										&::after {
											width: rem(11px);
											top: rem(-3px);
											left: 44.5%;
										}
									}

									.last-visit {
										top: -45px;

										.last-visit-title {
											line-height: 1;
											font-size: rem(10px);
										}
									}
								}

								.progress {
									height: rem(4px);
								}
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.new-member-summary {
		.debt-summary-wrapper {
			.money-value {
				.value {
					font-size: rem(22px);
				}
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.new-member-summary {

		.debt-summary-wrapper {
			margin: rem(5px) 0;

			.debt-title {
				padding: rem(10px) 0;
			}

			.debt-wrapper {
				display: flex;
			}

			.money-value {
				text-align: left;
			}

			.content-wrapper {
				flex: 1;

				&.money-wrapper {
					background-color: $gray-color-2;
					padding: rem(10px);
					border-radius: rem(3px);
					border: rem(1px) solid $gray-color-1;
					margin-bottom: rem(5px);
				}

				&.debt-content {
					padding: 0;
					background-color: unset;
					padding: 0;
					border-radius: unset;
					border: none;
					margin-bottom: 0;
				}

				&:first-child {
					margin-right: rem(5px);
				}

				&:last-child {
					margin-left: rem(5px);
				}
			}
		}

		.summary-wrapper {
			padding-left: 0;

			.summary-content {
				margin: 0 0 rem(5px);
				padding: rem(5px) 0;

				.action {
					.button-action {
						padding-right: rem(18px) !important;
					}
				}

				.membership-detail-wrapper,
				.personal-detail-wrapper,
				.activity-wrapper {
					padding: 0 rem(5px);
				}

				.summary-detail {

					.access-right {
						.right-wrapper {
							min-width: unset;

							&:first-child {
								margin-left: rem(15px);
								margin-right: rem(5px);
								margin-bottom: rem(5px);
							}

							p {
								font-size: rem(11px);
								line-height: 1.3;
							}

							.right-name {
								font-size: rem(18px);
							}
						}
					}

					.detail {
						margin-left: rem(5px);
					}
				}
			}
		}
	}
}