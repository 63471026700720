.member-config-page {
  .member-config-page-content {
    padding: 0.714em 1.4285714285714286em;
    box-shadow: 0 0 0.2142857143rem var(--gray-color-3);
  }
  .form-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 0.714em;

    .title {
      margin-bottom: 0;
      flex-basis: 5.714em;
    }

    .select-form {
      margin: 0 0.357em;
      flex-basis: 30%;
    }

    .btn-add {
      padding: 0.286em;
      flex-basis: 3.571em;
    }
  }

  .button-holder {
    text-align: right;

    button {
      padding: 0.286em;
      width: 5.714em;
      margin-left: 0.714em;
    }
  }

  .description {
    margin-top: 0.714em;
  }

  .checkbox-container {
    margin-left: 0.714em;
  }

  table {
    td,
    th {
      border-right: unset !important;
      border-bottom: unset !important;
      border-top: unset !important;
      border-color: transparent;
    }

    td:last-child,
    th:last-child {
      flex-grow: 1;
      flex-basis: 10%;
    }

    td:nth-child(2),
    th:nth-child(2) {
      flex-grow: 1;
      flex-basis: 30%;
    }

    td:nth-child(3),
    th:nth-child(3) {
      flex-grow: 1;
      flex-basis: 30%;
    }

    td:nth-child(4),
    th:nth-child(4) {
      flex-grow: 1;
      flex-basis: 65%;
    }

    td:nth-child(5),
    th:nth-child(5) {
      flex-grow: 1;
      flex-basis: 65%;
    }

    tbody {
      background-color: #fff;

      tr {
        .bold-text {
          font-weight: 600;
        }

        .pd-left-30 {
          padding-left: 2.143em;
        }

        .form-group {
          margin: 0;
        }

        .delete-icon {
          width: 1.714em;
          cursor: pointer;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .member-config-page {
    .form-wrapper {
      .select-form {
        flex-grow: 2;
      }

      .btn-add {
        flex-grow: 1;
        flex-basis: 10%;
      }
    }
  }
}
