.change-summary {
  .collapse-saas {
    box-shadow: none;
    .select-group {
      p.title {
        text-transform: uppercase;
        border-bottom: 0.07142857142857142em solid #ebebeb;
        padding-bottom: 0.35714285714285715em;
        color: $primary-color;
        margin-bottom: 0.5714285714285714em;
      }
    }
  }
  .option-group-change-summary {
    .form-check {
      &:nth-child(odd) {
        margin-right: rem(20px);
      }
      [type='checkbox']:not(:checked) + label:before,
      [type='checkbox']:checked + label:before {
        left: rem(10px);
        top: rem(10px);
      }
      [type='checkbox']:not(:checked) + label:after,
      [type='checkbox']:checked + label:after {
        left: rem(13px);
        top: rem(12px);
      }
      label {
        padding: rem(10px);
        padding-left: rem(45px);
        width: 100%;
        margin-bottom: 0;
      }
      .custom-radio {
        margin-bottom: 0;
        .checkmark-radio {
          top: rem(10px);
          left: rem(10px);
        }
      }
    }
  }
  .form-email {
    padding: 20px 100px 20px 40px;
    background: var(--gray-color-2);
  }
}
