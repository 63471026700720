.remove-change-membership {

	.collapse-saas {
		box-shadow: none;
		margin-bottom: 0.35714285714285715em;

		.collapsible {
			padding: 0.7142857142857143em 1.4285714285714286em;
		}

		.content {
			.info-content {
				padding: 0.7142857142857143em 1.4285714285714286em 0 1.4285714285714286em;
			}
		}
	}

	.collapsible {
		background: white;
		text-transform: uppercase;
		border-bottom: 1px solid $gray-color-2;
		font-weight: 700;
	}

	.remove-change-mbs-table {
		margin-top: 0.7142857142857143em;

		.amount-number {
			text-align: right;
		}
	}

	.future-info {
		line-height: 2;
		font-size: 15px;
	}

	.list-btn-action {
		display: flex;
		justify-content: flex-end;

		li {
			button {
				width: 150px;
				margin-left: 10px;
				text-transform: uppercase;
				font-weight: 700;
			}
		}
	}
}