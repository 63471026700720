.cancel-membership {
	.section-title {
		margin-top: 1rem;
		text-transform: uppercase;
	}

	.cancel-membership-form {
		margin-top: 1rem;
		width: 80%;

		.form-checkbox {
			margin-bottom: 0;
		}
	}

	.cancel-summary-section {
		width: 50%;
	}

	.text-title {
		font-size: 1em;
		margin-top: 1em;
		text-transform: uppercase;
	}

	.important-note {
		h4 {
			color: var(--primary-color);
			display: flex;

			ul {
				margin: 0;
				padding-left: 0.7142857142857143em;
				line-height: 1.5;
				color: var(--base-color);
				font-weight: normal;
			}
		}
	}

	.list-btn-action {
		text-align: right;
		padding: 0;

		li {
			display: inline-block;
			margin: 0 0 1rem 1rem;
		}

		button {
			text-transform: uppercase;
			font-weight: bold;
		}
	}
	.cancel-code {
		margin-top: 1rem;;
		&__title {
			margin-top: .5rem;
			@media (max-width: 575px) {
				& {
					margin-bottom: 0.5rem;
				}
			}
		}
	}
}
