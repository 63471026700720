.add-usergroup {
	.form-add-usergroup {
		padding: 0.714em 1.4285714285714286em;
		box-shadow: 0 0 0.2142857143rem var(--gray-color-3);
	}

	.switch-container {
		display: flex;
		align-items: center;

		.label-select-switch {
			padding-left: 0.714em;
		}
	}

	.subtennant-roles-panel {
		position: relative;
		border-radius: 0.357em;
		border: 0.071em solid $gray-color-3;
		padding: 1.071em 1.071em 0 1.071em;

		&.collapsed {
			padding-top: 0;
			padding-bottom: 0;
		}

		.expand-collapse-button {
			position: absolute;
			top: -3.571em;
			right: 0;
			outline: none;
		}
	}

	.user-list-table {
		border-radius: 0.357em;
		box-shadow: 0 0.143em 0.357em $gray-color-4;

		thead {
			background-color: $gray-color-3;
		}

		tbody {
			tr {
				background-color: $bg-row-2;

				&:nth-of-type(even) {
					background-color: $bg-row-1;
				}
			}
		}

		tr {
			height: 2.5em;
		}

		td,
		th {
			border-bottom: none;
		}
	}

	.w-300 {
		width: 21.429em !important;
	}

	.tenant-roles {
		.search-wrapper {
			.form-wrapper {
				.title-first-select {
					margin-right: 3em;
				}
			}
		}
	}

	.sub-teanant-roles {
		.search-wrapper {
			.form-wrapper {
				.title-first-select {
					margin-right: 1.8125em;
				}
			}
		}
	}
}

.form-add-user {
	.generate-pw {
		margin-bottom: 1.42857142857rem;
	}

	.hidden-tooltip {
		.btn-tooltip {
			visibility: hidden;
		}
	}
}

.add-usergroup {
	.sub-teanant-roles {
		.expand-collapse-button {
			background: #fff;
			box-shadow: none;
		}

	}
}


.form-add-user+.row {
	.add-usergroup {
		padding-top: 1em;
		padding-bottom: 1em;
		border-top: 0.071em solid $gray-color-3;
		border-bottom: 0.071em solid $gray-color-3;
	}

}

.system-page {
	.form-add-role {
		background-color: #fff;
		padding: 0.714em 1.4285714285714286em;
		box-shadow: 0 0 0.2142857143rem var(--gray-color-3);
		border-radius: 0.2142857143rem;
	}

	.row-btn {
		text-align: right;
		margin: 2rem 0;

		.btn {
			width: 10em;
			text-transform: uppercase;
			font-weight: 700;
		}

		.btn-primary {
			margin-right: 1rem;
		}
	}

	.edit-acc {
		.col-md-12 {
			margin-top: 2rem;

			.form-table {
				dt {
					max-width: 8.571em;
				}

				dd {
					display: flex;
					padding: 0;

					.form-group {
						max-width: 21.429em;
						flex: 1;
					}
				}
			}
		}

		.generate-pw {
			.btn-holder {
				margin-top: -0.714em;

				button {
					margin-right: 0.36em;
					padding: 0.143em 0.86em;
				}
			}
		}

		.form-input {
			display: flex;
			align-items: center;

			.form-group {
				flex: 1;
			}

			.btn-tooltip {
				height: 1.714em;
				width: 1.714em;

				&:hover {
					background: none;
				}
			}
		}
	}
}

.sub-tenant-content {
	display: none;
	margin-left: 10.714em;
	//   background: #fff;
	padding: 0.714em;

	&.active {
		display: block;
		position: relative;
	}

	.sub-tenant-list {
		padding: 0;
		margin: 0;

		.switch-container {
			display: flex;
			align-content: center;
		}
	}

	.expand-collapse-button {
		position: absolute;
		top: -4.286em;
		right: 0;
	}
}

.search-icon {
	position: absolute;
	left: 0;
}

.tag-list {
	padding: 0;
	margin: 0.714em 0;

	li {
		display: inline-block;
		padding: 0 0.357em;
		margin: 0.357em 0;
	}
}

.form-table {
	margin-top: 0.357em;
	margin-bottom: 0;

	dt {
		width: 10em;
		float: left;
		padding: 0.357em 0;
		font-weight: normal;
	}

	dd {
		display: flex;
		vertical-align: middle;
		align-items: center;
	}

	.form-group {
		display: inline-block;
		margin-bottom: 0;
		flex-basis: 70%;
	}
}

@media screen and (max-width: 768px) {
	.form-table {
		dt {
			width: 100%;
			float: left;
			padding-top: 0.357em;
		}

		dd {
			padding: 0;
		}

		.form-group {
			display: inline-block;
		}
	}
}