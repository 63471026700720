.freeze-membership {
  .list-btn-action {
    text-align: right;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 0 1rem 1rem;
    }

    button {
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .section-title {
    color: $primary-color;
  }

  .text-title {
    color: $primary-color;
    font-weight: bold;
    text-transform: uppercase;
  }

  .section-down {
    padding-right: 0;
    margin-right: -1.0714285714285714em;
  }

  .freeze-payment-summary-table {
    thead {
      background-color: #f2f2f2 !important;

      tr {
        th {
          color: $base-color;
        }
      }
    }

    .no-data {
      justify-content: center;
      padding: 0.7142857142857143em 0;
    }
  }
}
