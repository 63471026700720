.remove-freeze {
  margin-top: 1em;

  p {
    font-size: 0.857143em;
  }

  .text-title {
    font-size: 1em;
    margin-top: 1em;
  }

  .section-content {
    margin-left: 5%;

    .freeze-details-container {
      margin-left: 5%;
    }
  }

  .list-btn-action {
    text-align: right;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 0 1rem 1rem;
    }

    button {
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}
