/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 700 !important;
  color: #007bff;
  background-color: transparent; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-file-input:focus ~ .custom-file-control::before {
      border-color: #80bdff; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1550;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  margin-left: 1px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

:root {
  --base-color: #707070;
  --white: #ffffff;
  --white-1: #fafafa;
  --black: #000000;
  --primary-color: #00d6d0;
  --secondary-color: #14b3b8;
  --dark-color: #707070;
  --gray-color-1: #ebebeb;
  --gray-color-2: #f2f2f2;
  --gray-color-3: #cccccc;
  --gray-color-4: #9e9e9e;
  --danger-color: #ea0303;
  --danger-color-1: #fbcdcd;
  --warning-color: #ffba00;
  --warning-color-1: #fff1cc;
  --yellow-color: #ffcc00;
  --yellow-color-1: #e5b805;
  --pink-color: #ff6060;
  --pink-color-1: #f44242;
  --green-color: #a4d00c;
  --green-color-1: #8bb204;
  --blue-color: #0097b7;
  --blue-color-1: #08768d;
  --blue-color-2: #3b90a2;
  --orange-color: #ff6c00;
  --purple-color: #400c54;
  --purple-color-1: #885c99;
  --color-1: #8e9c5c;
  --color-2: #78844f;
  --bg-row-1: #f9f9f9;
  --hover-bg-color: #00cbc5;
  --positive-color: #36db1b;
  --primary-color-darken: #00a39e;
  --secondary-color-darken: #0f868a;
  --dark-color-darken: #575757; }

*,
:after,
:before {
  box-sizing: inherit; }

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  box-sizing: border-box; }

body {
  letter-spacing: 0.25px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: var(--base-color); }

a:hover {
  text-decoration: none; }

a:focus {
  outline: none; }

button {
  cursor: pointer; }

ul,
li {
  list-style: none; }

.pd-submenu {
  padding: 1.92857rem 1.42857rem; }

.hidden {
  display: none !important; }

.list-page .pagination,
.list-page .title-content {
  margin-bottom: 0; }

.list-page .box-btn-filter {
  align-self: center; }
  .list-page .box-btn-filter .btn-filter:hover span,
  .list-page .box-btn-filter .btn-filter:hover .icon {
    transition: 0.2s;
    color: var(--base-color); }
  .list-page .box-btn-filter .btn-filter span,
  .list-page .box-btn-filter .btn-filter .icon {
    transition: 0.2s;
    color: var(--white); }
  .list-page .box-btn-filter .btn-filter .icon {
    margin-right: 0.35714rem; }
  .list-page .box-btn-filter .btn-filter span {
    text-transform: uppercase;
    font-weight: 600; }

.info-err {
  font-size: 0.71429rem;
  color: var(--danger-color);
  display: block;
  font-style: italic;
  padding-left: 0.14286rem; }

.mail-user {
  margin-bottom: 1.42857rem;
  position: relative;
  padding-left: 1.92857rem;
  margin-bottom: 0;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis; }
  .mail-user::before {
    content: '';
    position: absolute;
    background: url(../images/microsoft-color-ico.svg) no-repeat;
    width: 17px;
    height: 17px;
    left: 0;
    top: 0.14286rem; }

.list-check-pass ul {
  padding-left: 1.42857rem; }
  .list-check-pass ul li {
    color: var(--gray-color-3);
    position: relative;
    margin-bottom: 0.71429rem; }
    .list-check-pass ul li::before {
      content: '';
      position: absolute;
      width: 0.57143rem;
      height: 0.57143rem;
      border-radius: 50%;
      background-color: var(--gray-color-4);
      left: -1.5rem;
      top: 0.42857rem; }
    .list-check-pass ul li.active {
      color: var(--base-color); }
      .list-check-pass ul li.active::before {
        background-color: var(--primary-color); }

.obligatory {
  margin-left: 0.35714rem;
  color: var(--danger-color); }

.mg-r-20 {
  margin-right: 1.42857rem; }

.bd-t-gray2 {
  border-top: 1px solid var(--gray-color-2); }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/font-icon/icomoon.eot?owwf1x");
  src: url("../fonts/font-icon/icomoon.eot?owwf1x#iefix") format("embedded-opentype"), url("../fonts/font-icon/icomoon.ttf?owwf1x") format("truetype"), url("../fonts/font-icon/icomoon.woff?owwf1x") format("woff"), url("../fonts/font-icon/icomoon.svg?owwf1x#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  [class^='icon-']::before,
  [class*=' icon-']::before {
    font-family: 'icomoon' !important; }

.icon-filter-table:before {
  content: '\e969'; }

.icon-save:before {
  content: '\e963'; }

.icon-delete:before {
  content: '\e964'; }

.icon-arr-sort:before {
  content: '\e965'; }

.icon-column-opt:before {
  content: '\e966'; }

.icon-dots-horizontally:before {
  content: '\e967'; }

.icon-dots-vertically:before {
  content: '\e968'; }

.icon-detail-no-boder:before {
  content: '\e962'; }

.icon-filter:before {
  content: '\e961'; }

.icon-export:before {
  content: '\e95f'; }

.icon-import:before {
  content: '\e960'; }

.icon-user-group:before {
  content: '\e95d'; }

.icon-permission:before {
  content: '\e95e'; }

.icon-hidden:before {
  content: '\e95c'; }

.icon-duplicated:before {
  content: '\e95b'; }

.icon-checked-circle:before {
  content: '\e95a'; }

.icon-email-ico:before {
  content: '\e927'; }

.icon-phone-ico:before {
  content: '\e959'; }

.icon-reset-password-ico:before {
  content: '\e926'; }

.icon-refuunds-batch:before {
  content: '\e924'; }

.icon-accounting-report:before {
  content: '\e922'; }

.icon-add-corporate:before {
  content: '\e923'; }

.icon-add-corporate-price-change:before {
  content: '\e925'; }

.icon-add-express-member:before {
  content: '\e928'; }

.icon-add-personal-trainer:before {
  content: '\e929'; }

.icon-authorisan:before {
  content: '\e92a'; }

.icon-blacklist:before {
  content: '\e92b'; }

.icon-bulk-payment:before {
  content: '\e92c'; }

.icon-bulk-update:before {
  content: '\e92d'; }

.icon-clubs:before {
  content: '\e92e'; }

.icon-clubs-report:before {
  content: '\e92f'; }

.icon-corporate:before {
  content: '\e930'; }

.icon-credit:before {
  content: '\e931'; }

.icon-database:before {
  content: '\e932'; }

.icon-debt:before {
  content: '\e933'; }

.icon-debt-action:before {
  content: '\e934'; }

.icon-debt-chase:before {
  content: '\e935'; }

.icon-deposit:before {
  content: '\e936'; }

.icon-detail:before {
  content: '\e937'; }

.icon-discount:before {
  content: '\e938'; }

.icon-front-desk:before {
  content: '\e939'; }

.icon-invoice-list:before {
  content: '\e93a'; }

.icon-items:before {
  content: '\e93b'; }

.icon-management-action:before {
  content: '\e93c'; }

.icon-manage-testing:before {
  content: '\e93d'; }

.icon-manual-fee:before {
  content: '\e93e'; }

.icon-member-report:before {
  content: '\e93f'; }

.icon-office-report:before {
  content: '\e940'; }

.icon-operators:before {
  content: '\e941'; }

.icon-others:before {
  content: '\e942'; }

.icon-packages:before {
  content: '\e943'; }

.icon-payment:before {
  content: '\e944'; }

.icon-pending-cancel:before {
  content: '\e945'; }

.icon-plan:before {
  content: '\e946'; }

.icon-plan-1:before {
  content: '\e947'; }

.icon-pos:before {
  content: '\e948'; }

.icon-product:before {
  content: '\e949'; }

.icon-promotion:before {
  content: '\e94a'; }

.icon-pt-report:before {
  content: '\e94b'; }

.icon-rejections:before {
  content: '\e94c'; }

.icon-report:before {
  content: '\e94d'; }

.icon-revenue:before {
  content: '\e94e'; }

.icon-role:before {
  content: '\e94f'; }

.icon-schedule-report:before {
  content: '\e950'; }

.icon-support-tool:before {
  content: '\e951'; }

.icon-system:before {
  content: '\e952'; }

.icon-system-config:before {
  content: '\e953'; }

.icon-system-configuration:before {
  content: '\e954'; }

.icon-tax-refund:before {
  content: '\e955'; }

.icon-unsigned-contract:before {
  content: '\e956'; }

.icon-view-corporate:before {
  content: '\e957'; }

.icon-view-n-search:before {
  content: '\e958'; }

.icon-logout-1:before {
  content: '\e920';
  color: #707070; }

.icon-edit-profile:before {
  content: '\e921';
  color: #707070; }

.icon-help-ico:before {
  content: '\e91f';
  color: #707070; }

.icon-double-arrow-left-icon:before {
  content: '\e91d'; }

.icon-double-arrow-right-icon:before {
  content: '\e91e'; }

.icon-zoom-in-ico:before {
  content: '\e91b'; }

.icon-zoom-out-ico:before {
  content: '\e91c'; }

.icon-image-ico:before {
  content: '\e91a'; }

.icon-add-member-icon:before {
  content: '\e900'; }

.icon-arrow-down-icon:before {
  content: '\e901'; }

.icon-arrow-left-icon:before {
  content: '\e902'; }

.icon-arrow-right-icon:before {
  content: '\e903'; }

.icon-arrow-up-icon:before {
  content: '\e904'; }

.icon-calendar-ico:before {
  content: '\e905'; }

.icon-checked-ico:before {
  content: '\e906'; }

.icon-close-ico:before {
  content: '\e907'; }

.icon-detail-config-ico:before {
  content: '\e908'; }

.icon-download-ico:before {
  content: '\e909'; }

.icon-edit-ico:before {
  content: '\e90a'; }

.icon-edit-layout-ico:before {
  content: '\e90b'; }

.icon-edit-setting-ico:before {
  content: '\e90c'; }

.icon-global-ico:before {
  content: '\e90d'; }

.icon-layout-ico:before {
  content: '\e90e'; }

.icon-localisasion-ico:before {
  content: '\e90f'; }

.icon-lock-icon:before {
  content: '\e910'; }

.icon-member-ico:before {
  content: '\e911'; }

.icon-membership-ico:before {
  content: '\e912'; }

.icon-noti-icon:before {
  content: '\e913'; }

.icon-operators-ico:before {
  content: '\e914'; }

.icon-search-ico:before {
  content: '\e915'; }

.icon-search-member-ico:before {
  content: '\e916'; }

.icon-system-config-ico:before {
  content: '\e917'; }

.icon-visible-ico:before {
  content: '\e918'; }

.icon-config-ico:before {
  content: '\e919'; }

.icon-filter-svg {
  width: 0.85714rem;
  margin-left: 0.35714rem; }

h1, .h1 {
  font-size: 30px;
  font-size: 2.14286rem;
  font-weight: 700; }

h2, .h2 {
  font-size: 24px;
  font-size: 1.71429rem;
  font-weight: 700; }

h3, .h3 {
  font-size: 18px;
  font-size: 1.28571rem;
  font-weight: 700; }

h4, .h4 {
  font-size: 14px;
  font-size: 1rem;
  font-weight: 700; }

.text-base {
  color: var(--base-color) !important; }

.text-primary {
  color: var(--primary-color) !important; }

.text-black {
  color: var(--black) !important; }

.text-link {
  color: var(--primary-color);
  transition: 0.3s; }
  .text-link:hover, .text-link:active {
    color: var(--secondary-color); }

.text-warning {
  color: var(--warning-color) !important; }

.text-danger {
  color: var(--danger-color) !important; }

.bg-primary {
  background-color: var(--primary-color) !important; }

.bg-secondary {
  background-color: var(--secondary-color) !important; }

.bg-black {
  background-color: var(--black) !important; }

.bg-dark {
  background-color: var(--dark-color) !important; }

.bg-gray-1 {
  background-color: var(--gray-color-1) !important; }

.bg-gray-2 {
  background-color: var(--gray-color-2) !important; }

.bg-white {
  background-color: var(--white) !important; }

.bg-danger {
  background-color: var(--danger-color) !important; }

.bg-warning {
  background-color: var(--warning-color) !important; }

.btn {
  padding: 0.71429rem;
  color: var(--white);
  letter-spacing: 0.25px;
  transition: 0.2s; }
  .btn:hover {
    background-color: transparent;
    transition: 0.2s; }
  .btn:focus {
    box-shadow: none;
    outline: none; }
  .btn:disabled {
    background-color: var(--gray-color-4);
    pointer-events: none;
    border-color: var(--gray-color-4); }

.btn-default {
  background-color: var(--gray-color-2);
  color: var(--base-color);
  border-color: var(--gray-color-3); }

.btn-transparent {
  background-color: transparent;
  border: none; }

.btn-upper {
  text-transform: uppercase;
  font-weight: 700; }

.btn-height-sm {
  margin-right: 1rem;
  height: 1.71429rem;
  position: relative;
  background-color: transparent;
  padding: 0 0.5rem;
  color: var(--base-color) !important;
  font-size: 0.85714rem;
  border: solid 1px var(--gray-color-3); }
  .btn-height-sm::after {
    content: '';
    height: 110%;
    width: 0.07143rem;
    position: absolute;
    right: -0.57143rem;
    top: -0.14286rem;
    background-color: var(--gray-color-3); }
  .btn-height-sm.not-after {
    margin-right: 0.35714rem; }
    .btn-height-sm.not-after::after {
      content: '';
      height: 0%; }

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color); }
  .btn-primary:hover {
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important; }
  .btn-primary.active {
    background-color: var(--primary-color-darken);
    border-color: var(--primary-color-darken); }
  .btn-primary:active, .btn-primary:focus {
    background: var(--primary-color-darken) !important;
    border-color: var(--primary-color-darken) !important;
    color: var(--white) !important; }
  .btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none; }

.btn-secondary {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color); }
  .btn-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none; }
  .btn-secondary:hover {
    border-color: var(--secondary-color) !important;
    color: var(--secondary-color) !important; }
  .btn-secondary.active {
    background-color: var(--secondary-color-darken);
    border-color: var(--secondary-color-darken); }
  .btn-secondary:active, .btn-secondary:focus {
    background: var(--secondary-color-darken) !important;
    border-color: var(--secondary-color-darken) !important;
    color: var(--white) !important; }

.btn-dark {
  background-color: var(--dark-color);
  border-color: var(--dark-color); }
  .btn-dark:not(:disabled):not(.disabled):active:focus {
    box-shadow: none; }
  .btn-dark:hover {
    border-color: var(--dark-color) !important;
    color: var(--dark-color) !important; }
  .btn-dark.active, .btn-dark:focus {
    background-color: var(--dark-color-darken);
    border-color: var(--dark-color-darken); }
  .btn-dark:active, .btn-dark:focus {
    background: var(--dark-color-darken) !important;
    border-color: var(--dark-color-darken) !important;
    color: var(--white) !important; }

.btn-white {
  background-color: var(--white);
  color: var(--primary-color); }

.group-btn {
  display: flex;
  justify-content: flex-end;
  padding: 1.07143rem 0.71429rem; }
  .group-btn .btn {
    text-transform: uppercase;
    font-weight: 700;
    min-width: 140px;
    margin-right: 1.42857rem; }
    .group-btn .btn:last-child {
      margin-right: 0; }

.alert {
  padding: 1.14286rem 1.35714rem; }

.alert-default {
  color: var(--dark-color);
  background-color: var(--gray-color-2);
  border-color: var(--gray-color-3); }

.alert-warning {
  color: var(--warning-color);
  background-color: var(--warning-color-1);
  border-color: var(--warning-color); }

.alert-danger {
  color: var(--danger-color);
  background-color: var(--danger-color-1);
  border-color: var(--danger-color); }

.alert-no-bg {
  background-color: transparent;
  padding: 1rem 1.35714rem; }

.tabs {
  /* Fade in tabs */ }
  .tabs .nav-tabs .nav-item {
    padding-right: 0.14286rem;
    cursor: pointer; }
    .tabs .nav-tabs .nav-item .nav-link {
      color: var(--base-color);
      background-color: var(--gray-color-1);
      padding: 0.64286rem 1.14286rem;
      border-color: var(--gray-color-3);
      transition: 0.2s; }
      .tabs .nav-tabs .nav-item .nav-link.active {
        color: var(--primary-color);
        background-color: transparent;
        border-color: var(--gray-color-3) var(--gray-color-3) var(--white);
        transition: 0.2s;
        margin-top: -0.21429rem;
        padding-bottom: 0.85714rem; }
  .tabs .tab-content {
    border: 1px solid var(--gray-color-3);
    border-radius: 0rem 0.28571rem 0.28571rem 0.28571rem;
    padding: 1.42857rem 1.42857rem 1.78571rem; }
    .tabs .tab-content .tab-pane {
      -webkit-animation: fadeEffect 0.6s;
      animation: fadeEffect 0.6s; }

@-webkit-keyframes fadeEffect {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeEffect {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.form-control {
  border: 1px solid var(--gray-color-3);
  color: var(--base-color);
  padding: 0.42857rem 0.75rem;
  letter-spacing: 0.25px;
  font-size: 0.85714rem; }
  .form-control:focus {
    box-shadow: none;
    border-color: var(--primary-color); }
  .form-control:disabled {
    background-color: var(--gray-color-2); }

::-webkit-input-placeholder {
  font-style: italic;
  font-size: 0.85714rem;
  letter-spacing: 0.25px; }

:-moz-placeholder {
  font-style: italic;
  font-size: 0.85714rem;
  letter-spacing: 0.25px; }

::-moz-placeholder {
  font-style: italic;
  font-size: 0.85714rem;
  letter-spacing: 0.25px; }

:-ms-input-placeholder {
  font-style: italic;
  font-size: 0.85714rem;
  letter-spacing: 0.25px; }

.fm-danger label {
  color: var(--danger-color); }

.fm-danger input,
.fm-danger input:focus {
  border-color: var(--danger-color); }

.fm-success label {
  color: var(--primary-color); }

.fm-success input {
  border-color: var(--primary-color); }

.input-group .input-group-prepend .icon {
  background-color: transparent;
  border: none; }

.select2-demo .select2 {
  width: 100% !important; }

.label-select2 {
  display: block;
  margin-bottom: 0.5rem; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b::before {
  content: '\e904'; }

.select2-container--default:focus,
.select2-container--default .selection,
.select2-container--default .select2-selection {
  outline: none; }

.select2-container--default .select2-selection--single {
  height: auto; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 21px;
    padding: 0.39286rem 0.75rem; }
  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    font-size: 0.85714rem;
    font-style: italic;
    color: var(--base-color); }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: auto;
    top: 0;
    right: 0.42857rem; }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      position: relative;
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 21px;
      -webkit-font-smoothing: antialiased;
      font-size: 1.92857rem;
      border: none;
      top: 0.5rem;
      left: 0; }
      .select2-container--default .select2-selection--single .select2-selection__arrow b::before {
        content: '\e901';
        position: absolute; }

.select2-container--default .select2-results__option {
  font-size: 0.85714rem; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: var(--gray-color-2);
  font-size: 0.85714rem;
  color: var(--base-color); }
  .select2-container--default .select2-results__option--highlighted[aria-selected]:last-child {
    border-radius: 0 0 0.28571rem 0.28571rem; }

span.select2-container--open {
  z-index: 1111; }

.select-custom {
  position: relative;
  margin-right: 0.71429rem;
  width: 100%; }
  .select-custom .select-filter {
    -webkit-appearance: none;
    height: 2.28571rem !important;
    cursor: pointer; }
  .select-custom .icon-arrow-down-icon {
    position: absolute;
    font-size: 1.78571rem;
    right: 0;
    top: 8%;
    cursor: pointer; }

.form-check {
  padding-left: 0; }
  .form-check .custom-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .form-check .custom-radio input {
      position: absolute;
      opacity: 0;
      cursor: pointer; }
      .form-check .custom-radio input:checked ~ .checkmark-radio {
        background-color: var(--white); }
        .form-check .custom-radio input:checked ~ .checkmark-radio::after {
          display: block; }
    .form-check .custom-radio .checkmark-radio {
      position: absolute;
      top: -0.14286rem;
      left: 0;
      height: 1.78571rem;
      width: 1.78571rem;
      border: 1px solid var(--gray-color-3);
      background-color: var(--white);
      border-radius: 50%; }
      .form-check .custom-radio .checkmark-radio::after {
        content: '';
        position: absolute;
        display: none;
        top: 0.21429rem;
        left: 0.21429rem;
        width: 1.21429rem;
        height: 1.21429rem;
        border-radius: 50%;
        background: var(--primary-color); }
    .form-check .custom-radio .checkmark-disable {
      background-color: var(--gray-color-2); }
      .form-check .custom-radio .checkmark-disable::after {
        background: var(--gray-color-3); }

.form-check [type='checkbox']:not(:checked),
.form-check [type='checkbox']:checked {
  position: absolute;
  left: -9999px; }

.form-check [type='checkbox']:not(:checked) + label,
.form-check [type='checkbox']:checked + label {
  position: relative;
  padding-left: 2.5rem;
  cursor: pointer; }

.form-check [type='checkbox']:not(:checked) + label:before,
.form-check [type='checkbox']:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.78571rem;
  height: 1.78571rem;
  border: 1px solid var(--gray-color-3);
  background: var(--white);
  border-radius: 0.14286rem; }

.form-check [type='checkbox']:not(:checked) + label:after,
.form-check [type='checkbox']:checked + label:after {
  content: '\e906';
  position: absolute;
  top: 0.14286rem;
  left: 0.21429rem;
  color: var(--primary-color);
  font-size: 1.42857rem;
  font-weight: 700;
  font-family: 'icomoon' !important; }

.form-check [type='checkbox']:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0); }

.form-check [type='checkbox']:checked + label:after {
  opacity: 1;
  transform: scale(1); }

.form-check [type='checkbox']:disabled:not(:checked) + label:before,
.form-check [type='checkbox']:disabled:checked + label:before {
  border-color: var(--gray-color-3);
  background-color: var(--gray-color-2); }

.form-check [type='checkbox']:disabled:checked + label:after {
  color: var(--gray-color-4); }

.form-check label[class^='icon-'], .form-check label[class*=' icon-'] {
  line-height: 21px; }

.form-check label span {
  font-family: "Poppins", sans-serif;
  color: var(--base-color);
  font-size: 14px;
  letter-spacing: 0.25px; }

.switch {
  position: relative;
  display: inline-block;
  width: 3.64286rem;
  height: 2rem;
  margin-bottom: 0; }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0; }
    .switch input:checked + .slider {
      background-color: var(--primary-color); }
    .switch input:not(:checked) + .slider {
      background-color: var(--gray-color-1); }
    .switch input:focus + .slider {
      box-shadow: none; }
    .switch input:checked + .slider:before {
      -webkit-transform: translateX(22px);
      -ms-transform: translateX(22px);
      transform: translateX(22px); }
  .switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--gray-color-1);
    border: 1px solid rgba(0, 0, 0, 0.02);
    -webkit-transition: 0.4s;
    transition: 0.4s; }
    .switch .slider:before {
      position: absolute;
      content: '';
      height: 1.42857rem;
      width: 1.42857rem;
      left: 0.21429rem;
      bottom: 0.21429rem;
      background-color: var(--white);
      -webkit-transition: 0.4s;
      transition: 0.4s; }
    .switch .slider.round {
      border-radius: 3.57143rem; }
      .switch .slider.round:before {
        border-radius: 50%; }

.checkbox-custom input {
  height: 2.28571rem; }

.table td {
  padding: 0.75rem 1.57143rem; }

.table thead th {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--white);
  border-bottom: none;
  border-top: none;
  padding: 1.28571rem 1.57143rem; }

.table.table-dark-gray thead {
  background-color: var(--dark-color); }

.table.table-base thead {
  background-color: var(--primary-color); }

.table.table-base td {
  border-bottom: 1px solid var(--gray-color-2); }

.table.table-base th {
  border-right: 1px solid var(--white); }

.table.table-striped tbody tr {
  background-color: var(--gray-color-2); }
  .table.table-striped tbody tr:nth-of-type(odd) {
    background-color: var(--bg-row-1); }

.table.table-white {
  background-color: var(--white); }
  .table.table-white th {
    color: var(--base-color); }

.table-bordered-white {
  border: none; }
  .table-bordered-white td {
    border: 1px solid var(--white); }

.table-list {
  background-color: var(--white);
  box-shadow: 0 0.14286rem 0.42857rem var(--gray-color-3);
  border-radius: 0.28571rem;
  padding: 0.21429rem;
  padding-bottom: 1.07143rem; }
  .table-list .btn-sort,
  .table-list .btn-filter,
  .table-list .btn-col-opt {
    padding: 0; }
    .table-list .btn-sort i,
    .table-list .btn-filter i,
    .table-list .btn-col-opt i {
      color: var(--base-color); }
  .table-list .text-filter {
    white-space: nowrap; }
  .table-list th:last-child,
  .table-list td:last-child {
    right: 0; }
  .table-list td:last-child {
    background-color: var(--white); }
  .table-list .table {
    font-size: 0.85714rem;
    margin-bottom: 0; }
    .table-list .table a {
      color: var(--base-color); }
    .table-list .table thead th:first-child {
      border-left: 1px solid var(--gray-color-2); }
    .table-list .table thead th:last-child {
      border-right: 1px solid var(--gray-color-2); }
    .table-list .table th {
      color: var(--base-color);
      text-transform: uppercase;
      padding: 0.85714rem;
      background-color: var(--gray-color-2);
      top: 0px;
      position: sticky;
      z-index: 1; }
    .table-list .table td {
      padding: 0.71429rem 0.85714rem;
      border-top: none;
      border-bottom: 1px solid var(--gray-color-2);
      vertical-align: middle;
      min-width: 160px; }
      .table-list .table td.action {
        min-width: 0; }
      .table-list .table td .td-action {
        position: relative; }
        .table-list .table td .td-action .btn-transparent {
          padding: 0; }
      .table-list .table td.td-stt .active {
        padding: 0.21429rem 0;
        border: 1px solid #36db1b !important;
        color: #36db1b;
        background-color: #cdf3c9;
        border-radius: 3.57143rem;
        max-width: 80px;
        display: block;
        text-align: center; }
      .table-list .table td.td-stt .inactive {
        padding: 0.21429rem 0;
        border: 1px solid #ea0303 !important;
        color: #ea0303 !important;
        background-color: #dea9a9;
        border-radius: 3.57143rem;
        max-width: 80px;
        display: block;
        text-align: center; }
    .table-list .table tr {
      transition: 0.2s;
      cursor: pointer; }
      .table-list .table tr:hover td {
        transition: 0.2s;
        background-color: var(--gray-color-1); }
    .table-list .table .th-filter {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .table-list .table .th-filter .section-filter {
        position: relative; }
        .table-list .table .th-filter .section-filter .btn-sort {
          margin-right: 0.35714rem; }
        .table-list .table .th-filter .section-filter .icon-dots-vertically {
          vertical-align: middle; }
        .table-list .table .th-filter .section-filter .icon-arr-sort::before {
          font-size: 0.85714rem; }
  .table-list .table-responsive {
    max-height: 570px;
    display: block;
    width: 100%; }
    .table-list .table-responsive .custom-select-custom {
      position: relative;
      margin-right: 0.71429rem;
      width: 100%; }
      .table-list .table-responsive .custom-select-custom .select-filter {
        -webkit-appearance: none;
        height: calc(2.1rem + 2px);
        cursor: pointer; }
      .table-list .table-responsive .custom-select-custom::before {
        position: absolute;
        content: '\e901';
        font-family: 'icomoon' !important;
        font-size: 1.7142857143rem;
        right: 0;
        top: -0.1428571429rem; }
  .table-list .table-above {
    margin: 1.42857rem;
    border-bottom: 0.07143rem solid var(--gray-color-2); }
    .table-list .table-above .table-above__content .row {
      padding: 0.35714rem 0; }
    .table-list .table-above .table-above__content:first-child {
      margin-right: 5rem; }
    .table-list .table-above .title {
      font-weight: 600; }

.drop-box-arr {
  background-color: var(--white);
  box-shadow: 0px 0px 5px var(--gray-color-3);
  border-radius: 0.28571rem;
  transform: scale(0);
  height: 0;
  transition: 0.2s;
  position: absolute;
  right: -0.35714rem;
  top: calc(100% + 5px);
  display: flex;
  margin-bottom: 0.71429rem;
  transform-origin: top; }
  .drop-box-arr::before, .drop-box-arr::after {
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    visibility: visible; }
  .drop-box-arr::before {
    border-width: 0.78571rem;
    border-bottom-color: var(--gray-color-2);
    top: -1.57143rem;
    right: 0.64286rem; }
  .drop-box-arr::after {
    border-width: 0.57143rem;
    border-bottom-color: var(--white);
    top: -1.14286rem;
    right: 0.85714rem; }
  .drop-box-arr.drop-box-button {
    top: calc(100% + 10px); }
    .drop-box-arr.drop-box-button .ul-btn {
      padding: 0;
      margin-bottom: 0; }
      .drop-box-arr.drop-box-button .ul-btn li a {
        color: var(--base-color);
        padding: 0.42857rem 1.78571rem;
        display: block; }
      .drop-box-arr.drop-box-button .ul-btn li:hover, .drop-box-arr.drop-box-button .ul-btn li.active {
        background-color: var(--gray-color-2); }
        .drop-box-arr.drop-box-button .ul-btn li:hover a, .drop-box-arr.drop-box-button .ul-btn li.active a {
          color: var(--primary-color); }
  .drop-box-arr.show {
    transform: scale(1);
    height: auto;
    padding: 0.71429rem;
    transition: 0.2s;
    z-index: 1;
    transform-origin: top; }
  .drop-box-arr .btn {
    margin-right: 0.71429rem;
    padding: 0.28571rem 0.57143rem; }
    .drop-box-arr .btn:last-child {
      margin-right: 0; }
  .drop-box-arr .btn--view {
    padding: 0.28571rem 1.42857rem; }

.drop-box-action {
  right: calc(100% + 20px);
  top: -0.71429rem;
  max-width: 250px;
  transform-origin: right center;
  display: flex;
  flex-wrap: wrap; }
  .drop-box-action.show {
    padding-bottom: 0;
    transform-origin: right center; }
  .drop-box-action .btn {
    margin-bottom: 0.71429rem; }
  .drop-box-action::before, .drop-box-action::after {
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    visibility: visible; }
  .drop-box-action::before {
    border-width: 12px;
    border-left-color: var(--gray-color-2);
    right: -24px;
    top: calc(50% - 12px); }
  .drop-box-action::after {
    border-width: 10px;
    border-left-color: var(--white);
    right: -20px;
    top: calc(50% - 10px); }

.drop-box-filter {
  display: block;
  padding: 1.07143rem;
  right: -0.92857rem;
  top: calc(100% + 10px); }
  .drop-box-filter.show {
    padding: 1.07143rem; }
  .drop-box-filter .form-group {
    min-width: 14.28571rem; }
    .drop-box-filter .form-group .btn-add-form {
      font-weight: 700;
      padding: 0.35714rem 0.71429rem; }
    .drop-box-filter .form-group .form-check {
      width: 50%; }
      .drop-box-filter .form-group .form-check label {
        padding-left: 2.14286rem;
        margin-bottom: 0; }
      .drop-box-filter .form-group .form-check:first-child {
        margin-right: 1.07143rem; }
    .drop-box-filter .form-group .custom-select-saas .select-selected,
    .drop-box-filter .form-group .custom-select-saas .select-items {
      text-transform: none; }
  .drop-box-filter .text-compare {
    text-align: center;
    margin-bottom: 0.57143rem;
    position: relative;
    font-size: 0.85714rem; }
    .drop-box-filter .text-compare::before, .drop-box-filter .text-compare::after {
      content: '';
      position: absolute;
      width: calc(50% - 20px);
      height: 1px;
      background: var(--gray-color-2);
      top: calc(50% - 1px); }
    .drop-box-filter .text-compare::before {
      left: 0; }
    .drop-box-filter .text-compare::after {
      right: 0; }
  .drop-box-filter .add-form .input-group input {
    margin-right: 0.71429rem;
    border-radius: 0.21429rem !important; }
  .drop-box-filter .btn-box {
    border-top: 1px solid var(--gray-color-2);
    padding: 0.71429rem 0 0;
    display: flex;
    justify-content: space-between;
    margin-top: 0.71429rem; }
    .drop-box-filter .btn-box .btn {
      text-transform: uppercase;
      width: 49%;
      font-weight: 700; }

.table-filter {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--gray-color-2);
  border-bottom: none;
  padding: 0.35714rem 0.71429rem;
  position: relative; }
  .table-filter i {
    font-size: 1.28571rem; }
    .table-filter i::before {
      vertical-align: middle; }
  .table-filter .left-filter {
    margin-right: 0.71429rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: auto;
    width: auto; }
    .table-filter .left-filter .btn {
      padding: 0.28571rem 0.42857rem;
      border-radius: 0.21429rem;
      margin-right: 0.71429rem; }
    .table-filter .left-filter span.btn {
      padding-right: 0.71429rem;
      padding-left: 0;
      position: relative;
      cursor: auto; }
      .table-filter .left-filter span.btn .icon-filter-table {
        color: var(--base-color); }
        .table-filter .left-filter span.btn .icon-filter-table::before {
          vertical-align: middle; }
      .table-filter .left-filter span.btn::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: var(--gray-color-2);
        right: 0;
        top: 0; }
    .table-filter .left-filter .custom-select {
      margin-right: 0.71429rem; }
    .table-filter .left-filter .custom-select-custom {
      position: relative;
      margin-right: 0.71429rem;
      width: 11.42857rem; }
      .table-filter .left-filter .custom-select-custom .select-filter {
        -webkit-appearance: none;
        height: calc(2.1rem + 2px);
        cursor: pointer; }
      .table-filter .left-filter .custom-select-custom::before {
        position: absolute;
        content: '\e901';
        font-family: 'icomoon' !important;
        font-size: 1.7142857143rem;
        right: 0;
        top: -0.1428571429rem; }
  .table-filter .right-filter {
    width: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .table-filter .right-filter > .btn {
      margin-right: 0.71429rem; }
    .table-filter .right-filter .btn {
      padding: 0.28571rem 0.42857rem;
      border-radius: 0.21429rem; }
      .table-filter .right-filter .btn.btn-dark {
        padding: 0.17857rem 0.21429rem; }
        .table-filter .right-filter .btn.btn-dark .icon::before {
          font-size: 1.71429rem; }
    .table-filter .right-filter .dropdown-col-button {
      position: relative; }
  .table-filter > .show-filter {
    max-width: 57%;
    margin-right: 0.71429rem;
    overflow: hidden; }
    .table-filter > .show-filter .items-filter:last-child {
      margin-right: 0; }
  .table-filter .show-full-filter {
    position: absolute;
    top: 100%;
    right: 0.71429rem;
    background: var(--white);
    z-index: 2;
    padding: 0.71429rem 0 0 0.71429rem;
    border: 1px solid var(--gray-color-3);
    border-radius: 0.28571rem;
    display: flex;
    flex-wrap: wrap;
    max-width: 90%;
    height: 0;
    transform: scale(0);
    transition: 0.2s;
    transform-origin: top; }
    .table-filter .show-full-filter.show {
      height: auto;
      transform: scale(1);
      transition: 0.2s;
      transform-origin: top; }
    .table-filter .show-full-filter .operator {
      text-transform: uppercase;
      font-size: 0.85714rem; }
    .table-filter .show-full-filter .show-filter {
      margin-right: 0; }
      .table-filter .show-full-filter .show-filter .display-filter {
        flex-wrap: wrap; }
    .table-filter .show-full-filter .items-filter {
      display: flex;
      background-color: var(--gray-color-2);
      margin-bottom: 0.71429rem;
      align-items: center;
      border-radius: 0.28571rem; }
    .table-filter .show-full-filter .text-filter {
      white-space: nowrap; }
  .table-filter .show-filter {
    display: flex;
    justify-content: space-between; }
    .table-filter .show-filter .display-filter {
      display: flex;
      align-items: center; }

.items-filter {
  margin-right: 0.71429rem; }

.item-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.35714rem;
  border-radius: 0.21429rem;
  background-color: var(--gray-color-2); }
  .item-filter .btn-show-filter {
    padding: 0 1rem 0 0.28571rem !important;
    font-size: 0.85714rem;
    text-transform: uppercase;
    position: relative; }
    .item-filter .btn-show-filter::after {
      content: '+';
      position: absolute;
      right: 0.21429rem; }
  .item-filter .alert-filter {
    display: flex;
    justify-content: space-between;
    background-color: var(--white);
    padding: 0 0 0 0.35714rem;
    border-radius: 0.28571rem;
    border: 1px solid var(--gray-color-3);
    line-height: 18px;
    font-size: 0.85714rem;
    margin-right: 0.35714rem; }
    .item-filter .alert-filter .icon {
      font-size: 1.14286rem;
      line-height: 1; }

.table-paging {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.07143rem; }
  .table-paging .title-paging {
    margin-right: 0.71429rem; }
  .table-paging .form-group {
    margin-bottom: 0;
    max-width: 5.71429rem;
    margin-bottom: 0;
    min-width: 3.92857rem;
    width: 100%;
    margin-right: 0.71429rem; }
  .table-paging .pagination {
    margin-bottom: 0; }
  .table-paging .paging-detail {
    padding: 0 0.71429rem;
    margin-left: 0.71429rem;
    position: relative; }
    .table-paging .paging-detail::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: var(--gray-color-2);
      left: 0; }

.box-opt {
  min-width: 220px; }
  .box-opt .title {
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    padding: 1.07143rem;
    padding-bottom: 0.35714rem;
    margin-bottom: 0; }
  .box-opt .items-opt {
    max-height: 400px;
    overflow-y: auto; }
  .box-opt ul {
    padding-left: 0;
    margin-bottom: 0; }
    .box-opt ul li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.71429rem 1.07143rem; }
      .box-opt ul li .icon {
        cursor: all-scroll; }
      .box-opt ul li .form-check label {
        margin-bottom: 0; }
      .box-opt ul li:hover {
        background-color: var(--gray-color-2); }

.th-col-opt {
  position: relative; }
  .th-col-opt .drop-col-opt {
    padding: 0;
    right: -0.21429rem;
    top: calc(100% + 8px); }

.pagination .page-item {
  margin-right: 0.5rem;
  text-align: center; }
  .pagination .page-item:last-child {
    margin-right: 0; }
  .pagination .page-item.disabled .page-link {
    background-color: var(--gray-color-2);
    opacity: 0.5; }
  .pagination .page-item.active .page-link {
    background-color: var(--primary-color);
    color: var(--white);
    border-color: var(--primary-color); }
  .pagination .page-item .page-link {
    color: var(--base-color);
    font-size: 0.85714rem;
    background-color: transparent;
    border-color: var(--gray-color-3);
    border-radius: 0.21429rem;
    min-width: 24px;
    width: auto;
    height: 24px;
    line-height: 1.2;
    position: relative; }
    .pagination .page-item .page-link:focus {
      outline: none;
      box-shadow: none; }
    .pagination .page-item .page-link .icon {
      font-size: 19px;
      position: absolute;
      top: 0;
      left: 0.14286rem; }

.search-box .input-group .input-group-prepend button {
  border: none;
  background-color: transparent;
  padding: 0; }
  .search-box .input-group .input-group-prepend button::before {
    font-size: 1.64286rem;
    opacity: 0.7; }

.search-box .input-group .form-control {
  border: none; }

.btn-sidebar {
  min-height: 60px;
  max-height: 60px;
  min-width: 70px;
  border: none;
  border-radius: 0; }
  .btn-sidebar img {
    width: auto;
    max-width: 19px;
    position: relative;
    top: calc(50% - 7px);
    transform: translate(0, -50%); }

.collapse-saas {
  margin-bottom: 0.71429rem;
  box-shadow: 0rem 0.07143rem 0.14286rem var(--gray-color-3); }
  .collapse-saas .collapsible {
    color: var(--base-color);
    cursor: pointer;
    padding: 1.07143rem 1.42857rem;
    background-color: var(--gray-color-2);
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    position: relative; }
    .collapse-saas .collapsible::after {
      content: '\e901';
      float: right;
      font-family: 'icomoon' !important;
      font-size: 1.78571rem;
      padding: 0;
      line-height: 1;
      transition: 0.5s; }
    .collapse-saas .collapsible.active::after {
      transform: rotate(180deg);
      transition: 0.5s; }
  .collapse-saas .content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
    background-color: var(--white); }
    .collapse-saas .content .info-content {
      padding: 1.42857rem; }

.container-modal {
  position: relative;
  z-index: 1111; }

.modalSaas {
  display: flex;
  overflow: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.25s; }
  .modalSaas__dialog {
    position: relative;
    max-width: 500px;
    margin: auto;
    border: 1px solid var(--gray-color-3);
    border-radius: 0.21429rem;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 3.5rem); }
  .modalSaas .modalSaas__dialog.consent {
    max-width: none;
    width: 90%; }
  .modalSaas__dark {
    background-color: var(--gray-color-2); }
  .modalSaas__btn--close .modalSaas__close {
    top: -0.92857rem;
    right: -0.92857rem;
    width: 2.5rem;
    height: 2.5rem;
    background-color: var(--primary-color);
    border-radius: 50%;
    color: var(--white); }
  .modalSaas__close {
    position: absolute;
    top: 1rem;
    right: 1.42857rem;
    padding: 0;
    border: none;
    color: var(--base-color);
    background-color: transparent;
    background-image: none;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    font-size: 2.14286rem;
    cursor: pointer; }
    .modalSaas__close:focus {
      outline: 0; }
    .modalSaas__close::before {
      content: '\e907'; }
  .modalSaas__header {
    border-bottom: 1px solid var(--gray-color-3); }
  .modalSaas__title {
    font-weight: 700;
    text-transform: uppercase;
    padding: 1.42857rem;
    display: block; }
  .modalSaas__content {
    padding: 1.42857rem;
    overflow-y: scroll;
    /* width */
    /* Handle */
    /* Handle on hover */ }
    .modalSaas__content::-webkit-scrollbar {
      width: 7px; }
    .modalSaas__content::-webkit-scrollbar-thumb {
      background: var(--gray-color-4);
      border-radius: 10px;
      transition: 0.2s; }
    .modalSaas__content::-webkit-scrollbar-thumb:hover {
      background: var(--dark-color);
      transition: 0.2s; }
    .modalSaas__content p:last-child {
      margin-bottom: 0; }
  .modalSaas__row--btn {
    display: flex;
    justify-content: center;
    padding: 1.07143rem 1.07143rem 0; }
    .modalSaas__row--btn .btn {
      min-width: 10rem;
      margin-right: 1.78571rem; }
      .modalSaas__row--btn .btn:last-child {
        margin-right: 0; }
  .modalSaas__footer {
    padding: 1.42857rem;
    border-top: 1px solid var(--gray-color-3); }
  .modalSaas.is-modal-active {
    display: flex;
    background-color: rgba(0, 0, 0, 0.67);
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
    transition: 0.25s; }

.modalSaas--fullscreen {
  padding: 5px; }
  .modalSaas--fullscreen .modalSaas__dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    border-radius: 0; }

.rounded-top-left {
  border-top-left-radius: 0.28571rem !important; }

.rounded-top-right {
  border-top-right-radius: 0.28571rem !important; }

.multi-box span.icon {
  cursor: pointer;
  position: relative; }
  .multi-box span.icon::before {
    font-size: 1.64286rem;
    left: -2.5rem;
    top: -0.14286rem;
    position: absolute; }
  .multi-box span.icon::after {
    content: '\e901';
    position: absolute;
    right: -1.78571rem;
    top: -0.21429rem;
    font-family: 'icomoon' !important;
    font-size: 1.64286rem;
    transition: 0.2s; }
  .multi-box span.icon.icon-active::after {
    transform: rotate(180deg);
    transition: 0.2s; }

.multi-box .dropdown-content {
  visibility: hidden;
  opacity: 0;
  transition: 0.2s;
  padding-left: 0;
  background-color: rgba(255, 255, 255, 0.12);
  border: 1px solid var(--white);
  text-align: left;
  border-radius: 0.21429rem; }
  .multi-box .dropdown-content.show {
    visibility: visible;
    opacity: 1;
    transition: 0.2s; }
  .multi-box .dropdown-content li:hover {
    background-color: rgba(255, 255, 255, 0.12); }
  .multi-box .dropdown-content li a {
    padding: 1.07143rem;
    display: block; }

.input-upload {
  display: flex; }
  .input-upload .input-file {
    position: relative;
    max-width: 450px;
    width: 100%;
    margin-right: 1.42857rem; }
    .input-upload .input-file::after {
      content: '';
      position: absolute;
      background: url(../images/ico-upload.png) no-repeat;
      width: 28px;
      height: 28px;
      top: 0.28571rem;
      right: 0.28571rem; }
  .input-upload .btn-browse {
    min-width: 140px;
    padding: 0.28571rem; }
  .input-upload .fileUpload {
    position: relative;
    overflow: hidden;
    font-weight: 700; }
    .input-upload .fileUpload input.upload {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
      font-size: 20px;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0); }

[tool-tip] {
  position: relative; }
  [tool-tip]::before, [tool-tip]::after {
    text-transform: none;
    font-size: 0.85714rem;
    line-height: 1;
    user-select: none;
    pointer-events: none;
    position: absolute;
    display: none;
    opacity: 0; }
  [tool-tip]::before {
    content: '';
    border: 5px solid transparent;
    z-index: 1001; }
  [tool-tip]::after {
    content: attr(tool-tip);
    font-family: Helvetica, sans-serif;
    text-align: center;
    min-width: 2.85714rem;
    max-width: 25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.5rem;
    border-radius: 0.3ch;
    box-shadow: 0 0.9rem 1.8rem -0.45rem rgba(0, 0, 0, 0.35);
    background: #333;
    color: var(--white);
    z-index: 1000; }
  [tool-tip]:hover::before, [tool-tip]:hover::after {
    display: block; }

[tool-tip='']::before, [tool-tip='']::after {
  display: none !important; }

[tool-tip]:not([data-flow])::before, [tool-tip][data-flow^='up']::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333; }

[tool-tip]:not([data-flow])::after, [tool-tip][data-flow^='up']::after {
  bottom: calc(100% + 5px); }

[tool-tip]:not([data-flow])::before, [tool-tip]:not([data-flow])::after {
  left: 50%;
  transform: translate(-50%, -0.5em); }

[tool-tip][data-flow^='up']::before, [tool-tip][data-flow^='up']::after {
  left: 50%;
  transform: translate(-50%, -0.5em); }

[tool-tip][data-flow^='down']::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333; }

[tool-tip][data-flow^='down']::after {
  top: calc(100% + 5px); }

[tool-tip][data-flow^='down']::before, [tool-tip][data-flow^='down']::after {
  left: 50%;
  transform: translate(-50%, 0.5em); }

[tool-tip][data-flow^='left']::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-0.5em, -50%); }

[tool-tip][data-flow^='left']::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-0.5em, -50%); }

[tool-tip][data-flow^='right']::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(0.5em, -50%); }

[tool-tip][data-flow^='right']::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(0.5em, -50%); }

[tool-tip]:not([data-flow]):hover::before, [tool-tip]:not([data-flow]):hover::after {
  animation: tooltips-vert 300ms ease-out forwards; }

[tool-tip][data-flow^='up']:hover::before, [tool-tip][data-flow^='up']:hover::after {
  animation: tooltips-vert 300ms ease-out forwards; }

[tool-tip][data-flow^='down']:hover::before, [tool-tip][data-flow^='down']:hover::after {
  animation: tooltips-vert 300ms ease-out forwards; }

[tool-tip][data-flow^='left']:hover::before, [tool-tip][data-flow^='left']:hover::after {
  animation: tooltips-horz 300ms ease-out forwards; }

[tool-tip][data-flow^='right']:hover::before, [tool-tip][data-flow^='right']:hover::after {
  animation: tooltips-horz 300ms ease-out forwards; }

@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 0); } }

@keyframes tooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(0, -50%); } }

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: rgba(0, 0, 0, 0.25); }

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--gray-color-4);
  border-radius: 0.28571rem;
  transition: 0.2s; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--dark-color);
  transition: 0.2s; }

.filter-sidebar {
  bottom: 0;
  position: fixed;
  top: 0;
  transition: all 0.2s ease;
  width: 0;
  z-index: 5;
  box-shadow: -0.14286rem 0rem 0.21429rem var(--gray-color-3);
  background-color: var(--white);
  right: 0; }
  .filter-sidebar .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid var(--gray-color-2);
    padding: 0 1.42857rem; }
    .filter-sidebar .filter-header h3 {
      margin-bottom: 0;
      text-transform: uppercase; }
    .filter-sidebar .filter-header i.icon {
      font-size: 24px;
      font-weight: 700;
      cursor: pointer; }
  .filter-sidebar .filter-content {
    min-height: calc(100vh - 160px);
    padding: 1.42857rem;
    overflow-y: scroll;
    height: calc(100vh - 160px); }
    .filter-sidebar .filter-content input.form-control::-webkit-input-placeholder {
      font-style: normal; }
    .filter-sidebar .filter-content input.form-control::-moz-placeholder {
      font-style: normal; }
    .filter-sidebar .filter-content input.form-control:-ms-input-placeholder {
      font-style: normal; }
    .filter-sidebar .filter-content input.form-control:-moz-placeholder {
      font-style: normal; }
  .filter-sidebar .filter-footer {
    border-top: 1px solid var(--gray-color-2);
    height: 80px;
    display: flex;
    padding: 0 1.42857rem; }
    .filter-sidebar .filter-footer .btn-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .filter-sidebar .filter-footer .btn-box .btn {
        width: calc(50% - 10px);
        text-transform: uppercase;
        font-weight: 700; }
  .filter-sidebar .btn-show-filter {
    position: absolute;
    top: 80px;
    width: 40px;
    height: 40px;
    left: -40px;
    border-radius: 0.28571rem 0 0 0.28571rem; }
  .filter-sidebar.collapsed-filter {
    width: 290px; }

.custom-select-saas {
  position: relative;
  font-family: Arial; }
  .custom-select-saas select {
    display: none; }
  .custom-select-saas .select-selected {
    border-radius: 0.28571rem; }
    .custom-select-saas .select-selected:after {
      position: absolute;
      content: '\e901';
      font-family: 'icomoon' !important;
      font-size: 1.71429rem;
      right: 0;
      top: -0.14286rem; }
    .custom-select-saas .select-selected.select-arrow-active {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
      .custom-select-saas .select-selected.select-arrow-active:after {
        content: '\e904'; }
  .custom-select-saas .select-items div,
  .custom-select-saas .select-selected {
    color: var(--base-color);
    font-size: 0.85714rem;
    padding: 0.42857rem 0.75rem;
    cursor: pointer;
    border: 1px solid var(--gray-color-3);
    user-select: none; }
  .custom-select-saas .select-items div {
    border-top: none; }
    .custom-select-saas .select-items div:last-child {
      border-bottom-left-radius: 0.28571rem;
      border-bottom-right-radius: 0.28571rem; }
  .custom-select-saas .select-items {
    position: absolute;
    background-color: var(--white);
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99; }
  .custom-select-saas .select-hide {
    display: none; }
  .custom-select-saas .select-items div:hover,
  .custom-select-saas .same-as-selected {
    background-color: var(--gray-color-2); }

.dragDropTable {
  display: flex; }
  .dragDropTable .dragDropTable-available,
  .dragDropTable .dragDropTable-selected {
    width: 40%;
    height: 21rem;
    border: 0.07143rem solid var(--gray-color-2); }
  .dragDropTable .dragDropTable-available {
    margin-right: 2.85714rem;
    position: relative; }
  .dragDropTable .dragDropTable-selected {
    position: relative; }
  .dragDropTable .dragDropTable__tile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.71429rem;
    background: var(--white);
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    border-bottom: 0.07143rem solid var(--gray-color-2); }
  .dragDropTable .dragDropMain {
    background: var(--gray-color-2);
    position: absolute;
    top: 3rem;
    left: 0;
    width: 100%;
    height: 18rem; }
    .dragDropTable .dragDropMain .dragDrop-item {
      width: 100%;
      padding: 0.71429rem;
      background: var(--white);
      border-bottom: 0.07143rem solid var(--gray-color-2);
      cursor: all-scroll;
      display: flex;
      justify-content: space-between; }
      .dragDropTable .dragDropMain .dragDrop-item.active {
        background: var(--gray-color-2);
        border-left: 0.21429rem solid var(--primary-color); }

@media (max-width: 1023px) {
  .table-filter {
    flex-wrap: wrap;
    justify-content: flex-end; }
    .table-filter .left-filter {
      margin-bottom: 0.71429rem; }
    .table-filter > .show-filter {
      max-width: calc(100% - 80px); } }

@media (max-width: 767px) {
  .hidden-mb {
    display: none; }
  .tabs .nav-tabs .nav-item {
    width: 50%; }
    .tabs .nav-tabs .nav-item:last-child {
      padding-right: 0; }
    .tabs .nav-tabs .nav-item .nav-link {
      text-align: center; }
  .tabs .tab-content {
    border-radius: 0 0 0.28571rem 0.28571rem; }
  .modalSaas__dialog {
    max-width: 90%; }
  .modalSaas__row--btn .btn {
    width: auto;
    min-width: 6.42857rem; }
  .filter-sidebar.collapsed-filter {
    width: 100%; }
  .table-paging {
    flex-wrap: wrap; }
    .table-paging .title-paging {
      order: 1; }
    .table-paging .form-group {
      order: 2;
      margin-right: 0; }
    .table-paging .pagination {
      margin-bottom: 1.07143rem;
      margin-right: 0.71429rem;
      justify-content: flex-end;
      width: 100%; }
    .table-paging .paging-detail {
      order: 3; }
  .table-filter {
    padding: 0.71429rem; }
    .table-filter .left-filter,
    .table-filter .show-filter,
    .table-filter .right-filter {
      max-width: 100%;
      width: 100%; }
    .table-filter > .show-filter {
      flex-wrap: wrap; }
      .table-filter > .show-filter .display-filter {
        flex-wrap: wrap;
        max-height: 120px;
        overflow: hidden; }
        .table-filter > .show-filter .display-filter .items-filter {
          margin-bottom: 0.71429rem; }
    .table-filter .right-filter {
      justify-content: flex-start; }
    .table-filter .show-full-filter {
      width: 100%;
      max-width: 100%; }
      .table-filter .show-full-filter .show-filter .display-filter .items-filter {
        flex-wrap: wrap; } }

#target {
  padding: 1.5em;
  margin: 1.5em;
  background-color: #eee; }

html {
  font-size: 14px; }

.container-guide .title-guide {
  margin-bottom: 1.78571rem; }

.container-guide .style-guide {
  padding: 5.71429rem 4.64286rem; }
  .container-guide .style-guide .title-guide {
    text-transform: uppercase;
    font-weight: 800; }
  .container-guide .style-guide .namming {
    margin-bottom: 3.21429rem; }
    .container-guide .style-guide .namming h1 {
      font-size: 48px;
      font-size: 3.42857rem;
      font-weight: 800;
      margin-bottom: 1.78571rem; }
  .container-guide .style-guide .colour {
    margin-bottom: 6.42857rem; }
    .container-guide .style-guide .colour__info {
      margin-bottom: 1.42857rem; }
      .container-guide .style-guide .colour__info p {
        margin-bottom: 0.35714rem; }
    .container-guide .style-guide .colour__block {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .container-guide .style-guide .colour__block .box-color {
        position: relative;
        width: 10%;
        padding-bottom: 10%; }
        .container-guide .style-guide .colour__block .box-color.bg-white {
          border: 1px solid var(--gray-color-2); }
        .container-guide .style-guide .colour__block .box-color span {
          position: absolute;
          bottom: -30px;
          left: 50%;
          transform: translate(-50%, 0);
          text-transform: uppercase; }
  .container-guide .style-guide .section-common {
    margin-bottom: 4.28571rem; }
    .container-guide .style-guide .section-common .icons__ex {
      display: flex;
      flex-wrap: wrap;
      margin-top: 2.28571rem; }
      .container-guide .style-guide .section-common .icons__ex [class^='icon-'],
      .container-guide .style-guide .section-common .icons__ex [class*=' icon-'] {
        font-size: 2.35714rem;
        padding-right: 1.21429rem;
        padding-bottom: 2.14286rem; }
  .container-guide .style-guide .typography__fonts {
    color: var(--dark-color);
    margin-top: 2.5rem; }
    .container-guide .style-guide .typography__fonts .typography-row {
      margin-bottom: 2.85714rem; }
      .container-guide .style-guide .typography__fonts .typography-row p {
        font-weight: 300;
        margin-top: 2.14286rem; }
  .container-guide .style-guide .typography__detail {
    color: var(--dark-color);
    border-top: 1px solid var(--gray-color-2);
    padding-top: 2.85714rem; }
    .container-guide .style-guide .typography__detail p {
      margin-bottom: 2.14286rem; }
      .container-guide .style-guide .typography__detail p:last-child {
        margin-bottom: 0; }
  .container-guide .style-guide .buttons__ex {
    margin-top: 2.14286rem; }
    .container-guide .style-guide .buttons__ex .row-btn {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4.64286rem; }
      .container-guide .style-guide .buttons__ex .row-btn .btn {
        position: relative;
        font-weight: 700;
        text-transform: uppercase;
        min-width: 140px;
        letter-spacing: 2.25px; }
        .container-guide .style-guide .buttons__ex .row-btn .btn.btn-primary.hover {
          background-color: transparent;
          color: var(--primary-color); }
        .container-guide .style-guide .buttons__ex .row-btn .btn.btn-primary.active {
          background-color: var(--primary-color-darken);
          border-color: var(--primary-color-darken); }
        .container-guide .style-guide .buttons__ex .row-btn .btn.btn-primary:active {
          background: var(--primary-color-darken) !important;
          border-color: var(--primary-color-darken) !important; }
        .container-guide .style-guide .buttons__ex .row-btn .btn.btn-secondary.hover {
          background-color: transparent;
          color: var(--secondary-color); }
        .container-guide .style-guide .buttons__ex .row-btn .btn.btn-secondary.active {
          background-color: var(--secondary-color-darken);
          border-color: var(--secondary-color-darken); }
        .container-guide .style-guide .buttons__ex .row-btn .btn.btn-secondary:active {
          background: var(--secondary-color-darken) !important;
          border-color: var(--secondary-color-darken) !important; }
        .container-guide .style-guide .buttons__ex .row-btn .btn.btn-dark.hover {
          background-color: transparent;
          color: var(--dark-color); }
        .container-guide .style-guide .buttons__ex .row-btn .btn.btn-dark.active {
          background-color: var(--dark-color-darken);
          border-color: var(--dark-color-darken); }
        .container-guide .style-guide .buttons__ex .row-btn .btn.btn-dark:active {
          background: var(--dark-color-darken) !important;
          border-color: var(--dark-color-darken) !important; }
        .container-guide .style-guide .buttons__ex .row-btn .btn span {
          position: absolute;
          bottom: -30px;
          color: var(--dark-color);
          left: 50%;
          transform: translate(-50%, 0);
          text-transform: capitalize;
          font-weight: 400;
          font-size: 1.28571rem; }
  .container-guide .style-guide .section-info {
    margin-bottom: 2.5rem; }
    .container-guide .style-guide .section-info .links-tabs > p {
      margin-bottom: 1.92857rem; }
    .container-guide .style-guide .section-info .links-tabs .links {
      display: flex;
      margin-bottom: 1.92857rem; }
      .container-guide .style-guide .section-info .links-tabs .links a {
        padding-right: 2.5rem; }
        .container-guide .style-guide .section-info .links-tabs .links a.link-active {
          padding-right: 0; }
      .container-guide .style-guide .section-info .links-tabs .links .link-hover,
      .container-guide .style-guide .section-info .links-tabs .links .link-active {
        color: var(--secondary-color); }
    .container-guide .style-guide .section-info .links-tabs .tabs p {
      margin-bottom: 0; }
      .container-guide .style-guide .section-info .links-tabs .tabs p:first-child {
        margin-bottom: 2.14286rem; }
    .container-guide .style-guide .section-info .alerts__title {
      margin-bottom: 2rem; }
    .container-guide .style-guide .section-info .alerts__boxs .alert {
      margin-bottom: 1.64286rem; }
      .container-guide .style-guide .section-info .alerts__boxs .alert:last-child {
        margin-bottom: 0.71429rem; }
    .container-guide .style-guide .section-info .alerts__text .alert {
      margin-bottom: 0; }
  .container-guide .style-guide .section-form {
    margin-bottom: 2.5rem; }
    .container-guide .style-guide .section-form .row-check {
      margin-top: 0.71429rem; }
    .container-guide .style-guide .section-form .form-check label {
      margin-bottom: 1.78571rem; }
    .container-guide .style-guide .section-form #select-demo {
      width: 100%; }
  .container-guide .style-guide .section-table {
    margin-bottom: 2.5rem; }
    .container-guide .style-guide .section-table p {
      margin-bottom: 0; }
    .container-guide .style-guide .section-table .table-responsive-lg {
      margin-top: 2.14286rem; }
  .container-guide .style-guide .section-modal .modals__ex {
    margin-top: 2.14286rem; }
    .container-guide .style-guide .section-modal .modals__ex .btn {
      margin-right: 1.78571rem; }

@media (min-width: 1200px) {
  .container-guide {
    max-width: 1300px; }
  .page-container header .header__search {
    min-width: 21.43em; }
  .is-collapsed .page-container header .header__search {
    min-width: 35.71em; } }

@media (max-width: 1260px) {
  .container-guide .style-guide .buttons__ex .row-btn {
    flex-wrap: wrap;
    justify-content: normal;
    margin-bottom: 1.78571rem; }
    .container-guide .style-guide .buttons__ex .row-btn:last-child {
      margin-bottom: 0; }
    .container-guide .style-guide .buttons__ex .row-btn .btn {
      letter-spacing: 0.25px;
      margin-bottom: 2.5rem;
      margin-right: 1.07143rem; }
      .container-guide .style-guide .buttons__ex .row-btn .btn span {
        font-size: 1rem; } }

@media (max-width: 1200px) {
  .container-guide .style-guide {
    padding: 2.85714rem 0; }
    .container-guide .style-guide .colour {
      margin-bottom: 2.5rem; }
      .container-guide .style-guide .colour__block {
        justify-content: normal; }
        .container-guide .style-guide .colour__block .box-color {
          margin-right: 1.07143rem;
          margin-bottom: 3.21429rem;
          width: 16%;
          padding-bottom: 16%; }
    .container-guide .style-guide .section-common {
      margin-bottom: 2.5rem; }
      .container-guide .style-guide .section-common .typography {
        margin-bottom: 2.5rem; }
      .container-guide .style-guide .section-common .icons {
        margin-bottom: 2.5rem; }
    .container-guide .style-guide .section-info .links-tabs {
      margin-bottom: 2.5rem; }
    .container-guide .style-guide .section-modal .modals__ex .btn {
      margin-bottom: 1.07143rem; } }

.sidebar-old {
  background-color: var(--gray-color-1);
  width: 300px;
  bottom: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 1000;
  color: var(--black); }
  .sidebar-old::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100vh;
    background-color: var(--gray-color-3);
    left: 4.92857rem; }
  .sidebar-old__toggle {
    display: flex; }
  .sidebar-old .sidebar__icon ul.sidebar__content {
    padding-left: 0; }
    .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown {
      cursor: pointer; }
      .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu .dropdown:hover, .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu .dropdown:active, .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu .dropdown:focus,
      .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown .dropdown-menu .dropdown-item:hover,
      .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown .dropdown-menu .dropdown-item:active,
      .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown .dropdown-menu .dropdown-item:focus {
        background-color: var(--bg-row-1);
        color: var(--primary-color); }
      .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown,
      .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown-item {
        padding-left: 1.42857rem; }
        .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown > span,
        .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown-item > span {
          position: relative;
          padding-left: 2.14286rem; }
          .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown > span::before,
          .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown-item > span::before {
            position: absolute;
            left: 0;
            bottom: 0; }
      .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown::after {
        content: '\e901';
        position: absolute;
        font-family: 'icomoon' !important;
        font-size: 1.64286rem;
        right: 1.42857rem;
        transition: all 0.2s ease; }
      .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown.show {
        background-color: var(--bg-row-1);
        color: var(--primary-color); }
        .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown.show::after {
          transition: all 0.2s ease;
          transform: rotate(180deg); }
        .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown.show > .dropdown-menu {
          width: calc(100% + 20px);
          top: 0;
          left: -1.42857rem; }
          .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown.show > .dropdown-menu > .dropdown-item {
            padding-left: 5rem;
            padding-right: 1.07143rem; }
            .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown.show > .dropdown-menu > .dropdown-item span {
              position: relative; }
              .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown.show > .dropdown-menu > .dropdown-item span::before {
                position: absolute;
                left: -2.14286rem;
                bottom: 0; }
          .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown.show > .dropdown-menu > .dropdown > span {
            position: relative;
            padding-left: 5rem; }
            .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown.show > .dropdown-menu > .dropdown > span::before {
              position: absolute;
              left: 2.85714rem;
              bottom: 0; }
          .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown.show > .dropdown-menu > .dropdown > .dropdown-menu {
            background-color: var(--gray-color-1);
            width: 100%; }
            .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown.show > .dropdown-menu > .dropdown > .dropdown-menu .dropdown-item {
              padding-left: 5rem;
              padding-right: 1.07143rem; }
              .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown.show > .dropdown-menu > .dropdown > .dropdown-menu .dropdown-item > span {
                position: relative; }
                .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown.show > .dropdown-menu > .dropdown > .dropdown-menu .dropdown-item > span::before {
                  position: absolute;
                  left: -2.14286rem;
                  bottom: 0; }
      .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown .icon {
        width: 70px;
        height: 70px; }
        .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown .icon:hover {
          background-color: var(--gray-color-3); }
      .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown .dropdown-menu {
        margin: 0;
        padding: 0;
        border-radius: 0;
        color: var(--black);
        border: 0; }
        .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown .dropdown-menu.active {
          position: fixed;
          top: 5.71429rem;
          left: 5rem;
          width: 270px;
          height: calc(100vh - 80px);
          display: block;
          float: none; }
        .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown .dropdown-menu .dropdown-item {
          color: var(--black); }
        .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown .dropdown-menu .sidebar__title--active {
          text-transform: uppercase;
          color: var(--base-color);
          font-weight: 700;
          font-size: 0.78571rem;
          margin-bottom: 0;
          border-top: 1px solid var(--gray-color-2);
          border-bottom: 1px solid var(--gray-color-2);
          height: 70px;
          line-height: 70px;
          padding-left: 1.42857rem;
          cursor: context-menu; }
    .sidebar-old .sidebar__icon ul.sidebar__content > li.dropdown.active > .icon {
      background-color: var(--white);
      border-top: 1px solid var(--gray-color-2);
      border-bottom: 1px solid var(--gray-color-2); }
    .sidebar-old .sidebar__icon ul.sidebar__content > li.dropdown.active > .dropdown-menu {
      position: fixed;
      top: 5.71429rem;
      left: 5rem;
      width: 270px;
      height: calc(100vh - 80px);
      display: block;
      float: none; }
      .sidebar-old .sidebar__icon ul.sidebar__content > li.dropdown.active > .dropdown-menu::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 100vh;
        top: -5.71429rem;
        right: 0;
        box-shadow: 0.14286rem 0rem 0.21429rem var(--gray-color-3); }
      .sidebar-old .sidebar__icon ul.sidebar__content > li.dropdown.active > .dropdown-menu > .dropdown,
      .sidebar-old .sidebar__icon ul.sidebar__content > li.dropdown.active > .dropdown-menu .dropdown-item {
        height: 70px;
        line-height: 5rem; }
        .sidebar-old .sidebar__icon ul.sidebar__content > li.dropdown.active > .dropdown-menu > .dropdown span::before,
        .sidebar-old .sidebar__icon ul.sidebar__content > li.dropdown.active > .dropdown-menu .dropdown-item span::before {
          font-size: 1.42857rem; }
      .sidebar-old .sidebar__icon ul.sidebar__content > li.dropdown.active > .dropdown-menu > .show > .dropdown-menu {
        display: block;
        position: relative; }
        .sidebar-old .sidebar__icon ul.sidebar__content > li.dropdown.active > .dropdown-menu > .show > .dropdown-menu .show {
          position: relative; }
  .sidebar-old .sidebar__logo {
    width: 100%;
    background-color: var(--white);
    transition: all 0.1s ease;
    line-height: 80px; }
    .sidebar-old .sidebar__logo img {
      position: relative;
      left: 1.42857rem; }
  .sidebar-old .sidebar__content > .dropdown .icon {
    font-size: 2.28571rem;
    text-align: center;
    line-height: 70px; }
    .sidebar-old .sidebar__content > .dropdown .icon::before {
      color: var(--black); }

.is-collapsed .page-container {
  padding-left: 60px; }
  .is-collapsed .page-container header {
    width: calc(100% - 60px); }
    .is-collapsed .page-container header .header__logo {
      display: block; }

.is-collapsed .dropdown > .dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/ }

.is-collapsed .sidebar-old {
  width: 70px;
  background-color: var(--primary-color); }
  .is-collapsed .sidebar-old::before {
    z-index: 1; }
  .is-collapsed .sidebar-old .sidebar__logo {
    width: 0;
    transition: all 0.2s ease; }
  .is-collapsed .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown:hover > .dropdown-menu {
    display: block !important;
    position: fixed;
    top: 80px;
    left: 70px;
    min-height: calc(100vh - 80px);
    min-width: 270px; }
  .is-collapsed .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown:hover .icon {
    background-color: var(--primary-color-darken); }
  .is-collapsed .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown .dropdown-menu {
    display: none; }
  .is-collapsed .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .icon {
    background-color: var(--primary-color);
    border-top: 0;
    border-bottom: 0; }
    .is-collapsed .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .icon::before {
      color: var(--white); }
  .is-collapsed .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu {
    position: fixed;
    top: 5.71429rem;
    left: 5rem;
    width: 270px;
    height: calc(100vh - 80px);
    float: none; }
    .is-collapsed .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 100vh;
      top: -5.71429rem;
      right: 0;
      box-shadow: 0.14286rem 0rem 0.21429rem var(--gray-color-3); }
    .is-collapsed .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown,
    .is-collapsed .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu .dropdown-item {
      height: 70px;
      line-height: 5rem; }
      .is-collapsed .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .dropdown span::before,
      .is-collapsed .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu .dropdown-item span::before {
        font-size: 1.42857rem; }
    .is-collapsed .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .show > .dropdown-menu {
      display: block;
      position: relative; }
      .is-collapsed .sidebar-old .sidebar__icon ul.sidebar__content li.dropdown > .dropdown-menu > .show > .dropdown-menu .show {
        position: relative;
        display: block; }

.page-container {
  min-height: 100vh;
  padding-left: 260px;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease; }
  .page-container header {
    position: fixed;
    width: calc(100% - 260px);
    background: var(--white);
    z-index: 4;
    transition: all 0.2s ease; }
    .page-container header .header {
      height: 4.28571rem;
      display: flex;
      justify-content: space-between;
      padding-left: 1.42857rem;
      padding-right: 2.14286rem;
      border-bottom: 1px solid var(--gray-color-2); }
      .page-container header .header .header__btn {
        display: none; }
      .page-container header .header__left, .page-container header .header__right {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
        position: relative; }
        .page-container header .header__left > li > a, .page-container header .header__right > li > a {
          display: block;
          line-height: 4.28571rem;
          min-height: 4.28571rem;
          transition: all 0.2s ease-in-out; }
      .page-container header .header__left {
        display: flex; }
        .page-container header .header__left .header__search {
          padding: 0 15px;
          align-self: center; }
      .page-container header .header__logo {
        min-width: 18.57em; }
      .page-container header .header__right {
        display: flex; }
        .page-container header .header__right .multi-box > span.icon {
          line-height: 4.28571rem; }
          .page-container header .header__right .multi-box > span.icon::before, .page-container header .header__right .multi-box > span.icon::after {
            line-height: 1; }
        .page-container header .header__right .multi-box .dropdown-content {
          width: 100%;
          position: absolute;
          z-index: 1;
          background-color: var(--white);
          border: 1px solid var(--gray-color-1);
          box-shadow: 0rem 0.07143rem 0.07143rem 0rem var(--gray-color-1);
          right: 0;
          top: 4.21429rem; }
          .page-container header .header__right .multi-box .dropdown-content li {
            text-align: center; }
            .page-container header .header__right .multi-box .dropdown-content li:hover {
              background-color: var(--bg-row-1); }
            .page-container header .header__right .multi-box .dropdown-content li a {
              line-height: 1;
              padding: 1.07143rem 0;
              text-align: left; }
              .page-container header .header__right .multi-box .dropdown-content li a i.icon {
                margin-right: 0.71429rem; }
                .page-container header .header__right .multi-box .dropdown-content li a i.icon::before {
                  font-size: 1.42857rem;
                  vertical-align: middle; }
        .page-container header .header__right li {
          padding: 0 1.42857rem; }
          .page-container header .header__right li a {
            color: var(--base-color); }
          .page-container header .header__right li.header__languages {
            line-height: 4.28571rem;
            padding: 0 2.85714rem 0 3.57143rem;
            border-left: 1px solid var(--gray-color-2);
            border-right: 1px solid var(--gray-color-2);
            position: relative;
            font-size: 0.92857rem; }
            .page-container header .header__right li.header__languages .multi-box .icon-global-ico:before {
              top: 0;
              font-size: 1.42857rem; }
          .page-container header .header__right li.header__notifications {
            border-right: 1px solid var(--gray-color-2);
            padding: 0 30px; }
            .page-container header .header__right li.header__notifications span.icon-noti-icon {
              position: relative; }
              .page-container header .header__right li.header__notifications span.icon-noti-icon::before {
                font-size: 1.42857rem;
                font-weight: 700; }
              .page-container header .header__right li.header__notifications span.icon-noti-icon .badge {
                position: absolute;
                top: -0.71429rem;
                left: 0.78571rem;
                color: var(--white);
                background-color: var(--danger-color);
                line-height: 1.1;
                border-radius: 3.57143rem;
                font-size: 0.71429rem;
                line-height: 1;
                padding: 0.21429rem 0.39286rem;
                font-weight: 400; }
          .page-container header .header__right li.header__account {
            position: relative; }
            .page-container header .header__right li.header__account .box-account {
              display: flex; }
              .page-container header .header__right li.header__account .box-account .account-img {
                position: relative; }
                .page-container header .header__right li.header__account .box-account .account-img::before {
                  content: '';
                  position: absolute;
                  width: 44px;
                  height: 44px;
                  transform: translate(-50%, -50%);
                  top: calc(50% + 1px);
                  border: 1px solid var(--white);
                  opacity: 0.6;
                  border-radius: 50%;
                  left: 50%; }
                .page-container header .header__right li.header__account .box-account .account-img img {
                  max-width: 48px; }
              .page-container header .header__right li.header__account .box-account .account-name {
                padding-left: 1.07143rem;
                font-size: 0.92857rem; }
              .page-container header .header__right li.header__account .box-account .icon {
                align-self: center; }
                .page-container header .header__right li.header__account .box-account .icon::before {
                  font-size: 1.64286rem; }
            .page-container header .header__right li.header__account .multi-box .dropdown-content {
              width: 100%; }
              .page-container header .header__right li.header__account .multi-box .dropdown-content .logout {
                border-top: 1px solid var(--gray-color-1); }

.login {
  background: url(../images/bg-login.jpg) no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  position: relative;
  color: var(--white); }
  .login::before, .login::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  .login::before {
    background-color: var(--black);
    opacity: 0.1; }
  .login::after {
    background-color: var(--primary-color);
    opacity: 0.5; }
  .login a {
    color: var(--white); }
    .login a:hover, .login a:focus, .login a:active {
      text-decoration: underline; }
  .login .container {
    position: relative;
    z-index: 1;
    min-height: 100vh; }
    .login .container .multi-box {
      text-align: right;
      padding-top: 2.85714rem;
      opacity: 0.9;
      z-index: 2; }
      .login .container .multi-box .dropdown-content {
        margin-top: 0.71429rem;
        right: -0.35714rem;
        position: absolute; }
    .login .container > .row {
      justify-content: center;
      min-height: calc(100vh - 80px); }
      .login .container > .row .loginForm {
        align-self: center; }
        .login .container > .row .loginForm__logo {
          text-align: center;
          margin-bottom: 2.5rem;
          opacity: 0.9; }
        .login .container > .row .loginForm__forgot {
          display: flex;
          justify-content: space-between;
          font-size: 0.85714rem;
          margin-bottom: 1.07143rem;
          opacity: 0.9; }
          .login .container > .row .loginForm__forgot .form-check label::before {
            background: transparent;
            border-color: var(--white); }
          .login .container > .row .loginForm__forgot .form-check label::after {
            color: var(--white); }
          .login .container > .row .loginForm__forgot .form-check label span {
            color: var(--white);
            font-size: 0.85714rem; }
          .login .container > .row .loginForm__forgot > a {
            line-height: 1.5rem; }
        .login .container > .row .loginForm__paragraph {
          position: relative;
          text-align: center;
          margin-bottom: 1.42857rem;
          opacity: 0.6; }
          .login .container > .row .loginForm__paragraph::before, .login .container > .row .loginForm__paragraph::after {
            content: '';
            position: absolute;
            width: 43%;
            height: 1px;
            background-color: var(--white);
            top: 50%; }
          .login .container > .row .loginForm__paragraph::before {
            left: 0; }
          .login .container > .row .loginForm__paragraph::after {
            right: 0; }
        .login .container > .row .loginForm__menu {
          font-size: 0.85714rem;
          opacity: 0.9; }
          .login .container > .row .loginForm__menu ul {
            padding-left: 0;
            display: flex;
            justify-content: center; }
            .login .container > .row .loginForm__menu ul li {
              position: relative;
              padding: 0 0.71429rem; }
              .login .container > .row .loginForm__menu ul li::after {
                position: absolute;
                content: '';
                width: 1px;
                height: 13px;
                background-color: var(--white);
                right: 0;
                top: 0.14286rem; }
              .login .container > .row .loginForm__menu ul li:last-child::after {
                content: none; }
        .login .container > .row .loginForm__desc {
          text-align: center;
          margin-bottom: 1.07143rem;
          opacity: 0.9; }
          .login .container > .row .loginForm__desc h3 {
            text-transform: uppercase;
            font-weight: 300;
            margin-bottom: 1.07143rem; }
          .login .container > .row .loginForm__desc p {
            line-height: 1.71429rem; }
        .login .container > .row .loginForm .link-back {
          text-align: center;
          opacity: 0.9; }
        .login .container > .row .loginForm form .input-group {
          position: relative;
          opacity: 0.9;
          margin-bottom: 2.5rem;
          display: flex;
          flex-direction: column; }
          .login .container > .row .loginForm form .input-group::after {
            position: absolute;
            font-size: 2.14286rem;
            color: var(--white);
            font-family: 'icomoon' !important;
            z-index: 3;
            font-size: 1.78571rem;
            top: 0.42857rem;
            left: 0.92857rem;
            opacity: 0.9; }
          .login .container > .row .loginForm form .input-group.input-acc::after {
            content: '\e911'; }
          .login .container > .row .loginForm form .input-group.input-pass::after {
            content: '\e910'; }
          .login .container > .row .loginForm form .input-group.input-pass input[type='text'] + span::before {
            content: '\e95c'; }
          .login .container > .row .loginForm form .input-group.input-email::after {
            content: '\e927'; }
          .login .container > .row .loginForm form .input-group.input-newpass {
            position: relative; }
          .login .container > .row .loginForm form .input-group .line-error {
            position: absolute;
            bottom: 0.07143rem;
            left: 0.07143rem;
            width: 25%;
            border-bottom: var(--danger-color) solid 0.14286rem; }
          .login .container > .row .loginForm form .input-group .text-error {
            position: absolute;
            right: 2.85714rem;
            color: var(--danger-color);
            top: 25%; }
          .login .container > .row .loginForm form .input-group .form-control {
            background-color: rgba(255, 255, 255, 0.12);
            border-color: var(--white);
            color: var(--white);
            font-size: 1rem;
            padding: 0.92857rem 2.5rem 0.92857rem 3.21429rem;
            border-radius: 0.28571rem;
            width: 100%; }
            .login .container > .row .loginForm form .input-group .form-control:focus {
              border-color: var(--primary-color) !important; }
            .login .container > .row .loginForm form .input-group .form-control::-webkit-input-placeholder {
              font-style: normal;
              font-size: 1rem;
              letter-spacing: 0.25px;
              color: var(--white); }
            .login .container > .row .loginForm form .input-group .form-control:-moz-placeholder {
              font-style: normal;
              font-size: 1rem;
              letter-spacing: 0.25px;
              color: var(--white); }
            .login .container > .row .loginForm form .input-group .form-control::-moz-placeholder {
              font-style: normal;
              font-size: 1rem;
              letter-spacing: 0.25px;
              color: var(--white); }
            .login .container > .row .loginForm form .input-group .form-control:-ms-input-placeholder {
              font-style: normal;
              font-size: 1rem;
              letter-spacing: 0.25px;
              color: var(--white); }
          .login .container > .row .loginForm form .input-group .icon-visible-ico {
            position: absolute;
            right: 1.07143rem;
            top: 0.71429rem;
            z-index: 1111;
            cursor: pointer; }
            .login .container > .row .loginForm form .input-group .icon-visible-ico::before {
              font-size: 1.64286rem; }
          .login .container > .row .loginForm form .input-group .input-error {
            bottom: -1.5em; }
        .login .container > .row .loginForm .info-change-pass {
          opacity: 0.9; }
          .login .container > .row .loginForm .info-change-pass .list-check-pass ul {
            display: flex;
            flex-wrap: wrap;
            font-size: 0.78571rem; }
            .login .container > .row .loginForm .info-change-pass .list-check-pass ul li {
              width: 47%;
              color: var(--gray-color-3); }
              .login .container > .row .loginForm .info-change-pass .list-check-pass ul li::before {
                background-color: var(--gray-color-3);
                left: -1.07143rem;
                top: 0.28571rem; }
              .login .container > .row .loginForm .info-change-pass .list-check-pass ul li.active {
                color: var(--white); }
                .login .container > .row .loginForm .info-change-pass .list-check-pass ul li.active::before {
                  background-color: var(--primary-color); }
        .login .container > .row .loginForm .btn-white {
          width: 100%;
          text-transform: uppercase;
          font-size: 1.28571rem;
          font-weight: 700;
          margin-bottom: 2.5rem;
          opacity: 0.95; }
          .login .container > .row .loginForm .btn-white:hover, .login .container > .row .loginForm .btn-white:focus {
            border: 1px solid var(--primary-color);
            background-color: var(--primary-color);
            color: var(--white); }
        .login .container > .row .loginForm.fist-password .btn-white {
          margin-bottom: 1.42857rem; }
        .login .container > .row .loginForm .btn-log-azure {
          width: 100%;
          text-transform: uppercase;
          font-weight: 700;
          margin-bottom: 1.42857rem;
          background-color: rgba(255, 255, 255, 0.12);
          position: relative;
          padding-top: 0.92857rem;
          padding-bottom: 0.92857rem;
          border-color: var(--white);
          opacity: 0.9; }
          .login .container > .row .loginForm .btn-log-azure:hover, .login .container > .row .loginForm .btn-log-azure:focus {
            color: var(--white) !important;
            background-color: var(--primary-color);
            border-color: var(--primary-color); }
          .login .container > .row .loginForm .btn-log-azure img {
            max-width: 34px;
            padding-right: 0.71429rem; }

.page-container .main-content {
  padding: 8.21429rem 1.42857rem 0.07143rem 1.42857rem;
  background-color: var(--white-1);
  min-height: calc(100vh - 40px); }
  .page-container .main-content .title-content {
    font-size: 1.14286rem;
    font-weight: 700;
    margin-bottom: 2.14286rem;
    text-transform: uppercase; }
  .page-container .main-content .appearance .tabs .nav-tabs .nav-item .nav-link.active {
    background-color: var(--white); }
  .page-container .main-content .appearance .tabs .tab-content {
    background-color: var(--white); }
    .page-container .main-content .appearance .tabs .tab-content .tab-pane .pickerColor {
      display: flex;
      padding-bottom: 2.5rem;
      margin-bottom: 2.5rem;
      border-bottom: 1px solid var(--gray-color-3); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .pickerColor__change {
        width: 25%;
        display: flex;
        justify-content: space-between; }
        .page-container .main-content .appearance .tabs .tab-content .tab-pane .pickerColor__change .change-color {
          width: 49%;
          padding-bottom: 49%;
          position: relative;
          cursor: pointer; }
          .page-container .main-content .appearance .tabs .tab-content .tab-pane .pickerColor__change .change-color:hover span {
            opacity: 1;
            transition: 0.2s; }
          .page-container .main-content .appearance .tabs .tab-content .tab-pane .pickerColor__change .change-color.change-color-primary {
            background-color: var(--primary-color); }
          .page-container .main-content .appearance .tabs .tab-content .tab-pane .pickerColor__change .change-color.change-color-secondary {
            background-color: var(--secondary-color); }
          .page-container .main-content .appearance .tabs .tab-content .tab-pane .pickerColor__change .change-color span {
            transform: translate(-50%, -50%);
            display: block;
            top: 50%;
            left: 50%;
            position: absolute;
            transition: 0.2s; }
            .page-container .main-content .appearance .tabs .tab-content .tab-pane .pickerColor__change .change-color span::before {
              font-size: 1.64286rem;
              color: var(--white); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .pickerColor__text {
        width: 75%;
        padding-left: 2.14286rem;
        align-self: center; }
    .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .title-content {
      margin-bottom: 1.07143rem; }
    .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1.78571rem; }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__box {
        width: 22%;
        margin-bottom: 1.07143rem;
        margin-right: 2%;
        padding: 1.07143rem 0.21429rem 0.21429rem; }
        .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__box.active {
          border: 1px solid var(--gray-color-3);
          border-radius: 0.21429rem;
          background-color: var(--gray-color-1); }
        .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__box p {
          text-align: center; }
        .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__box .combo-color {
          display: flex;
          justify-content: space-between; }
          .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__box .combo-color div {
            width: 49.5%;
            padding-bottom: 49.5%;
            cursor: pointer; }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__default .color-primary {
        background-color: var(--primary-color); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__default .color-secondary {
        background-color: var(--secondary-color); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__1 .color-primary {
        background-color: var(--yellow-color); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__1 .color-secondary {
        background-color: var(--yellow-color-1); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__2 .color-primary {
        background-color: var(--pink-color); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__2 .color-secondary {
        background-color: var(--pink-color-1); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__3 .color-primary {
        background-color: var(--green-color); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__3 .color-secondary {
        background-color: var(--green-color-1); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__4 .color-primary {
        background-color: var(--blue-color); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__4 .color-secondary {
        background-color: var(--blue-color-1); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__5 .color-primary {
        background-color: var(--orange-color); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__5 .color-secondary {
        background-color: var(--pink-color-1); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__6 .color-primary {
        background-color: var(--blue-color); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__6 .color-secondary {
        background-color: var(--pink-color-1); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__7 .color-primary {
        background-color: var(--purple-color); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__7 .color-secondary {
        background-color: var(--purple-color-1); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__8 .color-primary {
        background-color: var(--color-1); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__8 .color-secondary {
        background-color: var(--color-2); }
    .page-container .main-content .appearance .tabs .tab-content .tab-pane .saveColor {
      text-align: right; }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .saveColor .btn {
        padding-right: 0.71429rem;
        padding-left: 0.71429rem;
        min-width: 140px;
        text-transform: uppercase;
        font-weight: 700; }
        .page-container .main-content .appearance .tabs .tab-content .tab-pane .saveColor .btn.btn-dark {
          margin-right: 2rem; }
    .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .title span {
      position: relative;
      cursor: pointer; }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .title span::before {
        position: absolute;
        font-size: 1.42857rem;
        position: absolute;
        left: -1.78571rem;
        top: -0.14286rem; }
    .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .title .icon-help-ico:hover {
      color: var(--primary-color); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .title .icon-help-ico:hover::before {
        color: var(--primary-color); }
    .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .up-logo {
      background-color: var(--gray-color-2);
      border-radius: 0.21429rem;
      border: 1px solid var(--gray-color-3);
      padding: 0.71429rem;
      margin-bottom: 1rem; }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .up-logo .box-img {
        width: 300px;
        height: 300px;
        border: 1px solid var(--gray-color-3);
        margin: 0 auto;
        position: relative;
        background-color: var(--white); }
        .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .up-logo .box-img::before {
          content: '';
          position: absolute;
          width: 96%;
          height: 96%;
          border: 1px dashed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 0.21429rem; }
        .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .up-logo .box-img span.upload-title {
          position: absolute;
          transform: translate(0, -50%);
          top: calc(50% + 15px);
          left: 0;
          width: 100%;
          text-align: center; }
          .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .up-logo .box-img span.upload-title::before {
            position: absolute;
            top: -2.85714rem;
            font-size: 2.28571rem;
            left: 50%;
            transform: translateX(-50%);
            color: var(--gray-color-3); }
        .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .up-logo .box-img .change-img {
          position: absolute;
          bottom: 0;
          width: 100%;
          background-color: var(--primary-color); }
          .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .up-logo .box-img .change-img .btn {
            background-color: transparent; }
            .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .up-logo .box-img .change-img .btn::before {
              font-size: 1.78571rem; }
            .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .up-logo .box-img .change-img .btn.btn-zoom-out {
              float: right; }
    .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo--rectangle .up-logo {
      min-height: 19rem; }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo--rectangle .up-logo .box-img {
        width: 314px;
        height: 206px; }
        .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo--rectangle .up-logo .box-img::before {
          width: 94%;
          height: 68px; }
        .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo--rectangle .up-logo .box-img span.upload-title::before {
          top: -2.5rem; }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo--rectangle .up-logo .holder {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo--rectangle .up-logo canvas {
        display: block;
        border: none !important; }
  .page-container .main-content .change-pass {
    display: flex;
    box-shadow: 0.07143rem 0.07143rem 0.28571rem var(--gray-color-3);
    border-radius: 0.21429rem; }
    .page-container .main-content .change-pass .check-pass,
    .page-container .main-content .change-pass .form-pass {
      width: 50%; }
    .page-container .main-content .change-pass .check-pass {
      position: relative;
      padding: 3.57143rem 1.42857rem 2.5rem;
      background-color: var(--gray-color-2);
      display: flex;
      flex-direction: column; }
      .page-container .main-content .change-pass .check-pass .logo-change-pass {
        text-align: center;
        margin-bottom: 2.14286rem; }
        .page-container .main-content .change-pass .check-pass .logo-change-pass img {
          max-width: 100px; }
      .page-container .main-content .change-pass .check-pass .info-change-pass {
        align-self: center;
        max-width: 90%; }
        .page-container .main-content .change-pass .check-pass .info-change-pass h3 {
          text-transform: uppercase;
          margin-bottom: 15px;
          text-align: center; }
    .page-container .main-content .change-pass .form-pass {
      background-color: var(--white);
      padding: 3.57143rem 1.42857rem 2.5rem; }
      .page-container .main-content .change-pass .form-pass form {
        margin-bottom: 0.71429rem; }
      .page-container .main-content .change-pass .form-pass .input-group span.icon {
        position: absolute;
        right: 0.71429rem;
        top: calc(50% - 9px);
        z-index: 3;
        cursor: pointer; }
        .page-container .main-content .change-pass .form-pass .input-group span.icon::before {
          font-size: 1.28571rem; }
      .page-container .main-content .change-pass .form-pass .title {
        margin-bottom: 4.28571rem; }
      .page-container .main-content .change-pass .form-pass .btn-pass {
        margin-top: 0.71429rem; }
        .page-container .main-content .change-pass .form-pass .btn-pass .btn {
          min-width: 140px;
          margin-right: 2rem;
          text-transform: uppercase;
          font-weight: 700; }
          .page-container .main-content .change-pass .form-pass .btn-pass .btn:last-child {
            margin-right: 0; }
  .page-container .main-content.main-center {
    display: flex;
    justify-content: center; }
    .page-container .main-content.main-center #change-pass {
      align-self: center; }
  .page-container .main-content .bulk-import .bulk-upload {
    background-color: var(--white);
    border-radius: 0.21429rem;
    padding: 1.42857rem;
    box-shadow: 0rem 0.07143rem 0.14286rem var(--gray-color-3);
    margin-bottom: 0.71429rem; }
    .page-container .main-content .bulk-import .bulk-upload .input-upload .input-file .form-control {
      padding-right: 2.85714rem; }
    .page-container .main-content .bulk-import .bulk-upload .input-upload .btn-browse:focus, .page-container .main-content .bulk-import .bulk-upload .input-upload .btn-browse:hover {
      background: var(--primary-color-darken) !important;
      border-color: var(--primary-color-darken) !important;
      color: var(--white) !important; }
    .page-container .main-content .bulk-import .bulk-upload > p {
      font-size: 0.85714rem;
      align-self: center; }
      .page-container .main-content .bulk-import .bulk-upload > p span {
        margin-top: 0.71429rem;
        display: block; }
    .page-container .main-content .bulk-import .bulk-upload .text-err {
      margin-bottom: 0;
      margin-top: 0.71429rem;
      font-size: 0.71429rem; }
  .page-container .main-content .bulk-import .collapse-saas .content .info-content .section-down .btn-down {
    color: var(--base-color);
    margin-right: 7.14286rem; }
    .page-container .main-content .bulk-import .collapse-saas .content .info-content .section-down .btn-down span {
      padding-left: 1.78571rem;
      position: relative; }
      .page-container .main-content .bulk-import .collapse-saas .content .info-content .section-down .btn-down span::before {
        position: absolute;
        left: 0;
        top: 0.21429rem; }
  .page-container .main-content .bulk-import .list-account .list-page {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.71429rem; }
    .page-container .main-content .bulk-import .list-account .list-page ul.pagination {
      margin-bottom: 0;
      align-self: center; }
    .page-container .main-content .bulk-import .list-account .list-page .show-info {
      width: 63%; }
      .page-container .main-content .bulk-import .list-account .list-page .show-info .total-acc-err {
        display: flex; }
        .page-container .main-content .bulk-import .list-account .list-page .show-info .total-acc-err .acc-validated {
          margin-right: 1.07143rem; }
        .page-container .main-content .bulk-import .list-account .list-page .show-info .total-acc-err .icon {
          margin-right: 0.35714rem; }
          .page-container .main-content .bulk-import .list-account .list-page .show-info .total-acc-err .icon::before {
            font-size: 1.14286rem;
            vertical-align: middle; }
        .page-container .main-content .bulk-import .list-account .list-page .show-info .total-acc-err .line-vertical {
          position: relative;
          margin-right: 1.07143rem; }
          .page-container .main-content .bulk-import .list-account .list-page .show-info .total-acc-err .line-vertical::before {
            content: '';
            position: absolute;
            width: 1px;
            height: 15px;
            background-color: var(--gray-color-3);
            bottom: 0.21429rem; }
      .page-container .main-content .bulk-import .list-account .list-page .show-info p {
        margin-bottom: 0; }
    .page-container .main-content .bulk-import .list-account .list-page .title {
      max-width: 60%; }
  .page-container .main-content .bulk-import .list-account .table-account {
    box-shadow: 0rem 0.07143rem 0.14286rem var(--gray-color-3);
    background-color: var(--white);
    padding: 0 2.14286rem;
    border-radius: 0.21429rem; }
    .page-container .main-content .bulk-import .list-account .table-account th {
      color: var(--base-color);
      padding: 1.28571rem 1.07143rem; }
    .page-container .main-content .bulk-import .list-account .table-account td {
      padding: 1.28571rem 1.07143rem; }
    .page-container .main-content .bulk-import .list-account .table-account .acc-csv {
      min-width: 150px; }
    .page-container .main-content .bulk-import .list-account .table-account .acc-name {
      min-width: 200px; }
    .page-container .main-content .bulk-import .list-account .table-account .acc-err {
      min-width: 400px; }
  .page-container .main-content .edit-profile .profile-content {
    display: flex;
    justify-content: space-between; }
    .page-container .main-content .edit-profile .profile-content .info-acc {
      width: 100%;
      max-width: 255px; }
      .page-container .main-content .edit-profile .profile-content .info-acc .card-acc {
        background-color: var(--primary-color);
        position: relative;
        border-radius: 0.21429rem;
        padding-top: 0.07143rem; }
        .page-container .main-content .edit-profile .profile-content .info-acc .card-acc .box-avatar {
          width: 128px;
          height: 128px;
          border-radius: 50%;
          border: 4px solid rgba(255, 255, 255, 0.560784);
          position: absolute;
          top: 40px;
          transform: translateX(-50%);
          left: 50%; }
          .page-container .main-content .edit-profile .profile-content .info-acc .card-acc .box-avatar .edit-avartar {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            border: 3px solid var(--gray-color-3);
            position: absolute;
            transform: translateX(-50%);
            left: 50%;
            overflow: hidden;
            cursor: pointer; }
            .page-container .main-content .edit-profile .profile-content .info-acc .card-acc .box-avatar .edit-avartar img {
              width: 100%;
              height: 100%;
              object-fit: cover; }
            .page-container .main-content .edit-profile .profile-content .info-acc .card-acc .box-avatar .edit-avartar .icon-edit-ico {
              position: absolute;
              bottom: 0;
              display: block;
              width: 100%;
              padding: 5px;
              background-color: rgba(33, 37, 41, 0.52); }
              .page-container .main-content .edit-profile .profile-content .info-acc .card-acc .box-avatar .edit-avartar .icon-edit-ico::before {
                position: relative;
                top: 0;
                left: calc(50% - 7px);
                color: var(--white); }
        .page-container .main-content .edit-profile .profile-content .info-acc .card-acc .detail-acc {
          background-color: var(--white);
          border: 1px solid var(--gray-color-3);
          border-top: none;
          border-radius: 0 0 0.21429rem 0.21429rem;
          margin-top: 7.14286rem; }
          .page-container .main-content .edit-profile .profile-content .info-acc .card-acc .detail-acc p.acc-azure {
            margin-bottom: 0;
            padding: 1.42857rem;
            border-top: 1px solid var(--gray-color-2); }
          .page-container .main-content .edit-profile .profile-content .info-acc .card-acc .detail-acc .title-acc {
            padding: 5.71429rem 1.42857rem 0; }
            .page-container .main-content .edit-profile .profile-content .info-acc .card-acc .detail-acc .title-acc .acc-name {
              font-size: 1.28571rem; }
          .page-container .main-content .edit-profile .profile-content .info-acc .card-acc .detail-acc ul {
            padding-left: 0;
            margin-bottom: 0;
            border-top: 1px solid var(--gray-color-2); }
            .page-container .main-content .edit-profile .profile-content .info-acc .card-acc .detail-acc ul li {
              padding: 0.57143rem 1.42857rem;
              padding-left: 3.21429rem; }
              .page-container .main-content .edit-profile .profile-content .info-acc .card-acc .detail-acc ul li.border-tag {
                border-bottom: 1px solid var(--gray-color-2); }
              .page-container .main-content .edit-profile .profile-content .info-acc .card-acc .detail-acc ul li.tool-tip {
                padding-top: 0.21429rem;
                padding-bottom: 0.21429rem;
                padding-left: 0; }
                .page-container .main-content .edit-profile .profile-content .info-acc .card-acc .detail-acc ul li.tool-tip span {
                  display: block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  padding-left: 3.21429rem;
                  padding-top: 0.64286rem;
                  padding-bottom: 0.64286rem;
                  cursor: context-menu; }
                  .page-container .main-content .edit-profile .profile-content .info-acc .card-acc .detail-acc ul li.tool-tip span::before {
                    left: 1.42857rem;
                    top: 0.71429rem; }
              .page-container .main-content .edit-profile .profile-content .info-acc .card-acc .detail-acc ul li span {
                position: relative; }
                .page-container .main-content .edit-profile .profile-content .info-acc .card-acc .detail-acc ul li span::before {
                  position: absolute;
                  left: -1.78571rem;
                  top: 0.21429rem; }
    .page-container .main-content .edit-profile .profile-content .edit-acc {
      width: calc(100% - 295px); }
      .page-container .main-content .edit-profile .profile-content .edit-acc .nav-link.active {
        background-color: var(--white); }
      .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content {
        background-color: var(--white); }
        .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .edit-title {
          margin-bottom: 1.78571rem;
          font-weight: 700; }
        .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .form-group label {
          width: 100%; }
          .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .form-group label .select2-selection__rendered {
            color: var(--base-color);
            font-size: 0.85714rem; }
        .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .form-edit .acc-phone {
          position: relative; }
          .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .form-edit .acc-phone input {
            padding-left: 2.85714rem; }
          .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .form-edit .acc-phone .flag-phone {
            position: absolute;
            left: 0.35714rem; }
            .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .form-edit .acc-phone .flag-phone img {
              max-width: 30px;
              width: 100%; }
        .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .row-btn {
          text-align: right;
          padding-top: 0;
          margin-bottom: 1.42857rem; }
          .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .row-btn .btn {
            text-transform: uppercase;
            min-width: 140px;
            font-weight: 700; }
            .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .row-btn .btn.btn-dark {
              margin-right: 2rem; }
        .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .div-btn {
          padding: 1.78571rem 0;
          border-top: 2px solid var(--gray-color-3); }
          .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .div-btn .edit-title {
            margin-bottom: 0.71429rem; }
          .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .div-btn:last-child {
            padding-bottom: 0; }
          .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .div-btn .box-btn {
            flex-wrap: wrap; }
            .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .div-btn .box-btn .btn {
              margin-right: 0.71429rem;
              padding: 0.35714rem 0.71429rem; }
  .page-container .main-content .reset-password .reset-password-main {
    background-color: var(--white);
    padding: 1.78571rem 2.14286rem;
    border: 1px solid var(--gray-color-3);
    border-radius: 0.21429rem; }
    .page-container .main-content .reset-password .reset-password-main .rs-title {
      margin-bottom: 1.42857rem; }
    .page-container .main-content .reset-password .reset-password-main .rs-search {
      padding: 1.78571rem 0 1.42857rem;
      border-top: 1px solid var(--gray-color-2);
      display: flex; }
      .page-container .main-content .reset-password .reset-password-main .rs-search form {
        flex: 0 0 60%;
        margin-right: 1.07143rem; }
        .page-container .main-content .reset-password .reset-password-main .rs-search form .input-search {
          position: relative; }
          .page-container .main-content .reset-password .reset-password-main .rs-search form .input-search::after {
            content: '\e916';
            position: absolute;
            color: var(--base-color);
            font-family: 'icomoon' !important;
            z-index: 3;
            font-size: 1.42857rem;
            top: 0.14286rem;
            left: 0.71429rem; }
          .page-container .main-content .reset-password .reset-password-main .rs-search form .input-search input {
            padding-left: 2.5rem; }
      .page-container .main-content .reset-password .reset-password-main .rs-search .btn {
        min-width: 140px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 0.28571rem; }
    .page-container .main-content .reset-password .reset-password-main .rs-content {
      position: relative; }
      .page-container .main-content .reset-password .reset-password-main .rs-content .err-box {
        position: relative;
        left: 50%;
        top: 2.85714rem;
        width: 100%;
        transform: translate(-50%, 0);
        margin-bottom: 7.14286rem; }
        .page-container .main-content .reset-password .reset-password-main .rs-content .err-box .err-text {
          opacity: 0.3;
          margin-bottom: 1.78571rem; }
          .page-container .main-content .reset-password .reset-password-main .rs-content .err-box .err-text h2 {
            font-size: 3.57143rem; }
        .page-container .main-content .reset-password .reset-password-main .rs-content .err-box .err-img img {
          max-width: 160px;
          opacity: 0.3; }
      .page-container .main-content .reset-password .reset-password-main .rs-content .table-content {
        margin-bottom: 0; }
        .page-container .main-content .reset-password .reset-password-main .rs-content .table-content ul {
          margin-bottom: 0; }
      .page-container .main-content .reset-password .reset-password-main .rs-content .table-edit-pass .table td {
        padding: 1.07143rem 1.07143rem 1.07143rem 0; }
        .page-container .main-content .reset-password .reset-password-main .rs-content .table-edit-pass .table td.reset-icon {
          cursor: pointer;
          color: var(--primary-color); }
          .page-container .main-content .reset-password .reset-password-main .rs-content .table-edit-pass .table td.reset-icon .text-bl {
            padding-left: 1.28571rem; }
          .page-container .main-content .reset-password .reset-password-main .rs-content .table-edit-pass .table td.reset-icon .icon-reset-password-ico {
            margin-right: 0.71429rem;
            position: relative;
            font-size: 1.64286rem; }
            .page-container .main-content .reset-password .reset-password-main .rs-content .table-edit-pass .table td.reset-icon .icon-reset-password-ico::before {
              position: absolute;
              top: 0.5rem; }
      .page-container .main-content .reset-password .reset-password-main .rs-content .table-edit-pass .table thead th {
        color: var(--base-color);
        padding: 1.07143rem 1.07143rem 1.07143rem 0; }
        .page-container .main-content .reset-password .reset-password-main .rs-content .table-edit-pass .table thead th.acc-stt {
          min-width: 55px; }
        .page-container .main-content .reset-password .reset-password-main .rs-content .table-edit-pass .table thead th.acc-name {
          min-width: 200px; }
        .page-container .main-content .reset-password .reset-password-main .rs-content .table-edit-pass .table thead th.acc-fname {
          min-width: 300px; }
        .page-container .main-content .reset-password .reset-password-main .rs-content .table-edit-pass .table thead th.acc-reset {
          min-width: 150px; }
  .page-container .main-content .decentralize .box-nation {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out; }
  .page-container .main-content .decentralize .list-page {
    margin-bottom: 1.42857rem; }
    .page-container .main-content .decentralize .list-page .title-content {
      margin-bottom: 0; }
    .page-container .main-content .decentralize .list-page .box-btn-decentralize {
      align-self: center; }
      .page-container .main-content .decentralize .list-page .box-btn-decentralize .pagination {
        margin-bottom: 0; }
  .page-container .main-content .decentralize .switch-box {
    display: flex; }
    .page-container .main-content .decentralize .switch-box label {
      margin-right: 0.71429rem; }
    .page-container .main-content .decentralize .switch-box .title {
      align-self: center; }
  .page-container .main-content .decentralize .title-associate {
    display: block;
    margin-bottom: 0.5rem; }
  .page-container .main-content .decentralize .table-responsive {
    padding: 0.07143rem;
    border: solid 0.07143rem var(--gray-color-3);
    border-radius: 0.21429rem; }
  .page-container .main-content .decentralize .decentralize-main {
    margin-bottom: 1.07143rem; }
    .page-container .main-content .decentralize .decentralize-main .table-decentralize {
      background-color: var(--white);
      width: 100%;
      max-width: 100%; }
      .page-container .main-content .decentralize .decentralize-main .table-decentralize .th-1 {
        width: 70%; }
      .page-container .main-content .decentralize .decentralize-main .table-decentralize .th-2 {
        width: 30%; }
      .page-container .main-content .decentralize .decentralize-main .table-decentralize td {
        padding: 0.28571rem 1.57143rem; }
      .page-container .main-content .decentralize .decentralize-main .table-decentralize thead th {
        text-transform: uppercase;
        font-weight: 400;
        color: var(--white);
        border-bottom: none;
        border-top: none;
        padding: 0.85714rem 1.57143rem; }
        .page-container .main-content .decentralize .decentralize-main .table-decentralize thead th .three-dot {
          background-image: url("./../images/icon-three-dot.png");
          background-repeat: no-repeat;
          width: 1.42857rem;
          height: 1.42857rem;
          cursor: pointer;
          background-position: center; }
          .page-container .main-content .decentralize .decentralize-main .table-decentralize thead th .three-dot.option {
            border: none;
            background-color: transparent;
            color: var(--base-color);
            position: relative; }
            .page-container .main-content .decentralize .decentralize-main .table-decentralize thead th .three-dot.option:focus {
              outline: none; }
            .page-container .main-content .decentralize .decentralize-main .table-decentralize thead th .three-dot.option .dropdown-content-three-dot {
              position: absolute;
              top: 2.35714rem;
              right: 0;
              visibility: hidden;
              opacity: 0;
              transition: 0.2s;
              padding-left: 0;
              background-color: var(--white);
              border: 0.07143rem solid var(--gray-color-1);
              box-shadow: 0.07143rem 0.07143rem 0.14286rem var(--gray-color-3);
              padding: 0.71429rem;
              border-radius: 0.21429rem;
              z-index: 1;
              width: 10.71429rem; }
              .page-container .main-content .decentralize .decentralize-main .table-decentralize thead th .three-dot.option .dropdown-content-three-dot.show {
                visibility: visible;
                opacity: 1;
                transition: 0.2s; }
              .page-container .main-content .decentralize .decentralize-main .table-decentralize thead th .three-dot.option .dropdown-content-three-dot li {
                padding: 0.35714rem; }
                .page-container .main-content .decentralize .decentralize-main .table-decentralize thead th .three-dot.option .dropdown-content-three-dot li a {
                  padding: 0.35714rem;
                  display: block;
                  color: var(--gray-color-3); }
      .page-container .main-content .decentralize .decentralize-main .table-decentralize.table-base thead {
        background-color: var(--primary-color); }
      .page-container .main-content .decentralize .decentralize-main .table-decentralize.table-base tbody tr {
        border-top: 1px solid var(--gray-color-2); }
        .page-container .main-content .decentralize .decentralize-main .table-decentralize.table-base tbody tr:last-child {
          border-bottom: 1px solid var(--gray-color-2); }
      .page-container .main-content .decentralize .decentralize-main .table-decentralize .decentralize-btn {
        text-align: right; }
        .page-container .main-content .decentralize .decentralize-main .table-decentralize .decentralize-btn button {
          background-color: transparent;
          border: none; }
          .page-container .main-content .decentralize .decentralize-main .table-decentralize .decentralize-btn button:focus {
            outline: none; }
  .page-container .main-content .decentralize .btn-box {
    margin-top: 1.42857rem;
    margin-bottom: 1.07143rem;
    justify-content: flex-end; }
    .page-container .main-content .decentralize .btn-box .btn {
      width: 140px; }
      .page-container .main-content .decentralize .btn-box .btn:first-child {
        margin-right: 1.42857rem; }
  .page-container .main-content .decentralize .group-btn {
    margin-top: 0.5rem; }
    .page-container .main-content .decentralize .group-btn .btn__type {
      padding: 0 0.5rem;
      height: 2.28571rem;
      width: 6.07143rem;
      color: var(--base-color);
      font-size: 0.85714rem;
      border: solid 1px var(--gray-color-4);
      background-color: var(--gray-color-3);
      margin-right: 1rem;
      margin-bottom: 1rem; }
      .page-container .main-content .decentralize .group-btn .btn__type.active {
        color: var(--white);
        background-color: var(--primary-color);
        border-color: var(--primary-color); }
  .page-container .main-content .decentralize .modalSaas .modalSaas__content__1 {
    width: 50%;
    padding-left: 1.42857rem;
    padding-right: 1.42857rem; }
  .page-container .main-content .decentralize .modalSaas__close {
    z-index: 111111; }
  .page-container .main-content .decentralize .modalSaas__dialog {
    max-width: 91.42857rem;
    width: 90%; }
  .page-container .main-content .decentralize .modalSaas__content {
    padding-bottom: 0rem; }
  .page-container .main-content .decentralize.permission .decentralize-main .table-responsive {
    overflow-y: hidden; }
  .page-container .main-content .decentralize.permission .table-permission-modal {
    display: flex; }
    .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item {
      border-top: solid 0.07143rem var(--gray-color-2);
      border-bottom: solid 0.07143rem var(--gray-color-2);
      padding: 0.57143rem; }
      .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item.item-1 {
        width: 60%; }
      .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item.item-2 {
        width: 40%; }
      .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item.page {
        visibility: visible; }
      .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item .select-option {
        border: none;
        background-color: transparent;
        color: var(--base-color);
        position: relative; }
        .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item .select-option .icon__down {
          font-size: 1.71429rem; }
        .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item .select-option:focus {
          outline: none; }
        .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item .select-option .dropdown-content-permisson {
          position: absolute;
          bottom: -10rem;
          right: 0.5rem;
          visibility: hidden;
          opacity: 0;
          transition: 0.2s;
          padding-left: 0;
          background-color: var(--white);
          border: 0.07143rem solid var(--gray-color-1);
          box-shadow: 0rem 0.14286rem 0.21429rem var(--gray-color-3);
          padding: 0.71429rem;
          text-align: left;
          border-radius: 0.21429rem;
          z-index: 1; }
          .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item .select-option .dropdown-content-permisson.show {
            visibility: visible;
            opacity: 1;
            transition: 0.2s; }
          .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item .select-option .dropdown-content-permisson li {
            padding: 0.35714rem; }
            .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item .select-option .dropdown-content-permisson li a {
              padding: 1.07143rem;
              display: block;
              color: var(--gray-color-3); }
        .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item .select-option .select-content {
          width: 14.28571rem;
          height: 8.57143rem; }
          .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item .select-option .select-content .checkmark-radio {
            position: absolute;
            top: 0.35714rem;
            left: 0;
            height: 1.07143rem;
            width: 1.07143rem;
            border: 1px solid var(--gray-color-3);
            background-color: var(--white);
            border-radius: 50%; }
            .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item .select-option .select-content .checkmark-radio::after {
              content: '';
              position: absolute;
              top: 0.07143rem;
              left: 0.07143rem;
              width: 0.78571rem;
              height: 0.78571rem;
              border-radius: 50%;
              background: var(--primary-color); }
      .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item .breadcrumb-1 {
        background-color: var(--white);
        margin-bottom: 0;
        padding: 0 0; }
        .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item .breadcrumb-1 > li + li:before {
          color: #ccc;
          content: '>> ';
          padding: 0 5px;
          color: var(--base-color); }
        .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item .breadcrumb-1 li a {
          color: var(--base-color); }
    .page-container .main-content .decentralize.permission .table-permission-modal .btn-permission {
      border: solid 0.07143rem var(--gray-color-3);
      height: 2.28571rem;
      border-radius: 1.42857rem;
      position: relative;
      background-color: var(--white); }
      .page-container .main-content .decentralize.permission .table-permission-modal .btn-permission .btn-permission-common {
        height: 1.85714rem;
        width: 33%;
        border-radius: 1.42857rem;
        position: absolute;
        top: 0.14286rem;
        text-align: center;
        line-height: 1.5rem;
        vertical-align: middle;
        font-size: 0.78571rem;
        color: var(--base-color);
        padding: 0 5px;
        background-color: var(--white); }
        .page-container .main-content .decentralize.permission .table-permission-modal .btn-permission .btn-permission-common.active {
          line-height: 1.5rem;
          background-color: var(--primary-color);
          color: var(--white);
          border: 0.07143rem solid var(--primary-color); }
      .page-container .main-content .decentralize.permission .table-permission-modal .btn-permission .btn-permission__no {
        left: 0.14286rem; }
      .page-container .main-content .decentralize.permission .table-permission-modal .btn-permission .btn-permission__access {
        left: 33.33%; }
      .page-container .main-content .decentralize.permission .table-permission-modal .btn-permission .btn-permission__full {
        right: 0.14286rem; }
  .page-container .main-content .decentralize.permission .table__permission__main {
    max-height: 21.42857rem;
    overflow-y: auto;
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */ }
    .page-container .main-content .decentralize.permission .table__permission__main::-webkit-scrollbar {
      width: 0.28571rem; }
    .page-container .main-content .decentralize.permission .table__permission__main::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0.21429rem var(--gray-color-3);
      border-radius: 0.71429rem; }
    .page-container .main-content .decentralize.permission .table__permission__main::-webkit-scrollbar-thumb {
      background: var(--gray-color-4);
      border-radius: 0.71429rem; }
    .page-container .main-content .decentralize.permission .table__permission__main::-webkit-scrollbar-thumb:hover {
      background: var(--base-color); }
    .page-container .main-content .decentralize.permission .table__permission__main::-webkit-scrollbar-track-piece {
      height: 10px; }
  .page-container .main-content .decentralize.roles .form-group label, .page-container .main-content .decentralize.user-group .form-group label {
    width: 100%; }
  .page-container .main-content .decentralize.roles .table-responsive, .page-container .main-content .decentralize.user-group .table-responsive {
    overflow-y: hidden;
    min-height: 11.07143rem;
    background: var(--white); }
  .page-container .main-content .decentralize.roles .table-decentralize thead tr th, .page-container .main-content .decentralize.user-group .table-decentralize thead tr th {
    padding-right: 0; }
    .page-container .main-content .decentralize.roles .table-decentralize thead tr th.th-name, .page-container .main-content .decentralize.user-group .table-decentralize thead tr th.th-name {
      width: 20%; }
    .page-container .main-content .decentralize.roles .table-decentralize thead tr th.th-number, .page-container .main-content .decentralize.user-group .table-decentralize thead tr th.th-number {
      max-width: 70px; }
    .page-container .main-content .decentralize.roles .table-decentralize thead tr th.th-associated, .page-container .main-content .decentralize.roles .table-decentralize thead tr th.th-usergr, .page-container .main-content .decentralize.roles .table-decentralize thead tr th.th-roles, .page-container .main-content .decentralize.user-group .table-decentralize thead tr th.th-associated, .page-container .main-content .decentralize.user-group .table-decentralize thead tr th.th-usergr, .page-container .main-content .decentralize.user-group .table-decentralize thead tr th.th-roles {
      width: 33%; }
    .page-container .main-content .decentralize.roles .table-decentralize thead tr th .th, .page-container .main-content .decentralize.user-group .table-decentralize thead tr th .th {
      display: flex;
      justify-content: space-between; }
  .page-container .main-content .decentralize.roles .table-decentralize tbody td, .page-container .main-content .decentralize.user-group .table-decentralize tbody td {
    white-space: nowrap; }
  .page-container .main-content .decentralize.roles .table-decentralize tbody .td-roles, .page-container .main-content .decentralize.user-group .table-decentralize tbody .td-roles {
    display: flex; }
    .page-container .main-content .decentralize.roles .table-decentralize tbody .td-roles .gr-roles, .page-container .main-content .decentralize.user-group .table-decentralize tbody .td-roles .gr-roles {
      width: 90%;
      display: flex;
      overflow: hidden; }
      .page-container .main-content .decentralize.roles .table-decentralize tbody .td-roles .gr-roles .stamp, .page-container .main-content .decentralize.user-group .table-decentralize tbody .td-roles .gr-roles .stamp {
        padding: 0.35714rem;
        border: 1px solid var(--gray-color-3);
        border-radius: 0.21429rem;
        background-color: var(--gray-color-2);
        margin-right: 0.35714rem; }
    .page-container .main-content .decentralize.roles .table-decentralize tbody .td-roles .amount, .page-container .main-content .decentralize.user-group .table-decentralize tbody .td-roles .amount {
      width: 1.42857rem;
      height: 1.42857rem;
      text-align: center;
      background-color: var(--primary-color);
      border-radius: 0.21429rem;
      color: var(--white);
      align-self: baseline;
      cursor: pointer;
      margin-left: 0.5rem; }
  .page-container .main-content .decentralize.roles .table-decentralize tbody .td-number, .page-container .main-content .decentralize.user-group .table-decentralize tbody .td-number {
    padding-right: 2.5rem; }
  .page-container .main-content .decentralize.roles .modalSaas__dialog, .page-container .main-content .decentralize.user-group .modalSaas__dialog {
    width: 70%; }
  .page-container .main-content .decentralize.roles .modal-roles .modalSaas .modalSaas__content .box-nation, .page-container .main-content .decentralize.user-group .modal-roles .modalSaas .modalSaas__content .box-nation {
    padding-left: 1.42857rem;
    position: relative;
    margin-top: 1.07143rem;
    padding-top: 1.07143rem;
    max-width: 60%; }
    .page-container .main-content .decentralize.roles .modal-roles .modalSaas .modalSaas__content .box-nation::before, .page-container .main-content .decentralize.user-group .modal-roles .modalSaas .modalSaas__content .box-nation::before {
      content: '';
      position: absolute;
      width: 0.07143rem;
      height: 75%;
      background-color: var(--gray-color-1);
      top: 1.42857rem; }
    .page-container .main-content .decentralize.roles .modal-roles .modalSaas .modalSaas__content .box-nation .form-check, .page-container .main-content .decentralize.user-group .modal-roles .modalSaas .modalSaas__content .box-nation .form-check {
      padding: 0.35714rem 1.07143rem; }
      .page-container .main-content .decentralize.roles .modal-roles .modalSaas .modalSaas__content .box-nation .form-check::before, .page-container .main-content .decentralize.user-group .modal-roles .modalSaas .modalSaas__content .box-nation .form-check::before {
        content: '';
        position: absolute;
        width: 0.35714rem;
        height: 0.35714rem;
        border-radius: 50%;
        background-color: var(--gray-color-1);
        left: -0.14286rem;
        top: calc(50% - 2px); }
      .page-container .main-content .decentralize.roles .modal-roles .modalSaas .modalSaas__content .box-nation .form-check label, .page-container .main-content .decentralize.user-group .modal-roles .modalSaas .modalSaas__content .box-nation .form-check label {
        margin-bottom: 0; }
  .page-container .main-content .decentralize.clubs > nav, .page-container .main-content .decentralize.club-group-function > nav, .page-container .main-content .decentralize.addon-usage-club-groups > nav, .page-container .main-content .decentralize.add-new-club-groups > nav, .page-container .main-content .decentralize.edit-club-groups > nav {
    border-bottom: 0.07143rem solid var(--gray-color-2);
    margin-bottom: 0.71429rem; }
  .page-container .main-content .decentralize.clubs .list-page, .page-container .main-content .decentralize.club-group-function .list-page, .page-container .main-content .decentralize.addon-usage-club-groups .list-page, .page-container .main-content .decentralize.add-new-club-groups .list-page, .page-container .main-content .decentralize.edit-club-groups .list-page {
    margin-bottom: 0.71429rem;
    display: block; }
    .page-container .main-content .decentralize.clubs .list-page .title-content, .page-container .main-content .decentralize.club-group-function .list-page .title-content, .page-container .main-content .decentralize.addon-usage-club-groups .list-page .title-content, .page-container .main-content .decentralize.add-new-club-groups .list-page .title-content, .page-container .main-content .decentralize.edit-club-groups .list-page .title-content {
      margin-bottom: 0.71429rem; }
    .page-container .main-content .decentralize.clubs .list-page .box-btn-decentralize, .page-container .main-content .decentralize.club-group-function .list-page .box-btn-decentralize, .page-container .main-content .decentralize.addon-usage-club-groups .list-page .box-btn-decentralize, .page-container .main-content .decentralize.add-new-club-groups .list-page .box-btn-decentralize, .page-container .main-content .decentralize.edit-club-groups .list-page .box-btn-decentralize {
      justify-content: space-between; }
      .page-container .main-content .decentralize.clubs .list-page .box-btn-decentralize p, .page-container .main-content .decentralize.club-group-function .list-page .box-btn-decentralize p, .page-container .main-content .decentralize.addon-usage-club-groups .list-page .box-btn-decentralize p, .page-container .main-content .decentralize.add-new-club-groups .list-page .box-btn-decentralize p, .page-container .main-content .decentralize.edit-club-groups .list-page .box-btn-decentralize p {
        margin-bottom: 0;
        padding-right: 1.07143rem; }
      .page-container .main-content .decentralize.clubs .list-page .box-btn-decentralize .pagination, .page-container .main-content .decentralize.club-group-function .list-page .box-btn-decentralize .pagination, .page-container .main-content .decentralize.addon-usage-club-groups .list-page .box-btn-decentralize .pagination, .page-container .main-content .decentralize.add-new-club-groups .list-page .box-btn-decentralize .pagination, .page-container .main-content .decentralize.edit-club-groups .list-page .box-btn-decentralize .pagination {
        align-self: center; }
  .page-container .main-content .decentralize.clubs .decentralize-main.decentralize-club, .page-container .main-content .decentralize.club-group-function .decentralize-main.decentralize-club, .page-container .main-content .decentralize.addon-usage-club-groups .decentralize-main.decentralize-club, .page-container .main-content .decentralize.add-new-club-groups .decentralize-main.decentralize-club, .page-container .main-content .decentralize.edit-club-groups .decentralize-main.decentralize-club {
    border: solid 0.07143rem var(--gray-color-2); }
  .page-container .main-content .decentralize.clubs .decentralize-main .club-info, .page-container .main-content .decentralize.club-group-function .decentralize-main .club-info, .page-container .main-content .decentralize.addon-usage-club-groups .decentralize-main .club-info, .page-container .main-content .decentralize.add-new-club-groups .decentralize-main .club-info, .page-container .main-content .decentralize.edit-club-groups .decentralize-main .club-info {
    padding: 1rem 2rem; }
    .page-container .main-content .decentralize.clubs .decentralize-main .club-info .club-info__tilte, .page-container .main-content .decentralize.club-group-function .decentralize-main .club-info .club-info__tilte, .page-container .main-content .decentralize.addon-usage-club-groups .decentralize-main .club-info .club-info__tilte, .page-container .main-content .decentralize.add-new-club-groups .decentralize-main .club-info .club-info__tilte, .page-container .main-content .decentralize.edit-club-groups .decentralize-main .club-info .club-info__tilte {
      text-transform: uppercase;
      font-weight: bold;
      padding: 1rem 0; }
    .page-container .main-content .decentralize.clubs .decentralize-main .club-info .form-check [type='checkbox']:not(:checked) + label:before,
    .page-container .main-content .decentralize.clubs .decentralize-main .club-info .form-check [type='checkbox']:checked + label:before, .page-container .main-content .decentralize.club-group-function .decentralize-main .club-info .form-check [type='checkbox']:not(:checked) + label:before,
    .page-container .main-content .decentralize.club-group-function .decentralize-main .club-info .form-check [type='checkbox']:checked + label:before, .page-container .main-content .decentralize.addon-usage-club-groups .decentralize-main .club-info .form-check [type='checkbox']:not(:checked) + label:before,
    .page-container .main-content .decentralize.addon-usage-club-groups .decentralize-main .club-info .form-check [type='checkbox']:checked + label:before, .page-container .main-content .decentralize.add-new-club-groups .decentralize-main .club-info .form-check [type='checkbox']:not(:checked) + label:before,
    .page-container .main-content .decentralize.add-new-club-groups .decentralize-main .club-info .form-check [type='checkbox']:checked + label:before, .page-container .main-content .decentralize.edit-club-groups .decentralize-main .club-info .form-check [type='checkbox']:not(:checked) + label:before,
    .page-container .main-content .decentralize.edit-club-groups .decentralize-main .club-info .form-check [type='checkbox']:checked + label:before {
      top: -0.71429rem; }
    .page-container .main-content .decentralize.clubs .decentralize-main .club-info .form-check [type='checkbox']:not(:checked) + label:after,
    .page-container .main-content .decentralize.clubs .decentralize-main .club-info .form-check [type='checkbox']:checked + label:after, .page-container .main-content .decentralize.club-group-function .decentralize-main .club-info .form-check [type='checkbox']:not(:checked) + label:after,
    .page-container .main-content .decentralize.club-group-function .decentralize-main .club-info .form-check [type='checkbox']:checked + label:after, .page-container .main-content .decentralize.addon-usage-club-groups .decentralize-main .club-info .form-check [type='checkbox']:not(:checked) + label:after,
    .page-container .main-content .decentralize.addon-usage-club-groups .decentralize-main .club-info .form-check [type='checkbox']:checked + label:after, .page-container .main-content .decentralize.add-new-club-groups .decentralize-main .club-info .form-check [type='checkbox']:not(:checked) + label:after,
    .page-container .main-content .decentralize.add-new-club-groups .decentralize-main .club-info .form-check [type='checkbox']:checked + label:after, .page-container .main-content .decentralize.edit-club-groups .decentralize-main .club-info .form-check [type='checkbox']:not(:checked) + label:after,
    .page-container .main-content .decentralize.edit-club-groups .decentralize-main .club-info .form-check [type='checkbox']:checked + label:after {
      top: -0.57143rem; }
    .page-container .main-content .decentralize.clubs .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:not(:checked) + label:before,
    .page-container .main-content .decentralize.clubs .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:checked + label:before, .page-container .main-content .decentralize.club-group-function .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:not(:checked) + label:before,
    .page-container .main-content .decentralize.club-group-function .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:checked + label:before, .page-container .main-content .decentralize.addon-usage-club-groups .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:not(:checked) + label:before,
    .page-container .main-content .decentralize.addon-usage-club-groups .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:checked + label:before, .page-container .main-content .decentralize.add-new-club-groups .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:not(:checked) + label:before,
    .page-container .main-content .decentralize.add-new-club-groups .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:checked + label:before, .page-container .main-content .decentralize.edit-club-groups .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:not(:checked) + label:before,
    .page-container .main-content .decentralize.edit-club-groups .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:checked + label:before {
      background: var(--primary-color); }
    .page-container .main-content .decentralize.clubs .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:not(:checked) + label:after,
    .page-container .main-content .decentralize.clubs .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:checked + label:after, .page-container .main-content .decentralize.club-group-function .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:not(:checked) + label:after,
    .page-container .main-content .decentralize.club-group-function .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:checked + label:after, .page-container .main-content .decentralize.addon-usage-club-groups .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:not(:checked) + label:after,
    .page-container .main-content .decentralize.addon-usage-club-groups .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:checked + label:after, .page-container .main-content .decentralize.add-new-club-groups .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:not(:checked) + label:after,
    .page-container .main-content .decentralize.add-new-club-groups .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:checked + label:after, .page-container .main-content .decentralize.edit-club-groups .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:not(:checked) + label:after,
    .page-container .main-content .decentralize.edit-club-groups .decentralize-main .club-info .form-check.form-check--th [type='checkbox']:checked + label:after {
      color: var(--white); }
  .page-container .main-content .decentralize.clubs .table-decentralize thead tr th.th-center, .page-container .main-content .decentralize.club-group-function .table-decentralize thead tr th.th-center, .page-container .main-content .decentralize.addon-usage-club-groups .table-decentralize thead tr th.th-center, .page-container .main-content .decentralize.add-new-club-groups .table-decentralize thead tr th.th-center, .page-container .main-content .decentralize.edit-club-groups .table-decentralize thead tr th.th-center {
    text-align: center; }
  .page-container .main-content .decentralize.clubs .table-decentralize thead tr th.th-right, .page-container .main-content .decentralize.club-group-function .table-decentralize thead tr th.th-right, .page-container .main-content .decentralize.addon-usage-club-groups .table-decentralize thead tr th.th-right, .page-container .main-content .decentralize.add-new-club-groups .table-decentralize thead tr th.th-right, .page-container .main-content .decentralize.edit-club-groups .table-decentralize thead tr th.th-right {
    text-align: right; }
  .page-container .main-content .decentralize.clubs .table-decentralize thead tr th.th-left, .page-container .main-content .decentralize.club-group-function .table-decentralize thead tr th.th-left, .page-container .main-content .decentralize.addon-usage-club-groups .table-decentralize thead tr th.th-left, .page-container .main-content .decentralize.add-new-club-groups .table-decentralize thead tr th.th-left, .page-container .main-content .decentralize.edit-club-groups .table-decentralize thead tr th.th-left {
    text-align: left; }
  .page-container .main-content .decentralize.clubs .table-decentralize thead tr th.th-name, .page-container .main-content .decentralize.club-group-function .table-decentralize thead tr th.th-name, .page-container .main-content .decentralize.addon-usage-club-groups .table-decentralize thead tr th.th-name, .page-container .main-content .decentralize.add-new-club-groups .table-decentralize thead tr th.th-name, .page-container .main-content .decentralize.edit-club-groups .table-decentralize thead tr th.th-name {
    width: 40%; }
  .page-container .main-content .decentralize.clubs .table-decentralize thead tr th.th-code, .page-container .main-content .decentralize.clubs .table-decentralize thead tr th.th-group, .page-container .main-content .decentralize.club-group-function .table-decentralize thead tr th.th-code, .page-container .main-content .decentralize.club-group-function .table-decentralize thead tr th.th-group, .page-container .main-content .decentralize.addon-usage-club-groups .table-decentralize thead tr th.th-code, .page-container .main-content .decentralize.addon-usage-club-groups .table-decentralize thead tr th.th-group, .page-container .main-content .decentralize.add-new-club-groups .table-decentralize thead tr th.th-code, .page-container .main-content .decentralize.add-new-club-groups .table-decentralize thead tr th.th-group, .page-container .main-content .decentralize.edit-club-groups .table-decentralize thead tr th.th-code, .page-container .main-content .decentralize.edit-club-groups .table-decentralize thead tr th.th-group {
    width: 30%; }
  .page-container .main-content .decentralize.clubs .table-decentralize tbody td, .page-container .main-content .decentralize.club-group-function .table-decentralize tbody td, .page-container .main-content .decentralize.addon-usage-club-groups .table-decentralize tbody td, .page-container .main-content .decentralize.add-new-club-groups .table-decentralize tbody td, .page-container .main-content .decentralize.edit-club-groups .table-decentralize tbody td {
    padding: 0.57143rem 1.57143rem; }
    .page-container .main-content .decentralize.clubs .table-decentralize tbody td.td-center, .page-container .main-content .decentralize.club-group-function .table-decentralize tbody td.td-center, .page-container .main-content .decentralize.addon-usage-club-groups .table-decentralize tbody td.td-center, .page-container .main-content .decentralize.add-new-club-groups .table-decentralize tbody td.td-center, .page-container .main-content .decentralize.edit-club-groups .table-decentralize tbody td.td-center {
      text-align: center; }
    .page-container .main-content .decentralize.clubs .table-decentralize tbody td.td-right, .page-container .main-content .decentralize.club-group-function .table-decentralize tbody td.td-right, .page-container .main-content .decentralize.addon-usage-club-groups .table-decentralize tbody td.td-right, .page-container .main-content .decentralize.add-new-club-groups .table-decentralize tbody td.td-right, .page-container .main-content .decentralize.edit-club-groups .table-decentralize tbody td.td-right {
      text-align: right; }
    .page-container .main-content .decentralize.clubs .table-decentralize tbody td.td-left, .page-container .main-content .decentralize.club-group-function .table-decentralize tbody td.td-left, .page-container .main-content .decentralize.addon-usage-club-groups .table-decentralize tbody td.td-left, .page-container .main-content .decentralize.add-new-club-groups .table-decentralize tbody td.td-left, .page-container .main-content .decentralize.edit-club-groups .table-decentralize tbody td.td-left {
      text-align: left; }
  .page-container .main-content .decentralize.clubs .modal-clubs .modalSaas .modalSaas__content .modal-checkbox, .page-container .main-content .decentralize.club-group-function .modal-clubs .modalSaas .modalSaas__content .modal-checkbox, .page-container .main-content .decentralize.addon-usage-club-groups .modal-clubs .modalSaas .modalSaas__content .modal-checkbox, .page-container .main-content .decentralize.add-new-club-groups .modal-clubs .modalSaas .modalSaas__content .modal-checkbox, .page-container .main-content .decentralize.edit-club-groups .modal-clubs .modalSaas .modalSaas__content .modal-checkbox {
    padding-top: 2rem; }
  .page-container .main-content .decentralize.clubs .btn.btn-height-sm, .page-container .main-content .decentralize.club-group-function .btn.btn-height-sm, .page-container .main-content .decentralize.addon-usage-club-groups .btn.btn-height-sm, .page-container .main-content .decentralize.add-new-club-groups .btn.btn-height-sm, .page-container .main-content .decentralize.edit-club-groups .btn.btn-height-sm {
    margin-right: 0; }
    .page-container .main-content .decentralize.clubs .btn.btn-height-sm::after, .page-container .main-content .decentralize.club-group-function .btn.btn-height-sm::after, .page-container .main-content .decentralize.addon-usage-club-groups .btn.btn-height-sm::after, .page-container .main-content .decentralize.add-new-club-groups .btn.btn-height-sm::after, .page-container .main-content .decentralize.edit-club-groups .btn.btn-height-sm::after {
      content: '';
      height: 0;
      width: 0; }
  .page-container .main-content .list-people {
    position: relative; }
    .page-container .main-content .list-people .tabs .link-tabs {
      display: flex;
      position: absolute;
      top: 0.5rem;
      left: 1.42857rem; }
      .page-container .main-content .list-people .tabs .link-tabs .btn {
        min-width: 140px;
        background-color: var(--white);
        position: relative;
        padding: 1.14286rem; }
        .page-container .main-content .list-people .tabs .link-tabs .btn:hover::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 0.21429rem;
          background: var(--primary-color);
          left: 0;
          bottom: 0; }
        .page-container .main-content .list-people .tabs .link-tabs .btn.active::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 0.21429rem;
          background: var(--primary-color);
          left: 0;
          bottom: 0;
          border-bottom-left-radius: 0.21429rem; }
        .page-container .main-content .list-people .tabs .link-tabs .btn.active:last-child::after {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0.21429rem; }
    .page-container .main-content .list-people .tabs .tab-list-people {
      border: none; }
      .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-info {
        margin-bottom: 1.07143rem; }
        .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-info .total-people {
          text-align: right;
          margin-bottom: 1.85714rem; }
          .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-info .total-people p {
            margin-bottom: 0.14286rem; }
        .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-info .list-page .title-content {
          margin-bottom: 0; }
        .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-info .list-page .box-control-list {
          align-self: center; }
          .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-info .list-page .box-control-list .btn i.icon {
            margin-right: 0.35714rem; }
          .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-info .list-page .box-control-list .btn-export {
            margin-right: 0.5rem; }
            .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-info .list-page .box-control-list .btn-export::after {
              content: none; }
          .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-info .list-page .box-control-list .pagination {
            margin-bottom: 0; }
      .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main {
        background-color: var(--white);
        padding: 0 1.42857rem 0.35714rem; }
        .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main .table-responsive .table {
          margin-bottom: 0.35714rem; }
          .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main .table-responsive .table thead th {
            color: var(--base-color);
            white-space: nowrap; }
          .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main .table-responsive .table td {
            white-space: nowrap;
            padding: 0 1.57143rem;
            height: 55px;
            vertical-align: middle;
            border-top: 1px solid var(--gray-color-2); }
            .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main .table-responsive .table td.list-photo .photo-user {
              width: 40px;
              height: 40px; }
              .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main .table-responsive .table td.list-photo .photo-user img {
                max-width: 40px;
                border-radius: 0.28571rem;
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%; }
            .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main .table-responsive .table td.edit-btn button {
              background-color: transparent;
              border: none; }
              .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main .table-responsive .table td.edit-btn button:focus, .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main .table-responsive .table td.edit-btn button:active {
                outline: 3px auto var(--primary-color); }
              .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main .table-responsive .table td.edit-btn button i.icon::before {
                color: var(--base-color); }
            .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main .table-responsive .table td.reset-icon {
              display: flex;
              justify-content: flex-end;
              align-items: center; }
              .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main .table-responsive .table td.reset-icon .reset-pass {
                padding: 0 0.71429rem;
                height: 40px;
                display: flex;
                align-items: center; }
                .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main .table-responsive .table td.reset-icon .reset-pass i.icon {
                  font-size: 1.71429rem;
                  margin-right: 0.5rem;
                  line-height: 1.5rem; }
                .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main .table-responsive .table td.reset-icon .reset-pass span {
                  align-self: baseline; }
            .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main .table-responsive .table td.status .multi-box {
              height: 40px;
              position: relative;
              display: flex;
              align-items: center;
              cursor: pointer; }
              .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main .table-responsive .table td.status .multi-box .stt-active {
                padding: 0.21429rem 1.42857rem;
                border: 1px solid #36db1b !important;
                color: #36db1b;
                background-color: #cdf3c9;
                border-radius: 3.57143rem; }
              .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main .table-responsive .table td.status .multi-box .stt-inactive {
                padding: 0.21429rem 1.42857rem;
                border: 1px solid #ea0303 !important;
                color: #ea0303 !important;
                background-color: #dea9a9;
                border-radius: 3.57143rem; }
              .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main .table-responsive .table td.status .multi-box i.icon {
                font-size: 1.57143rem; }
              .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main .table-responsive .table td.status .multi-box .drop-box {
                position: absolute;
                background-color: var(--white);
                top: 2.85714rem;
                border: 1px solid var(--gray-color-3);
                transform: translateX(-50%) scale(0);
                transform-origin: top;
                left: calc(50% - 10px);
                padding: 1.07143rem;
                z-index: 1; }
                .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-people-main .table-responsive .table td.status .multi-box .drop-box.show {
                  transform: translateX(-50%) scale(1); }
  .page-container .main-content .view-search-item .list-page .list-page-title {
    padding-right: 3.57143rem; }
    .page-container .main-content .view-search-item .list-page .list-page-title .title-content {
      margin-bottom: 0.35714rem; }
  .page-container .main-content .view-search-item .list-page .list-page-content {
    align-items: center; }
    .page-container .main-content .view-search-item .list-page .list-page-content .pagination {
      margin-bottom: 0; }
    .page-container .main-content .view-search-item .list-page .list-page-content .btn-filter {
      text-transform: uppercase; }
      .page-container .main-content .view-search-item .list-page .list-page-content .btn-filter i.icon {
        margin-right: 0.35714rem;
        color: var(--white); }
      .page-container .main-content .view-search-item .list-page .list-page-content .btn-filter span {
        color: var(--white);
        font-weight: 600; }
      .page-container .main-content .view-search-item .list-page .list-page-content .btn-filter:hover span,
      .page-container .main-content .view-search-item .list-page .list-page-content .btn-filter:hover i.icon {
        color: var(--base-color); }
  .page-container .main-content .view-search-item .view-search-main {
    padding: 0.14286rem;
    background-color: var(--white);
    box-shadow: 0rem 0.07143rem 0.28571rem var(--gray-color-3);
    border-radius: 0.28571rem; }
    .page-container .main-content .view-search-item .view-search-main table {
      margin-bottom: 0.35714rem; }
      .page-container .main-content .view-search-item .view-search-main table.table-base thead tr {
        border: 1px solid var(--primary-color); }
      .page-container .main-content .view-search-item .view-search-main table.table-base th {
        border: none;
        white-space: nowrap; }
      .page-container .main-content .view-search-item .view-search-main table.table-base td {
        white-space: nowrap; }
      .page-container .main-content .view-search-item .view-search-main table.table-base tbody tr {
        border-left: 1px solid var(--gray-color-2);
        border-right: 1px solid var(--gray-color-2); }
  .page-container .main-content .member .member-main {
    background-color: var(--white);
    padding: 0.14286rem 0.14286rem 0.07143rem;
    box-shadow: 0 0 0.21429rem var(--gray-color-3);
    border-radius: 0.21429rem; }
    .page-container .main-content .member .member-main .paging-steps {
      background-color: var(--gray-color-2);
      justify-content: space-between;
      padding: 1.07143rem 0 0.71429rem;
      margin-bottom: 0.71429rem; }
      .page-container .main-content .member .member-main .paging-steps .step {
        width: 15%;
        padding: 0 0.71429rem;
        transition: 0.2s; }
        .page-container .main-content .member .member-main .paging-steps .step:hover .number-step {
          background-color: var(--primary-color);
          color: var(--white);
          transition: 0.2s; }
        .page-container .main-content .member .member-main .paging-steps .step:hover p.text-step {
          font-weight: 700; }
        .page-container .main-content .member .member-main .paging-steps .step p.text-step {
          margin-bottom: 0;
          text-align: center;
          margin-top: 0.71429rem;
          text-transform: uppercase;
          color: var(--base-color); }
        .page-container .main-content .member .member-main .paging-steps .step .number-step {
          width: 3.57143rem;
          height: 3.57143rem;
          border-radius: 50%;
          border: 1px solid var(--primary-color);
          color: var(--primary-color);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.28571rem;
          font-weight: 700;
          margin: 0 auto;
          transition: 0.2s; }
        .page-container .main-content .member .member-main .paging-steps .step.active p.text-step {
          font-weight: 700; }
        .page-container .main-content .member .member-main .paging-steps .step.active .number-step {
          background: var(--primary-color);
          color: var(--white); }
      .page-container .main-content .member .member-main .paging-steps .section-dots {
        width: 6%;
        display: flex;
        justify-content: space-evenly;
        align-items: center; }
        .page-container .main-content .member .member-main .paging-steps .section-dots .dot {
          width: 0.57143rem;
          height: 0.57143rem;
          background-color: var(--gray-color-3);
          border-radius: 50%;
          display: block; }
        .page-container .main-content .member .member-main .paging-steps .section-dots.dots-primary .dot {
          background-color: var(--primary-color); }
          .page-container .main-content .member .member-main .paging-steps .section-dots.dots-primary .dot:nth-child(1) {
            opacity: 0.4; }
          .page-container .main-content .member .member-main .paging-steps .section-dots.dots-primary .dot:nth-child(2) {
            opacity: 0.6; }
          .page-container .main-content .member .member-main .paging-steps .section-dots.dots-primary .dot:nth-child(3) {
            opacity: 0.8; }
    .page-container .main-content .member .member-main .collapse-promotion {
      box-shadow: none;
      margin-bottom: 0;
      padding-bottom: 0.35714rem;
      border-bottom: 1px solid var(--gray-color-2); }
      .page-container .main-content .member .member-main .collapse-promotion .collapsible {
        background-color: transparent;
        text-transform: uppercase;
        font-weight: 700;
        padding: 0.71429rem 0 0; }
      .page-container .main-content .member .member-main .collapse-promotion .content {
        margin-top: 0.71429rem;
        padding: 0; }
        .page-container .main-content .member .member-main .collapse-promotion .content .amount {
          margin-bottom: 0;
          color: var(--primary-color);
          font-size: 1.21429rem;
          position: relative; }
          .page-container .main-content .member .member-main .collapse-promotion .content .amount sup {
            font-size: 0.57143rem;
            top: -0.57143rem; }
        .page-container .main-content .member .member-main .collapse-promotion .content .row .title {
          font-weight: 600; }
      .page-container .main-content .member .member-main .collapse-promotion:last-child {
        border-bottom: 0;
        padding-bottom: 0; }
    .page-container .main-content .member .member-main .list-page {
      margin: 0 0.71429rem 0.71429rem;
      padding-bottom: 0.71429rem;
      border-bottom: 1px solid var(--gray-color-2);
      align-items: center; }
      .page-container .main-content .member .member-main .list-page .title-content {
        margin-bottom: 0;
        font-size: 1rem; }
        .page-container .main-content .member .member-main .list-page .title-content.title-step {
          display: none; }
    .page-container .main-content .member .member-main .gr-gone-away {
      display: flex;
      justify-content: space-between; }
      .page-container .main-content .member .member-main .gr-gone-away .form-group {
        width: 48%; }
      .page-container .main-content .member .member-main .gr-gone-away .form-check {
        width: 52%;
        align-self: flex-end;
        margin-bottom: 1rem;
        padding-left: 0.71429rem; }
    .page-container .main-content .member .member-main .national-title,
    .page-container .main-content .member .member-main .payer-title {
      font-weight: 700; }
    .page-container .main-content .member .member-main .section-select {
      padding: 0 0.71429rem;
      display: flex;
      flex-wrap: wrap; }
      .page-container .main-content .member .member-main .section-select .form-group {
        width: calc(30% - 10px);
        margin-right: 1.42857rem; }
    .page-container .main-content .member .member-main .content {
      padding: 0 0.71429rem; }
      .page-container .main-content .member .member-main .content .row-content {
        justify-content: space-between;
        box-shadow: 0 0.07143rem 0.21429rem var(--gray-color-3);
        border-radius: 0.21429rem;
        margin-bottom: 0.71429rem; }
        .page-container .main-content .member .member-main .content .row-content ul {
          padding-left: 0;
          margin-bottom: 0; }
        .page-container .main-content .member .member-main .content .row-content .col-content {
          width: 25.66667%;
          position: relative;
          padding: 1.07143rem 0;
          padding-left: 1.07143rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
          .page-container .main-content .member .member-main .content .row-content .col-content::after {
            content: '';
            position: absolute;
            width: 1px;
            height: calc(100% - 30px);
            background-color: var(--gray-color-2);
            right: 0;
            top: 1.07143rem; }
          .page-container .main-content .member .member-main .content .row-content .col-content.pay::after {
            content: none; }
          .page-container .main-content .member .member-main .content .row-content .col-content.pay ul li::before {
            content: none; }
          .page-container .main-content .member .member-main .content .row-content .col-content.pay ul li p {
            margin-bottom: 0; }
          .page-container .main-content .member .member-main .content .row-content .col-content.pay ul li .pay-now .number-pay-now {
            font-size: 2.42857rem;
            font-weight: 700;
            color: var(--primary-color); }
            .page-container .main-content .member .member-main .content .row-content .col-content.pay ul li .pay-now .number-pay-now sup {
              font-size: 1.42857rem; }
          .page-container .main-content .member .member-main .content .row-content .col-content.pay ul li .regular .number-regular {
            margin-left: 0.42857rem;
            font-weight: 700;
            font-size: 1rem;
            vertical-align: top; }
            .page-container .main-content .member .member-main .content .row-content .col-content.pay ul li .regular .number-regular sup {
              font-size: 0.85714rem;
              top: -0.21429rem; }
          .page-container .main-content .member .member-main .content .row-content .col-content.select {
            width: 23%;
            margin-right: 0;
            padding: 0;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center; }
            .page-container .main-content .member .member-main .content .row-content .col-content.select::before {
              content: '';
              position: absolute;
              background: url("../images/bg-select-member.png") no-repeat;
              background-size: cover;
              width: 100%;
              height: 100%; }
            .page-container .main-content .member .member-main .content .row-content .col-content.select:after {
              content: none; }
            .page-container .main-content .member .member-main .content .row-content .col-content.select .btn {
              position: relative;
              font-weight: 700;
              min-width: 10rem;
              margin-left: 1.07143rem; }
          .page-container .main-content .member .member-main .content .row-content .col-content p.title {
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 0.71429rem; }
            .page-container .main-content .member .member-main .content .row-content .col-content p.title .note {
              text-transform: capitalize; }
          .page-container .main-content .member .member-main .content .row-content .col-content ul {
            font-size: 0.85714rem; }
            .page-container .main-content .member .member-main .content .row-content .col-content ul li {
              position: relative;
              padding-left: 0.92857rem; }
              .page-container .main-content .member .member-main .content .row-content .col-content ul li::before {
                content: '';
                position: absolute;
                width: 0.35714rem;
                height: 0.35714rem;
                background-color: var(--primary-color);
                border-radius: 50%;
                top: calc(50% - 2.5px);
                left: 0; }
      .page-container .main-content .member .member-main .content .row-gender {
        display: flex; }
        .page-container .main-content .member .member-main .content .row-gender .form-check {
          width: 50%; }
      .page-container .main-content .member .member-main .content .box-nation {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        background-color: var(--gray-color-2); }
      .page-container .main-content .member .member-main .content.select-add-on {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.71429rem; }
        .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main {
          width: 66%; }
          .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .option-group {
            display: flex;
            flex-wrap: wrap;
            overflow: visible;
            padding: 0.35714rem 0.14286rem 0; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .option-group .form-check {
              width: calc(50% - 10px);
              margin-bottom: 0.71429rem;
              box-shadow: 0 0 0.35714rem var(--gray-color-3);
              border-radius: 0.21429rem; }
              .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .option-group .form-check:nth-child(odd) {
                margin-right: 1.42857rem; }
              .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .option-group .form-check [type='checkbox']:not(:checked) + label:before,
              .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .option-group .form-check [type='checkbox']:checked + label:before {
                left: 0.71429rem;
                top: 0.71429rem; }
              .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .option-group .form-check [type='checkbox']:not(:checked) + label:after,
              .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .option-group .form-check [type='checkbox']:checked + label:after {
                left: 0.92857rem;
                top: 0.85714rem; }
              .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .option-group .form-check h4 {
                text-transform: uppercase;
                margin-bottom: 0; }
                .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .option-group .form-check h4.name-add-on {
                  margin-top: 0.28571rem; }
              .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .option-group .form-check label {
                padding: 0.71429rem;
                padding-left: 3.21429rem;
                width: 100%;
                margin-bottom: 0; }
              .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .option-group .form-check .info-add-on {
                background-color: var(--gray-color-2);
                padding: 0.35714rem 1.07143rem 0.35714rem 3.21429rem; }
                .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .option-group .form-check .info-add-on .monthly {
                  margin-bottom: 0; }
                  .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .option-group .form-check .info-add-on .monthly .add-on-number {
                    float: right;
                    font-weight: 700;
                    font-size: 1.14286rem;
                    color: var(--primary-color); }
                    .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .option-group .form-check .info-add-on .monthly .add-on-number sub {
                      top: -0.35714rem; }
              .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .option-group .form-check .custom-radio {
                margin-bottom: 0; }
                .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .option-group .form-check .custom-radio .card-gr ul {
                  padding-left: 0;
                  margin-bottom: 0; }
                .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .option-group .form-check .custom-radio .checkmark-radio {
                  top: 0.71429rem;
                  left: 0.71429rem; }
          .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .select-add-on-title {
            border-bottom: 1px solid var(--gray-color-2);
            margin-bottom: 0;
            padding-bottom: 0.71429rem;
            font-weight: 700; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .select-add-on-title.select-add-on-title--custom {
              padding-top: 0.71429rem;
              padding-bottom: 1.07143rem;
              font-size: 1.14286rem;
              font-weight: 500; }
          .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image {
            width: 24.64286rem;
            margin: 0 auto;
            position: relative;
            margin-bottom: 2.5rem; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image img {
              width: 100%; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image h2,
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image h3,
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image h4,
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image .section-exp {
              color: var(--gray-color-3);
              position: absolute;
              left: 2.5rem; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image .name-payment {
              text-transform: uppercase;
              top: 1.78571rem;
              font-weight: 400; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image .card-number {
              top: 50%;
              font-weight: 500;
              letter-spacing: 0.14286rem; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image .section-exp {
              top: calc(50% + 35px);
              left: auto;
              right: 2.85714rem;
              display: flex; }
              .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image .section-exp .exp-title {
                display: flex;
                flex-direction: column;
                margin-right: 1.07143rem;
                align-self: center;
                align-items: center;
                position: relative; }
                .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image .section-exp .exp-title::after {
                  content: '';
                  position: absolute;
                  background: url(../images/arr-card-pay.png) no-repeat;
                  width: 0.42857rem;
                  height: 0.42857rem;
                  right: -0.71429rem;
                  background-size: 100%;
                  top: calc(50% - 3px); }
                .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image .section-exp .exp-title span {
                  line-height: 1;
                  font-size: 0.5rem;
                  text-transform: uppercase; }
              .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image .section-exp .exp-date {
                margin-bottom: 0;
                font-weight: 400;
                left: auto;
                position: relative;
                align-self: center; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image .acc-name {
              bottom: 1.42857rem;
              font-weight: 500; }
          .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .info-card {
            display: flex;
            flex-wrap: wrap; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .info-card .form-group {
              width: calc(50% - 10px); }
              .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .info-card .form-group:nth-child(odd) {
                margin-right: 1.07143rem; }
          .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .behind-card .detail-card {
            display: flex;
            justify-content: space-between; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .behind-card .detail-card .form-group:nth-child(1) {
              width: 49%; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .behind-card .detail-card .form-group:nth-child(2), .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .behind-card .detail-card .form-group:nth-child(3) {
              width: 23%; }
          .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .national,
          .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .payer-detail {
            margin-top: 0.71429rem;
            border-bottom: 1px solid var(--gray-color-2); }
      .page-container .main-content .member .member-main .content.product-extra {
        padding-bottom: 3.71429rem; }
      .page-container .main-content .member .member-main .content.personal .select-add-on-main .main-table .title-dupplicate {
        margin-bottom: 0.71429rem;
        padding-bottom: 0.71429rem;
        border-bottom: 1px solid var(--gray-color-2);
        font-weight: 700; }
      .page-container .main-content .member .member-main .content.personal .select-add-on-main .main-table .title-search .total-member {
        font-weight: 700; }
      .page-container .main-content .member .member-main .content.personal .select-add-on-main .collapse-promotion > .content {
        margin-top: 0.71429rem; }
      .page-container .main-content .member .member-main .content.personal .box-nation p {
        padding: 1.07143rem 1.07143rem 0; }
      .page-container .main-content .member .member-main .content.personal .box-nation .custom-select-saas .select-selected {
        background-color: var(--white); }
      .page-container .main-content .member .member-main .content .aside-summary {
        width: 30%;
        border: 1px solid var(--gray-color-2);
        padding: 0.07143rem;
        border-radius: 0.21429rem; }
        .page-container .main-content .member .member-main .content .aside-summary button.btn-tooltip {
          padding: 0 !important;
          margin: 0 0.25em; }
        .page-container .main-content .member .member-main .content .aside-summary .icon-detail-no-boder {
          color: var(--secondary-color); }
        .page-container .main-content .member .member-main .content .aside-summary .title-content {
          margin-bottom: 0;
          font-size: 1rem; }
        .page-container .main-content .member .member-main .content .aside-summary .section-billing,
        .page-container .main-content .member .member-main .content .aside-summary .section-info,
        .page-container .main-content .member .member-main .content .aside-summary .section-add-ons,
        .page-container .main-content .member .member-main .content .aside-summary .section-promotions {
          padding: 1.07143rem;
          border-bottom: 1px solid var(--gray-color-2); }
        .page-container .main-content .member .member-main .content .aside-summary .section-promotions {
          border-bottom: none; }
        .page-container .main-content .member .member-main .content .aside-summary .row-span {
          display: flex;
          justify-content: space-between; }
          .page-container .main-content .member .member-main .content .aside-summary .row-span .last-span {
            text-align: right; }
            .page-container .main-content .member .member-main .content .aside-summary .row-span .last-span .icon {
              margin-left: 0.21429rem; }
          .page-container .main-content .member .member-main .content .aside-summary .row-span .first-span {
            width: 27%;
            flex-grow: 0; }
          .page-container .main-content .member .member-main .content .aside-summary .row-span .dots {
            flex-grow: 2;
            display: block;
            align-self: end;
            width: auto;
            margin: 0 10px;
            position: relative; }
            .page-container .main-content .member .member-main .content .aside-summary .row-span .dots::after {
              content: '';
              position: absolute;
              width: 100%;
              height: 1px;
              border-bottom: 1px dotted var(--gray-color-3);
              top: 1rem; }
          .page-container .main-content .member .member-main .content .aside-summary .row-span .last-span {
            flex-grow: 0;
            max-width: 60%; }
        .page-container .main-content .member .member-main .content .aside-summary .title-summary,
        .page-container .main-content .member .member-main .content .aside-summary .pay-now {
          text-align: center;
          font-weight: 700;
          text-transform: uppercase;
          background-color: var(--gray-color-2);
          padding: 1.07143rem 0.35714rem; }
        .page-container .main-content .member .member-main .content .aside-summary .title-summary {
          margin-bottom: 0;
          position: relative; }
          .page-container .main-content .member .member-main .content .aside-summary .title-summary::after {
            content: '';
            position: absolute;
            width: calc(100% - 24px);
            height: 1px;
            background: var(--white);
            left: 50%;
            bottom: 0;
            transform: translateX(-50%); }
        .page-container .main-content .member .member-main .content .aside-summary .pay-now p {
          margin-bottom: 0; }
        .page-container .main-content .member .member-main .content .aside-summary .pay-now .number-pay-now {
          color: var(--primary-color);
          font-size: 1.85714rem;
          position: relative; }
          .page-container .main-content .member .member-main .content .aside-summary .pay-now .number-pay-now sup {
            font-size: 1.21429rem;
            top: -0.57143rem; }
          .page-container .main-content .member .member-main .content .aside-summary .pay-now .number-pay-now .icon {
            font-size: 1.14286rem;
            margin-left: 0.35714rem;
            position: absolute;
            top: 0.42857rem; }
        .page-container .main-content .member .member-main .content .aside-summary .box-btn .btn--aside {
          min-width: calc((100% - 10px) / 2); }
          .page-container .main-content .member .member-main .content .aside-summary .box-btn .btn--aside.btn-secondary {
            margin-right: 0.71429rem; }
        .page-container .main-content .member .member-main .content .aside-summary .box-btn .btn--aside-block {
          min-width: 100%; }
        .page-container .main-content .member .member-main .content .aside-summary .box-btn.box-btn-mt0 {
          padding-top: 0; }
    .page-container .main-content .member .member-main .box-btn {
      display: flex;
      justify-content: flex-end;
      padding: 1.07143rem 0.71429rem; }
      .page-container .main-content .member .member-main .box-btn .btn {
        text-transform: uppercase;
        font-weight: 700;
        min-width: 140px;
        margin-right: 1.42857rem; }
        .page-container .main-content .member .member-main .box-btn .btn:last-child {
          margin-right: 0; }
    .page-container .main-content .member .member-main .btn--apply {
      text-transform: uppercase;
      font-size: 12px;
      width: 25%;
      padding: 0.21429rem;
      position: absolute;
      right: 0.21429rem;
      top: 0.21429rem;
      z-index: 4; }
    .page-container .main-content .member .member-main .edit-custom-btn {
      padding: 1.07143rem 0rem; }
      .page-container .main-content .member .member-main .edit-custom-btn .btn {
        text-transform: uppercase;
        font-weight: 700;
        min-width: 140px;
        margin-right: 1.42857rem; }
  .page-container .main-content .member .table-responsive-auto {
    overflow: auto; }
    .page-container .main-content .member .table-responsive-auto .table.table-base th {
      border-right: none; }
    .page-container .main-content .member .table-responsive-auto .table thead th {
      padding: 0.71429rem;
      vertical-align: middle; }
    .page-container .main-content .member .table-responsive-auto .table tbody tr {
      cursor: pointer; }
    .page-container .main-content .member .table-responsive-auto .status-ok {
      color: var(--primary-color); }
    .page-container .main-content .member .table-responsive-auto .status-pending {
      color: var(--danger-color); }
    .page-container .main-content .member .table-responsive-auto .status-joined {
      color: var(--warning-color); }
  .page-container .main-content .member .container-modal .modalSaas .modalSaas__content p span {
    color: var(--primary-color); }
  .page-container .main-content #sub-tenant {
    align-self: center; }
    .page-container .main-content #sub-tenant .sub-tenant {
      background-color: var(--white);
      box-shadow: -0.07143rem 0rem 0.35714rem var(--gray-color-3);
      max-width: 540px;
      border-radius: 0.21429rem;
      padding: 0.07143rem; }
      .page-container .main-content #sub-tenant .sub-tenant .title-sub-tenant {
        min-height: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url(../images/map.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center; }
        .page-container .main-content #sub-tenant .sub-tenant .title-sub-tenant h3 {
          margin-bottom: 0; }
      .page-container .main-content #sub-tenant .sub-tenant .main-sub-tenant .intro-sub-tenant {
        padding: 1.42857rem; }
        .page-container .main-content #sub-tenant .sub-tenant .main-sub-tenant .intro-sub-tenant .title {
          margin-bottom: 2.14286rem; }
        .page-container .main-content #sub-tenant .sub-tenant .main-sub-tenant .intro-sub-tenant .note {
          font-size: 0.85714rem;
          margin-bottom: 0; }
      .page-container .main-content #sub-tenant .sub-tenant .main-sub-tenant .box-btn-select {
        padding: 0 1.42857rem; }
        .page-container .main-content #sub-tenant .sub-tenant .main-sub-tenant .box-btn-select .btn {
          width: 100%;
          text-transform: uppercase;
          background-color: #fcfcfc;
          margin-bottom: 1.42857rem; }
          .page-container .main-content #sub-tenant .sub-tenant .main-sub-tenant .box-btn-select .btn.active, .page-container .main-content #sub-tenant .sub-tenant .main-sub-tenant .box-btn-select .btn:hover {
            background-color: var(--primary-color);
            color: var(--white);
            border-color: var(--primary-color); }
      .page-container .main-content #sub-tenant .sub-tenant .main-sub-tenant .box-btn-confirm {
        padding: 1.42857rem 0;
        border-top: 1px solid var(--gray-color-2); }
        .page-container .main-content #sub-tenant .sub-tenant .main-sub-tenant .box-btn-confirm .row {
          justify-content: center; }
        .page-container .main-content #sub-tenant .sub-tenant .main-sub-tenant .box-btn-confirm .btn {
          text-transform: uppercase;
          font-weight: 700;
          width: 100%; }

.page-container .main-content--custom {
  padding: 0rem 0.71429rem; }

.modal-new-pass .title {
  margin-bottom: 0;
  padding-bottom: 1.42857rem; }

.modal-new-pass .new-pass {
  font-size: 3.21429rem;
  font-weight: 700; }
  .modal-new-pass .new-pass .code-pass {
    border: 1px dashed var(--gray-color-3);
    padding: 0.35714rem 1.42857rem;
    position: relative;
    cursor: pointer; }
    .modal-new-pass .new-pass .code-pass .icon {
      font-size: 1.14286rem;
      position: absolute;
      bottom: 0.35714rem; }
  .modal-new-pass .new-pass p.confirm {
    font-weight: 400;
    font-size: 0.85714rem;
    font-style: italic;
    margin-top: 0.35714rem; }

.modal-new-pass .info-new-pass {
  padding-top: 0.71429rem; }

.plan-edit .basic-3 {
  display: flex;
  flex-direction: column; }

.plan-edit .available-from {
  padding-right: 0; }

.plan-edit .line-date {
  padding: 0;
  width: 0.14286rem;
  position: relative; }
  .plan-edit .line-date span {
    position: absolute;
    top: 45%;
    left: 40%; }

.plan-edit .available-to {
  padding-left: 0; }

.plan-edit .available-from .form-control,
.plan-edit .available-to .form-control {
  height: 2.28571rem;
  border-radius: 0.28571rem; }

.plan-edit .club-group {
  text-align: left; }
  .plan-edit .club-group .stamp {
    padding: 0.35714rem;
    border: 1px solid var(--gray-color-3);
    border-radius: 1.42857rem;
    background-color: var(--gray-color-2);
    margin-right: 0.35714rem; }

.plan-edit .periods {
  font-size: 13px; }
  .plan-edit .periods .period-item {
    display: flex; }
    .plan-edit .periods .period-item .period-item-1 {
      width: 43%;
      justify-content: start; }
  .plan-edit .periods .form-check span {
    font-size: 13px; }
  .plan-edit .periods .plan {
    width: 50%;
    padding-right: 0;
    padding-left: 1.07143rem; }
  .plan-edit .periods .package {
    width: 50%;
    padding-left: 0; }
  .plan-edit .periods .input-group {
    width: 2.64286rem;
    margin: 0 0.35714rem; }
  .plan-edit .periods .select-custom {
    width: 5.71429rem; }
  .plan-edit .periods .periods-between {
    padding: 0.71429rem 0.35714rem 0.35714rem 0.35714rem;
    margin-bottom: 0.71429rem;
    background-color: var(--gray-color-2); }
    .plan-edit .periods .periods-between .select-custom--between {
      width: 9.07143rem; }

.plan-edit .payments .payments-main {
  max-height: 85.71429rem;
  overflow: auto; }
  .plan-edit .payments .payments-main .title-medium {
    font-weight: 500; }

.plan-edit .payments .title {
  margin-right: 1.42857rem; }

.plan-edit .payments .select-new-item {
  color: var(--primary-color);
  cursor: pointer; }

.plan-edit .payments .selling-price .price-is,
.plan-edit .payments .selling-price .priority,
.plan-edit .payments .selling-price .is-club,
.plan-edit .payments .selling-price .from-to {
  margin-right: 1.42857rem; }

.plan-edit .payments .selling-price .priority > div,
.plan-edit .payments .selling-price .is-club > div {
  width: 12.14286rem; }

.plan-edit .payments .selling-price form.price-is {
  display: inline-block; }
  .plan-edit .payments .selling-price form.price-is .input-group {
    width: unset; }
    .plan-edit .payments .selling-price form.price-is .input-group .form-control {
      width: 10rem; }

.plan-edit .payments .selling-price .priority.select-custom,
.plan-edit .payments .selling-price .is-club.select-custom,
.plan-edit .payments .selling-price .from-to.input-group {
  display: inline-block;
  width: unset; }
  .plan-edit .payments .selling-price .priority.select-custom .form-control,
  .plan-edit .payments .selling-price .is-club.select-custom .form-control,
  .plan-edit .payments .selling-price .from-to.input-group .form-control {
    width: 10rem;
    height: 2.28571rem;
    border-radius: 0.28571rem; }

.plan-edit .payments .selling-price .span-3 {
  display: block;
  width: 100%; }

.plan-edit .payments .selling-price .from-to.input-group {
  margin-top: 0.71429rem; }

.plan-edit .payments .hr {
  width: 100%;
  height: 0.07143rem;
  background: var(--gray-color-2);
  margin: 1.42857rem 0; }

.plan-edit .comment {
  width: 100%; }
  .plan-edit .comment .form-control {
    width: 80%; }
  .plan-edit .comment .title {
    font-weight: 500; }
  .plan-edit .comment p {
    margin-left: 1.42857rem; }
    .plan-edit .comment p span {
      text-decoration: underline;
      font-style: italic;
      color: var(--blue-color);
      cursor: pointer; }

.plan-edit .no-padding-left {
  padding-left: 0 !important; }

.plan-edit .member .member-main.member-main--custom > .content {
  padding: 0.3rem 1.3rem; }
  .plan-edit .member .member-main.member-main--custom > .content .collapse-promotion {
    border-bottom: none; }
    .plan-edit .member .member-main.member-main--custom > .content .collapse-promotion button.collapsible {
      border-bottom: 1px solid var(--gray-color-2);
      padding-bottom: 0.7142857143rem; }
    .plan-edit .member .member-main.member-main--custom > .content .collapse-promotion .content .title {
      font-weight: 500; }

.plan-edit .icon--calendar {
  position: absolute;
  border: none;
  background: transparent;
  right: 0;
  top: 15%;
  font-size: 20px;
  z-index: 3;
  outline: none; }

.update-plan-availability .table-main-content {
  padding: 1.07143rem; }

footer {
  height: 2.5714285714285716em;
  padding: 0 1.42857rem 0 2.14286rem;
  background-color: var(--white-1);
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  footer h3 {
    margin-bottom: 0;
    font-size: 1rem; }

@media (min-width: 1367px) {
  .plan-edit .payments .selling-price .span-3 {
    display: inline-block;
    width: 0; } }

.tooltip-info-table thead {
  border-bottom: 1px solid var(--gray-color-1); }
  .tooltip-info-table thead th {
    vertical-align: bottom;
    padding: 0.25em 0.5em; }

.tooltip-info-table tbody td {
  vertical-align: top;
  padding: 0.25em 0.5em; }

.sidebar {
  bottom: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: all 0.2s ease;
  width: 260px;
  z-index: 5;
  box-shadow: 0em 0.14286em 0.32143em 0.03571em rgba(0, 0, 0, 0.2);
  background-color: var(--white); }
  .sidebar ul {
    list-style-type: none;
    cursor: pointer; }
  .sidebar .title {
    color: var(--base-color);
    white-space: nowrap; }
  .sidebar .icon::before {
    color: var(--base-color);
    vertical-align: middle; }
  .sidebar .sub-menu > li.nav-item:not(.dropdown):hover span,
  .sidebar .sub-menu > li.nav-item:not(.dropdown):hover .icon::before {
    color: var(--primary-color); }
  .sidebar .sub-menu > li.nav-item.dropdown > .dropdown-toggle:hover {
    background-color: transparent;
    color: var(--primary-color); }
    .sidebar .sub-menu > li.nav-item.dropdown > .dropdown-toggle:hover span,
    .sidebar .sub-menu > li.nav-item.dropdown > .dropdown-toggle:hover .icon::before {
      color: var(--primary-color); }
  .sidebar .sub-menu .sub-menu-1 .icon-holder {
    display: none; }
  .sidebar .sub-menu .sub-menu-1 > li {
    padding-left: 2.57143rem !important; }
    .sidebar .sub-menu .sub-menu-1 > li:not(.dropdown):hover span,
    .sidebar .sub-menu .sub-menu-1 > li:not(.dropdown):hover .icon::before {
      color: var(--primary-color); }
    .sidebar .sub-menu .sub-menu-1 > li.dropdown > .dropdown-toggle:hover {
      color: var(--primary-color); }
      .sidebar .sub-menu .sub-menu-1 > li.dropdown > .dropdown-toggle:hover span,
      .sidebar .sub-menu .sub-menu-1 > li.dropdown > .dropdown-toggle:hover .icon::before {
        color: var(--primary-color); }
  .sidebar .sub-menu .sub-menu-2 > li:not(.dropdown):hover span,
  .sidebar .sub-menu .sub-menu-2 > li:not(.dropdown):hover .icon::before {
    color: var(--primary-color); }
  .sidebar .sub-menu .sub-menu-2 > li.dropdown > .dropdown-toggle:hover {
    color: var(--primary-color); }
    .sidebar .sub-menu .sub-menu-2 > li.dropdown > .dropdown-toggle:hover span,
    .sidebar .sub-menu .sub-menu-2 > li.dropdown > .dropdown-toggle:hover .icon::before {
      color: var(--primary-color); }
  .sidebar .sub-menu > li.nav-item {
    padding-right: 1.07143rem; }
  .sidebar .sub-menu > li.nav-item:not(.dropdown) {
    line-height: 21px;
    display: flex;
    align-items: center;
    padding-right: 1.07143rem; }
    .sidebar .sub-menu > li.nav-item:not(.dropdown) .sidebar-link {
      display: flex; }
      .sidebar .sub-menu > li.nav-item:not(.dropdown) .sidebar-link .icon-holder {
        display: block;
        align-self: center; }
      .sidebar .sub-menu > li.nav-item:not(.dropdown) .sidebar-link .title {
        white-space: normal;
        line-height: 21px; }
  .sidebar .dropdown-toggle {
    position: relative;
    padding: 0 1.42857rem; }
    .sidebar .dropdown-toggle::before {
      content: '\e901';
      position: absolute;
      font-family: 'icomoon' !important;
      border: none;
      font-size: 1.78571rem;
      right: 0.53571rem; }
    .sidebar .dropdown-toggle::after {
      content: none; }
  .sidebar .dropdown.active > .dropdown-toggle {
    background-color: var(--gray-color-2);
    color: var(--primary-color); }
    .sidebar .dropdown.active > .dropdown-toggle::before {
      transform: rotate(180deg);
      transition: all 0.1s ease; }
  .sidebar .sidebar-inner .slidebar-search {
    display: none; }
  .sidebar .sidebar-inner .sidebar-logo {
    height: 60px;
    display: flex;
    padding: 0 1.57143rem;
    border-bottom: 1px solid var(--gray-color-2);
    cursor: pointer; }
    .sidebar .sidebar-inner .sidebar-logo .logo-box {
      display: flex;
      align-items: center;
      width: 100%; }
      .sidebar .sidebar-inner .sidebar-logo .logo-box .logo-menu {
        display: block;
        position: relative;
        z-index: 1;
        -webkit-user-select: none;
        user-select: none; }
        .sidebar .sidebar-inner .sidebar-logo .logo-box .logo-menu span {
          display: block;
          width: 14px;
          height: 2px;
          margin-bottom: 0.21429rem;
          position: relative;
          background: var(--black);
          border-radius: 0.28571rem;
          z-index: 1;
          transform-origin: 4px 0px;
          transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease; }
          .sidebar .sidebar-inner .sidebar-logo .logo-box .logo-menu span:last-child {
            margin-bottom: 0; }
      .sidebar .sidebar-inner .sidebar-logo .logo-box h5 {
        margin-bottom: 0;
        margin-left: 1.07143rem; }
    .sidebar .sidebar-inner .sidebar-logo .logo-mb {
      display: none; }
  .sidebar .sidebar-inner .sidebar-menu {
    height: calc(100vh - 80px);
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    position: relative; }
    .sidebar .sidebar-inner .sidebar-menu::-webkit-scrollbar {
      width: 0; }
    .sidebar .sidebar-inner .sidebar-menu .nav-item .dropdown-menu {
      border: none;
      padding: 0;
      position: relative;
      float: none;
      font-size: 0.92857rem; }
      .sidebar .sidebar-inner .sidebar-menu .nav-item .dropdown-menu li {
        padding: 0;
        height: 45px;
        line-height: 3.21429rem; }
        .sidebar .sidebar-inner .sidebar-menu .nav-item .dropdown-menu li.dropdown {
          height: auto; }
        .sidebar .sidebar-inner .sidebar-menu .nav-item .dropdown-menu li .sidebar-link {
          color: var(--base-color); }
          .sidebar .sidebar-inner .sidebar-menu .nav-item .dropdown-menu li .sidebar-link .icon-holder {
            padding-right: 0.71429rem; }
            .sidebar .sidebar-inner .sidebar-menu .nav-item .dropdown-menu li .sidebar-link .icon-holder .icon::before {
              font-size: 1.28571rem;
              vertical-align: middle; }
      .sidebar .sidebar-inner .sidebar-menu .nav-item .dropdown-menu.sub-menu-1 li.dropdown {
        height: auto; }
        .sidebar .sidebar-inner .sidebar-menu .nav-item .dropdown-menu.sub-menu-1 li.dropdown > .dropdown-toggle.active span,
        .sidebar .sidebar-inner .sidebar-menu .nav-item .dropdown-menu.sub-menu-1 li.dropdown > .dropdown-toggle.active .icon::before {
          color: var(--primary-color); }
        .sidebar .sidebar-inner .sidebar-menu .nav-item .dropdown-menu.sub-menu-1 li.dropdown > .dropdown-toggle .icon-holder {
          padding-right: 0.71429rem; }
          .sidebar .sidebar-inner .sidebar-menu .nav-item .dropdown-menu.sub-menu-1 li.dropdown > .dropdown-toggle .icon-holder .icon::before {
            font-size: 1.5rem; }
        .sidebar .sidebar-inner .sidebar-menu .nav-item .dropdown-menu.sub-menu-1 li.dropdown > .dropdown-toggle > .dropdown-menu {
          padding-left: 0; }
    .sidebar .sidebar-inner .sidebar-menu > .nav-item {
      border-bottom: none; }
      .sidebar .sidebar-inner .sidebar-menu > .nav-item > .dropdown-menu li {
        padding-left: 2.14286rem; }
        .sidebar .sidebar-inner .sidebar-menu > .nav-item > .dropdown-menu li:not(.dropdown):hover {
          background-color: var(--bg-row-1); }
        .sidebar .sidebar-inner .sidebar-menu > .nav-item > .dropdown-menu li.dropdown {
          padding-left: 0; }
          .sidebar .sidebar-inner .sidebar-menu > .nav-item > .dropdown-menu li.dropdown > .dropdown-toggle {
            padding: 0;
            padding-left: 2.14286rem; }
            .sidebar .sidebar-inner .sidebar-menu > .nav-item > .dropdown-menu li.dropdown > .dropdown-toggle:hover {
              background-color: var(--bg-row-1); }
      .sidebar .sidebar-inner .sidebar-menu > .nav-item.dropdown.active > .dropdown-toggle {
        background-color: var(--gray-color-2);
        color: var(--primary-color); }
        .sidebar .sidebar-inner .sidebar-menu > .nav-item.dropdown.active > .dropdown-toggle::before {
          transform: rotate(180deg);
          transition: all 0.1s ease; }
        .sidebar .sidebar-inner .sidebar-menu > .nav-item.dropdown.active > .dropdown-toggle span,
        .sidebar .sidebar-inner .sidebar-menu > .nav-item.dropdown.active > .dropdown-toggle .icon::before {
          color: var(--primary-color); }
      .sidebar .sidebar-inner .sidebar-menu > .nav-item > .dropdown-toggle {
        height: 45px;
        line-height: 3.21429rem; }
        .sidebar .sidebar-inner .sidebar-menu > .nav-item > .dropdown-toggle:hover {
          background-color: var(--bg-row-1); }
        .sidebar .sidebar-inner .sidebar-menu > .nav-item > .dropdown-toggle .icon-holder {
          padding-right: 0.71429rem; }
          .sidebar .sidebar-inner .sidebar-menu > .nav-item > .dropdown-toggle .icon-holder .icon::before {
            font-size: 1.28571rem;
            vertical-align: middle; }
        .sidebar .sidebar-inner .sidebar-menu > .nav-item > .dropdown-toggle .title {
          font-weight: 600;
          text-transform: uppercase;
          font-size: 0.80357rem; }
      .sidebar .sidebar-inner .sidebar-menu > .nav-item > .dropdown-menu > .nav-item > .dropdown-toggle .icon-holder {
        padding-right: 0.71429rem; }
        .sidebar .sidebar-inner .sidebar-menu > .nav-item > .dropdown-menu > .nav-item > .dropdown-toggle .icon-holder .icon::before {
          font-size: 1.5rem;
          vertical-align: middle; }
    .sidebar .sidebar-inner .sidebar-menu .dropdown-menu.open {
      position: relative;
      display: block;
      float: none; }

.is-collapsed .sidebar {
  width: 60px;
  transition: all 0.2s ease;
  background-color: var(--primary-color);
  color: var(--white); }
  .is-collapsed .sidebar .dropdown-toggle::before {
    content: none; }
  .is-collapsed .sidebar .dropdown.active > .dropdown-toggle {
    background-color: var(--hover-bg-color); }
  .is-collapsed .sidebar .sidebar-inner .icon::before,
  .is-collapsed .sidebar .sidebar-inner span,
  .is-collapsed .sidebar .sidebar-inner .dropdown-toggle {
    color: var(--white); }
  .is-collapsed .sidebar .sidebar-inner .sidebar-logo {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
    .is-collapsed .sidebar .sidebar-inner .sidebar-logo .logo-box h5 {
      display: none;
      color: var(--white); }
    .is-collapsed .sidebar .sidebar-inner .sidebar-logo .logo-box .logo-menu span {
      background: var(--white); }
  .is-collapsed .sidebar .sidebar-inner .sidebar-menu span {
    color: var(--white); }
  .is-collapsed .sidebar .sidebar-inner .sidebar-menu .nav-item .dropdown-menu {
    background-color: var(--hover-bg-color);
    border-radius: 0;
    margin: 0; }
  .is-collapsed .sidebar .sidebar-inner .sidebar-menu > .nav-item {
    border-bottom: none; }
    .is-collapsed .sidebar .sidebar-inner .sidebar-menu > .nav-item > .dropdown-toggle:hover {
      background-color: var(--hover-bg-color); }
    .is-collapsed .sidebar .sidebar-inner .sidebar-menu > .nav-item.dropdown.active > .dropdown-toggle {
      background-color: var(--hover-bg-color);
      color: var(--white); }
      .is-collapsed .sidebar .sidebar-inner .sidebar-menu > .nav-item.dropdown.active > .dropdown-toggle .icon::before,
      .is-collapsed .sidebar .sidebar-inner .sidebar-menu > .nav-item.dropdown.active > .dropdown-toggle span {
        color: var(--white); }
  .is-collapsed .sidebar .title {
    display: none; }
  .is-collapsed .sidebar.sidebar-open {
    width: 300px; }
    .is-collapsed .sidebar.sidebar-open .title {
      display: inline; }
    .is-collapsed .sidebar.sidebar-open .sidebar-inner .sidebar-logo .logo-box h5 {
      display: block; }

@media (max-width: 1200px) {
  .page-container {
    padding-left: 5rem; }
    .page-container header {
      width: calc(100% - 70px); } }

@media (max-width: 767px) {
  body.app {
    transition: all 0.2s ease; }
    body.app:not(.is-collapsed) {
      position: relative;
      transition: all 0.2s ease; }
      body.app:not(.is-collapsed)::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--black);
        z-index: 5;
        opacity: 0.5; }
  nav.sidebar {
    background-color: var(--white);
    width: calc(100% - 40px); }
    nav.sidebar::before {
      left: auto;
      right: 0;
      z-index: 5; }
    nav.sidebar .sidebar__icon .search-box {
      padding: 0.71429rem 1.42857rem;
      border-top: 1px solid var(--gray-color-2); }
    nav.sidebar .sidebar__icon ul.sidebar__content span::before {
      font-size: 1.64286rem;
      color: var(--base-color); }
    nav.sidebar .sidebar__icon ul.sidebar__content > li.dropdown .icon,
    nav.sidebar .sidebar__icon ul.sidebar__content li.dropdown.active .icon {
      width: 100%;
      background-color: var(--white);
      border-top: 1px solid var(--gray-color-2);
      border-bottom: 1px solid var(--gray-color-2);
      position: relative;
      height: 45px;
      line-height: 3.21429rem; }
      nav.sidebar .sidebar__icon ul.sidebar__content > li.dropdown .icon::before,
      nav.sidebar .sidebar__icon ul.sidebar__content li.dropdown.active .icon::before {
        font-size: 1.71429rem;
        position: absolute;
        left: 1.42857rem; }
      nav.sidebar .sidebar__icon ul.sidebar__content > li.dropdown .icon > p.sidebar__title,
      nav.sidebar .sidebar__icon ul.sidebar__content li.dropdown.active .icon > p.sidebar__title {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.85714rem;
        color: var(--base-color);
        margin-bottom: 0;
        padding-left: 3.57143rem;
        text-align: left; }
    nav.sidebar .sidebar__icon ul.sidebar__content > li.dropdown > .dropdown-menu,
    nav.sidebar .sidebar__icon ul.sidebar__content li.dropdown.active > .dropdown-menu {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      height: auto;
      width: 100%;
      float: none; }
      nav.sidebar .sidebar__icon ul.sidebar__content > li.dropdown > .dropdown-menu .dropdown-item,
      nav.sidebar .sidebar__icon ul.sidebar__content li.dropdown.active > .dropdown-menu .dropdown-item {
        height: 45px;
        line-height: 3.21429rem; }
        nav.sidebar .sidebar__icon ul.sidebar__content > li.dropdown > .dropdown-menu .dropdown-item span::before,
        nav.sidebar .sidebar__icon ul.sidebar__content li.dropdown.active > .dropdown-menu .dropdown-item span::before {
          font-size: 1.64286rem; }
      nav.sidebar .sidebar__icon ul.sidebar__content > li.dropdown > .dropdown-menu > .dropdown,
      nav.sidebar .sidebar__icon ul.sidebar__content li.dropdown.active > .dropdown-menu > .dropdown {
        height: 55px;
        line-height: 3.92857rem; }
      nav.sidebar .sidebar__icon ul.sidebar__content > li.dropdown > .dropdown-menu .sidebar__title--active,
      nav.sidebar .sidebar__icon ul.sidebar__content li.dropdown.active > .dropdown-menu .sidebar__title--active {
        display: none; }
    nav.sidebar .sidebar-inner .slidebar-search {
      display: block; }
      nav.sidebar .sidebar-inner .slidebar-search.search-box {
        padding: 0.92857rem 1.07143rem;
        border-bottom: 1px solid var(--gray-color-2); }
    nav.sidebar .sidebar-inner .sidebar-logo .logo-box {
      width: auto; }
      nav.sidebar .sidebar-inner .sidebar-logo .logo-box .logo-text {
        display: none; }
    nav.sidebar .sidebar-inner .sidebar-logo .logo-mb {
      display: block;
      align-self: center;
      margin-left: 1.42857rem; }
  .is-collapsed .sidebar {
    margin-left: -5.35714rem;
    background-color: var(--white); }
    .is-collapsed .sidebar .sidebar__icon ul.sidebar__content li.dropdown > .icon {
      background-color: var(--white); }
  .is-collapsed .page-container {
    padding-left: 0; }
    .is-collapsed .page-container header {
      width: 100%; } }

@media (max-width: 1023px) {
  .login .container .multi-box {
    padding-right: 1.42857rem; }
    .login .container .multi-box .dropdown-content {
      right: 1.07143rem; }
  .page-container header .text-hidden-mb, .page-container header .account-name {
    display: none; }
  .page-container header .header {
    padding-right: 2.5rem; }
  .page-container header .header__logo {
    min-width: 170px; }
  .page-container header .header__right .header__languages .multi-box span.icon::before, .page-container header .header__right .header__languages .multi-box span.icon::after {
    line-height: 4.28571rem !important; }
  .page-container header .header__right .header__languages .multi-box .dropdown-content {
    top: 4.28571rem; }
  .page-container .main-content {
    padding: 2.5rem 2.5rem 0.07143rem;
    padding-top: 8.21429rem; }
    .page-container .main-content .edit-profile .profile-content {
      justify-content: normal;
      flex-direction: column; }
      .page-container .main-content .edit-profile .profile-content .info-acc {
        max-width: none;
        margin-bottom: 2.14286rem; }
      .page-container .main-content .edit-profile .profile-content .edit-acc {
        width: 100%; }
        .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .row-btn {
          text-align: center; }
          .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .row-btn .btn {
            min-width: 49%; }
            .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .row-btn .btn.btn-dark {
              margin-right: 2%; }
    .page-container .main-content .reset-password .reset-password-main .rs-search form {
      flex: none;
      width: calc(100% - 160px); }
    .page-container .main-content .reset-password .reset-password-main .rs-content .table-edit-pass td {
      white-space: nowrap; }
    .page-container .main-content .reset-password .reset-password-main .rs-content .table-edit-pass .table thead th.acc-name, .page-container .main-content .reset-password .reset-password-main .rs-content .table-edit-pass .table thead th.acc-fname {
      min-width: 150px; }
    .page-container .main-content .change-pass .form-pass .btn-pass {
      display: flex;
      justify-content: space-between; }
      .page-container .main-content .change-pass .form-pass .btn-pass .btn {
        min-width: 48%;
        margin-right: 0; }
    .page-container .main-content .change-pass .check-pass .info-change-pass {
      width: 100%; }
    .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-color .pickerColor {
      flex-wrap: wrap;
      padding-bottom: 0;
      margin-bottom: 1rem; }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-color .pickerColor .pickerColor__change {
        width: 100%;
        justify-content: center; }
        .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-color .pickerColor .pickerColor__change .change-color {
          width: 30%;
          padding-bottom: 30%;
          margin-bottom: 1.07143rem; }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-color .pickerColor .pickerColor__text {
        width: 100%;
        padding-left: 0; }
    .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor {
      margin-bottom: 1rem; }
    .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__box {
      width: 30%; }
    .page-container .main-content .decentralize .decentralize-main {
      margin-bottom: 0; }
    .page-container .main-content .decentralize .modalSaas__dialog {
      max-width: 73.07143rem;
      width: 95%; }
    .page-container .main-content .decentralize.roles .modalSaas__dialog {
      width: 95%; }
    .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-info .list-page {
      flex-wrap: wrap; }
      .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-info .list-page .title-content {
        margin-bottom: 0.71429rem; }
      .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-info .list-page .box-control-list {
        margin-left: 0 !important;
        width: 100%;
        justify-content: space-between; }
    .page-container .main-content .member .member-main .paging-steps {
      padding: 1.07143rem; }
      .page-container .main-content .member .member-main .paging-steps .step > p {
        display: none; }
      .page-container .main-content .member .member-main .paging-steps .section-dots {
        width: 9%;
        justify-content: space-between; }
    .page-container .main-content .member .member-main .box-btn {
      width: 100%; }
    .page-container .main-content .member .member-main .list-page {
      flex-direction: column-reverse;
      align-items: flex-start; }
      .page-container .main-content .member .member-main .list-page > .title-content {
        font-weight: normal; }
      .page-container .main-content .member .member-main .list-page .list-page-content {
        align-self: flex-start;
        margin-left: 0 !important;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.71429rem;
        border-bottom: 1px solid var(--gray-color-2);
        margin-bottom: 0.71429rem; }
        .page-container .main-content .member .member-main .list-page .list-page-content .title-step {
          display: block; }
    .page-container .main-content .member .member-main .content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      .page-container .main-content .member .member-main .content .row-content {
        flex-wrap: wrap;
        width: 49%; }
        .page-container .main-content .member .member-main .content .row-content .col-content {
          width: 100%; }
          .page-container .main-content .member .member-main .content .row-content .col-content p.title {
            font-size: 0.85714rem; }
          .page-container .main-content .member .member-main .content .row-content .col-content.pay {
            padding-left: 0; }
            .page-container .main-content .member .member-main .content .row-content .col-content.pay ul li .pay-now .number-pay-now {
              font-size: 1.42857rem; }
              .page-container .main-content .member .member-main .content .row-content .col-content.pay ul li .pay-now .number-pay-now sup {
                font-size: 1rem; }
          .page-container .main-content .member .member-main .content .row-content .col-content.select {
            width: 100%;
            background-color: var(--gray-color-2);
            padding: 1.07143rem 2.85714rem; }
            .page-container .main-content .member .member-main .content .row-content .col-content.select::before {
              content: none; }
            .page-container .main-content .member .member-main .content .row-content .col-content.select .btn {
              min-width: 140px;
              padding: 0.35714rem 0.71429rem;
              margin-left: 0; }
      .page-container .main-content .member .member-main .content.select-add-on {
        flex-wrap: wrap;
        flex-direction: column-reverse; }
        .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main {
          width: 100%;
          padding: 0 0.14286rem; }
          .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .collapse-saas .content .personal-group {
            width: 100%; }
      .page-container .main-content .member .member-main .content .aside-summary {
        width: 100%;
        margin-bottom: 1.07143rem; }
        .page-container .main-content .member .member-main .content .aside-summary .row-span .first-span {
          width: 15%; }
  .page-container .main-content--custom {
    padding: 0rem 0.71429rem; }
  .plan-edit .periods .plan {
    width: 100%;
    padding: 1.07143rem; }
    .plan-edit .periods .plan .d-flex {
      justify-content: space-around; }
  .plan-edit .periods .package {
    width: 100%;
    padding: 1.07143rem; }
    .plan-edit .periods .package .d-flex {
      justify-content: space-around; }
  .plan-edit .periods .period-item-1 {
    width: 45%;
    justify-content: start !important; }
  .plan-edit .restrictions-item {
    width: 100%; }
  .plan-edit .payments .selling-price .is-club.select-custom {
    margin-top: 0.71429rem; }
  .plan-edit .payments .selling-price .span-3, .plan-edit .payments .selling-price .span-2 {
    display: block;
    width: 100%; } }

@media (min-width: 992px) and (max-width: 1200px) {
  .col-lg-5.fist-password {
    flex: 0 0 50.666667%;
    max-width: 50.666667%; } }

@media (max-width: 992px) {
  .login .container > .row .loginForm.fist-password .info-change-pass .list-check-pass ul {
    font-size: 0.85714rem; }
    .login .container > .row .loginForm.fist-password .info-change-pass .list-check-pass ul li {
      width: 100%; }
  .page-container header .header__right li.header__account .multi-box .dropdown-content {
    min-width: calc(100% + 60px); }
  .main-content--custom {
    padding: 0rem 0.71429rem; } }

@media (min-width: 1024px) and (max-width: 1200px) {
  .page-container .main-content .appearance .tabs .tab-content .tab-pane .pickerColor {
    flex-wrap: wrap;
    padding-bottom: 0;
    margin-bottom: 1.07143rem; }
    .page-container .main-content .appearance .tabs .tab-content .tab-pane .pickerColor .pickerColor__change {
      width: 100%;
      justify-content: center;
      margin-bottom: 1.07143rem; }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .pickerColor .pickerColor__change .change-color {
        width: 20%;
        padding-bottom: 20%; }
        .page-container .main-content .appearance .tabs .tab-content .tab-pane .pickerColor .pickerColor__change .change-color:first-child {
          margin-right: 0.35714rem; }
    .page-container .main-content .appearance .tabs .tab-content .tab-pane .pickerColor .pickerColor__text {
      width: 100%;
      padding-left: 0; }
  .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor {
    margin-bottom: 0; }
  .page-container .main-content--custom {
    padding: 0rem 0.71429rem; } }

@media (max-width: 767px) {
  .text-hidden-mb {
    display: none; }
  .login {
    background-position: center; }
    .login .container .multi-box {
      padding-top: 1.07143rem;
      padding-right: 2.5rem;
      top: 0; }
      .login .container .multi-box .dropdown-content {
        right: 2.14286rem; }
    .login .container > .row .loginForm__menu ul li:first-child {
      padding-left: 0; }
    .login .container > .row .loginForm__menu ul li:last-child {
      padding-right: 0; }
  .page-container {
    padding-left: 0; }
    .page-container header {
      width: 100%; }
      .page-container header .header {
        padding-right: 1.07143rem; }
        .page-container header .header .account-name {
          display: none; }
        .page-container header .header .header__left {
          display: none; }
        .page-container header .header .header__btn {
          display: block; }
          .page-container header .header .header__btn .btn-sidebar {
            position: relative;
            min-width: auto; }
            .page-container header .header .header__btn .btn-sidebar img {
              width: auto;
              max-width: 19px;
              position: absolute;
              top: 50%;
              transform: translate(-50%, -50%);
              left: 50%; }
        .page-container header .header .header__right .header__languages {
          padding: 0 3.57143rem; }
          .page-container header .header .header__right .header__languages .multi-box span.icon::before, .page-container header .header .header__right .header__languages .multi-box span.icon::after {
            line-height: 4.28571rem; }
          .page-container header .header .header__right .header__languages .multi-box span.icon::before {
            left: -1.28571rem; }
          .page-container header .header .header__right .header__languages .multi-box span.icon::after {
            left: 0.35714rem; }
          .page-container header .header .header__right .header__languages .multi-box .dropdown-content {
            top: 4.28571rem; }
        .page-container header .header .header__right li.header__account .multi-box .dropdown-content {
          width: max-content; }
    .page-container .main-content {
      padding: 8.21429rem 1.07143rem 0.07143rem; }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .pickerColor {
        display: block; }
        .page-container .main-content .appearance .tabs .tab-content .tab-pane .pickerColor__change {
          width: 60%;
          margin: 0 auto;
          margin-bottom: 1rem; }
        .page-container .main-content .appearance .tabs .tab-content .tab-pane .pickerColor__text {
          padding-left: 0;
          width: 100%; }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__box {
        width: 47%;
        margin-right: 2%; }
        .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__box p {
          font-size: 0.85714rem; }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane .saveColor {
        text-align: center;
        display: flex;
        flex-wrap: wrap; }
        .page-container .main-content .appearance .tabs .tab-content .tab-pane .saveColor .btn {
          min-width: auto;
          width: 100%;
          margin-bottom: 0.71429rem;
          margin-right: 0; }
          .page-container .main-content .appearance .tabs .tab-content .tab-pane .saveColor .btn.btn-dark {
            margin-right: 0; }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .up-logo .box-img {
        width: 220px;
        height: 220px; }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .title {
        display: flex;
        justify-content: space-between; }
        .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .title .text {
          width: 77%; }
        .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .title .icon {
          width: 20%;
          text-align: right;
          align-self: center; }
          .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .title .icon::before {
            right: 2.85714rem; }
      .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo--rectangle .up-logo {
        min-height: 14rem;
        padding: 7px; }
        .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo--rectangle .up-logo .box-img {
          width: 100%;
          height: 135px;
          max-width: 230px; }
          .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo--rectangle .up-logo .box-img::before {
            height: 50px; }
          .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo--rectangle .up-logo .box-img span.upload-title {
            font-size: 0.89286rem; }
            .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo--rectangle .up-logo .box-img span.upload-title::before {
              top: -2.2rem;
              font-size: 2rem; }
      .page-container .main-content .bulk-import .collapse-saas .content .info-content .section-down {
        display: flex;
        flex-direction: column; }
        .page-container .main-content .bulk-import .collapse-saas .content .info-content .section-down .btn-down {
          margin-bottom: 0.71429rem; }
      .page-container .main-content .bulk-import .bulk-upload .input-upload {
        flex-direction: column; }
        .page-container .main-content .bulk-import .bulk-upload .input-upload .input-file {
          margin-right: 0;
          margin-bottom: 0.71429rem; }
      .page-container .main-content .bulk-import .list-account {
        position: relative;
        padding-bottom: 2.85714rem; }
        .page-container .main-content .bulk-import .list-account .table-account {
          padding: 0; }
          .page-container .main-content .bulk-import .list-account .table-account .acc-name {
            min-width: 150px; }
          .page-container .main-content .bulk-import .list-account .table-account .acc-err {
            min-width: 300px; }
          .page-container .main-content .bulk-import .list-account .table-account .table td {
            padding: 1.28571rem 0.71429rem; }
          .page-container .main-content .bulk-import .list-account .table-account .table thead th {
            padding: 1.28571rem 0.71429rem; }
        .page-container .main-content .bulk-import .list-account .list-page .show-info {
          max-width: 100%; }
          .page-container .main-content .bulk-import .list-account .list-page .show-info .total-acc-err {
            flex-wrap: wrap; }
            .page-container .main-content .bulk-import .list-account .list-page .show-info .total-acc-err .line-vertical {
              display: none; }
        .page-container .main-content .bulk-import .list-account .list-page ul.pagination {
          position: absolute;
          bottom: 0;
          right: 0; }
      .page-container .main-content .reset-password {
        padding-bottom: 2.85714rem; }
        .page-container .main-content .reset-password .reset-password-main {
          padding: 1.07143rem;
          padding-bottom: 0; }
          .page-container .main-content .reset-password .reset-password-main .rs-search {
            flex-direction: column; }
            .page-container .main-content .reset-password .reset-password-main .rs-search form {
              width: 100%;
              margin-right: 0;
              margin-bottom: 1.07143rem; }
          .page-container .main-content .reset-password .reset-password-main .rs-content .table-content {
            position: relative; }
            .page-container .main-content .reset-password .reset-password-main .rs-content .table-content .list-page {
              flex-direction: column; }
              .page-container .main-content .reset-password .reset-password-main .rs-content .table-content .list-page > span {
                margin-bottom: 0.71429rem; }
              .page-container .main-content .reset-password .reset-password-main .rs-content .table-content .list-page ul.pagination {
                position: absolute;
                bottom: -2.85714rem;
                right: -1.07143rem; }
          .page-container .main-content .reset-password .reset-password-main .rs-content .table-edit-pass .table .text-bl {
            white-space: nowrap; }
          .page-container .main-content .reset-password .reset-password-main .rs-content .table-edit-pass .table thead th.acc-stt {
            min-width: 50px; }
          .page-container .main-content .reset-password .reset-password-main .rs-content .table-edit-pass .table thead th.acc-fname {
            min-width: 110px; }
          .page-container .main-content .reset-password .reset-password-main .rs-content .table-edit-pass .table thead th.acc-name {
            min-width: 160px; }
          .page-container .main-content .reset-password .reset-password-main .rs-content .table-edit-pass .table thead th.acc-reset {
            min-width: 50px; }
      .page-container .main-content .edit-profile .profile-content {
        justify-content: normal;
        flex-direction: column; }
        .page-container .main-content .edit-profile .profile-content .info-acc {
          max-width: none;
          margin-bottom: 1.42857rem; }
        .page-container .main-content .edit-profile .profile-content .edit-acc {
          width: 100%; }
          .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .edit-title {
            margin-bottom: 0.71429rem; }
          .page-container .main-content .edit-profile .profile-content .edit-acc .tab-content .tab-pane .div-btn .box-btn .btn {
            margin-bottom: 0.71429rem; }
      .page-container .main-content .change-pass {
        flex-direction: column;
        border: none; }
        .page-container .main-content .change-pass .check-pass {
          width: 100%;
          background-color: var(--gray-color-2); }
          .page-container .main-content .change-pass .check-pass .info-change-pass {
            position: relative;
            width: 100%; }
        .page-container .main-content .change-pass .form-pass {
          width: 100%; }
          .page-container .main-content .change-pass .form-pass .title {
            margin-bottom: 2.14286rem; }
      .page-container .main-content .decentralize {
        padding-bottom: 2.85714rem;
        position: relative; }
        .page-container .main-content .decentralize.club-group-function .list-page .box-btn-decentralize .pagination {
          bottom: 0; }
        .page-container .main-content .decentralize .decentralize-main .table-decentralize .th-1 {
          width: auto; }
        .page-container .main-content .decentralize .list-page .box-btn-decentralize .pagination {
          position: absolute;
          right: 0;
          bottom: 0; }
        .page-container .main-content .decentralize .btn-height-sm {
          background-color: var(--primary-color);
          color: var(--white); }
          .page-container .main-content .decentralize .btn-height-sm::after {
            content: "";
            height: 0%; }
        .page-container .main-content .decentralize .modalSaas .modalSaas__content__1 {
          width: 100%; }
        .page-container .main-content .decentralize .modalSaas__dialog {
          max-width: 54.78571rem;
          width: 100%; }
        .page-container .main-content .decentralize .modalSaas .btn-box {
          justify-content: space-between; }
          .page-container .main-content .decentralize .modalSaas .btn-box .btn {
            width: 48%; }
            .page-container .main-content .decentralize .modalSaas .btn-box .btn:first-child {
              margin-right: 0; }
        .page-container .main-content .decentralize.permission .table-permission-modal {
          display: flex;
          flex-direction: column; }
          .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item {
            padding: 0.57143rem; }
            .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item.item-1 {
              width: 100%; }
            .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item.item-2 {
              width: 100%; }
            .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item.page {
              visibility: hidden; }
        .page-container .main-content .decentralize.roles .modalSaas__dialog {
          width: 100%; }
      .page-container .main-content .list-people .tabs .link-tabs {
        position: relative;
        left: 0;
        top: 0;
        margin-bottom: 0.71429rem; }
        .page-container .main-content .list-people .tabs .link-tabs .btn {
          width: 50%; }
      .page-container .main-content .list-people .tabs .tab-list-people {
        padding: 0; }
        .page-container .main-content .list-people .tabs .tab-list-people .list-people-content {
          position: relative;
          padding-bottom: 2.85714rem; }
          .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-info {
            display: flex;
            flex-direction: column-reverse; }
            .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-info .total-people {
              margin-bottom: 0;
              text-align: left; }
            .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-info .list-page .box-control-list {
              flex-wrap: wrap;
              justify-content: space-between; }
              .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-info .list-page .box-control-list .btn-height-sm {
                margin-right: 0;
                width: 32%;
                margin-bottom: 0.71429rem; }
                .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-info .list-page .box-control-list .btn-height-sm.btn-add-new {
                  margin-bottom: 0; }
                .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-info .list-page .box-control-list .btn-height-sm::after {
                  content: none; }
              .page-container .main-content .list-people .tabs .tab-list-people .list-people-content .list-info .list-page .box-control-list .pagination {
                position: absolute;
                bottom: 0;
                right: 0; }
      .page-container .main-content .view-search-item {
        position: relative;
        padding-bottom: 2.85714rem; }
        .page-container .main-content .view-search-item .list-page .list-page-title {
          padding-right: 0; }
        .page-container .main-content .view-search-item .list-page .box-btn-filter {
          position: absolute;
          bottom: 0;
          right: 0; }
      .page-container .main-content .member {
        position: relative;
        padding-bottom: 2.85714rem; }
        .page-container .main-content .member:not(.member-paging) {
          padding-bottom: 0; }
        .page-container .main-content .member .member-main .paging-steps {
          padding: 0.71429rem; }
          .page-container .main-content .member .member-main .paging-steps .step {
            padding: 0; }
            .page-container .main-content .member .member-main .paging-steps .step .number-step {
              width: 2.14286rem;
              height: 2.14286rem;
              font-size: 1rem; }
          .page-container .main-content .member .member-main .paging-steps .section-dots .dot {
            width: 0.21429rem;
            height: 0.21429rem; }
        .page-container .main-content .member .member-main .list-page .box-btn-filter {
          position: absolute;
          bottom: 0;
          right: 0; }
        .page-container .main-content .member .member-main .list-page .list-page-title {
          width: 100%; }
          .page-container .main-content .member .member-main .list-page .list-page-title .title-step {
            padding-bottom: 0.71429rem;
            border-bottom: 1px solid var(--gray-color-2);
            margin-bottom: 0.71429rem;
            width: 100%; }
        .page-container .main-content .member .member-main .content {
          display: block; }
          .page-container .main-content .member .member-main .content .row-content {
            flex-wrap: wrap;
            width: 100%; }
            .page-container .main-content .member .member-main .content .row-content .col-content {
              width: 100%;
              border-bottom: 1px solid var(--gray-color-2);
              padding: 1.07143rem; }
              .page-container .main-content .member .member-main .content .row-content .col-content::after {
                content: none; }
              .page-container .main-content .member .member-main .content .row-content .col-content.select {
                width: 100%;
                background-color: var(--gray-color-2);
                padding: 1.07143rem 1.42857rem; }
                .page-container .main-content .member .member-main .content .row-content .col-content.select::before {
                  content: none; }
                .page-container .main-content .member .member-main .content .row-content .col-content.select .btn {
                  padding: 0.42857rem;
                  min-width: auto;
                  width: 100%;
                  margin-left: 0; }
          .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image {
            width: 18.57143rem;
            margin-bottom: 1.42857rem; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image h2,
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image h3,
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image h4,
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image .section-exp {
              left: 1.78571rem; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image h4, .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image h3 {
              font-size: 0.85714rem; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image .name-payment {
              top: 1.07143rem; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image .card-number {
              font-size: 1.28571rem; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image .section-exp {
              left: auto;
              top: calc(50% + 30px);
              right: 1.78571rem; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .card-image .acc-name {
              bottom: 0.71429rem; }
          .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .info-card .form-group {
            width: 100%; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .front-card .info-card .form-group:nth-child(odd) {
              margin-right: 0; }
          .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .behind-card .detail-card {
            flex-wrap: wrap; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main.main-payment .collapse-saas .behind-card .detail-card .form-group {
              width: 100% !important; }
          .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .option-group .form-check {
            width: 100%; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .option-group .form-check:nth-child(odd) {
              margin-right: 0; }
          .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .box-btn {
            flex-wrap: wrap;
            padding: 1.07143rem 0 0.71429rem; }
            .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .box-btn .btn {
              width: 100%;
              margin-right: 0;
              margin-bottom: 0.71429rem; }
              .page-container .main-content .member .member-main .content.select-add-on .select-add-on-main .box-btn .btn:last-child {
                margin-bottom: 0; }
          .page-container .main-content .member .member-main .content .aside-summary .section-promotions, .page-container .main-content .member .member-main .content .aside-summary .section-billing, .page-container .main-content .member .member-main .content .aside-summary .section-info, .page-container .main-content .member .member-main .content .aside-summary .section-add-ons {
            padding: 1.07143rem 0.71429rem; }
          .page-container .main-content .member .member-main .content .aside-summary .row-span .first-span {
            width: 30%; }
          .page-container .main-content .member .member-main .content.payments {
            overflow: auto; }
        .page-container .main-content .member .member-main .box-btn {
          justify-content: space-between;
          padding: 1.07143rem 0.71429rem; }
          .page-container .main-content .member .member-main .box-btn .btn {
            min-width: auto;
            width: 49%; }
        .page-container .main-content .member .member-main .section-select .form-group {
          width: 100%;
          margin-right: 0; }
        .page-container .main-content .member .member-main--custom {
          width: 100%; }
      .page-container .main-content #sub-tenant .sub-tenant .title-sub-tenant {
        min-height: 70px; }
        .page-container .main-content #sub-tenant .sub-tenant .title-sub-tenant h3 {
          text-align: center;
          font-size: 1.14286rem; }
      .page-container .main-content #sub-tenant .sub-tenant .main-sub-tenant .box-btn-confirm {
        padding: 1.42857rem; }
    .page-container .main-content--custom {
      padding: 0rem 0.71429rem; }
    .page-container footer {
      padding: 1.07143rem;
      justify-content: center; }
      .page-container footer h3 {
        text-align: center; }
  .table-list .table-above .table-above__content:first-child {
    margin-right: 0; } }

@media (max-width: 575px) {
  .plan-edit .basic-3 {
    display: flex;
    flex-direction: row; }
    .plan-edit .basic-3 .input-group {
      width: 86% !important; }
  .plan-edit .periods {
    max-height: 85.71429rem;
    overflow-y: auto; }
    .plan-edit .periods .period-item {
      flex-direction: column; }
      .plan-edit .periods .period-item .d-flex {
        justify-content: space-between; }
        .plan-edit .periods .period-item .d-flex .form-check {
          padding-left: 0.35714rem; }
      .plan-edit .periods .period-item .period-item-1 {
        width: 100%; }
    .plan-edit .periods .periods-between .select-custom--between {
      width: 11.07143rem; }
    .plan-edit .periods .periods-between .period-item {
      margin: 1.42857rem 0; }
  .plan-edit .payments .selling-price .price-is,
  .plan-edit .payments .selling-price .priority,
  .plan-edit .payments .selling-price .is-club,
  .plan-edit .payments .selling-price .from-to {
    margin-right: unset; }
  .plan-edit .payments .selling-price .priority.select-custom {
    margin-top: 0.71429rem; }
  .plan-edit .payments .selling-price .span-3, .plan-edit .payments .selling-price .span-2, .plan-edit .payments .selling-price .span-1, .plan-edit .payments .selling-price .span-4 {
    display: block;
    width: 100%; }
  .group-btn {
    flex-direction: column;
    justify-content: center; }
    .group-btn .btn {
      margin-right: 0;
      margin-top: 0.71429rem; } }

@media (max-width: 374px) {
  .login .container .multi-box .dropdown-content li a {
    padding: 0.71429rem; }
  .login .container .multi-box .dropdown-content.show {
    margin-top: 0.35714rem; }
  .login .container .multi-box span::before {
    font-size: 1.42857rem;
    left: -1.78571rem;
    top: 0px; }
  .login .container .row .loginForm {
    margin-top: 1.07143rem; }
    .login .container .row .loginForm__logo {
      margin-bottom: 1.78571rem; }
      .login .container .row .loginForm__logo img {
        max-width: 60px; }
    .login .container .row .loginForm__forgot {
      flex-direction: column;
      text-align: center; }
      .login .container .row .loginForm__forgot .form-check [type="checkbox"]:not(:checked) + label, .login .container .row .loginForm__forgot .form-check [type="checkbox"]:checked + label {
        padding-left: 1.78571rem; }
        .login .container .row .loginForm__forgot .form-check [type="checkbox"]:not(:checked) + label::before, .login .container .row .loginForm__forgot .form-check [type="checkbox"]:checked + label::before {
          width: 1.42857rem;
          height: 1.42857rem; }
        .login .container .row .loginForm__forgot .form-check [type="checkbox"]:not(:checked) + label::after, .login .container .row .loginForm__forgot .form-check [type="checkbox"]:checked + label::after {
          top: 0;
          left: 0; }
    .login .container .row .loginForm__menu {
      font-size: 0.71429rem; }
      .login .container .row .loginForm__menu ul li {
        padding: 0 0.71429rem; }
    .login .container .row .loginForm form {
      margin-bottom: 1.78571rem; }
      .login .container .row .loginForm form .input-group::after {
        font-size: 1.42857rem; }
      .login .container .row .loginForm form .input-group .form-control {
        padding: 0.71429rem 2.5rem 0.71429rem 2.85714rem; }
    .login .container .row .loginForm .btn-white {
      margin-bottom: 1.07143rem;
      font-size: 1rem; }
    .login .container .row .loginForm .btn-log-azure {
      padding: 0.71429rem; }
      .login .container .row .loginForm .btn-log-azure img {
        max-width: 27px; }
  .tabs .nav-tabs {
    margin-bottom: -0.07143rem; }
    .tabs .nav-tabs .nav-item a {
      height: 100%;
      border-bottom: 0;
      font-size: 0.85714rem; }
  .tabs .tab-content {
    border-top: 0; }
  .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__box {
    width: 49%;
    margin-right: 1%; }
    .page-container .main-content .appearance .tabs .tab-content .tab-pane .section-combo-color .exColor__box p {
      font-size: 0.71429rem; }
  .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .title .text {
    width: 80%; }
  .page-container .main-content .appearance .tabs .tab-content .tab-pane.change-logo .title .icon {
    width: 20%; }
  .page-container .main-content .decentralize.permission .table-permission-modal {
    display: flex;
    flex-direction: column; }
    .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item {
      padding: 0.21429rem; }
      .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item.item-1 {
        width: 100%;
        display: none !important; }
      .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item.item-2 {
        width: 100%; }
      .page-container .main-content .decentralize.permission .table-permission-modal .table__permission__item.hidden {
        visibility: hidden; }
    .page-container .main-content .decentralize.permission .table-permission-modal .btn-permission .btn-permission-common {
      font-size: 0.71429rem; }
  .page-container .main-content .member .member-main .content .aside-summary .row-span .first-span {
    width: 35%; }
  .page-container .main-content .member .member-main .content .aside-summary .row-span .dots {
    margin: 0; }
    .page-container .main-content .member .member-main .content .aside-summary .row-span .dots::after {
      width: 95%; }
  .page-container .main-content--custom {
    padding: 0rem 0.71429rem; } }

@media (hover: none), (hover: on-demand) {
  .btn-browse:hover {
    background: var(--primary-color-darken) !important;
    border-color: var(--primary-color-darken) !important;
    color: var(--white) !important; } }

.up-logo.system-config {
  display: flex;
  align-items: center;
  min-height: 26rem; }

.up-logo .box-img {
  margin-bottom: 45px !important; }
  .up-logo .box-img .change-img {
    bottom: -45px !important;
    z-index: 1; }

.up-logo #img-store {
  display: none; }

.up-logo .holder {
  z-index: 1;
  position: absolute; }
  .up-logo .holder.invisible-holder {
    opacity: 0; }
  .up-logo .holder canvas {
    background-image: none !important; }

.up-logo .uou-rangeslider {
  width: calc(100% - 90px);
  vertical-align: middle;
  margin: 0 auto; }
  .up-logo .uou-rangeslider .rangeslider {
    box-shadow: none;
    background: rgba(0, 0, 0, 0.25); }
  .up-logo .uou-rangeslider .rangeslider-horizontal {
    height: 5px; }
    .up-logo .uou-rangeslider .rangeslider-horizontal .rangeslider__fill {
      background-color: var(--white);
      box-shadow: none; }
    .up-logo .uou-rangeslider .rangeslider-horizontal .rangeslider__handle {
      width: 15px;
      height: 15px;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
      border: none; }
      .up-logo .uou-rangeslider .rangeslider-horizontal .rangeslider__handle:after {
        display: none; }

.up-logo .btn-zoom-in {
  float: left; }

@media screen and (max-width: 1366px) {
  .note-info {
    font-size: 0.8571428571428571em; } }

.fs-i {
  font-style: italic; }

.membersummary-page .membersummary-list .member-contact-detail .row.promotion-addon-wrapper {
  margin-right: 0;
  margin-left: 0; }

.table-wrapper {
  margin-left: 0 !important;
  margin-right: 0 !important; }
  .table-wrapper .table-list .table-filter .left-filter .filter-input-label {
    font-size: 0.85714rem;
    margin-right: 0.5em;
    margin-left: 0.5em; }
  .table-wrapper .table-list .table-filter .left-filter .custom-select-filter,
  .table-wrapper .table-list .table-filter .left-filter .custom-input-filter {
    width: 11em;
    margin-right: 1em; }
  .table-wrapper .table-list .table-responsive {
    max-height: 300px; }
    .table-wrapper .table-list .table-responsive table th {
      white-space: nowrap; }
      .table-wrapper .table-list .table-responsive table th > div {
        display: inline-block;
        margin-left: 0.5em; }
      .table-wrapper .table-list .table-responsive table th:last-child {
        right: unset; }

button[disabled] {
  cursor: default; }

.swal2-modal {
  border-radius: 3px !important; }
  .swal2-modal .swal2-icon.swal2-error {
    border-color: var(--danger-color); }
    .swal2-modal .swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
      background-color: var(--danger-color); }
  .swal2-modal .swal2-icon.swal2-warning {
    border-color: var(--warning-color);
    color: var(--warning-color); }
  .swal2-modal .swal2-icon.swal2-success [class^="swal2-success-line"] {
    background-color: var(--green-color); }
  .swal2-modal .swal2-icon.swal2-success .swal2-success-ring {
    border-color: var(--green-color); }
  .swal2-modal .swal2-actions button {
    min-width: 140px;
    margin: 0 10px; }

.saveColor .btn-secondary {
  margin-right: 2rem; }

.btn.btn-secondary {
  background-color: var(--primary-color-darken);
  border-color: var(--primary-color-darken); }

.btn.btn-secondary:hover {
  background-color: white;
  border-color: var(--primary-color-darken) !important;
  color: var(--primary-color-darken) !important; }

.header__logo img {
  height: 2.5rem;
  cursor: pointer; }

.container-modal {
  z-index: 10000; }
  .container-modal .backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0; }

.sidebar-logo .logo-mb img {
  max-height: 48px; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }
  .pagination .page-item {
    cursor: pointer;
    color: var(--base-color);
    background-color: transparent;
    border: 0.5px solid;
    border-color: var(--gray-color-3);
    border-radius: 0.21429rem;
    width: auto;
    height: 24px; }
    .pagination .page-item .page-link {
      line-height: 1.5 !important;
      border: none;
      margin-left: 0;
      height: 23px !important; }
      .pagination .page-item .page-link .icon {
        line-height: 1.3; }
    .pagination .page-item.disabled {
      background-color: var(--gray-color-2);
      opacity: 0.5; }
    .pagination .page-item.active {
      background-color: var(--primary-color);
      border-color: var(--primary-color); }
      .pagination .page-item.active #img-store {
        display: none; }
    .pagination .page-item:not(.disabled):not(.active):hover {
      background: var(--gray-color-3);
      transition: 0.5s; }

div.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.hidden {
  display: none; }

.header__languages,
.header__account {
  cursor: pointer; }
  .header__languages .text-hidden-mb,
  .header__account .text-hidden-mb {
    white-space: nowrap; }
  .header__languages .dropdown-content li a,
  .header__account .dropdown-content li a {
    white-space: nowrap; }

.modal-new-pass .code-pass {
  cursor: pointer; }

.swal2-modal .swal2-actions button {
  text-transform: uppercase;
  font-weight: bold;
  min-width: 160px;
  width: auto; }

.error-holder .error {
  flex: 0 0 100%;
  color: var(--danger-color);
  font-size: 0.8571428571rem; }

.error-holder input,
.error-holder textarea,
.error-holder select,
.error-holder .icon-checked-ico:before,
.error-holder .select__control {
  border-color: var(--danger-color) !important; }

.page-container header {
  z-index: 4; }

.page-container .plan-edit .member .member-main.member-main--custom > .content {
  padding: 0.3rem 1.3rem; }
  .page-container .plan-edit .member .member-main.member-main--custom > .content .collapse-promotion.collapse-saas {
    width: 100%; }
  .page-container .plan-edit .member .member-main.member-main--custom > .content .content.open {
    overflow: visible; }
  .page-container .plan-edit .member .member-main.member-main--custom > .content .form-control:not(.datetime-control) {
    padding: 0.4285714286rem 1.4rem 0.4285714286rem 0.75rem; }
  .page-container .plan-edit .member .member-main.member-main--custom > .content .uppercase {
    text-transform: uppercase; }
  .page-container .plan-edit .member .member-main.member-main--custom > .content .react-datepicker-popper {
    z-index: 10; }
  .page-container .plan-edit .member .member-main.member-main--custom > .content .required {
    color: var(--danger-color); }
  .page-container .plan-edit .member .member-main.member-main--custom > .content .icon--calendar {
    top: 0.2em; }
  .page-container .plan-edit .member .member-main.member-main--custom > .content .line-date {
    vertical-align: text-top;
    display: table;
    padding-top: 5em; }
  .page-container .plan-edit .member .member-main.member-main--custom > .content .periods .input-group {
    width: 5rem; }
  .page-container .plan-edit .member .member-main.member-main--custom > .content .select-fluid {
    width: 100%; }
  .page-container .plan-edit .member .member-main.member-main--custom > .content .no-resize {
    resize: none; }
  .page-container .plan-edit .member .member-main.member-main--custom > .content .react-datepicker-wrapper {
    width: 100%;
    margin-right: 0; }
    .page-container .plan-edit .member .member-main.member-main--custom > .content .react-datepicker-wrapper .react-datepicker__close-icon {
      display: none; }
  .page-container .plan-edit .member .member-main.member-main--custom > .content .title-medium {
    font-weight: 500; }
  .page-container .plan-edit .member .member-main.member-main--custom > .content .selling-price .priority,
  .page-container .plan-edit .member .member-main.member-main--custom > .content .selling-price .is-club,
  .page-container .plan-edit .member .member-main.member-main--custom > .content .selling-price .from-to,
  .page-container .plan-edit .member .member-main.member-main--custom > .content .selling-price .inline-block {
    display: inline-block;
    width: unset; }
  .page-container .plan-edit .member .member-main.member-main--custom > .content .selling-price .priority .select-custom,
  .page-container .plan-edit .member .member-main.member-main--custom > .content .selling-price .is-club .select-custom,
  .page-container .plan-edit .member .member-main.member-main--custom > .content .selling-price .from-to .input-group {
    margin-top: 0.7142857143rem; }
  .page-container .plan-edit .member .member-main.member-main--custom > .content .selling-price .mrc-5 {
    margin-right: 5px; }
  .page-container .plan-edit .member .member-main.member-main--custom > .content .selling-price .price-is {
    display: inline-block; }
    .page-container .plan-edit .member .member-main.member-main--custom > .content .selling-price .price-is .input-group {
      width: unset; }
      .page-container .plan-edit .member .member-main.member-main--custom > .content .selling-price .price-is .input-group .form-control {
        width: 10rem; }

@media (max-width: 1680px) {
  .plan-edit .payments .selling-price .span-3 {
    display: block;
    width: 100%; } }

@media (min-width: 1024px) and (max-width: 1680px) {
  .plan-edit .payments .selling-price .span-null {
    display: inline-block;
    width: 13.95em; }
  .plan-edit .payments .selling-price .price-is .input-group input {
    width: 7em !important; }
  .plan-edit .payments .selling-price .priority .select-custom,
  .plan-edit .payments .selling-price .is-club .select-custom {
    width: 12em !important; }
  .plan-edit .payments .selling-price .react-datepicker-wrapper {
    width: 12em !important; }
  .plan-edit .payments .selling-price .span-4 + .mrc-5 {
    width: 10.55em;
    display: inline-block;
    text-align: right; }
  .plan-edit .payments .selling-price .mrc-5 .price-is {
    width: 3.65em !important;
    word-break: break-word;
    vertical-align: middle; }
  .plan-edit .payments .selling-price .mrc-5 .priority {
    width: 3.65em !important;
    word-break: break-word;
    vertical-align: middle; }
  .plan-edit .payments .selling-price .mrc-5 .is-club {
    width: 8.38em !important;
    word-break: break-word;
    vertical-align: middle; }
  .plan-edit .payments .selling-price .mrc-5 .from-to {
    width: 2.55em !important;
    word-break: break-word;
    vertical-align: middle; } }

@media (max-width: 719px) {
  .plan-edit .payments .selling-price .mt-3 {
    clear: both;
    margin-bottom: 3em; }
    .plan-edit .payments .selling-price .mt-3 .price-is .input-group input {
      width: 15.05em !important; }
    .plan-edit .payments .selling-price .mt-3 .priority .select-custom {
      width: 13em !important;
      margin-right: 0; }
    .plan-edit .payments .selling-price .mt-3 .react-datepicker-wrapper {
      width: 13em !important; }
    .plan-edit .payments .selling-price .mt-3 .mrc-5 + span {
      float: right; }
    .plan-edit .payments .selling-price .mt-3 .mrc-5 > span {
      display: inline-block;
      padding-top: 0.85em; }
    .plan-edit .payments .selling-price .mt-3 .mrc-5 span.price-is {
      padding-top: 0.15em; }
    .plan-edit .payments .selling-price .mt-3 .span-1,
    .plan-edit .payments .selling-price .mt-3 .span-2,
    .plan-edit .payments .selling-price .mt-3 .span-3,
    .plan-edit .payments .selling-price .mt-3 .span-4,
    .plan-edit .payments .selling-price .mt-3 .span-5 {
      display: block;
      width: 100%;
      clear: both; } }

.swal2-container.swal2-fade {
  z-index: 10000; }

.reset-password .wraptext {
  display: inline-block;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden; }

@media screen and (max-width: 768px) {
  .user-profile-picture .up-logo .box-img {
    width: 190px !important;
    height: 190px !important; }
  .user-profile-picture .saveColor {
    display: flex;
    flex-direction: row-reverse; }
    .user-profile-picture .saveColor .btn {
      flex: 1; }
      .user-profile-picture .saveColor .btn.btn-primary {
        margin-right: 10px !important; }
  .reset-password .wraptext {
    max-width: 220px; }
  .up-logo.system-config {
    min-height: 20rem; }
  .up-logo .box-img {
    margin-bottom: 45px !important; }
  .ReactPasswordStrength-strength-desc {
    padding: 8px 1% !important;
    right: 11% !important; } }

@media screen and (min-width: 768px) {
  .user-profile-picture .up-logo .box-img {
    height: 17.43em !important;
    width: 20.714em !important; }
  .ReactPasswordStrength-strength-desc {
    padding: 8px 19% 8px 1% !important; } }

.user-profile-picture .modalSaas__dialog {
  max-width: 400px; }

.user-profile-picture .holder {
  width: 100%; }
  .user-profile-picture .holder canvas {
    width: 100%; }

.user-profile-picture .saveColor .btn-primary {
  float: left; }

.user-profile-picture .saveColor .btn-dark {
  margin-right: 0 !important; }

.user-profile-picture .description {
  text-align: center;
  margin: 10px 0;
  font-style: italic;
  font-size: 0.85714rem; }
  .user-profile-picture .description p {
    margin: 0 -20px; }

.info-acc .border-tag a {
  color: var(--primary-color) !important; }

.edit-acc .tab-content .tab-pane {
  display: block; }
  .edit-acc .tab-content .tab-pane .row-btn {
    margin-top: 1.4285714286rem;
    padding-bottom: 1.78571rem;
    border-top: none !important; }
  .edit-acc .tab-content .tab-pane .list {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 0.071em solid var(--gray-color-3); }
    .edit-acc .tab-content .tab-pane .list .title {
      padding-bottom: 0.2rem; }
    .edit-acc .tab-content .tab-pane .list .item-holder {
      padding: 0;
      display: flex;
      flex-wrap: wrap; }
      .edit-acc .tab-content .tab-pane .list .item-holder .item {
        background-color: var(--gray-color-1);
        padding: 0.143em 0.357em;
        margin-right: 0.357em;
        margin-bottom: 0.357em;
        border-radius: 1.071em;
        border: 0.071em solid var(--gray-color-3);
        min-width: 5%;
        overflow: hidden;
        text-overflow: ellipsis; }

.logo-box .logo-text {
  margin-left: 30px; }

.accordion .dropdown-menu li {
  padding-left: 2.14286rem !important; }
  .accordion .dropdown-menu li .dropdown-toggle {
    padding: 0; }
    .accordion .dropdown-menu li .dropdown-toggle .icon-holder {
      padding-right: 0.71429rem; }
      .accordion .dropdown-menu li .dropdown-toggle .icon-holder .icon {
        font-size: 1.28571rem;
        vertical-align: middle; }

.page-container header .header__left .header__logo {
  border-right: 1px solid var(--gray-color-2);
  display: block; }

.page-container header .header__right .header__account {
  min-width: 10.714em; }
  .page-container header .header__right .header__account .box-account {
    align-items: center; }
    .page-container header .header__right .header__account .box-account .account-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 14.285714285714286em; }
    .page-container header .header__right .header__account .box-account .account-img {
      overflow: hidden;
      width: 3.4285714285714284em;
      height: 3.4285714285714284em;
      border-radius: 50%; }
      .page-container header .header__right .header__account .box-account .account-img img {
        width: 100%;
        height: 100%;
        vertical-align: baseline; }

.page-container .main-content .edit-profile .profile-content .edit-avartar {
  background: white; }
  .page-container .main-content .edit-profile .profile-content .edit-avartar img {
    background-color: white; }

.page-container .main-content .appearance .title-content + .box-btn-filter .select-form {
  margin-bottom: 0;
  vertical-align: text-top;
  display: inline-block;
  min-width: 12em;
  margin-left: 1em;
  margin-top: -0.25em; }

.pickerColor .popover {
  position: absolute;
  z-index: 2;
  top: unset;
  left: unset;
  margin-top: 40px; }

.pickerColor .secondary {
  padding-left: 0; }

.pickerColor .cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px; }

@media (max-width: 1200px) and (min-width: 320px) {
  .pickerColor {
    justify-content: center;
    display: flex !important; } }

.bulk-import .collapse-saas .content a:hover {
  color: var(--primary-color) !important; }

.bulk-import .bulk-upload .fileUpload {
  min-width: 140px;
  padding: 0.28571rem;
  text-transform: uppercase; }

.bulk-import .bulk-upload p {
  font-size: 14px !important; }

.bulk-import .list-account table thead th {
  vertical-align: middle; }

.component-list {
  margin-top: 0.7142857142857143em; }
  .component-list .box-opt .items-opt {
    overflow-y: unset;
    max-height: 200px; }
  .component-list table tbody tr .drop-box-arr {
    top: -1.3em;
    right: 2em; }
    .component-list table tbody tr .drop-box-arr:after {
      top: 1.1rem;
      right: -1.35em;
      transform: rotate(90deg); }
    .component-list table tbody tr .drop-box-arr:before {
      top: 0.9rem;
      right: -1.8em;
      transform: rotate(90deg); }
  .component-list .table-list .table-responsive {
    max-height: none;
    overflow-y: visible;
    overflow-x: visible;
    height: unset !important; }
  .component-list .table-list .table thead th {
    vertical-align: middle; }
    .component-list .table-list .table thead th .box-opt .items-opt {
      max-height: none; }
    .component-list .table-list .table thead th .drop-box-filter {
      max-height: calc(100vh - 50px);
      overflow-y: auto; }
    .component-list .table-list .table thead th .th-filter {
      flex-direction: row; }
      .component-list .table-list .table thead th .th-filter > span {
        max-width: calc(100% - 20px);
        white-space: initial !important;
        overflow: initial !important; }
        .component-list .table-list .table thead th .th-filter > span:first-child {
          padding-right: 1.07143rem;
          position: relative; }
        .component-list .table-list .table thead th .th-filter > span:before {
          position: absolute;
          top: 50%;
          right: 0.21429rem;
          transform: translateY(-50%); }
  .component-list .table-list .table th {
    padding: 0.57143rem; }
  .component-list .table-list .table td {
    padding: 0.57143rem; }
  .component-list .table-list .th-col-opt .drop-col-opt {
    max-height: none; }
  .component-list .table-list .sort-control {
    display: flex;
    flex-direction: column;
    margin-right: 5px; }
    .component-list .table-list .sort-control > span {
      line-height: 5px; }
      .component-list .table-list .sort-control > span:first-child {
        padding-top: 5px;
        padding-bottom: 0; }
      .component-list .table-list .sort-control > span:last-child {
        padding-top: 0;
        padding-bottom: 5px; }
        .component-list .table-list .sort-control > span:last-child .icon.icon-desc-sort {
          transform: rotate(180deg) translateX(0.5px); }

.form-group {
  margin-bottom: 1.2rem; }

.intl-tel-input {
  width: 100%; }
  .intl-tel-input .selected-flag {
    outline: none; }

.input-error {
  font-style: italic;
  font-size: 0.85714rem;
  position: absolute; }

.login .container .loginForm__logo {
  margin-bottom: 2rem !important; }

.login .container .loginForm__desc {
  margin-bottom: 2.07143rem !important; }

.login .container .loginForm .btn-white {
  color: var(--primary-color) !important; }
  .login .container .loginForm .btn-white:hover {
    color: white !important; }
  .login .container .loginForm .btn-white:focus {
    color: white !important; }

.login .container .loginForm .icon-hidden {
  position: absolute;
  right: 1.07143rem;
  top: 0.71429rem;
  z-index: 1111;
  cursor: pointer; }
  .login .container .loginForm .icon-hidden::before {
    font-size: 1.6428571429rem; }

.login .container .loginForm .ReactPasswordStrength {
  padding: 0 !important; }
  .login .container .loginForm .ReactPasswordStrength input {
    background-color: rgba(255, 255, 255, 0) !important; }
  .login .container .loginForm .ReactPasswordStrength .ReactPasswordStrength-strength-desc {
    right: 2.5rem; }

.login .container .multi-box li {
  cursor: pointer; }

/*  Add Role  */
.w-300 {
  width: 300px; }

.label-select-switch {
  display: inline;
  padding: 5px 10px; }

.label-select-club {
  display: inline;
  padding: 5px 20px 0 0; }

.collapse-root {
  border-radius: 0px !important; }
  .collapse-root .collapse-header {
    border-bottom: 1px solid #cccccc; }
  .collapse-root .collapse-detail {
    display: block !important; }

.feature-list .accordion-group {
  position: relative; }

.feature-list dl {
  margin: 15px 0; }
  .feature-list dl dt {
    margin-bottom: 10px;
    font-weight: normal; }

.feature-list .group-list,
.feature-list .resource-list {
  margin: 0px;
  padding: 0px; }
  .feature-list .group-list li,
  .feature-list .resource-list li {
    display: inline; }
    .feature-list .group-list li:last-child,
    .feature-list .resource-list li:last-child {
      margin-left: 5px;
      padding-left: 5px;
      border-left: 1px solid #ccc; }
    .feature-list .group-list li a,
    .feature-list .resource-list li a {
      cursor: pointer; }
      .feature-list .group-list li a:hover,
      .feature-list .resource-list li a:hover {
        color: var(--primary-color); }

.feature-list .group-list {
  position: absolute;
  top: 16px;
  left: 170px;
  z-index: 2; }

.group-feature {
  width: 100%;
  margin: 10px 0; }
  .group-feature dt {
    width: 230px;
    float: left;
    font-size: 13px; }
  .group-feature dd {
    padding: 0 0 0 250px; }
  .group-feature .right-list {
    width: 100%;
    padding: 0px; }
    .group-feature .right-list li {
      display: inline-block;
      width: 33%; }
      .group-feature .right-list li .checkbox-container {
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: middle;
        overflow-wrap: break-word; }

.accordion .accordion-item .group-panel {
  padding: 20px 50px;
  background-color: #fff;
  border-bottom: 1px solid #ccc; }

.accordion .accordion-item .title {
  position: relative;
  border-radius: 0px !important;
  background-color: #fff !important;
  border-bottom: 1px solid #ccc !important; }
  .accordion .accordion-item .title:after {
    position: absolute;
    top: 16px;
    left: 15px; }
  .accordion .accordion-item .title h4 {
    padding-left: 25px; }

.accordion .accordion-item.active .panel {
  max-height: 1200px !important; }

.change-personal-detail-wrapper .select-add-on-main {
  width: 100% !important; }

.select-add-on-main .collapse-promotion {
  padding-bottom: 0.7142857143rem !important; }
  .select-add-on-main .collapse-promotion .content {
    margin-top: 0 !important; }
    .select-add-on-main .collapse-promotion .content .info-content {
      width: 100%;
      padding: 0 !important; }
      .select-add-on-main .collapse-promotion .content .info-content .form-group .input-error.long-text-error {
        display: contents !important; }
      @media (max-width: 767px) {
        .select-add-on-main .collapse-promotion .content .info-content .align-self-end {
          min-height: 6rem; } }
  .select-add-on-main .collapse-promotion .active {
    overflow: visible !important; }

.btn-tooltip {
  padding: 7px !important; }

.infoIcon {
  color: var(--secondary-color);
  font-size: 20px !important; }

.add-new-section {
  text-align: right;
  margin-bottom: 1rem; }
  .add-new-section .box-btn-filter {
    justify-content: space-between; }
  .add-new-section .btn-add-new {
    margin-right: 0px; }
    .add-new-section .btn-add-new:after {
      display: none; }
    .add-new-section .btn-add-new:hover {
      background-color: transparent;
      color: var(--primary-color) !important;
      border-color: var(--primary-color); }

.form-add-user .form-group {
  display: inline-block;
  margin-bottom: 2rem; }

.form-add-user .intl-tel-input {
  display: block; }

.profile-picture {
  text-align: center; }
  .profile-picture .title {
    font-size: 16px; }

.sub-tenant-role .form-group {
  display: block; }

.add-role-wrap {
  margin: 1rem 0; }
  .add-role-wrap .form-group {
    margin: 1rem 0; }

#guideBlocksHelpBeacon {
  display: none; }

#data-table .section-filter {
  position: relative;
  top: -4px;
  height: 0; }

#data-table .sort-control {
  display: flex;
  flex-direction: column;
  margin-right: 5px; }
  #data-table .sort-control > span {
    line-height: 5px; }
    #data-table .sort-control > span:first-child {
      padding-top: 5px;
      padding-bottom: 0; }
    #data-table .sort-control > span:last-child {
      padding-top: 0;
      padding-bottom: 5px; }
      #data-table .sort-control > span:last-child .icon.icon-desc-sort {
        transform: rotate(180deg) translateX(0.5px); }

@media screen and (max-width: 768px) {
  .form-table dt {
    width: 100%;
    float: left;
    padding-top: 5px; }
  .form-table dd {
    padding: 0px; }
  .form-table .form-group {
    display: inline-block; }
  .sub-tenant-content {
    margin-left: 0px; }
  .feature-list .group-list {
    top: 8px; }
    .feature-list .group-list li:last-child {
      display: block;
      border: none;
      padding-left: 0px;
      margin-left: 0px; }
  .group-feature dd {
    padding: 0px; }
  .group-feature .right-list li {
    width: 100%; }
  .member .list-action {
    text-align: left !important; }
    .member .list-action li {
      display: block !important;
      margin: 0 !important; }
  .member .list-btn-action {
    text-align: left !important; }
    .member .list-btn-action li {
      display: block !important;
      margin: 0 0 1rem 0 !important; }
      .member .list-btn-action li a {
        display: block !important; } }

.list-btn-action li a,
.list-btn-action li button {
  min-width: 8.14286rem; }

.sidebar-menu .accordion {
  border-radius: 0;
  border: none; }

.view-user-group .btn-add-usergroup {
  border: 1px solid var(--gray-color-3); }
  .view-user-group .btn-add-usergroup:hover {
    background-color: var(--gray-color-1); }

.page-container .main-content .title-content,
.page-container .main-content .title-content-list {
  margin-bottom: 0.3571428571rem !important; }

.page-container .main-content .btn-height-sm.no-after:after {
  display: none; }

.page-container .main-content .btn-height-sm.no-margin-right {
  margin-right: 0; }

.page-container .main-content .action-hint {
  font-size: 0.8571428571rem;
  margin-right: 1rem; }

.add-member-page .select-subtenant {
  min-height: calc(100vh - 200px); }

.add-member-page .member-main .list-page {
  display: flex; }

.add-member-page .member-main .paging-steps .step.active.go-to-step .number-step {
  cursor: pointer; }

.add-member-page .member-main .btn--apply {
  z-index: 0 !important; }

.add-member-page .member-main .form-group {
  margin-bottom: 1.5rem !important; }

.add-member-page .member-main .select-add-on-title--custom {
  font-size: 1.14286rem !important;
  font-weight: 500 !important; }

.add-member-page .member-main .select__indicator-separator {
  background-color: transparent !important; }

.add-member-page .member-main .icon-arrow-down-icon {
  position: absolute;
  font-size: 1.78571rem;
  right: 0;
  top: 8%;
  cursor: pointer; }
  .add-member-page .member-main .icon-arrow-down-icon::before {
    content: "\E901";
    color: var(--primary-color);
    background-color: transparent !important; }

.add-member-page .member-main .input-group {
  flex-wrap: inherit !important; }
  .add-member-page .member-main .input-group .react-datepicker-wrapper {
    width: 100% !important; }
  .add-member-page .member-main .input-group .react-datepicker__close-icon {
    height: 16px !important;
    top: 25% !important;
    right: 7px !important; }

.add-member-page .member-main .content .row-content.active {
  border: 1px solid var(--primary-color); }

.add-member-page .member-main .filter-sidebar .filter-header i.icon {
  font-size: 3em; }

.add-member-page .member-main .tbody-height {
  height: auto !important;
  max-height: 21.428571428571427em !important; }

.add-member-page .box-nation {
  margin-left: -1rem;
  margin-right: -1rem; }
  .add-member-page .box-nation.active {
    max-height: 20rem !important;
    transition: max-height 0.2s ease-out; }

.add-member-page .marketing-source {
  background-color: var(--gray-color-2); }

.add-member-page .phone-require {
  margin-top: -1.45rem; }

.add-member-page .debit-card-image {
  text-align: center;
  margin: 1rem 0;
  padding: 1rem 0;
  background-color: var(--gray-color-2);
  border-radius: 0.4rem; }
  .add-member-page .debit-card-image img {
    width: 8rem; }

.add-member-page .title-dupplicate {
  padding-bottom: 0em !important; }

.add-member-page .icon-close-ico {
  font-size: 3em;
  cursor: pointer; }

.add-member-page .duplicate-members-list tbody {
  border: 0.1em solid var(--gray-color-1); }
  .add-member-page .duplicate-members-list tbody tr {
    border-bottom: none; }
  .add-member-page .duplicate-members-list tbody td {
    border-bottom: none; }

.container-modal .swal2-icon.swal2-warning.add-member-page {
  display: flex;
  border-color: var(--warning-color);
  color: var(--warning-color); }

.container-modal .content-center.add-member-page {
  text-align: center;
  color: #545454; }

.container-modal .modalSaas__row--btn .btn {
  font-weight: 700; }

.duplicate-member-popup {
  width: 35em !important; }
  .duplicate-member-popup .close-button {
    color: var(--gray-color-2);
    background-color: var(--primary-color);
    border-radius: 50%;
    top: -1em;
    right: -1em;
    line-height: 0em;
    padding: 1.2em;
    font-size: 1em;
    font-weight: bold; }
    .duplicate-member-popup .close-button:hover {
      background-color: var(--primary-color);
      color: var(--gray-color-2); }
  .duplicate-member-popup .memberName {
    color: var(--primary-color);
    font-weight: bold; }

.change-personal-details-page .input-group {
  flex-wrap: inherit !important; }
  .change-personal-details-page .input-group .react-datepicker-wrapper {
    width: 100% !important; }
  .change-personal-details-page .input-group .react-datepicker__close-icon {
    height: 16px !important;
    top: 25% !important;
    right: 7px !important; }

.change-personal-details-page .box-nation {
  margin-left: -1rem;
  margin-right: -1rem; }
  .change-personal-details-page .box-nation.active {
    max-height: 20rem !important;
    transition: max-height 0.2s ease-out; }

.change-personal-details-page .marketing-source {
  background-color: var(--gray-color-2); }

.change-personal-details-page .phone-require {
  margin-top: -1.45rem; }

.change-personal-details-page .noLongerInUse {
  display: flex;
  flex-direction: column-reverse;
  margin-top: -0.7em; }

.change-personal-details-page .multi-line {
  display: flex;
  flex-direction: column; }

.change-personal-details-page .data-has-changed {
  font-weight: bold;
  font-style: italic; }

@media (height: 768px) {
  .page-container .main-content .member .member .member-paging .title-content p {
    margin-bottom: 0 !important; } }

.page-container .main-content .member .member-main .sticky-breadcrum {
  position: sticky;
  top: 4.28571rem;
  z-index: 2; }

@media (min-width: 1024px) {
  .page-container .main-content .member .member-main .content.select-add-on .sticky-aside {
    height: 8.714285714285714em !important;
    position: sticky !important;
    top: 13.5rem !important; }
    .page-container .main-content .member .member-main .content.select-add-on .sticky-aside .aside-summary {
      width: 100% !important; } }

@media (min-width: 1024px) and (max-width: 1366px) {
  .page-container .main-content .member .member-main .sticky-breadcrum .paging-steps {
    padding: 0.35714285714285715em 0;
    margin-bottom: 0.35714285714285715em !important; }
  .page-container .main-content .member .member-main .content.select-add-on .sticky-aside {
    height: 5.714285714285714em;
    position: sticky;
    top: 12.5rem; }
    .page-container .main-content .member .member-main .content.select-add-on .sticky-aside .aside-summary {
      width: 100%; } }
  @media (min-width: 1024px) and (max-width: 1366px) and (height: 768px) {
    .page-container .main-content .member .member-main .content.select-add-on .sticky-aside {
      top: 13.5rem !important; }
      .page-container .main-content .member .member-main .content.select-add-on .sticky-aside .title-summary {
        padding: 0.21428571428571427em 0.7142857142857143em; }
      .page-container .main-content .member .member-main .content.select-add-on .sticky-aside .pay-now {
        padding: 0.21428571428571427em 0.7142857142857143em; }
        .page-container .main-content .member .member-main .content.select-add-on .sticky-aside .pay-now .number-pay-now {
          padding: 0;
          height: 1.142857142857143em; }
      .page-container .main-content .member .member-main .content.select-add-on .sticky-aside .section-billing {
        padding: 0.21428571428571427em 0.7142857142857143em; }
      .page-container .main-content .member .member-main .content.select-add-on .sticky-aside .section-info {
        padding: 0.21428571428571427em 0.7142857142857143em; }
      .page-container .main-content .member .member-main .content.select-add-on .sticky-aside .section-add-ons {
        padding: 0.21428571428571427em 0.7142857142857143em; }
        .page-container .main-content .member .member-main .content.select-add-on .sticky-aside .section-add-ons .title-content {
          margin-bottom: 0 !important; }
      .page-container .main-content .member .member-main .content.select-add-on .sticky-aside .section-promotions {
        padding: 0.21428571428571427em 0.7142857142857143em; }
        .page-container .main-content .member .member-main .content.select-add-on .sticky-aside .section-promotions .title-content {
          margin-bottom: 0 !important; }
      .page-container .main-content .member .member-main .content.select-add-on .sticky-aside .box-btn {
        padding: 0.2857142857142857em 0.7142857142857143em; } }

@media (min-width: 1024px) and (max-width: 1366px) {
  .page-container .main-content .member .member-main .content .aside-summary .row-span .first-span {
    width: 35% !important; } }

@media (max-width: 991px) {
  .plan-list-view-page.list-page .list-page-title {
    width: 100%; }
  .plan-list-view-page.list-page .box-btn-filter {
    flex-wrap: wrap;
    justify-content: flex-end; }
    .plan-list-view-page.list-page .box-btn-filter .plan-hint {
      width: 100%; }
    .plan-list-view-page.list-page .box-btn-filter .plan-action {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end; }
      .plan-list-view-page.list-page .box-btn-filter .plan-action .fm-success {
        width: 100%;
        text-align: right; }
        .plan-list-view-page.list-page .box-btn-filter .plan-action .fm-success .action-hint {
          margin-right: 0;
          margin-bottom: 0; }
  .member .general-info {
    justify-content: normal;
    flex-direction: column; }
    .member .general-info .info-acc {
      max-width: none;
      margin-bottom: 2.1428571429rem; }
    .member .general-info .general-content {
      width: 100%; } }

.package-detail .table-above {
  border-bottom: none; }

.package-detail .grid-divider > :last-child:after {
  content: "";
  background-color: var(--gray-color-1);
  position: absolute;
  top: 0;
  bottom: 0; }
  @media (min-width: 768px) {
    .package-detail .grid-divider > :last-child:after {
      width: 1px;
      height: auto;
      left: -1px; } }

.member-config-page .table-responsive .checkbox-container .checkmark.disabled {
  background-color: var(--gray-color-1) !important; }

.pd-left-35 {
  padding-left: 2.5em !important; }

.btlr-0 {
  border-top-left-radius: 0 !important; }

.bblr-0 {
  border-bottom-left-radius: 0 !important; }

.select-async.select--is-disabled .select__control,
.select-fluid.select--is-disabled .select__control {
  border: 1px solid var(--gray-color-3); }

.select-async .select__control,
.select-fluid .select__control {
  box-shadow: none !important; }
  .select-async .select__control.select__control--menu-is-open,
  .select-fluid .select__control.select__control--menu-is-open {
    border-color: var(--primary-color) !important; }
  .select-async .select__control.select__control--is-focused,
  .select-fluid .select__control.select__control--is-focused {
    border-color: var(--primary-color) !important; }

.select-async .select__menu .select__menu-list .select__option--is-selected,
.select-fluid .select__menu .select__menu-list .select__option--is-selected {
  background-color: var(--primary-color) !important; }

.select-async .select__menu .select__menu-list .select__option--is-focused,
.select-fluid .select__menu .select__menu-list .select__option--is-focused {
  background-color: var(--primary-color-darken) !important; }

.list-page {
  display: unset;
  justify-content: unset; }

.select__indicator-separator {
  display: none; }

.add-edit-fees .row {
  width: 100%; }

.add-edit-fees .collapse-saas .collapsible:after {
  display: none; }

.add-edit-fees .collapse-saas .content {
  overflow: visible; }
  .add-edit-fees .collapse-saas .content .select--is-disabled .select__indicators {
    display: none; }

.iframe {
  width: 100%; }

.plan-edit-availability .member .member-main.member-main--custom > .content .collapse-saas .collapsible::after {
  display: none; }

.plan-edit-availability .member .member-main.member-main--custom > .content .collapse-promotion button.collapsible {
  border-bottom: none;
  padding-bottom: 0; }

.plan-edit .form-check label span {
  line-height: 1.714em; }

.plan-edit .member .member-main.member-main--custom > .content .item-count {
  font-weight: 400;
  margin-left: 5px;
  font-size: 0.8em; }

.plan-edit .member .member-main.member-main--custom > .content .table-list {
  box-shadow: none;
  border: none;
  padding: 0;
  max-width: 100%;
  width: 100%; }
  .plan-edit .member .member-main.member-main--custom > .content .table-list .table-responsive {
    min-height: 0;
    max-height: 100%; }

.plan-edit .member .member-main.member-main--custom > .content .select-custom .form-control:not(:disabled) {
  z-index: 0;
  position: relative;
  background-color: transparent; }

.plan-edit .member .member-main.member-main--custom > .content .component-list {
  max-width: 100%;
  width: 100%; }
  .plan-edit .member .member-main.member-main--custom > .content .component-list .icon-arrow-down-icon {
    position: relative; }
    .plan-edit .member .member-main.member-main--custom > .content .component-list .icon-arrow-down-icon:before {
      color: var(--white); }

.plan-edit .member .member-main.member-main--custom > .content .table-paging .form-group {
  margin-bottom: 0 !important; }

.page-container .main-content .add-edit-tender-class .member .member-main.member-main--custom > .content, .page-container .main-content .add-edit-tender-type .member .member-main.member-main--custom > .content {
  padding: 0.3rem 1.3rem; }
  .page-container .main-content .add-edit-tender-class .member .member-main.member-main--custom > .content .collapse-saas .content, .page-container .main-content .add-edit-tender-type .member .member-main.member-main--custom > .content .collapse-saas .content {
    overflow: visible; }
  .page-container .main-content .add-edit-tender-class .member .member-main.member-main--custom > .content .collapse-saas .collapsible:after, .page-container .main-content .add-edit-tender-type .member .member-main.member-main--custom > .content .collapse-saas .collapsible:after {
    display: none; }
  .page-container .main-content .add-edit-tender-class .member .member-main.member-main--custom > .content .item-count, .page-container .main-content .add-edit-tender-type .member .member-main.member-main--custom > .content .item-count {
    font-weight: 400;
    margin-left: 5px;
    font-size: 0.8em; }
  .page-container .main-content .add-edit-tender-class .member .member-main.member-main--custom > .content > .row, .page-container .main-content .add-edit-tender-type .member .member-main.member-main--custom > .content > .row {
    flex: auto; }
  .page-container .main-content .add-edit-tender-class .member .member-main.member-main--custom > .content .group-btn, .page-container .main-content .add-edit-tender-type .member .member-main.member-main--custom > .content .group-btn {
    padding-left: 0;
    padding-right: 0;
    width: 100%; }
  .page-container .main-content .add-edit-tender-class .member .member-main.member-main--custom > .content .required, .page-container .main-content .add-edit-tender-type .member .member-main.member-main--custom > .content .required {
    color: var(--danger-color); }
  .page-container .main-content .add-edit-tender-class .member .member-main.member-main--custom > .content .error-holder .error, .page-container .main-content .add-edit-tender-type .member .member-main.member-main--custom > .content .error-holder .error {
    flex: 0 0 100%;
    color: var(--danger-color);
    font-size: 0.8571428571rem; }
  .page-container .main-content .add-edit-tender-class .member .member-main.member-main--custom > .content .error-holder input,
  .page-container .main-content .add-edit-tender-class .member .member-main.member-main--custom > .content .error-holder textarea,
  .page-container .main-content .add-edit-tender-class .member .member-main.member-main--custom > .content .error-holder select,
  .page-container .main-content .add-edit-tender-class .member .member-main.member-main--custom > .content .error-holder .icon-checked-ico:before,
  .page-container .main-content .add-edit-tender-class .member .member-main.member-main--custom > .content .error-holder .select__control, .page-container .main-content .add-edit-tender-type .member .member-main.member-main--custom > .content .error-holder input,
  .page-container .main-content .add-edit-tender-type .member .member-main.member-main--custom > .content .error-holder textarea,
  .page-container .main-content .add-edit-tender-type .member .member-main.member-main--custom > .content .error-holder select,
  .page-container .main-content .add-edit-tender-type .member .member-main.member-main--custom > .content .error-holder .icon-checked-ico:before,
  .page-container .main-content .add-edit-tender-type .member .member-main.member-main--custom > .content .error-holder .select__control {
    border-color: var(--danger-color) !important; }
  @media (max-width: 1024px) and (min-width: 991px) {
    .page-container .main-content .add-edit-tender-class .member .member-main.member-main--custom > .content .overflow-text, .page-container .main-content .add-edit-tender-type .member .member-main.member-main--custom > .content .overflow-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: calc(100% - 3em);
      overflow: hidden;
      display: inline-block;
      vertical-align: text-top; } }
  @media (max-width: 575px) {
    .page-container .main-content .add-edit-tender-class .member .member-main.member-main--custom > .content .group-btn, .page-container .main-content .add-edit-tender-type .member .member-main.member-main--custom > .content .group-btn {
      flex-direction: unset;
      justify-content: space-between; }
    .page-container .main-content .add-edit-tender-class .member .member-main.member-main--custom > .content .item-count, .page-container .main-content .add-edit-tender-type .member .member-main.member-main--custom > .content .item-count {
      display: block;
      margin: 0; } }

.look-up .lookup-action .btn-add-new:hover {
  background-color: transparent;
  color: var(--primary-color) !important;
  border-color: var(--primary-color); }

.add-lookup .lookup-main {
  padding: 0.714em 1.4285714285714286em;
  box-shadow: 0 0 0.21429rem var(--gray-color-3); }
  .add-lookup .lookup-main .lookup-control {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0; }
    .add-lookup .lookup-main .lookup-control .root-lookup {
      font-weight: 600; }
    .add-lookup .lookup-main .lookup-control .form-group {
      flex: 1;
      margin-right: 3.571em; }
    .add-lookup .lookup-main .lookup-control .form-group:last-child {
      margin-right: 0; }
  .add-lookup .lookup-main .lookup-list-item {
    background: white;
    overflow: auto; }
    .add-lookup .lookup-main .lookup-list-item .box-btn-filter {
      z-index: 1;
      background: white;
      margin-top: 1rem;
      padding-left: 2rem;
      position: absolute; }
      .add-lookup .lookup-main .lookup-list-item .box-btn-filter .btn {
        font-size: 0.786em; }
    .add-lookup .lookup-main .lookup-list-item .look-up-control-list {
      padding: 2.5rem 2rem 0 2rem; }
    .add-lookup .lookup-main .lookup-list-item .lookup-control {
      border-bottom: 0.071em solid var(--gray-color-3); }
      .add-lookup .lookup-main .lookup-list-item .lookup-control .form-group {
        margin-bottom: 1.5rem; }
      .add-lookup .lookup-main .lookup-list-item .lookup-control:last-child {
        border-bottom: none; }
    .add-lookup .lookup-main .lookup-list-item .lookup-action .btn {
      padding-top: 0.357em;
      padding-bottom: 0.357em; }
  .add-lookup .lookup-main .btn-holder {
    margin-top: 1rem;
    text-align: right; }
    .add-lookup .lookup-main .btn-holder .btn {
      width: 7.143em;
      margin-left: 0.714em;
      text-transform: uppercase;
      font-weight: 700; }

@media (max-width: 768px) {
  .add-lookup .lookup-control {
    display: block; }
    .add-lookup .lookup-control .form-group {
      width: 100%; } }

.member-config-page .member-config-page-content {
  padding: 0.714em 1.4285714285714286em;
  box-shadow: 0 0 0.21429rem var(--gray-color-3); }

.member-config-page .form-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.714em; }
  .member-config-page .form-wrapper .title {
    margin-bottom: 0;
    flex-basis: 5.714em; }
  .member-config-page .form-wrapper .select-form {
    margin: 0 0.357em;
    flex-basis: 30%; }
  .member-config-page .form-wrapper .btn-add {
    padding: 0.286em;
    flex-basis: 3.571em; }

.member-config-page .button-holder {
  text-align: right; }
  .member-config-page .button-holder button {
    padding: 0.286em;
    width: 5.714em;
    margin-left: 0.714em; }

.member-config-page .description {
  margin-top: 0.714em; }

.member-config-page .checkbox-container {
  margin-left: 0.714em; }

.member-config-page table td,
.member-config-page table th {
  border-right: unset !important;
  border-bottom: unset !important;
  border-top: unset !important;
  border-color: transparent; }

.member-config-page table td:last-child,
.member-config-page table th:last-child {
  flex-grow: 1;
  flex-basis: 10%; }

.member-config-page table td:nth-child(2),
.member-config-page table th:nth-child(2) {
  flex-grow: 1;
  flex-basis: 30%; }

.member-config-page table td:nth-child(3),
.member-config-page table th:nth-child(3) {
  flex-grow: 1;
  flex-basis: 30%; }

.member-config-page table td:nth-child(4),
.member-config-page table th:nth-child(4) {
  flex-grow: 1;
  flex-basis: 65%; }

.member-config-page table td:nth-child(5),
.member-config-page table th:nth-child(5) {
  flex-grow: 1;
  flex-basis: 65%; }

.member-config-page table tbody {
  background-color: #fff; }
  .member-config-page table tbody tr .bold-text {
    font-weight: 600; }
  .member-config-page table tbody tr .pd-left-30 {
    padding-left: 2.143em; }
  .member-config-page table tbody tr .form-group {
    margin: 0; }
  .member-config-page table tbody tr .delete-icon {
    width: 1.714em;
    cursor: pointer; }
    .member-config-page table tbody tr .delete-icon img {
      width: 100%; }

@media (max-width: 768px) {
  .member-config-page .form-wrapper .select-form {
    flex-grow: 2; }
  .member-config-page .form-wrapper .btn-add {
    flex-grow: 1;
    flex-basis: 10%; } }

.form-pass .title .hint {
  font-weight: bold;
  color: #2FBF71; }

.form-pass .form-input {
  margin-bottom: 0.71429rem; }
  .form-pass .form-input .error {
    border-color: var(--danger-color); }

.form-pass .input-group input[type="text"] + span::before {
  content: "\e95c"; }

.form-pass .ReactPasswordStrength:active {
  border-color: var(--primary-color) !important; }

.form-pass .ReactPasswordStrength:focus {
  border-color: var(--primary-color) !important; }

.form-pass .ReactPasswordStrength .ReactPasswordStrength-input {
  border: none;
  padding: 0 !important;
  line-height: 0 !important;
  font-size: 0.85714rem !important; }
  .form-pass .ReactPasswordStrength .ReactPasswordStrength-input:active {
    border-color: var(--primary-color) !important; }
  .form-pass .ReactPasswordStrength .ReactPasswordStrength-input:focus {
    border-color: var(--primary-color) !important; }

.form-pass .ReactPasswordStrength .ReactPasswordStrength-strength-desc {
  font-size: 0.85714rem !important; }

.form-pass .ReactPasswordStrength .ReactPasswordStrength-strength-bar {
  top: 6px !important;
  margin: 0 -0.75rem !important;
  width: calc(100% + 1.571em) !important; }

.form-pass .react-password-strength-input {
  border-color: var(--primary-color) !important; }

.add-usergroup .form-add-usergroup {
  padding: 0.714em 1.4285714285714286em;
  box-shadow: 0 0 0.21429rem var(--gray-color-3); }

.add-usergroup .switch-container {
  display: flex;
  align-items: center; }
  .add-usergroup .switch-container .label-select-switch {
    padding-left: 0.714em; }

.add-usergroup .subtennant-roles-panel {
  position: relative;
  border-radius: 0.357em;
  border: 0.071em solid var(--gray-color-3);
  padding: 1.071em 1.071em 0 1.071em; }
  .add-usergroup .subtennant-roles-panel.collapsed {
    padding-top: 0;
    padding-bottom: 0; }
  .add-usergroup .subtennant-roles-panel .expand-collapse-button {
    position: absolute;
    top: -3.571em;
    right: 0;
    outline: none; }

.add-usergroup .user-list-table {
  border-radius: 0.357em;
  box-shadow: 0 0.143em 0.357em var(--gray-color-4); }
  .add-usergroup .user-list-table thead {
    background-color: var(--gray-color-3); }
  .add-usergroup .user-list-table tbody tr {
    background-color: var(--gray-color-2); }
    .add-usergroup .user-list-table tbody tr:nth-of-type(even) {
      background-color: var(--bg-row-1); }
  .add-usergroup .user-list-table tr {
    height: 2.5em; }
  .add-usergroup .user-list-table td,
  .add-usergroup .user-list-table th {
    border-bottom: none; }

.add-usergroup .w-300 {
  width: 21.429em !important; }

.add-usergroup .tenant-roles .search-wrapper .form-wrapper .title-first-select {
  margin-right: 3em; }

.add-usergroup .sub-teanant-roles .search-wrapper .form-wrapper .title-first-select {
  margin-right: 1.8125em; }

.form-add-user .generate-pw {
  margin-bottom: 1.42857142857rem; }

.form-add-user .hidden-tooltip .btn-tooltip {
  visibility: hidden; }

.add-usergroup .sub-teanant-roles .expand-collapse-button {
  background: #fff;
  box-shadow: none; }

.form-add-user + .row .add-usergroup {
  padding-top: 1em;
  padding-bottom: 1em;
  border-top: 0.071em solid var(--gray-color-3);
  border-bottom: 0.071em solid var(--gray-color-3); }

.system-page .form-add-role {
  background-color: #fff;
  padding: 0.714em 1.4285714285714286em;
  box-shadow: 0 0 0.21429rem var(--gray-color-3);
  border-radius: 0.2142857143rem; }

.system-page .row-btn {
  text-align: right;
  margin: 2rem 0; }
  .system-page .row-btn .btn {
    width: 10em;
    text-transform: uppercase;
    font-weight: 700; }
  .system-page .row-btn .btn-primary {
    margin-right: 1rem; }

.system-page .edit-acc .col-md-12 {
  margin-top: 2rem; }
  .system-page .edit-acc .col-md-12 .form-table dt {
    max-width: 8.571em; }
  .system-page .edit-acc .col-md-12 .form-table dd {
    display: flex;
    padding: 0; }
    .system-page .edit-acc .col-md-12 .form-table dd .form-group {
      max-width: 21.429em;
      flex: 1; }

.system-page .edit-acc .generate-pw .btn-holder {
  margin-top: -0.714em; }
  .system-page .edit-acc .generate-pw .btn-holder button {
    margin-right: 0.36em;
    padding: 0.143em 0.86em; }

.system-page .edit-acc .form-input {
  display: flex;
  align-items: center; }
  .system-page .edit-acc .form-input .form-group {
    flex: 1; }
  .system-page .edit-acc .form-input .btn-tooltip {
    height: 1.714em;
    width: 1.714em; }
    .system-page .edit-acc .form-input .btn-tooltip:hover {
      background: none; }

.sub-tenant-content {
  display: none;
  margin-left: 10.714em;
  padding: 0.714em; }
  .sub-tenant-content.active {
    display: block;
    position: relative; }
  .sub-tenant-content .sub-tenant-list {
    padding: 0;
    margin: 0; }
    .sub-tenant-content .sub-tenant-list .switch-container {
      display: flex;
      align-content: center; }
  .sub-tenant-content .expand-collapse-button {
    position: absolute;
    top: -4.286em;
    right: 0; }

.search-icon {
  position: absolute;
  left: 0; }

.tag-list {
  padding: 0;
  margin: 0.714em 0; }
  .tag-list li {
    display: inline-block;
    padding: 0 0.357em;
    margin: 0.357em 0; }

.form-table {
  margin-top: 0.357em;
  margin-bottom: 0; }
  .form-table dt {
    width: 10em;
    float: left;
    padding: 0.357em 0;
    font-weight: normal; }
  .form-table dd {
    display: flex;
    vertical-align: middle;
    align-items: center; }
  .form-table .form-group {
    display: inline-block;
    margin-bottom: 0;
    flex-basis: 70%; }

@media screen and (max-width: 768px) {
  .form-table dt {
    width: 100%;
    float: left;
    padding-top: 0.357em; }
  .form-table dd {
    padding: 0; }
  .form-table .form-group {
    display: inline-block; } }

.membersummary-page {
  border-left: none !important;
  border-right: none !important;
  padding-left: 1.4285714285714286em;
  padding-right: 1.4285714285714286em; }
  .membersummary-page .color-red {
    color: #dc3545; }
  .membersummary-page .color-green {
    color: #36db1b; }
  .membersummary-page .general-info p,
  .membersummary-page .general-content p {
    font-size: 0.8571428571428571em; }
  .membersummary-page .general-info .large-size,
  .membersummary-page .general-content .large-size {
    text-transform: uppercase; }
  .membersummary-page .general-info {
    background-color: white;
    justify-content: space-between;
    border-bottom: 0.07143em solid var(--gray-color-2);
    padding: 10px 20px 5px;
    margin: 0 -20px; }
    .membersummary-page .general-info p {
      margin-bottom: 0;
      line-height: 1.375rem; }
    .membersummary-page .general-info .info-acc {
      display: flex; }
      .membersummary-page .general-info .info-acc .color-text {
        color: var(--primary-color);
        font-weight: 700; }
      .membersummary-page .general-info .info-acc .box-avatar {
        width: 5.714285714285714em;
        height: 5.714285714285714em;
        border-radius: 50%;
        border: 0.28571em solid rgba(255, 255, 255, 0.560784);
        margin-left: -5px; }
        .membersummary-page .general-info .info-acc .box-avatar .edit-avartar {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          border: 0.21429em solid var(--gray-color-3);
          position: absolute;
          overflow: hidden;
          cursor: pointer; }
          .membersummary-page .general-info .info-acc .box-avatar .edit-avartar img {
            width: 100%;
            height: 100%;
            object-fit: cover; }
          .membersummary-page .general-info .info-acc .box-avatar .edit-avartar .icon-edit-ico {
            position: absolute;
            bottom: 0;
            display: block;
            width: 100%;
            padding: 0.35714285714285715em;
            background-color: rgba(33, 37, 41, 0.52); }
            .membersummary-page .general-info .info-acc .box-avatar .edit-avartar .icon-edit-ico::before {
              position: relative;
              top: 0;
              left: calc(50% - 7px);
              color: var(--white); }
  .membersummary-page .general-content .positive,
  .membersummary-page .general-content .smile {
    color: var(--positive-color);
    font-weight: 700; }
    .membersummary-page .general-content .positive::before,
    .membersummary-page .general-content .smile::before {
      color: var(--positive-color); }
  .membersummary-page .general-content .neutral,
  .membersummary-page .general-content .sad {
    color: var(--warning-color);
    font-weight: 700; }
    .membersummary-page .general-content .neutral::before,
    .membersummary-page .general-content .sad::before {
      color: var(--warning-color); }
  .membersummary-page .general-content .negative {
    color: var(--danger-color);
    font-weight: 700; }
    .membersummary-page .general-content .negative::before {
      color: var(--danger-color); }
  .membersummary-page .general-content .membership-stt,
  .membersummary-page .general-content .payment-stt,
  .membersummary-page .general-content .last-swipe-date-stt {
    color: unset;
    font-weight: unset; }
    .membersummary-page .general-content .membership-stt:before,
    .membersummary-page .general-content .payment-stt:before,
    .membersummary-page .general-content .last-swipe-date-stt:before {
      font-family: 'icomoon' !important;
      margin-right: 0.5714285714285714em;
      font-weight: 700; }
    .membersummary-page .general-content .membership-stt.positive::before,
    .membersummary-page .general-content .payment-stt.positive::before,
    .membersummary-page .general-content .last-swipe-date-stt.positive::before {
      content: '\e95a';
      font-size: 0.8571428571428571em; }
    .membersummary-page .general-content .membership-stt.neutral::before,
    .membersummary-page .general-content .payment-stt.neutral::before,
    .membersummary-page .general-content .last-swipe-date-stt.neutral::before {
      content: '\e937';
      font-size: 1em; }
    .membersummary-page .general-content .membership-stt.negative::before,
    .membersummary-page .general-content .payment-stt.negative::before,
    .membersummary-page .general-content .last-swipe-date-stt.negative::before {
      content: '\e96c' !important;
      font-size: 0.8571428571428571em; }
    .membersummary-page .general-content .membership-stt.smile::before,
    .membersummary-page .general-content .payment-stt.smile::before,
    .membersummary-page .general-content .last-swipe-date-stt.smile::before {
      content: '\e96b';
      font-size: 0.8571428571428571em; }
    .membersummary-page .general-content .membership-stt.sad::before,
    .membersummary-page .general-content .payment-stt.sad::before,
    .membersummary-page .general-content .last-swipe-date-stt.sad::before {
      content: '\e96a';
      font-size: 0.8571428571428571em; }
  .membersummary-page .membersummary-list {
    margin-top: 0.28571428571rem; }
    .membersummary-page .membersummary-list .section-summary {
      padding: 7px 0; }
    .membersummary-page .membersummary-list .row:first-child .section-summary {
      border-top: none; }
    .membersummary-page .membersummary-list .section-line-between {
      position: relative; }
      .membersummary-page .membersummary-list .section-line-between::before {
        content: "";
        position: absolute;
        display: block;
        width: 0.07142857142857142em;
        top: 10px;
        left: 0;
        bottom: 15px;
        background-color: #f2f2f2; }
    .membersummary-page .membersummary-list .section-line-row {
      border-top: 1px solid #f2f2f2; }
    .membersummary-page .membersummary-list .list-action {
      display: block;
      text-align: right; }
      .membersummary-page .membersummary-list .list-action li {
        display: inline-block; }
        .membersummary-page .membersummary-list .list-action li a,
        .membersummary-page .membersummary-list .list-action li span {
          cursor: pointer; }
          .membersummary-page .membersummary-list .list-action li a:hover,
          .membersummary-page .membersummary-list .list-action li span:hover {
            color: var(--primary-color-darken); }
    .membersummary-page .membersummary-list dl {
      font-size: 11px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      line-height: 1.7; }
      .membersummary-page .membersummary-list dl dt {
        font-weight: 600; }
      .membersummary-page .membersummary-list dl dd {
        margin-bottom: 0;
        text-align: right; }
    .membersummary-page .membersummary-list .summary-header {
      display: flex;
      justify-content: space-between; }
      .membersummary-page .membersummary-list .summary-header.member-account-header, .membersummary-page .membersummary-list .summary-header.membership-header {
        display: unset; }
        .membersummary-page .membersummary-list .summary-header.member-account-header .list-action, .membersummary-page .membersummary-list .summary-header.membership-header .list-action {
          padding-left: 0; }
      .membersummary-page .membersummary-list .summary-header p {
        margin-bottom: 0;
        font-size: 0.8571428571428571em;
        color: var(--primary-color);
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer; }
      .membersummary-page .membersummary-list .summary-header .list-action {
        margin-bottom: 0;
        position: relative; }
        .membersummary-page .membersummary-list .summary-header .list-action li.list-no-action {
          padding-right: 0; }
        .membersummary-page .membersummary-list .summary-header .list-action .show-box {
          display: flex;
          flex-direction: column;
          position: absolute;
          z-index: 10;
          background-color: var(--white);
          border: 0.07143em solid var(--gray-color-1);
          box-shadow: 0px 0.07143em 0.07143em 0px var(--gray-color-1);
          right: -1.07143rem;
          top: 2.542857142857143em;
          padding-left: 0;
          width: auto;
          font-size: 0.8571428571428571em; }
          .membersummary-page .membersummary-list .summary-header .list-action .show-box li {
            padding: 0.5714285714285714em 1.2142857142857142em;
            font-size: 0.8571428571428571em;
            cursor: pointer; }
            .membersummary-page .membersummary-list .summary-header .list-action .show-box li span {
              white-space: nowrap; }
            .membersummary-page .membersummary-list .summary-header .list-action .show-box li:hover {
              background-color: #f2f2f2;
              color: var(--primary-color); }
            .membersummary-page .membersummary-list .summary-header .list-action .show-box li a {
              color: var(--dark-color); }
              .membersummary-page .membersummary-list .summary-header .list-action .show-box li a:hover {
                color: var(--primary-color); }
          .membersummary-page .membersummary-list .summary-header .list-action .show-box::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-left: 0.6em solid transparent;
            border-right: 0.6em solid transparent;
            top: -0.8142857142857143em;
            border-bottom: 0.8em solid var(--gray-color-1);
            right: 1em; }
          .membersummary-page .membersummary-list .summary-header .list-action .show-box::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-left: 0.6em solid transparent;
            border-right: 0.6em solid transparent;
            top: -0.6714285714285714em;
            border-bottom: 0.8em solid var(--white);
            right: 1em;
            z-index: 1; }
        .membersummary-page .membersummary-list .summary-header .list-action .action {
          cursor: pointer;
          color: var(--primary-color);
          position: relative;
          font-size: 0.7142857142857143em; }
          .membersummary-page .membersummary-list .summary-header .list-action .action a,
          .membersummary-page .membersummary-list .summary-header .list-action .action span {
            padding-right: 1.7142857142857143em; }
            .membersummary-page .membersummary-list .summary-header .list-action .action a:after,
            .membersummary-page .membersummary-list .summary-header .list-action .action span:after {
              content: '\e901';
              position: absolute;
              font-family: 'icomoon' !important;
              border: none;
              font-size: 1.78571rem;
              right: -0.3em;
              top: -10px; }
        .membersummary-page .membersummary-list .summary-header .list-action .list-action__items {
          cursor: pointer; }
          .membersummary-page .membersummary-list .summary-header .list-action .list-action__items a,
          .membersummary-page .membersummary-list .summary-header .list-action .list-action__items span {
            color: var(--primary-color);
            position: relative;
            font-size: 0.7142857142857143em;
            margin-right: 1.4285714285714286em; }
            .membersummary-page .membersummary-list .summary-header .list-action .list-action__items a:hover,
            .membersummary-page .membersummary-list .summary-header .list-action .list-action__items span:hover {
              color: var(--primary-color-darken); }
            .membersummary-page .membersummary-list .summary-header .list-action .list-action__items a:before,
            .membersummary-page .membersummary-list .summary-header .list-action .list-action__items span:before {
              content: '\e91e';
              position: absolute;
              font-family: 'icomoon' !important;
              border: none;
              font-size: 1.4285714285714286em;
              left: -1em;
              top: -0.14285714285714285em; }
        .membersummary-page .membersummary-list .summary-header .list-action .list-action__items:not(:last-child) {
          margin-right: 1.4285714285714286em; }
    .membersummary-page .membersummary-list .debt-summary,
    .membersummary-page .membersummary-list .member-account,
    .membersummary-page .membersummary-list .membership,
    .membersummary-page .membersummary-list .personal-detail,
    .membersummary-page .membersummary-list .activity {
      margin-bottom: 0; }
  .membersummary-page .row-btn {
    margin-top: 0.3142857142857143em; }
    .membersummary-page .row-btn .list-btn-action {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0; }
      .membersummary-page .row-btn .list-btn-action li {
        color: white; }
      .membersummary-page .row-btn .list-btn-action .btn {
        margin-right: 0.7142857142857143em;
        font-size: 0.8571428571428571em;
        text-transform: uppercase;
        font-weight: 700;
        padding: 11px 10px !important; }
        .membersummary-page .row-btn .list-btn-action .btn.btn-dark {
          margin-right: 0; }

.member-summary-page footer h3 {
  padding-right: 1.42857142857rem; }

.page-container .main-content {
  padding-top: 5.5em; }

.page-container .member-summary-main-content {
  padding: 4em 0 0 0 !important; }

.change-summary .section-email .col-12 {
  padding-top: 10px;
  background: var(--gray-color-2); }

.change-summary .section-email .payment-email {
  margin-left: 3em;
  flex-direction: column; }
  .change-summary .section-email .payment-email label {
    padding-left: 0; }
  .change-summary .section-email .payment-email .col-5 {
    margin-left: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 0; }

@media screen and (max-width: 1366px) {
  .membersummary-page .select-add-on-main.no-debt-section {
    min-height: 490px; }
  .membersummary-page .membersummary-list dl dd {
    max-width: 58%; }
  .membersummary-page .membersummary-list .summary-header .list-action span:last-child .list-action__items a,
  .membersummary-page .membersummary-list .summary-header .list-action span:last-child .list-action__items span {
    margin-right: 0; }
  .membersummary-page .row-btn .list-btn-action .btn-primary,
  .membersummary-page .row-btn .list-btn-action .btn-dark {
    padding: 6px;
    font-size: 10px; } }

@media only screen and (max-width: 950px) {
  .page-container header .header {
    padding-right: 1.5rem;
    padding-left: 3rem; }
  .page-container header .header__right .header__account {
    min-width: 7em; }
  .membersummary-page {
    border: none; }
    .membersummary-page .table-wrapper .table-filter .left-filter {
      justify-content: left;
      flex-wrap: wrap; }
      .membersummary-page .table-wrapper .table-filter .left-filter > * {
        margin-bottom: 0.5em; }
    .membersummary-page .general-info p {
      font-size: 0.8571428571428571em; }
    .membersummary-page .general-info .info-acc .col-md-9 {
      padding-left: 0; }
    .membersummary-page .row-btn .list-btn-action {
      padding-left: 0; }
      .membersummary-page .row-btn .list-btn-action .btn {
        font-size: 0.7857142857142857em;
        white-space: normal; }
  footer {
    padding-right: 1.1428571428571428em; } }

@media only screen and (max-width: 540px) {
  .membersummary-page .general-info {
    flex-direction: column; }
    .membersummary-page .general-info .info-acc {
      flex-direction: column; }
      .membersummary-page .general-info .info-acc .box-avatar {
        margin: 0 auto; }
  .membersummary-page .member-contact-detail .list-btn-action,
  .membersummary-page .member-account-detail .list-btn-action {
    flex-wrap: wrap;
    padding: 0;
    flex-direction: unset !important; }
    .membersummary-page .member-contact-detail .list-btn-action button,
    .membersummary-page .member-account-detail .list-btn-action button {
      margin-left: 0.5em !important;
      margin-right: 0 !important;
      width: 135px !important;
      margin-bottom: 0.5em; }
  .membersummary-page .membersummary-list .summary-header {
    flex-direction: column; }
    .membersummary-page .membersummary-list .summary-header ul {
      flex-direction: column;
      padding-left: 0.7142857142857143em; }
  .membersummary-page .row-btn .list-btn-action {
    flex-direction: column; }
    .membersummary-page .row-btn .list-btn-action .btn {
      width: 100%;
      margin-right: 0;
      margin-bottom: 0.7142857142857143em; }
      .membersummary-page .row-btn .list-btn-action .btn.btn-dark {
        margin-bottom: 0; } }

@media only screen and (min-device-width: 1600px) {
  .membersummary-page .general-info p,
  .membersummary-page .general-content p {
    font-size: 1rem; }
  .membersummary-page .membersummary-list .summary-header {
    margin-top: 0 !important; }
    .membersummary-page .membersummary-list .summary-header p {
      font-size: 1.1428571428571428em; }
    .membersummary-page .membersummary-list .summary-header .list-action span:last-child .list-action__items {
      padding-right: 0; }
    .membersummary-page .membersummary-list .summary-header .list-action .list-action__items {
      cursor: pointer;
      padding-right: 1.4285714285714286em; }
      .membersummary-page .membersummary-list .summary-header .list-action .list-action__items a,
      .membersummary-page .membersummary-list .summary-header .list-action .list-action__items span {
        color: var(--primary-color);
        position: relative;
        font-size: 0.85714285714rem;
        margin-right: 1.42857142857rem; }
        .membersummary-page .membersummary-list .summary-header .list-action .list-action__items a:last-child,
        .membersummary-page .membersummary-list .summary-header .list-action .list-action__items span:last-child {
          margin-right: 0; }
        .membersummary-page .membersummary-list .summary-header .list-action .list-action__items a:before,
        .membersummary-page .membersummary-list .summary-header .list-action .list-action__items span:before {
          content: '\e91e';
          position: absolute;
          font-family: 'icomoon' !important;
          border: none;
          font-size: 1.78571rem;
          left: -1.42857142857rem;
          top: -0.35714285714285715em; }
    .membersummary-page .membersummary-list .summary-header .list-action .action {
      cursor: pointer;
      padding-right: 0.71428571428rem;
      color: var(--primary-color);
      position: relative;
      font-size: 0.85714285714rem; }
      .membersummary-page .membersummary-list .summary-header .list-action .action a,
      .membersummary-page .membersummary-list .summary-header .list-action .action span {
        padding-right: 0.7142857142857143em; }
        .membersummary-page .membersummary-list .summary-header .list-action .action a:after,
        .membersummary-page .membersummary-list .summary-header .list-action .action span:after {
          right: -0.1em;
          top: -0.71428571428rem; }
    .membersummary-page .membersummary-list .summary-header .list-action .show-box {
      font-size: 1rem; }
  .membersummary-page .membersummary-list dl {
    font-size: 1rem;
    line-height: 2; } }

.view-personal-detail {
  padding-top: 0.71428571428em; }
  .view-personal-detail p {
    font-size: 0.8571428571428571em; }
  .view-personal-detail .list-content {
    padding-left: 0; }
    .view-personal-detail .list-content dl {
      line-height: 1.45;
      font-size: 0.857em; }
      .view-personal-detail .list-content dl dd {
        text-align: left; }
  .view-personal-detail .view-personal-detail_header,
  .view-personal-detail .view-personal-detail-health {
    display: flex;
    justify-content: space-between; }
    .view-personal-detail .view-personal-detail_header .text-title,
    .view-personal-detail .view-personal-detail-health .text-title {
      font-size: 1.143em;
      text-transform: uppercase;
      line-height: 2.14285714286rem;
      white-space: nowrap; }
    .view-personal-detail .view-personal-detail_header .list-action,
    .view-personal-detail .view-personal-detail-health .list-action {
      margin-bottom: 0; }
      .view-personal-detail .view-personal-detail_header .list-action li,
      .view-personal-detail .view-personal-detail-health .list-action li {
        margin-left: 1.78571428571rem; }
      .view-personal-detail .view-personal-detail_header .list-action a,
      .view-personal-detail .view-personal-detail-health .list-action a {
        color: var(--primary-color);
        position: relative;
        font-size: 0.786em;
        margin-right: 1.42857142857em; }
        .view-personal-detail .view-personal-detail_header .list-action a:last-child,
        .view-personal-detail .view-personal-detail-health .list-action a:last-child {
          margin-right: 0; }
        .view-personal-detail .view-personal-detail_header .list-action a:before,
        .view-personal-detail .view-personal-detail-health .list-action a:before {
          content: '\e91e';
          position: absolute;
          font-family: 'icomoon' !important;
          border: none;
          font-size: 1.4285714285714286em;
          left: -1em;
          top: -0.14285714285714285em; }
  .view-personal-detail .view-personal-detail-health {
    padding-top: 0.85714285714em;
    border-top: 1px solid var(--gray-color-2); }
    .view-personal-detail .view-personal-detail-health dl {
      display: flex;
      margin-bottom: 0; }
      .view-personal-detail .view-personal-detail-health dl dd {
        text-align: left; }
    .view-personal-detail .view-personal-detail-health .row-btn {
      margin-top: 0.71428571428em; }
      .view-personal-detail .view-personal-detail-health .row-btn .list-btn-action {
        display: flex;
        justify-content: flex-end; }
        .view-personal-detail .view-personal-detail-health .row-btn .list-btn-action .btn {
          margin-right: 0.71428571428em;
          font-size: 0.857em;
          text-transform: uppercase; }
          .view-personal-detail .view-personal-detail-health .row-btn .list-btn-action .btn.btn-dark {
            margin-right: 0; }

@media only screen and (min-device-width: 1600px) {
  .view-personal-detail p {
    font-size: 1rem; }
  .view-personal-detail .view-personal-detail_header .list-action a,
  .view-personal-detail .view-personal-detail-health .list-action a {
    color: var(--primary-color);
    position: relative;
    font-size: 0.786em;
    margin-right: 1.42857142857rem; }
    .view-personal-detail .view-personal-detail_header .list-action a:last-child,
    .view-personal-detail .view-personal-detail-health .list-action a:last-child {
      margin-right: 0; }
    .view-personal-detail .view-personal-detail_header .list-action a:before,
    .view-personal-detail .view-personal-detail-health .list-action a:before {
      content: '\e91e';
      position: absolute;
      font-family: 'icomoon' !important;
      border: none;
      font-size: 1.78571rem;
      left: -1.42857142857rem;
      top: -0.42857142857142855em; } }

.membersummary-page .membersummary-list .member-account-detail .row-btn .list-btn-action .btn {
  width: 150px;
  padding: 0.71429rem;
  font-size: 1rem; }

.membersummary-page .membersummary-list .member-account-detail hr {
  margin-top: 0.25em;
  margin-bottom: 0.25em; }

.membersummary-page .membersummary-list .member-account-detail .member-account-top .section-summary .summary-header p {
  font-weight: 700; }

.membersummary-page .membersummary-list .member-account-detail .member-account-top .section-summary .summary-header .list-action {
  padding-left: 1em; }
  .membersummary-page .membersummary-list .member-account-detail .member-account-top .section-summary .summary-header .list-action .action {
    left: 1em;
    margin-right: 0.5em; }
    .membersummary-page .membersummary-list .member-account-detail .member-account-top .section-summary .summary-header .list-action .action > span {
      padding-right: 1.2em; }
      .membersummary-page .membersummary-list .member-account-detail .member-account-top .section-summary .summary-header .list-action .action > span:after {
        right: -0.2em; }
  .membersummary-page .membersummary-list .member-account-detail .member-account-top .section-summary .summary-header .list-action > span {
    position: relative; }

.membersummary-page .membersummary-list .member-account-detail .section-summary {
  padding: 0.71429rem 0; }
  .membersummary-page .membersummary-list .member-account-detail .section-summary .summary-header {
    align-items: center; }
    .membersummary-page .membersummary-list .member-account-detail .section-summary .summary-header p {
      color: var(--base-color);
      font-weight: 400;
      cursor: unset; }
    .membersummary-page .membersummary-list .member-account-detail .section-summary .summary-header .show-dropdown {
      display: flex; }
      .membersummary-page .membersummary-list .member-account-detail .section-summary .summary-header .show-dropdown label {
        margin-right: 0.71429rem;
        display: flex;
        align-items: center; }
      .membersummary-page .membersummary-list .member-account-detail .section-summary .summary-header .show-dropdown .select-custom {
        width: 200px;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 0.5rem; }
  .membersummary-page .membersummary-list .member-account-detail .section-summary .left-content {
    font-weight: 700; }

@media only screen and (min-device-width: 1600px) {
  .membersummary-page .membersummary-list .member-account-detail .member-account-top .section-summary .summary-header .list-action {
    padding-left: 1em; }
    .membersummary-page .membersummary-list .member-account-detail .member-account-top .section-summary .summary-header .list-action .action {
      left: 1em;
      margin-right: 0em; }
      .membersummary-page .membersummary-list .member-account-detail .member-account-top .section-summary .summary-header .list-action .action > span {
        padding-right: 1.2em; }
        .membersummary-page .membersummary-list .member-account-detail .member-account-top .section-summary .summary-header .list-action .action > span:after {
          right: 0.15em; } }

@media screen and (max-width: 1366px) {
  .membersummary-page .membersummary-list .member-account-detail .section-summary {
    padding: 0.57143rem 0;
    font-size: 0.85714rem; }
    .membersummary-page .membersummary-list .member-account-detail .section-summary .summary-header p {
      font-size: 0.85714rem; }
    .membersummary-page .membersummary-list .member-account-detail .section-summary .summary-header .list-action .list-action__items span,
    .membersummary-page .membersummary-list .member-account-detail .section-summary .summary-header .list-action .action span,
    .membersummary-page .membersummary-list .member-account-detail .section-summary .summary-header .list-action .show-box span {
      font-size: 0.85714rem; } }

.new-member-summary {
  margin: 0;
  display: flex; }
  .new-member-summary .debt-summary-wrapper {
    border-radius: 0.21429rem;
    padding: 0.71429rem 0.71429rem;
    border: 0.07143rem solid var(--gray-color-1);
    background-color: white; }
    .new-member-summary .debt-summary-wrapper .debt-title {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.42857rem;
      text-align: center;
      padding: 0.71429rem 0; }
    .new-member-summary .debt-summary-wrapper .money-value {
      margin: 0;
      text-align: center;
      text-transform: uppercase;
      padding: 0.71429rem 0 1.42857rem 0;
      white-space: nowrap; }
      .new-member-summary .debt-summary-wrapper .money-value .title-value {
        margin: 0; }
      .new-member-summary .debt-summary-wrapper .money-value .value {
        font-size: 2rem;
        font-weight: 700; }
        .new-member-summary .debt-summary-wrapper .money-value .value sup {
          font-size: 65%; }
    .new-member-summary .debt-summary-wrapper .content-wrapper {
      background-color: var(--gray-color-2);
      padding: 0.71429rem;
      border-radius: 0.21429rem;
      border: 0.07143rem solid var(--gray-color-1);
      margin-bottom: 0.71429rem; }
      .new-member-summary .debt-summary-wrapper .content-wrapper dl {
        justify-content: unset; }
        .new-member-summary .debt-summary-wrapper .content-wrapper dl .debt-chase {
          min-width: 12.28571rem;
          padding-left: 1.07143rem;
          padding-right: 0.35714rem; }
        .new-member-summary .debt-summary-wrapper .content-wrapper dl .original-state {
          min-width: 13.57143rem;
          padding-left: 1.07143rem; }
        .new-member-summary .debt-summary-wrapper .content-wrapper dl .debt-value {
          padding: 0; }
        .new-member-summary .debt-summary-wrapper .content-wrapper dl dd {
          text-align: left; }
      .new-member-summary .debt-summary-wrapper .content-wrapper.money-wrapper {
        padding: 0;
        background-color: unset;
        padding: 0;
        border-radius: unset;
        border: none;
        margin-bottom: 0; }
      .new-member-summary .debt-summary-wrapper .content-wrapper .title {
        text-transform: uppercase;
        margin: 0; }
      .new-member-summary .debt-summary-wrapper .content-wrapper .debt-state {
        text-transform: uppercase;
        font-size: 1.28571rem;
        font-weight: 700; }
      .new-member-summary .debt-summary-wrapper .content-wrapper .debit-content {
        display: flex;
        align-items: center; }
        .new-member-summary .debt-summary-wrapper .content-wrapper .debit-content .image-content {
          border: 0.07143rem solid white;
          border-radius: 50%;
          background-color: white;
          width: 7.14286rem;
          height: 7.14286rem;
          padding: 0.71429rem;
          margin-right: 0.71429rem; }
        .new-member-summary .debt-summary-wrapper .content-wrapper .debit-content img {
          position: relative;
          top: 0.35714rem;
          width: 100%;
          height: 100%; }
        .new-member-summary .debt-summary-wrapper .content-wrapper .debit-content .text-content {
          margin-bottom: 0.71429rem; }
          .new-member-summary .debt-summary-wrapper .content-wrapper .debit-content .text-content .text-value {
            margin: 0;
            color: black;
            line-height: 1.3;
            font-size: 0.92857rem; }
          .new-member-summary .debt-summary-wrapper .content-wrapper .debit-content .text-content .value {
            color: var(--primary-color);
            font-size: 1.28571rem;
            font-weight: 600; }
      .new-member-summary .debt-summary-wrapper .content-wrapper .last-bank-reject {
        display: block; }
        .new-member-summary .debt-summary-wrapper .content-wrapper .last-bank-reject dd {
          text-align: left; }
    .new-member-summary .debt-summary-wrapper .button-wrapper {
      display: flex; }
      .new-member-summary .debt-summary-wrapper .button-wrapper button {
        flex: 1;
        font-weight: 600;
        text-transform: uppercase; }
        .new-member-summary .debt-summary-wrapper .button-wrapper button.btn-view-debt {
          color: var(--base-color);
          background-color: var(--gray-color-1);
          border: 0.07143rem solid var(--gray-color-1); }
        .new-member-summary .debt-summary-wrapper .button-wrapper button:first-child {
          margin-right: 0.35714rem; }
        .new-member-summary .debt-summary-wrapper .button-wrapper button:nth-child(2) {
          margin-left: 0.35714rem; }
  .new-member-summary .summary-wrapper {
    margin: 0;
    padding-left: 1.07143rem;
    padding-right: 0;
    height: fit-content;
    overflow-x: hidden; }
    .new-member-summary .summary-wrapper .summary-content {
      height: fit-content;
      border: 0.07143rem solid var(--gray-color-1);
      border-radius: 0.21429rem;
      margin: 0 0 0.71429rem 0;
      padding: 0.71429rem 0;
      background-color: white; }
      .new-member-summary .summary-wrapper .summary-content:last-child {
        margin: 0; }
      .new-member-summary .summary-wrapper .summary-content .membership-detail-wrapper,
      .new-member-summary .summary-wrapper .summary-content .personal-detail-wrapper,
      .new-member-summary .summary-wrapper .summary-content .activity-wrapper {
        padding: 0 0.71429rem; }
      .new-member-summary .summary-wrapper .summary-content .future-event {
        padding: 0 0.71429rem;
        margin-top: 0.35714rem; }
      .new-member-summary .summary-wrapper .summary-content .freeze-event-wrapper {
        display: flex;
        justify-content: space-between;
        background: #f2f2f2;
        border-radius: 0.21429rem;
        padding: 0.71429rem;
        padding-right: 0; }
        .new-member-summary .summary-wrapper .summary-content .freeze-event-wrapper .no-mg {
          margin: 0; }
        .new-member-summary .summary-wrapper .summary-content .freeze-event-wrapper .feature-event {
          display: flex;
          align-items: center; }
          .new-member-summary .summary-wrapper .summary-content .freeze-event-wrapper .feature-event .text-holder {
            margin-left: 0.71429rem; }
            .new-member-summary .summary-wrapper .summary-content .freeze-event-wrapper .feature-event .text-holder .title {
              text-transform: uppercase;
              margin: 0;
              font-size: 0.85714rem;
              color: var(--primary-color); }
            .new-member-summary .summary-wrapper .summary-content .freeze-event-wrapper .feature-event .text-holder .freeze-description {
              font-size: 0.85714rem; }
          .new-member-summary .summary-wrapper .summary-content .freeze-event-wrapper .feature-event .calendar-img {
            width: 2.28571rem;
            height: 2.28571rem; }
        .new-member-summary .summary-wrapper .summary-content .freeze-event-wrapper .summary-header .list-action .remove-freeze {
          padding: 0; }
      .new-member-summary .summary-wrapper .summary-content .action {
        width: 1.42857rem;
        height: 1.42857rem;
        padding: 0; }
        .new-member-summary .summary-wrapper .summary-content .action .button-action {
          width: 100%;
          height: 100%;
          border-radius: 0.21429rem;
          border: 0.07143rem solid var(--primary-color);
          background: var(--primary-color);
          opacity: 0.2;
          padding-right: 1.42857rem !important;
          padding-bottom: 0.21429rem; }
          .new-member-summary .summary-wrapper .summary-content .action .button-action::after {
            display: none; }
        .new-member-summary .summary-wrapper .summary-content .action .triangle {
          width: 0;
          height: 0;
          border-left: 0.28571rem solid transparent;
          border-right: 0.28571rem solid transparent;
          position: absolute;
          border-top: 0.28571rem solid var(--primary-color);
          top: 0.57143rem;
          left: 0.5rem; }
      .new-member-summary .summary-wrapper .summary-content .summary-header {
        margin-bottom: 0.14286rem;
        display: flex;
        justify-content: space-between; }
        .new-member-summary .summary-wrapper .summary-content .summary-header p {
          color: var(--base-color) !important;
          line-height: 2; }
      .new-member-summary .summary-wrapper .summary-content .list-action {
        display: flex;
        justify-content: flex-end; }
        .new-member-summary .summary-wrapper .summary-content .list-action .list-action__items span {
          text-transform: capitalize; }
      .new-member-summary .summary-wrapper .summary-content .summary-detail {
        align-items: center;
        margin: 0; }
        .new-member-summary .summary-wrapper .summary-content .summary-detail .access-right .right-wrapper {
          border: 0.07143rem solid var(--primary-color);
          border-radius: 0.21429rem;
          padding: 0.35714rem 0.71429rem;
          margin: 0.35714rem 0;
          min-width: 10.71429rem; }
          .new-member-summary .summary-wrapper .summary-content .summary-detail .access-right .right-wrapper:first-child {
            margin-bottom: 0; }
          .new-member-summary .summary-wrapper .summary-content .summary-detail .access-right .right-wrapper p {
            margin: 0; }
          .new-member-summary .summary-wrapper .summary-content .summary-detail .access-right .right-wrapper .right-name {
            color: var(--primary-color);
            font-size: 1.14286rem;
            font-weight: 700; }
        .new-member-summary .summary-wrapper .summary-content .summary-detail .detail {
          width: 100%;
          margin-left: 1.42857rem;
          padding: 0; }
          .new-member-summary .summary-wrapper .summary-content .summary-detail .detail .col-5 {
            padding-right: 0; }
          .new-member-summary .summary-wrapper .summary-content .summary-detail .detail dl {
            line-height: 1.5 !important;
            justify-content: unset !important; }
            .new-member-summary .summary-wrapper .summary-content .summary-detail .detail dl.row {
              margin: 0; }
            .new-member-summary .summary-wrapper .summary-content .summary-detail .detail dl dd {
              text-align: left !important; }
      .new-member-summary .summary-wrapper .summary-content .activity-summary {
        height: 100%;
        display: flex;
        flex-direction: column; }
      .new-member-summary .summary-wrapper .summary-content .activity-detail {
        border: 0.07143rem solid var(--gray-color-1);
        border-radius: 0.21429rem;
        flex-grow: 2;
        margin-bottom: 0;
        align-items: stretch;
        align-content: stretch;
        display: flex;
        flex-direction: column; }
        .new-member-summary .summary-wrapper .summary-content .activity-detail .member-ship-card {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0.35714rem 0.71429rem; }
          .new-member-summary .summary-wrapper .summary-content .activity-detail .member-ship-card p {
            text-transform: uppercase;
            margin: 0;
            line-height: 1.1; }
          .new-member-summary .summary-wrapper .summary-content .activity-detail .member-ship-card .card-number {
            color: var(--primary-color);
            font-size: 1.42857rem;
            font-weight: 700; }
        .new-member-summary .summary-wrapper .summary-content .activity-detail .activist {
          background-color: var(--gray-color-2);
          height: 100%;
          display: flex;
          flex-direction: column; }
          .new-member-summary .summary-wrapper .summary-content .activity-detail .activist .visit,
          .new-member-summary .summary-wrapper .summary-content .activity-detail .activist .access {
            margin: 0;
            padding-top: 0.71429rem;
            padding-bottom: 0.35714rem;
            border-bottom: 0.07143rem solid var(--gray-color-1);
            flex-grow: 2;
            display: flex;
            align-items: center; }
          .new-member-summary .summary-wrapper .summary-content .activity-detail .activist .access:last-child {
            border-bottom: 0; }
          .new-member-summary .summary-wrapper .summary-content .activity-detail .activist .visit .text-content {
            margin-bottom: -0.71429rem; }
          .new-member-summary .summary-wrapper .summary-content .activity-detail .activist .text-content {
            display: flex;
            align-items: center;
            padding: 0 0.71429rem; }
            .new-member-summary .summary-wrapper .summary-content .activity-detail .activist .text-content .no {
              color: var(--primary-color);
              font-size: 2.85714rem;
              font-weight: 700;
              line-height: 0.8;
              padding-right: 0.35714rem;
              position: relative;
              top: -0.57143rem;
              min-width: 3.57143rem;
              text-align: right; }
              .new-member-summary .summary-wrapper .summary-content .activity-detail .activist .text-content .no.no-assgigned {
                top: unset; }
            .new-member-summary .summary-wrapper .summary-content .activity-detail .activist .text-content .text {
              line-height: 1.4; }
              .new-member-summary .summary-wrapper .summary-content .activity-detail .activist .text-content .text .main-text {
                font-size: 1.07143rem;
                text-transform: uppercase;
                color: black; }
                .new-member-summary .summary-wrapper .summary-content .activity-detail .activist .text-content .text .main-text.member-card {
                  max-width: 11.42857rem; }
          .new-member-summary .summary-wrapper .summary-content .activity-detail .activist .description {
            padding: 0;
            font-size: 1.07143rem;
            line-height: 1.3; }
            .new-member-summary .summary-wrapper .summary-content .activity-detail .activist .description .status {
              font-size: 1.35714rem;
              font-weight: 700;
              color: var(--primary-color); }
      .new-member-summary .summary-wrapper .summary-content .personal-detail-summary {
        height: 100%;
        display: flex;
        flex-direction: column; }
      .new-member-summary .summary-wrapper .summary-content .personal-detail {
        border: 0.07143rem solid var(--gray-color-1);
        border-radius: 0.21429rem;
        flex-grow: 2;
        margin-bottom: 0;
        align-items: stretch;
        align-content: stretch;
        display: flex;
        flex-direction: column; }
        .new-member-summary .summary-wrapper .summary-content .personal-detail .content {
          border-bottom: 0.07143rem solid var(--gray-color-1);
          margin-left: 0;
          margin-right: 0;
          padding: 0.71429rem 0;
          flex-grow: 1;
          align-items: center; }
          .new-member-summary .summary-wrapper .summary-content .personal-detail .content:last-child {
            border-bottom: unset; }
        .new-member-summary .summary-wrapper .summary-content .personal-detail p {
          font-weight: 700;
          margin-bottom: 0.1rem; }
        .new-member-summary .summary-wrapper .summary-content .personal-detail .text {
          line-height: 1.1;
          word-break: break-word; }
      .new-member-summary .summary-wrapper .summary-content .contact-detail {
        border: 0.07143rem solid var(--gray-color-1);
        border-radius: 0.21429rem; }
        .new-member-summary .summary-wrapper .summary-content .contact-detail .content {
          border-bottom: 0.07143rem solid var(--gray-color-1);
          margin-left: 0;
          margin-right: 0; }
          .new-member-summary .summary-wrapper .summary-content .contact-detail .content:last-child {
            border-bottom: unset; }
          .new-member-summary .summary-wrapper .summary-content .contact-detail .content .detail-data {
            display: flex;
            align-items: center;
            border-right: 0.07143rem solid var(--gray-color-1);
            padding: 0.35714rem 0; }
            .new-member-summary .summary-wrapper .summary-content .contact-detail .content .detail-data:last-child {
              border-right: unset; }
            .new-member-summary .summary-wrapper .summary-content .contact-detail .content .detail-data .number {
              color: var(--primary-color);
              font-size: 2.85714rem;
              font-weight: 700;
              min-width: 3.92857rem;
              text-align: right;
              margin-right: 0.71429rem; }
            .new-member-summary .summary-wrapper .summary-content .contact-detail .content .detail-data .text-content {
              line-height: 1.3; }
            .new-member-summary .summary-wrapper .summary-content .contact-detail .content .detail-data .title {
              font-size: 1.07143rem;
              text-transform: uppercase;
              font-weight: 700; }
      .new-member-summary .summary-wrapper .summary-content.member-account-wrapper {
        border: 0.07143rem solid var(--primary-color);
        background-color: var(--primary-color);
        padding: 0 0.71429rem; }
        .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .action .button-action {
          border: 0.07143rem solid white;
          background: white;
          opacity: 1; }
        .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .action .triangle {
          border-top: 0.28571rem solid var(--primary-color); }
        .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary {
          display: flex;
          width: 100%; }
          .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .current-balance {
            color: white;
            border-right: 0.07143rem solid var(--gray-color-1);
            padding: 2.85714rem 0;
            min-width: 10.71429rem;
            text-align: center;
            align-items: center;
            margin: 0; }
            .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .current-balance p {
              margin: 0;
              padding: 0;
              line-height: 1;
              text-transform: uppercase; }
            .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .current-balance .current-value {
              font-weight: 700;
              padding: 0;
              line-height: 1; }
              .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .current-balance .current-value .number-pay-now {
                font-size: 1.71429rem; }
          .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .charge-content {
            color: white;
            border-right: 0.07143rem solid var(--gray-color-1);
            padding: 1.78571rem 0.71429rem;
            width: 12.14286rem; }
            .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .charge-content.has-current-balance {
              padding: 1.07143rem;
              width: auto; }
            .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .charge-content .content-wrapper:first-child {
              margin-bottom: 1.07143rem; }
            .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .charge-content .charge-title {
              text-transform: uppercase; }
            .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .charge-content .charge-value {
              font-weight: 700;
              width: 100%;
              margin-right: 6.42857rem; }
              .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .charge-content .charge-value span {
                font-weight: 400; }
          .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content {
            width: 100%; }
            .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .list-action {
              padding-top: 0.71429rem;
              width: 100%;
              display: flex;
              justify-content: flex-end; }
              .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .list-action .list-action__items span {
                color: white;
                text-transform: capitalize; }
              .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .list-action .list-action__items a {
                color: white; }
              .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .list-action .show-box {
                top: 3.2em; }
            .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right {
              color: white;
              display: flex;
              align-items: center; }
              .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .start-date {
                text-align: right;
                width: 7.14286rem;
                margin: 0 1.42857rem; }
                .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .start-date .title {
                  line-height: 1.1; }
                .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .start-date .value {
                  font-weight: 700;
                  font-size: 1.14286rem; }
              .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .date-mcp {
                margin: 1.07143rem; }
                .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .date-mcp .contract-month {
                  display: flex;
                  align-items: flex-end;
                  border-bottom: 0.07143rem solid var(--gray-color-1);
                  padding-bottom: 0.71429rem;
                  width: 9.28571rem; }
                  .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .date-mcp .contract-month .no {
                    text-align: right;
                    font-size: 2.85714rem;
                    font-weight: 700;
                    line-height: 0.8;
                    position: relative;
                    top: 0;
                    min-width: 3.57143rem;
                    margin-right: 0.35714rem; }
                  .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .date-mcp .contract-month .contract-text {
                    line-height: 1.2; }
                .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .date-mcp .date-mcp-content {
                  text-align: center; }
                  .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .date-mcp .date-mcp-content .date {
                    line-height: 1.2;
                    font-size: 1rem;
                    font-weight: 700; }
              .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper {
                position: relative;
                width: 60%; }
                .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper .debt-start-date,
                .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper .last-visit {
                  display: inline-grid;
                  text-align: center;
                  width: auto;
                  position: absolute; }
                .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper .debt-start-date {
                  top: 1.07143rem; }
                  .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper .debt-start-date .debt-start-date-title {
                    line-height: 1; }
                  .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper .debt-start-date .debt-start-date-value {
                    line-height: 1.1;
                    font-weight: 700; }
                  .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper .debt-start-date::before {
                    content: '';
                    position: absolute;
                    width: 0.85714rem;
                    height: 0.85714rem;
                    border-radius: 50%;
                    top: -1.28571rem;
                    left: 42%;
                    background-color: white; }
                  .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper .debt-start-date::after {
                    content: '';
                    transform: rotate(90deg);
                    position: absolute;
                    width: 1.07143rem;
                    height: 0.07143rem;
                    top: -0.42857rem;
                    left: 41.2%;
                    background-color: white; }
                .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper .last-visit {
                  top: -3.71429rem;
                  padding: 0.35714rem 0.71429rem; }
                  .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper .last-visit::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: white;
                    opacity: 0.2;
                    border-radius: 0.21429rem; }
                  .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper .last-visit .last-visit-title {
                    line-height: 1.1; }
                  .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper .last-visit .last-visit-value {
                    line-height: 1.1;
                    font-weight: 700; }
                  .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper .last-visit::before {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 0.6rem solid transparent;
                    border-right: 0.6rem solid transparent;
                    position: absolute;
                    border-top: 0.6rem solid white;
                    opacity: 0.2;
                    bottom: -0.60714rem;
                    left: 40%; }
              .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress {
                background-color: transparent;
                height: 0.42857rem; }
                .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress::after {
                  content: '';
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  width: 100%;
                  background-color: white;
                  opacity: 0.4;
                  border-radius: 0.35714rem; }
              .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-bar {
                background-color: white; }

@media screen and (max-width: 1440px) {
  .new-member-summary .debt-summary-wrapper {
    padding: 0.35714rem; }
    .new-member-summary .debt-summary-wrapper .debt-title {
      font-size: 1.28571rem;
      padding: 0.35714rem 0; }
    .new-member-summary .debt-summary-wrapper .title-value {
      font-size: 0.85714rem; }
    .new-member-summary .debt-summary-wrapper .money-value {
      margin: 0;
      padding: 0.71429rem 0; }
      .new-member-summary .debt-summary-wrapper .money-value .value {
        font-size: 1.71429rem; }
    .new-member-summary .debt-summary-wrapper .content-wrapper {
      margin-bottom: 0.35714rem;
      padding: 0.35714rem 0.71429rem; }
      .new-member-summary .debt-summary-wrapper .content-wrapper dl .debt-chase {
        min-width: 11.42857rem; }
      .new-member-summary .debt-summary-wrapper .content-wrapper dl .original-state {
        min-width: 12.14286rem; }
      .new-member-summary .debt-summary-wrapper .content-wrapper .title {
        font-size: 0.78571rem; }
      .new-member-summary .debt-summary-wrapper .content-wrapper .debt-state {
        line-height: 1.2; }
      .new-member-summary .debt-summary-wrapper .content-wrapper .debit-content .image-content {
        width: 5.71429rem;
        height: 5.71429rem; }
      .new-member-summary .debt-summary-wrapper .content-wrapper .debit-content .text-content .text-value {
        font-size: 0.78571rem; }
      .new-member-summary .debt-summary-wrapper .content-wrapper .debit-content .text-content .value {
        font-size: 1rem;
        line-height: 1.3; }
    .new-member-summary .debt-summary-wrapper .button-wrapper {
      flex-wrap: wrap; }
      .new-member-summary .debt-summary-wrapper .button-wrapper button {
        font-size: 0.85714rem;
        margin: 0 -0.07143rem;
        padding-left: 0.35714rem;
        padding-right: 0.35714rem; }
  .new-member-summary .list-action .list-action__items {
    line-height: 1; }
    .new-member-summary .list-action .list-action__items span,
    .new-member-summary .list-action .list-action__items a {
      line-height: 1; }
      .new-member-summary .list-action .list-action__items span::before,
      .new-member-summary .list-action .list-action__items a::before {
        top: 0 !important; }
  .new-member-summary .list-action .show-box {
    right: -0.71429rem !important; }
  .new-member-summary .summary-wrapper {
    padding-left: 0.35714rem; }
    .new-member-summary .summary-wrapper .summary-content {
      margin: 0 0 0.35714rem;
      padding: 0.35714rem 0; }
      .new-member-summary .summary-wrapper .summary-content .future-event {
        padding: 0 0.35714rem; }
      .new-member-summary .summary-wrapper .summary-content .action .button-action {
        padding-right: 1.28571rem !important; }
      .new-member-summary .summary-wrapper .summary-content .action .triangle {
        width: 0;
        height: 0;
        top: 0.5rem;
        left: 0.42857rem; }
      .new-member-summary .summary-wrapper .summary-content .membership-detail-wrapper,
      .new-member-summary .summary-wrapper .summary-content .personal-detail-wrapper,
      .new-member-summary .summary-wrapper .summary-content .activity-wrapper {
        padding: 0 0.35714rem; }
      .new-member-summary .summary-wrapper .summary-content .summary-detail .access-right .right-wrapper {
        min-width: unset; }
        .new-member-summary .summary-wrapper .summary-content .summary-detail .access-right .right-wrapper p {
          font-size: 0.78571rem;
          line-height: 1.3; }
        .new-member-summary .summary-wrapper .summary-content .summary-detail .access-right .right-wrapper .right-name {
          font-size: 1.28571rem; }
      .new-member-summary .summary-wrapper .summary-content .summary-detail .detail {
        margin-left: 0.35714rem; }
      .new-member-summary .summary-wrapper .summary-content .activity-detail .member-ship-card {
        margin: 0.21429rem 0.71429rem; }
        .new-member-summary .summary-wrapper .summary-content .activity-detail .member-ship-card p {
          font-size: 0.78571rem; }
        .new-member-summary .summary-wrapper .summary-content .activity-detail .member-ship-card .card-number {
          font-size: 1.28571rem; }
      .new-member-summary .summary-wrapper .summary-content .activity-detail .activist .visit,
      .new-member-summary .summary-wrapper .summary-content .activity-detail .activist .access {
        padding-top: 0.28571rem;
        padding-bottom: 0.28571rem; }
      .new-member-summary .summary-wrapper .summary-content .activity-detail .activist .text-content .no {
        font-size: 1.71429rem;
        min-width: 2.5rem; }
      .new-member-summary .summary-wrapper .summary-content .activity-detail .activist .text-content .text .main-text {
        font-size: 0.78571rem;
        line-height: 1; }
      .new-member-summary .summary-wrapper .summary-content .activity-detail .activist .text-content .sub-text {
        font-size: 0.78571rem; }
      .new-member-summary .summary-wrapper .summary-content .activity-detail .activist .description {
        font-size: 0.85714rem; }
        .new-member-summary .summary-wrapper .summary-content .activity-detail .activist .description .status {
          font-size: 1rem;
          line-height: 1; }
      .new-member-summary .summary-wrapper .summary-content .personal-detail .content {
        padding: 0.21429rem 0; }
      .new-member-summary .summary-wrapper .summary-content .personal-detail p {
        font-size: 0.78571rem; }
      .new-member-summary .summary-wrapper .summary-content .personal-detail .text {
        font-size: 0.78571rem; }
      .new-member-summary .summary-wrapper .summary-content .contact-detail .content .detail-data {
        padding: 0.28571rem 0; }
        .new-member-summary .summary-wrapper .summary-content .contact-detail .content .detail-data .number {
          font-size: 1.71429rem;
          min-width: 2.5rem; }
        .new-member-summary .summary-wrapper .summary-content .contact-detail .content .detail-data .title {
          font-size: 0.85714rem; }
        .new-member-summary .summary-wrapper .summary-content .contact-detail .content .detail-data .value {
          font-size: 0.78571rem; }
      .new-member-summary .summary-wrapper .summary-content.member-account-wrapper {
        padding-left: 0.35714rem; }
        .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .current-balance {
          padding: 2.5rem 0;
          min-width: 10.71429rem;
          text-transform: uppercase; }
          .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .current-balance p {
            font-size: 0.85714rem; }
          .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .current-balance .current-value .number-pay-now {
            font-size: 1.28571rem; }
        .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .charge-content .content-wrapper:first-child {
          margin-bottom: 0.71429rem; }
        .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .charge-content .charge-title {
          font-size: 0.85714rem; }
        .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .charge-content .charge-value {
          font-size: 0.85714rem; }
        .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content {
          padding: 0; }
          .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .list-action {
            padding: 0; }
          .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right {
            padding-top: 0.71429rem; }
            .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .start-date {
              margin: 0 0.71429rem;
              width: 5rem; }
              .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .start-date .title {
                font-size: 0.71429rem;
                line-height: 1; }
              .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .start-date .value {
                font-size: 0.92857rem; }
            .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .date-mcp {
              margin: 0.35714rem; }
              .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .date-mcp .contract-month {
                width: 6.42857rem; }
                .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .date-mcp .contract-month .no {
                  font-size: 1.71429rem;
                  min-width: 2.14286rem; }
                .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .date-mcp .contract-month .contract-text {
                  font-size: 0.71429rem;
                  line-height: 1; }
              .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .date-mcp .date-mcp-content .text {
                font-size: 0.71429rem; }
              .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .date-mcp .date-mcp-content .date {
                font-size: 0.92857rem; }
            .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper {
              font-size: 0.85714rem; }
              .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper .debt-start-date {
                top: 0.71429rem; }
                .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper .debt-start-date .debt-start-date-title {
                  font-size: 0.71429rem; }
                .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper .debt-start-date .debt-start-date-value {
                  font-size: 0.85714rem; }
                .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper .debt-start-date::before {
                  width: 0.57143rem;
                  height: 0.57143rem;
                  top: -0.85714rem;
                  left: 46%; }
                .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper .debt-start-date::after {
                  width: 0.78571rem;
                  top: -0.21429rem;
                  left: 44.5%; }
              .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper .last-visit {
                top: -45px; }
                .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress-wrapper .last-visit .last-visit-title {
                  line-height: 1;
                  font-size: 0.71429rem; }
            .new-member-summary .summary-wrapper .summary-content.member-account-wrapper .member-account-summary .overview-content .contact-right .progress {
              height: 0.28571rem; } }

@media screen and (max-width: 1366px) {
  .new-member-summary .debt-summary-wrapper .money-value .value {
    font-size: 1.57143rem; } }

@media screen and (max-width: 1200px) {
  .new-member-summary .debt-summary-wrapper {
    margin: 0.35714rem 0; }
    .new-member-summary .debt-summary-wrapper .debt-title {
      padding: 0.71429rem 0; }
    .new-member-summary .debt-summary-wrapper .debt-wrapper {
      display: flex; }
    .new-member-summary .debt-summary-wrapper .money-value {
      text-align: left; }
    .new-member-summary .debt-summary-wrapper .content-wrapper {
      flex: 1; }
      .new-member-summary .debt-summary-wrapper .content-wrapper.money-wrapper {
        background-color: var(--gray-color-2);
        padding: 0.71429rem;
        border-radius: 0.21429rem;
        border: 0.07143rem solid var(--gray-color-1);
        margin-bottom: 0.35714rem; }
      .new-member-summary .debt-summary-wrapper .content-wrapper.debt-content {
        padding: 0;
        background-color: unset;
        padding: 0;
        border-radius: unset;
        border: none;
        margin-bottom: 0; }
      .new-member-summary .debt-summary-wrapper .content-wrapper:first-child {
        margin-right: 0.35714rem; }
      .new-member-summary .debt-summary-wrapper .content-wrapper:last-child {
        margin-left: 0.35714rem; }
  .new-member-summary .summary-wrapper {
    padding-left: 0; }
    .new-member-summary .summary-wrapper .summary-content {
      margin: 0 0 0.35714rem;
      padding: 0.35714rem 0; }
      .new-member-summary .summary-wrapper .summary-content .action .button-action {
        padding-right: 1.28571rem !important; }
      .new-member-summary .summary-wrapper .summary-content .membership-detail-wrapper,
      .new-member-summary .summary-wrapper .summary-content .personal-detail-wrapper,
      .new-member-summary .summary-wrapper .summary-content .activity-wrapper {
        padding: 0 0.35714rem; }
      .new-member-summary .summary-wrapper .summary-content .summary-detail .access-right .right-wrapper {
        min-width: unset; }
        .new-member-summary .summary-wrapper .summary-content .summary-detail .access-right .right-wrapper:first-child {
          margin-left: 1.07143rem;
          margin-right: 0.35714rem;
          margin-bottom: 0.35714rem; }
        .new-member-summary .summary-wrapper .summary-content .summary-detail .access-right .right-wrapper p {
          font-size: 0.78571rem;
          line-height: 1.3; }
        .new-member-summary .summary-wrapper .summary-content .summary-detail .access-right .right-wrapper .right-name {
          font-size: 1.28571rem; }
      .new-member-summary .summary-wrapper .summary-content .summary-detail .detail {
        margin-left: 0.35714rem; } }

.membersummary-page .membersummary-list .member-activity-detail .section-summary {
  padding: 0.71429rem 0; }
  .membersummary-page .membersummary-list .member-activity-detail .section-summary .btn-add-new {
    margin-right: 0; }
    .membersummary-page .membersummary-list .member-activity-detail .section-summary .btn-add-new:after {
      display: none; }
  .membersummary-page .membersummary-list .member-activity-detail .section-summary .summary-header {
    align-items: center; }
    .membersummary-page .membersummary-list .member-activity-detail .section-summary .summary-header p {
      color: var(--base-color);
      font-weight: 700;
      cursor: unset; }
    .membersummary-page .membersummary-list .member-activity-detail .section-summary .summary-header .show-dropdown {
      display: flex; }
      .membersummary-page .membersummary-list .member-activity-detail .section-summary .summary-header .show-dropdown label {
        margin-right: 0.71429rem;
        display: flex;
        align-items: center; }
      .membersummary-page .membersummary-list .member-activity-detail .section-summary .summary-header .show-dropdown .select-custom {
        width: 200px;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 0.5rem; }

.membersummary-page .membersummary-list .member-activity-detail .desc-list {
  font-size: 0.85714rem; }
  .membersummary-page .membersummary-list .member-activity-detail .desc-list .left-content {
    font-weight: 700; }

@media only screen and (max-width: 540px) {
  .membersummary-page .membersummary-list .member-activity-detail .list-btn-action {
    padding: 0;
    flex-wrap: wrap; }
    .membersummary-page .membersummary-list .member-activity-detail .list-btn-action .btn {
      margin-bottom: 0.5em;
      width: 150px;
      padding: 0.71429rem;
      font-size: 1rem; } }

@media screen and (max-width: 1366px) {
  .membersummary-page .membersummary-list .member-activity-detail .section-summary {
    padding: 0.57143rem 0;
    font-size: 0.85714rem; }
    .membersummary-page .membersummary-list .member-activity-detail .section-summary .summary-header p {
      font-size: 0.85714rem; }
    .membersummary-page .membersummary-list .member-activity-detail .section-summary .summary-header .list-action .list-action__items span,
    .membersummary-page .membersummary-list .member-activity-detail .section-summary .summary-header .list-action .action span,
    .membersummary-page .membersummary-list .member-activity-detail .section-summary .summary-header .list-action .show-box span {
      font-size: 0.85714rem; } }

.change-summary .collapse-saas {
  box-shadow: none; }
  .change-summary .collapse-saas .select-group p.title {
    text-transform: uppercase;
    border-bottom: 0.07142857142857142em solid #ebebeb;
    padding-bottom: 0.35714285714285715em;
    color: var(--primary-color);
    margin-bottom: 0.5714285714285714em; }

.change-summary .option-group-change-summary .form-check:nth-child(odd) {
  margin-right: 1.42857rem; }

.change-summary .option-group-change-summary .form-check [type='checkbox']:not(:checked) + label:before,
.change-summary .option-group-change-summary .form-check [type='checkbox']:checked + label:before {
  left: 0.71429rem;
  top: 0.71429rem; }

.change-summary .option-group-change-summary .form-check [type='checkbox']:not(:checked) + label:after,
.change-summary .option-group-change-summary .form-check [type='checkbox']:checked + label:after {
  left: 0.92857rem;
  top: 0.85714rem; }

.change-summary .option-group-change-summary .form-check label {
  padding: 0.71429rem;
  padding-left: 3.21429rem;
  width: 100%;
  margin-bottom: 0; }

.change-summary .option-group-change-summary .form-check .custom-radio {
  margin-bottom: 0; }
  .change-summary .option-group-change-summary .form-check .custom-radio .checkmark-radio {
    top: 0.71429rem;
    left: 0.71429rem; }

.change-summary .form-email {
  padding: 20px 100px 20px 40px;
  background: var(--gray-color-2); }

.list-component-upgrade-css .table-list .table-responsive {
  max-height: none;
  overflow-y: auto; }

.list-component-upgrade-css .table-list .table thead th {
  vertical-align: middle; }
  .list-component-upgrade-css .table-list .table thead th .box-opt .items-opt {
    max-height: none; }
  .list-component-upgrade-css .table-list .table thead th .drop-box-filter {
    max-height: calc(100vh - 50px);
    overflow-y: auto; }
  .list-component-upgrade-css .table-list .table thead th .th-filter {
    flex-direction: row; }
    .list-component-upgrade-css .table-list .table thead th .th-filter > span {
      max-width: calc(100% - 20px);
      white-space: initial;
      overflow: initial; }
      .list-component-upgrade-css .table-list .table thead th .th-filter > span:first-child {
        padding-right: 1.07143rem;
        position: relative; }
      .list-component-upgrade-css .table-list .table thead th .th-filter > span:before {
        position: absolute;
        top: 50%;
        right: 0.21429rem;
        transform: translateY(-50%); }

.list-component-upgrade-css .table-list .table th {
  padding: 0.57143rem; }

.list-component-upgrade-css .table-list .table td {
  padding: 0.57143rem; }

.list-component-upgrade-css .table-list .drop-box-arr > .popup-action > .form-group, .list-component-upgrade-css .table-list .drop-box-arr > .popup-action .operation-select-filter {
  display: none !important; }

.list-component-upgrade-css .table-list .drop-box-arr .btn-add-form {
  display: none; }

.list-component-upgrade-css .table-list .drop-box-arr .form-group > .popup-action > .popup-action {
  width: 100%; }

.list-component-upgrade-css .table-list .th-col-opt .drop-col-opt {
  max-height: none; }

.list-component-upgrade-css .table-list .sort-control {
  display: flex;
  flex-direction: column;
  margin-right: 5px; }
  .list-component-upgrade-css .table-list .sort-control > span {
    line-height: 5px; }
    .list-component-upgrade-css .table-list .sort-control > span:first-child {
      padding-top: 5px;
      padding-bottom: 0; }
    .list-component-upgrade-css .table-list .sort-control > span:last-child {
      padding-top: 0;
      padding-bottom: 5px; }
      .list-component-upgrade-css .table-list .sort-control > span:last-child .icon.icon-desc-sort {
        transform: rotate(180deg) translateX(0.5px); }

.is-collapsed .sidebar-demo .page-container {
  padding-left: 60px; }
  .is-collapsed .sidebar-demo .page-container header {
    width: calc(100% - 60px); }

.is-collapsed .sidebar-demo .sidebar {
  width: 70px; }

.sidebar-demo .page-container {
  padding-left: 260px; }
  .sidebar-demo .page-container header {
    width: calc(100% - 260px); }
    .sidebar-demo .page-container header .header {
      padding-right: 30px; }
      .sidebar-demo .page-container header .header li.header__languages {
        font-size: 0.92857rem; }
        .sidebar-demo .page-container header .header li.header__languages .multi-box .icon-global-ico:before {
          top: 0;
          font-size: 1.42857rem; }
      .sidebar-demo .page-container header .header li.header__account .box-account .account-name {
        font-size: 0.92857rem; }
    .sidebar-demo .page-container header .header__right li.header__notifications {
      padding: 0 30px; }
  .sidebar-demo .page-container .main-content {
    padding: 8.21429rem 1.42857rem 0.07143rem 1.42857rem;
    min-height: calc(100vh - 40px); }
    .sidebar-demo .page-container .main-content .title-content {
      font-size: 1.14286rem; }

.sidebar-demo footer {
  height: 2.5714285714285716em;
  padding: 0 1.42857rem 0 2.14286rem; }

.sidebar-demo .sidebar {
  width: 260px; }
  .sidebar-demo .sidebar .dropdown-toggle {
    padding: 0 0.71429rem; }
    .sidebar-demo .sidebar .dropdown-toggle::after {
      right: 0.21429rem; }
  .sidebar-demo .sidebar .sidebar-inner .sidebar-logo {
    padding: 0 1.28571rem; }
  .sidebar-demo .sidebar .sidebar-inner .sidebar-menu .nav-item .dropdown-menu {
    font-size: 0.92857rem; }
    .sidebar-demo .sidebar .sidebar-inner .sidebar-menu .nav-item .dropdown-menu li {
      height: 45px; }
  .sidebar-demo .sidebar .sidebar-inner .sidebar-menu .nav-item > .dropdown-toggle {
    height: 45px;
    line-height: 3.21429rem; }
    .sidebar-demo .sidebar .sidebar-inner .sidebar-menu .nav-item > .dropdown-toggle .title {
      font-size: 0.80357rem; }

.sidebar-demo .is-collapsed .sidebar {
  width: 60px; }
  .sidebar-demo .is-collapsed .sidebar.sidebar-open {
    width: 260px; }

@media (max-width: 767px) {
  .sidebar-demo body.app nav.sidebar .sidebar__icon ul.sidebar__content > li.dropdown .icon,
  .sidebar-demo body.app nav.sidebar .sidebar__icon ul.sidebar__content li.dropdown.active .icon {
    height: 45px;
    line-height: 3.21429rem; }
    .sidebar-demo body.app nav.sidebar .sidebar__icon ul.sidebar__content > li.dropdown .icon > p.sidebar__title,
    .sidebar-demo body.app nav.sidebar .sidebar__icon ul.sidebar__content li.dropdown.active .icon > p.sidebar__title {
      font-size: 0.85714rem; }
  .sidebar-demo body.app nav.sidebar .sidebar__icon ul.sidebar__content > li.dropdown > .dropdown-menu .dropdown-item,
  .sidebar-demo body.app nav.sidebar .sidebar__icon ul.sidebar__content li.dropdown.active > .dropdown-menu .dropdown-item {
    height: 45px;
    line-height: 3.21429rem; } }

.search-wrapper {
  flex-wrap: wrap;
  width: 70%; }
  .search-wrapper .form-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 0.36em; }
    .search-wrapper .form-wrapper .font-weight-bold {
      width: 6.071em; }
    .search-wrapper .form-wrapper .form-select {
      margin: 0 0.714em;
      flex: 1; }
      .search-wrapper .form-wrapper .form-select .select__control .select__value-container {
        padding: 0 0 0 24px; }
  .search-wrapper .btn-add {
    padding: 0.36em;
    margin-left: 6.625em;
    width: 7.14em;
    text-transform: uppercase;
    font-weight: 700; }

.ewh-autosuggest-container {
  border-radius: 0.357em;
  padding: 0.714em;
  margin-bottom: 1.071em;
  background-color: var(--gray-color-2); }
  .ewh-autosuggest-container.hideTag {
    border: none;
    background-color: inherit;
    padding: 0;
    box-shadow: none; }
  .ewh-autosuggest-container .autosugest-controller {
    display: flex;
    align-items: center;
    margin-bottom: 0.714em; }
  .ewh-autosuggest-container .tooltipText {
    color: var(--secondary-color);
    max-width: none; }
  .ewh-autosuggest-container .tag-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 3.571em;
    padding: 0.714em;
    background-color: var(--white-1); }
    .ewh-autosuggest-container .tag-container.centerText {
      justify-content: center; }
    .ewh-autosuggest-container .tag-container .tag-item {
      margin: 0.36em; }
  .ewh-autosuggest-container .react-autosuggest__container {
    position: relative; }
  .ewh-autosuggest-container .autosuggest-input-container {
    position: relative;
    margin: 0 0.714em; }
  .ewh-autosuggest-container .autosuggest-search-icon {
    position: absolute;
    top: 0.357em;
    bottom: 0.357em;
    left: 0.714em; }
  .ewh-autosuggest-container .react-autosuggest__input {
    min-width: 17.143em;
    padding: 0.357em 1.071em 0.357em 2.857em;
    border: 0.071em solid #aaa;
    border-radius: 1.071em; }
  .ewh-autosuggest-container .react-autosuggest__input--focused {
    outline: none; }
  .ewh-autosuggest-container .react-autosuggest__suggestions-container {
    display: none; }
  .ewh-autosuggest-container .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    margin-left: 0.714em;
    min-width: 17.143em;
    border: 0.071em solid #aaa;
    background-color: #fff;
    z-index: 2; }
  .ewh-autosuggest-container .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none; }
  .ewh-autosuggest-container .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 0.357em 1.071em; }
  .ewh-autosuggest-container .react-autosuggest__suggestion--highlighted {
    background-color: #ddd; }

@media (max-width: 768px) {
  .ewh-autosuggest-container .search-wrapper {
    width: 100%; }
  .ewh-autosuggest-container .tag-container .MuiChip-root-457 {
    height: auto; }
    .ewh-autosuggest-container .tag-container .MuiChip-root-457 .MuiChip-label-476 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal; } }

.table-base {
  display: block;
  empty-cells: show; }
  .table-base tr {
    width: 100%;
    display: flex; }
  .table-base td,
  .table-base th {
    border-right: unset !important;
    flex-basis: 100%;
    flex-grow: 2;
    display: block;
    border-color: transparent; }
  .table-base .align-center {
    text-align: center; }
  .table-base thead {
    display: block;
    width: 100%; }
    .table-base thead th {
      padding: 0.75rem !important; }
  .table-base tbody {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    background-color: #fff; }
    .table-base tbody tr {
      vertical-align: baseline;
      background: transparent;
      border-bottom: 2px solid #fff; }
      .table-base tbody tr td {
        padding: 0.75rem; }

.sidebar .sidebar-inner .sidebar-menu::-webkit-scrollbar {
  width: 0.5em !important; }

.sidebar .sidebar-inner ul {
  cursor: unset; }
  .sidebar .sidebar-inner ul .dropdown-toggle {
    cursor: pointer; }
  .sidebar .sidebar-inner ul .dropdown-menu li.nav-item {
    cursor: pointer; }
    .sidebar .sidebar-inner ul .dropdown-menu li.nav-item.active .dropdown-toggle {
      background-color: transparent; }
      .sidebar .sidebar-inner ul .dropdown-menu li.nav-item.active .dropdown-toggle .icon-holder i.icon::before {
        color: var(--primary-color) !important; }
      .sidebar .sidebar-inner ul .dropdown-menu li.nav-item.active .dropdown-toggle .title {
        color: var(--primary-color); }
    .sidebar .sidebar-inner ul .dropdown-menu li.nav-item .sidebar-link.active .icon-holder i.icon::before {
      color: var(--primary-color) !important; }
    .sidebar .sidebar-inner ul .dropdown-menu li.nav-item .sidebar-link.active .title {
      color: var(--primary-color); }

.is-collapsed .sidebar .sidebar-inner .sidebar-menu .nav-item {
  background-color: var(--primary-color) !important; }
  .is-collapsed .sidebar .sidebar-inner .sidebar-menu .nav-item.dropdown {
    background-color: var(--primary-color) !important; }
    .is-collapsed .sidebar .sidebar-inner .sidebar-menu .nav-item.dropdown .dropdown-toggle:hover {
      background-color: var(--primary-color-darken) !important; }
    .is-collapsed .sidebar .sidebar-inner .sidebar-menu .nav-item.dropdown .dropdown-menu li {
      background-color: var(--primary-color) !important;
      color: var(--base-color); }

.form-checkbox {
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* Show the checkmark when checked */ }
  .form-checkbox .checkbox-container {
    display: block;
    position: relative;
    height: 1.7857142857rem;
    padding-left: 2.543em;
    margin: 0 1.429em 0.714em 0;
    cursor: pointer;
    font-size: 0.9285714285714286em;
    line-height: 1.7857142857142858em;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .form-checkbox .checkbox-container:hover .checkmark {
      background-color: var(--gray-color-2); }
      .form-checkbox .checkbox-container:hover .checkmark.disabled {
        background-color: var(--gray-color-2); }
  .form-checkbox .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .form-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 1.7857142857rem;
    height: 1.7857142857rem;
    background-color: #fff;
    border-radius: 0.1428571429rem;
    border: 0.071em solid var(--gray-color-3);
    /* Style the checkmark/indicator */ }
    .form-checkbox .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      left: 0.6428571428rem;
      top: 0.2857142857rem;
      font-size: 1.2142857142rem;
      width: 0.357em;
      height: 0.714em;
      border: solid var(--primary-color);
      border-width: 0 0.143em 0.143em 0;
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }
    .form-checkbox .checkmark.disabled {
      background-color: var(--gray-color-2);
      border: 0.071em solid var(--gray-color-3); }
      .form-checkbox .checkmark.disabled:after {
        border: solid var(--gray-color-4);
        border-width: 0 0.143em 0.143em 0; }
  .form-checkbox .checkbox-container:hover input ~ .checkmark {
    background-color: #fff; }
  .form-checkbox .checkbox-container input:checked ~ .checkmark:after {
    display: block; }

.form-select .select__control,
.select-custom .select__control {
  min-width: 5.714285714285714em;
  width: 100%; }
  .form-select .select__control:hover,
  .select-custom .select__control:hover {
    border-color: var(--primary-color); }
  .form-select .select__control.select__control--menu-is-open,
  .select-custom .select__control.select__control--menu-is-open {
    border-color: var(--primary-color) !important; }
  .form-select .select__control.select__control--is-focused,
  .select-custom .select__control.select__control--is-focused {
    border-color: var(--primary-color) !important; }

.form-select .select__menu .select__menu-list .select__option--is-selected,
.select-custom .select__menu .select__menu-list .select__option--is-selected {
  background-color: var(--primary-color) !important; }
  .form-select .select__menu .select__menu-list .select__option--is-selected:hover,
  .select-custom .select__menu .select__menu-list .select__option--is-selected:hover {
    background-color: var(--primary-color-darken) !important;
    color: var(--white) !important; }

.form-select .select__menu .select__menu-list .select__option--is-focused,
.select-custom .select__menu .select__menu-list .select__option--is-focused {
  background-color: #f2f2f2; }
  .form-select .select__menu .select__menu-list .select__option--is-focused:hover,
  .select-custom .select__menu .select__menu-list .select__option--is-focused:hover {
    color: var(--primary-color); }

.phone-input .intl-tel-input .country-list {
  white-space: normal;
  width: inherit; }

.payment-email {
  display: flex;
  margin-left: 5.5em;
  padding-left: 0; }

.payment-title {
  color: var(--primary-color); }

.select-custom {
  margin-bottom: 1em; }

.change-payment-detail {
  margin-top: 0.7142857142857143em; }
  .change-payment-detail .section-down {
    padding: 0 1rem; }
  .change-payment-detail .general-info {
    padding: 1em 0;
    border-bottom: 0.07143em solid var(--gray-color-2); }
  .change-payment-detail .collapsible a {
    color: var(--primary-color) !important; }
    .change-payment-detail .collapsible a:before {
      font-family: 'icomoon' !important;
      font-size: 2rem;
      content: '\e91e';
      vertical-align: middle; }
  .change-payment-detail .list-action {
    text-align: right;
    padding: 0; }
    .change-payment-detail .list-action li {
      display: inline-block;
      margin: 0 0 0 1rem; }
      .change-payment-detail .list-action li a {
        color: var(--primary-color) !important;
        cursor: pointer; }
        .change-payment-detail .list-action li a:before {
          font-family: 'icomoon' !important;
          font-size: 1.8rem;
          content: '\e91e';
          vertical-align: middle; }
  .change-payment-detail .list-btn-action {
    text-align: right;
    padding: 0; }
    .change-payment-detail .list-btn-action li {
      display: inline-block;
      margin: 0 0 1rem 1rem; }
    .change-payment-detail .list-btn-action button {
      font-weight: bold;
      text-transform: uppercase; }
  .change-payment-detail .large-size {
    font-size: 1.1rem; }
  .change-payment-detail .color-red {
    color: red; }
  .change-payment-detail .change-payment-detail {
    margin-top: 0.7142857142857143em; }
    .change-payment-detail .change-payment-detail .list-btn-action {
      text-align: right;
      padding: 0; }
      .change-payment-detail .change-payment-detail .list-btn-action li {
        display: inline-block;
        margin: 0 0 1em 1em; }
        .change-payment-detail .change-payment-detail .list-btn-action li .btn {
          width: 7.142857142857143em; }
      .change-payment-detail .change-payment-detail .list-btn-action button {
        text-transform: uppercase; }
    .change-payment-detail .change-payment-detail .payment-method .section-content {
      margin-bottom: 1em; }
      .change-payment-detail .change-payment-detail .payment-method .section-content .direct-debit-form .select-bank {
        margin-bottom: 1em; }
      .change-payment-detail .change-payment-detail .payment-method .section-content .direct-debit-form .card-holder {
        border: 0.07143em solid var(--gray-color-3);
        padding: 1.0714285714285714em;
        margin-bottom: 0.7142857142857143em; }
        .change-payment-detail .change-payment-detail .payment-method .section-content .direct-debit-form .card-holder .expiry-date {
          width: 5.714285714285714em; }
      .change-payment-detail .change-payment-detail .payment-method .section-content .direct-debit-form .national-id-details .national-id-details-content label {
        margin-left: 5%; }
      .change-payment-detail .change-payment-detail .payment-method .section-content .error-holder .error {
        flex: 0 0 100%;
        color: var(--danger-color);
        font-size: 0.8571428571em; }
      .change-payment-detail .change-payment-detail .payment-method .section-content .error-holder input,
      .change-payment-detail .change-payment-detail .payment-method .section-content .error-holder textarea,
      .change-payment-detail .change-payment-detail .payment-method .section-content .error-holder select,
      .change-payment-detail .change-payment-detail .payment-method .section-content .error-holder .icon-checked-ico:before,
      .change-payment-detail .change-payment-detail .payment-method .section-content .error-holder .select__control {
        border-color: var(--danger-color) !important; }
    .change-payment-detail .change-payment-detail .section-title {
      color: var(--primary-color); }
    .change-payment-detail .change-payment-detail .text-title {
      color: var(--primary-color);
      font-weight: bold; }
  .change-payment-detail .payment-method .section-content {
    margin-bottom: 1rem; }
    .change-payment-detail .payment-method .section-content .direct-debit-form .select-bank {
      margin-bottom: 1rem; }
    .change-payment-detail .payment-method .section-content .direct-debit-form .card-holder {
      border: 0.07143em solid var(--gray-color-3);
      padding: 1.0714285714285714em;
      margin-bottom: 0.7142857142857143em; }
      .change-payment-detail .payment-method .section-content .direct-debit-form .card-holder .expiry-date {
        width: 5.714285714285714em; }
    .change-payment-detail .payment-method .section-content .direct-debit-form .national-id-details .national-id-details-content label {
      margin-left: 5%; }
    .change-payment-detail .payment-method .section-content .error-holder .error {
      flex: 0 0 100%;
      color: var(--danger-color);
      font-size: 0.8571428571rem; }
    .change-payment-detail .payment-method .section-content .error-holder input,
    .change-payment-detail .payment-method .section-content .error-holder textarea,
    .change-payment-detail .payment-method .section-content .error-holder select,
    .change-payment-detail .payment-method .section-content .error-holder .icon-checked-ico:before,
    .change-payment-detail .payment-method .section-content .error-holder .select__control {
      border-color: var(--danger-color) !important; }
  .change-payment-detail .section-title {
    color: var(--primary-color); }
  .change-payment-detail .text-title {
    color: var(--primary-color);
    font-weight: bold; }

.form-date-picker .react-datepicker-wrapper {
  width: 100%; }

.form-date-picker .icon--calendar {
  position: absolute;
  border: none;
  background: transparent;
  right: 0;
  top: 0.2em;
  font-size: 20px;
  z-index: 3; }

.freeze-membership .list-btn-action {
  text-align: right;
  padding: 0; }
  .freeze-membership .list-btn-action li {
    display: inline-block;
    margin: 0 0 1rem 1rem; }
  .freeze-membership .list-btn-action button {
    text-transform: uppercase;
    font-weight: bold; }

.freeze-membership .section-title {
  color: var(--primary-color); }

.freeze-membership .text-title {
  color: var(--primary-color);
  font-weight: bold;
  text-transform: uppercase; }

.freeze-membership .section-down {
  padding-right: 0;
  margin-right: -1.0714285714285714em; }

.freeze-membership .freeze-payment-summary-table thead {
  background-color: #f2f2f2 !important; }
  .freeze-membership .freeze-payment-summary-table thead tr th {
    color: var(--base-color); }

.freeze-membership .freeze-payment-summary-table .no-data {
  justify-content: center;
  padding: 0.7142857142857143em 0; }

.remove-freeze {
  margin-top: 1em; }
  .remove-freeze p {
    font-size: 0.857143em; }
  .remove-freeze .text-title {
    font-size: 1em;
    margin-top: 1em; }
  .remove-freeze .section-content {
    margin-left: 5%; }
    .remove-freeze .section-content .freeze-details-container {
      margin-left: 5%; }
  .remove-freeze .list-btn-action {
    text-align: right;
    padding: 0; }
    .remove-freeze .list-btn-action li {
      display: inline-block;
      margin: 0 0 1rem 1rem; }
    .remove-freeze .list-btn-action button {
      text-transform: uppercase;
      font-weight: bold; }

.payment-email {
  background: #f2f2f2;
  margin-left: -1.1428571428571428em;
  display: flex;
  flex-direction: column; }
  .payment-email > label {
    padding: 0.7142857142857143em 0 0 4.071428571428571em; }

.make-payment {
  margin-top: 0.7142857142857143em; }
  .make-payment .fees-table {
    border-collapse: collapse; }
    .make-payment .fees-table tbody tr {
      border: 1px solid #f2f2f2;
      border-top: none;
      border-bottom: none; }
    .make-payment .fees-table tbody tr td,
    .make-payment .fees-table tfoot tr td {
      padding: .75rem .25rem !important;
      min-width: min-content; }
    .make-payment .fees-table tr {
      padding-left: 1em;
      padding-right: 1em; }
    .make-payment .fees-table thead {
      background-color: #f2f2f2 !important; }
      .make-payment .fees-table thead tr th {
        padding: .75rem .25rem !important;
        color: var(--base-color); }
    .make-payment .fees-table tfoot {
      display: block;
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      background-color: #fff;
      border: 1px solid #f2f2f2;
      border-top: none;
      border-right: none; }
      .make-payment .fees-table tfoot tr {
        cursor: default; }
        .make-payment .fees-table tfoot tr:hover td {
          background-color: transparent !important; }
        .make-payment .fees-table tfoot tr td {
          display: table-cell;
          border: none;
          padding: 0.75rem .25em !important; }
      .make-payment .fees-table tfoot .text-total,
      .make-payment .fees-table tfoot .text-total-in-arrears {
        text-transform: uppercase; }
      .make-payment .fees-table tfoot .total-payment-amount {
        color: var(--primary-color);
        text-align: right;
        font-size: 1.2em;
        font-weight: bold; }
      .make-payment .fees-table tfoot tr.payment-amount {
        border-top: 1px solid #f2f2f2; }
    .make-payment .fees-table .no-data {
      justify-content: center;
      padding: 0.7142857142857143em 0; }
  .make-payment .list-btn-action {
    text-align: right;
    padding: 0; }
    .make-payment .list-btn-action li {
      display: inline-block;
      margin: 0 0 1rem 1rem; }
    .make-payment .list-btn-action button {
      text-transform: uppercase;
      font-weight: bold; }
  .make-payment .payment-details {
    padding: 1.5em 4em;
    background-color: #f2f2f2;
    width: 100%; }

.unfreeze-membership .collapse-saas {
  border: none;
  box-shadow: none !important; }
  .unfreeze-membership .collapse-saas .content.open {
    overflow: visible; }
  .unfreeze-membership .collapse-saas .content {
    transition: max-height 0.4s ease-in-out; }

.unfreeze-membership .section-title-bar button.collapsible {
  background-color: transparent;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1em;
  border-bottom: 1px solid #f2f2f2; }

.unfreeze-membership .text-title {
  font-size: 1em;
  margin-top: 1em;
  text-transform: uppercase; }

.unfreeze-membership .unfreeze-summary-info {
  width: 80%; }

.unfreeze-membership .unfreeze-payment-summary-table thead {
  background-color: #f2f2f2 !important; }
  .unfreeze-membership .unfreeze-payment-summary-table thead tr th {
    color: var(--base-color); }

.unfreeze-membership .unfreeze-payment-summary-table .no-data {
  justify-content: center;
  padding: 0.7142857142857143em 0; }

.unfreeze-membership .list-btn-action {
  text-align: right;
  padding: 0; }
  .unfreeze-membership .list-btn-action li {
    display: inline-block;
    margin: 0 0 1rem 1rem; }
  .unfreeze-membership .list-btn-action button {
    text-transform: uppercase;
    font-weight: bold; }

.cancel-membership .section-title {
  margin-top: 1rem;
  text-transform: uppercase; }

.cancel-membership .cancel-membership-form {
  margin-top: 1rem;
  width: 80%; }
  .cancel-membership .cancel-membership-form .form-checkbox {
    margin-bottom: 0; }

.cancel-membership .cancel-summary-section {
  width: 50%; }

.cancel-membership .text-title {
  font-size: 1em;
  margin-top: 1em;
  text-transform: uppercase; }

.cancel-membership .important-note h4 {
  color: var(--primary-color);
  display: flex; }
  .cancel-membership .important-note h4 ul {
    margin: 0;
    padding-left: 0.7142857142857143em;
    line-height: 1.5;
    color: var(--base-color);
    font-weight: normal; }

.cancel-membership .list-btn-action {
  text-align: right;
  padding: 0; }
  .cancel-membership .list-btn-action li {
    display: inline-block;
    margin: 0 0 1rem 1rem; }
  .cancel-membership .list-btn-action button {
    text-transform: uppercase;
    font-weight: bold; }

.cancel-membership .cancel-code {
  margin-top: 1rem; }
  .cancel-membership .cancel-code__title {
    margin-top: .5rem; }
    @media (max-width: 575px) {
      .cancel-membership .cancel-code__title {
        margin-bottom: 0.5rem; } }

.member-ship-detail .section-summary {
  margin-bottom: 2em; }
  .member-ship-detail .section-summary .summary-header {
    margin-bottom: 0.7142857142857143em; }
    .member-ship-detail .section-summary .summary-header p {
      color: var(--base-color);
      text-transform: uppercase; }
    .member-ship-detail .section-summary .summary-header .list-action .list-action__items span {
      margin-right: 0; }

.member-ship-detail hr {
  margin-bottom: 0.7142857142857143em; }

.member-ship-detail .list-action {
  text-align: right;
  padding: 0; }
  .member-ship-detail .list-action li {
    display: inline-block;
    margin: 0 0 0 1.5rem; }
    .member-ship-detail .list-action li a,
    .member-ship-detail .list-action li span {
      color: var(--primary-color);
      cursor: pointer;
      font-size: 0.85714285714rem; }
      .member-ship-detail .list-action li a:hover,
      .member-ship-detail .list-action li span:hover {
        color: var(--primary-color-darken); }
      .member-ship-detail .list-action li a:before,
      .member-ship-detail .list-action li span:before {
        font-family: "icomoon";
        font-size: 1.8rem;
        content: "\e91e";
        vertical-align: middle; }

.member-ship-detail .content-wrapper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0.8571428571428571em;
  padding-bottom: 1em; }
  .member-ship-detail .content-wrapper.no-border {
    border: none;
    padding-bottom: 0; }
  .member-ship-detail .content-wrapper .content-header {
    display: flex;
    justify-content: space-between; }
    .member-ship-detail .content-wrapper .content-header .head-title {
      font-size: 1.1428571428571428em;
      text-transform: uppercase; }
    .member-ship-detail .content-wrapper .content-header .list-action {
      margin-bottom: -1em; }

.member-ship-detail .promotion-addon-wrapper {
  margin: 0; }
  .member-ship-detail .promotion-addon-wrapper .promotion-addon {
    font-style: italic;
    background-color: var(--gray-color-2);
    border: 0.07143em solid var(--gray-color-2);
    border-radius: 0.21428571428571427em;
    padding: 0.5em; }

.member-ship-detail .package-period-wrapper .package-detail {
  display: flex;
  justify-content: space-between;
  font-size: 1rem; }
  .member-ship-detail .package-period-wrapper .package-detail .data-label {
    font-weight: 700; }

.member-ship-detail .list-btn-action {
  display: flex;
  justify-content: flex-end; }
  .member-ship-detail .list-btn-action button {
    margin-left: 10px;
    width: 150px;
    font-weight: bold;
    text-transform: uppercase; }

@media screen and (max-width: 1366px) {
  .member-ship-detail .content-wrapper .content-header .head-title {
    font-size: 0.8571428571428571em; }
  .member-ship-detail .content-wrapper.no-border {
    margin-bottom: 0; }
  .member-ship-detail .promotion-addon-wrapper .promotion-addon {
    font-size: 0.8571428571428571em; }
  .member-ship-detail .package-period-wrapper .package-detail {
    font-size: 0.7142857142857143em; }
  .member-ship-detail .list-action {
    line-height: 1.2; }
    .member-ship-detail .list-action li span {
      font-size: 0.7142857142857143em; }
      .member-ship-detail .list-action li span:before {
        font-size: 1.4285714285714286em; } }

.remove-change-membership .collapse-saas {
  box-shadow: none;
  margin-bottom: 0.35714285714285715em; }
  .remove-change-membership .collapse-saas .collapsible {
    padding: 0.7142857142857143em 1.4285714285714286em; }
  .remove-change-membership .collapse-saas .content .info-content {
    padding: 0.7142857142857143em 1.4285714285714286em 0 1.4285714285714286em; }

.remove-change-membership .collapsible {
  background: white;
  text-transform: uppercase;
  border-bottom: 1px solid var(--gray-color-2);
  font-weight: 700; }

.remove-change-membership .remove-change-mbs-table {
  margin-top: 0.7142857142857143em; }
  .remove-change-membership .remove-change-mbs-table .amount-number {
    text-align: right; }

.remove-change-membership .future-info {
  line-height: 2;
  font-size: 15px; }

.remove-change-membership .list-btn-action {
  display: flex;
  justify-content: flex-end; }
  .remove-change-membership .list-btn-action li button {
    width: 150px;
    margin-left: 10px;
    text-transform: uppercase;
    font-weight: 700; }

.page-container .main-content .add-edit-fee .member .member-main.member-main--custom > .content {
  padding: 0.3rem 1.3rem; }
  .page-container .main-content .add-edit-fee .member .member-main.member-main--custom > .content .collapse-promotion {
    border-bottom: none; }
    .page-container .main-content .add-edit-fee .member .member-main.member-main--custom > .content .collapse-promotion.collapse-saas .collapsible {
      border-bottom: 1px solid var(--gray-color-2);
      padding-bottom: .7142857143rem;
      cursor: default; }
      .page-container .main-content .add-edit-fee .member .member-main.member-main--custom > .content .collapse-promotion.collapse-saas .collapsible::after {
        content: ''; }
    .page-container .main-content .add-edit-fee .member .member-main.member-main--custom > .content .collapse-promotion.collapse-saas .content.open {
      max-height: 100%; }
    .page-container .main-content .add-edit-fee .member .member-main.member-main--custom > .content .collapse-promotion.collapse-saas .plan-availability-club {
      display: inline-block; }
      .page-container .main-content .add-edit-fee .member .member-main.member-main--custom > .content .collapse-promotion.collapse-saas .plan-availability-club .btn-add-new {
        float: right; }
        .page-container .main-content .add-edit-fee .member .member-main.member-main--custom > .content .collapse-promotion.collapse-saas .plan-availability-club .btn-add-new:hover {
          background-color: transparent;
          color: var(--primary-color) !important;
          border-color: var(--primary-color); }

.page-container .main-content .add-edit-selling-prices .member .member-main.member-main--custom > .content {
  padding: 0.3rem 1.3rem; }
  .page-container .main-content .add-edit-selling-prices .member .member-main.member-main--custom > .content .collapse-promotion {
    border-bottom: none; }
    .page-container .main-content .add-edit-selling-prices .member .member-main.member-main--custom > .content .collapse-promotion.collapse-saas .collapsible {
      cursor: default; }
      .page-container .main-content .add-edit-selling-prices .member .member-main.member-main--custom > .content .collapse-promotion.collapse-saas .collapsible::after {
        content: ''; }
    .page-container .main-content .add-edit-selling-prices .member .member-main.member-main--custom > .content .collapse-promotion.collapse-saas .content {
      overflow: visible; }
      .page-container .main-content .add-edit-selling-prices .member .member-main.member-main--custom > .content .collapse-promotion.collapse-saas .content.open {
        max-height: 100%; }

.page-container .main-content .add-edit-selling-prices .member .member-main.member-main--custom .selling-price-table thead {
  background-color: #f2f2f2; }
  .page-container .main-content .add-edit-selling-prices .member .member-main.member-main--custom .selling-price-table thead th {
    color: var(--base-color); }

.page-container .main-content .add-edit-selling-prices .member .member-main.member-main--custom .selling-price-table tbody {
  margin-bottom: 0.71429rem; }
  .page-container .main-content .add-edit-selling-prices .member .member-main.member-main--custom .selling-price-table tbody .btn {
    padding: 0.28571rem 0.57143rem; }

.page-container .main-content .add-edit-selling-prices .member .member-main.member-main--custom .selling-price-table tfoot {
  display: flex;
  justify-content: flex-end; }

.page-container .main-content .add-edit-selling-prices .member .member-main.member-main--custom .selling-price-table .action {
  max-width: 7.14286rem;
  text-align: center; }

.page-container .main-content .add-edit-selling-prices .member .member-main.member-main--custom .selling-price-table .priority {
  text-align: center; }

.page-container .main-content .add-edit-selling-prices .member .member-main.member-main--custom .list-btn-action {
  display: flex;
  padding: 0;
  justify-content: flex-end; }

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .add-edit-selling-prices .selling-price-1,
  .add-edit-selling-prices .selling-price-2,
  .add-edit-selling-prices .selling-price-3 {
    width: 100%; }
  .add-edit-selling-prices .selling-price-3 {
    align-items: baseline; } }

.btn-one {
  min-width: 8.14286rem;
  text-transform: uppercase;
  font-weight: 700;
  margin: 5px; }

.two-button,
.three-button {
  text-align: right; }

.four-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end; }

@media screen and (max-width: 768px) {
  .btn-one {
    flex: 1; }
  .three-button {
    display: flex;
    flex-wrap: wrap;
    text-align: right; }
  .four-button .two-button {
    display: flex;
    flex-wrap: wrap;
    flex: 1; } }

@media screen and (max-width: 475px) {
  .two-button {
    display: flex;
    flex-wrap: wrap; } }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fade-in {
  -webkit-animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@-webkit-keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.fade-out {
  -webkit-animation: fade-out 0.5s ease-out both;
  animation: fade-out 0.5s ease-out both; }

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px; }

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #aaa;
  animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite; }

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite; }

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(19px, 0); } }

.loading-dot-holder {
  text-align: center;
  width: 100%; }

.force-hidden {
  display: none !important; }

.table-responsive {
  overflow: visible; }
