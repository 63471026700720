.make-payment {
  margin-top: 0.7142857142857143em;

  .fees-table {
    border-collapse: collapse;

    tbody tr {
      border: 1px solid #f2f2f2;
      border-top: none;
      border-bottom: none;
    }

    tbody tr,
    tfoot tr {
      td {
        padding: .75rem .25rem !important;
        min-width: min-content;
      }
    }

    tr {
      padding-left: 1em;
      padding-right: 1em;
    }
    thead {
      background-color: #f2f2f2 !important;

      tr {
        th {
          padding: .75rem .25rem !important;
          color: $base-color;
        }
      }
    }

    tfoot {
      display: block;
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      background-color: #fff;
      border: 1px solid #f2f2f2;
      border-top: none;
      border-right: none;

      tr {
        cursor: default;
        &:hover td {
          background-color: transparent !important;
        }
        td {
          display: table-cell;
          border: none;
          padding: 0.75rem .25em !important;
        }
      }

      .text-total,
      .text-total-in-arrears {
        text-transform: uppercase;
      }

      .total-payment-amount {
        color: var(--primary-color);
        text-align: right;
        font-size: 1.2em;
        font-weight: bold;
      }

      tr.payment-amount {
        border-top: 1px solid #f2f2f2;
      }
    }

    .no-data {
      justify-content: center;
      padding: 0.7142857142857143em 0;
    }
  }

  .list-btn-action {
    text-align: right;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 0 1rem 1rem;
    }
    button {
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .payment-details {
    padding: 1.5em 4em;
    background-color: #f2f2f2;
    width: 100%;
  }
}
