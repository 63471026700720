.page-container .main-content {
    .add-edit-tender-class, .add-edit-tender-type {
        .member {
            .member-main.member-main--custom {
                >.content {
                    padding: 0.3rem 1.3rem;
                    .collapse-saas .content {
                        overflow: visible;
                    }
                    .collapse-saas .collapsible:after {
                        display: none;
                    }
                    .item-count {
                        font-weight: 400;
                        margin-left: 5px;
                        font-size: 0.8em;
                    }
                    > .row {
                        flex: auto;
                    }
                    .group-btn {
                        padding-left: 0;
                        padding-right: 0;
                        width: 100%;
                    }

                    .required {
                        color: var(--danger-color);
                    }

                    .error-holder {
                        .error {
                            flex: 0 0 100%;
                            color: var(--danger-color);
                            font-size: 0.8571428571rem;
                        }

                        input,
                        textarea,
                        select,
                        .icon-checked-ico:before,
                        .select__control {
                            border-color: var(--danger-color) !important;
                        }
                    }
                    @media (max-width: 1024px) and (min-width: 991px) {
                        .overflow-text {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            max-width: calc(100% - 3em);
                            overflow: hidden;
                            display: inline-block;
                            vertical-align: text-top;
                        }
                    } 
                    
                    @media (max-width: 575px) {
                        .group-btn {
                            flex-direction: unset;
                            justify-content: space-between;
                        }
                        .item-count {
                            display: block;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}