.page-container {
	.main-content {
		.add-edit-selling-prices {
			.member {
				.member-main {
					&.member-main--custom {
						&>.content {
							padding: 0.3rem 1.3rem;

							.collapse-promotion {
								border-bottom: none;

								&.collapse-saas {
									.collapsible {
										cursor: default;

										&::after {
											content: '';
										}
									}

									.content {
										overflow: visible;

										&.open {
											max-height: 100%;
										}
									}
								}
							}
						}

						.selling-price-table {
							thead {
								background-color: #f2f2f2;

								th {
									color: $base-color;
								}
							}

							tbody {
								margin-bottom: rem(10px);

								.btn {
									padding: 0.28571rem 0.57143rem;
								}
							}

							tfoot {
								display: flex;
								justify-content: flex-end;
							}

							.action {
								max-width: rem(100px);
								text-align: center;
							}

							.priority {
								text-align: center;
							}
						}

						.list-btn-action {
							display: flex;
							padding: 0;
							justify-content: flex-end;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
	.add-edit-selling-prices {

		.selling-price-1,
		.selling-price-2,
		.selling-price-3 {
			width: 100%;
		}

		.selling-price-3 {
			align-items: baseline;
		}
	}
}