.form-date-picker {
    .react-datepicker-wrapper {
        width: 100%;
    }

    .icon--calendar {
        position: absolute;
        border: none;
        background: transparent;
        right: 0;
        top: 0.2em;
        font-size: 20px;
        z-index: 3;
    }
}