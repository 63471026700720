.change-payment-detail {
	margin-top: 0.7142857142857143em;

	.section-down {
		padding: 0 1rem;
	}

	.general-info {
		padding: 1em 0;
		border-bottom: 0.07142857142857142em solid $gray-color-2;

		.general-content {
			// width: calc(100% - 295px);
		}
	}

	.collapsible {
		a {
			color: $primary-color !important;

			&:before {
				font-family: 'icomoon' !important;
				font-size: 2rem;
				content: '\e91e';
				vertical-align: middle;
			}
		}
	}

	.list-action {
		text-align: right;
		padding: 0;

		li {
			display: inline-block;
			margin: 0 0 0 1rem;

			a {
				color: $primary-color !important;
				cursor: pointer;

				&:before {
					font-family: 'icomoon' !important;
					font-size: 1.8rem;
					content: '\e91e';
					vertical-align: middle;
				}
			}
		}
	}

	.list-btn-action {
		text-align: right;
		padding: 0;

		li {
			display: inline-block;
			margin: 0 0 1rem 1rem;
		}

		button {
			font-weight: bold;
			text-transform: uppercase;
		}
	}

	.large-size {
		font-size: 1.1rem;
	}

	.color-red {
		color: red;
	}

	.change-payment-detail {
		margin-top: 0.7142857142857143em;

		.list-btn-action {
			text-align: right;
			padding: 0;

			li {
				display: inline-block;
				margin: 0 0 1em 1em;

				.btn {
					width: 7.142857142857143em;
				}
			}

			button {
				text-transform: uppercase;
			}
		}

		.payment-method {
			.section-content {
				margin-bottom: 1em;

				.direct-debit-form {
					.select-bank {
						margin-bottom: 1em;
					}

					.card-holder {
						border: 0.07142857142857142em solid $gray-color-3;
						padding: 1.0714285714285714em;
						margin-bottom: 0.7142857142857143em;

						.expiry-date {
							width: 5.714285714285714em;
						}
					}
				}

				.direct-debit-form .national-id-details .national-id-details-content label {
					margin-left: 5%;
				}

				.error-holder {
					.error {
						flex: 0 0 100%;
						color: var(--danger-color);
						font-size: 0.8571428571em;
					}

					input,
					textarea,
					select,
					.icon-checked-ico:before,
					.select__control {
						border-color: var(--danger-color) !important;
					}
				}
			}
		}

		.section-title {
			color: $primary-color;
		}

		.text-title {
			color: $primary-color;
			font-weight: bold;
		}
	}

	.payment-method {
		.section-content {
			margin-bottom: 1rem;

			.direct-debit-form {
				.select-bank {
					margin-bottom: 1rem;
				}

				.card-holder {
					border: 0.07142857142857142em solid $gray-color-3;
					padding: 1.0714285714285714em;
					margin-bottom: 0.7142857142857143em;

					.expiry-date {
						width: 5.714285714285714em;
					}
				}
			}

			.direct-debit-form .national-id-details .national-id-details-content label {
				margin-left: 5%;
			}

			.error-holder {
				.error {
					flex: 0 0 100%;
					color: var(--danger-color);
					font-size: 0.8571428571rem;
				}

				input,
				textarea,
				select,
				.icon-checked-ico:before,
				.select__control {
					border-color: var(--danger-color) !important;
				}
			}
		}
	}

	.section-title {
		color: $primary-color;
	}

	.text-title {
		color: $primary-color;
		font-weight: bold;
	}
}