.view-personal-detail {
  padding-top: 0.71428571428em;
  p {
    font-size: 0.8571428571428571em;
  }
  .list-content {
    padding-left: 0;

    dl {
      line-height: 1.45;
      font-size: 0.857em;
      dd {
        text-align: left;
      }
    }
  }
  .view-personal-detail_header,
  .view-personal-detail-health {
    display: flex;
    justify-content: space-between;
    .text-title {
      font-size: 1.143em;
      text-transform: uppercase;
      line-height: 2.14285714286rem;
      white-space: nowrap;
    }
    .list-action {
      margin-bottom: 0;
      li {
        margin-left: 1.78571428571rem;
      }
      a {
        color: $primary-color;
        position: relative;
        font-size: 0.786em;
        margin-right: 1.42857142857em;
        &:last-child {
          margin-right: 0;
        }
        &:before {
          content: '\e91e';
          position: absolute;
          font-family: 'icomoon' !important;
          border: none;
          font-size: 1.4285714285714286em;
          left: -1em;
          top: -0.14285714285714285em;
        }
      }
    }
  }
  .view-personal-detail-health {
    padding-top: 0.85714285714em;
    border-top: 1px solid $gray-color-2;
    dl {
      display: flex;
      margin-bottom: 0;
      dd {
        text-align: left;
      }
    }
    .row-btn {
      margin-top: 0.71428571428em;
      .list-btn-action {
        display: flex;
        justify-content: flex-end;
        .btn {
          margin-right: 0.71428571428em;
          font-size: 0.857em;
          text-transform: uppercase;
          &.btn-dark {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 1600px) {
  .view-personal-detail {
    p {
      font-size: 1rem;
    }

    .view-personal-detail_header,
    .view-personal-detail-health {
      .list-action {
        a {
          color: $primary-color;
          position: relative;
          font-size: 0.786em;
          margin-right: 1.42857142857rem;
          &:last-child {
            margin-right: 0;
          }
          &:before {
            content: '\e91e';
            position: absolute;
            font-family: 'icomoon' !important;
            border: none;
            font-size: 1.78571rem;
            left: -1.42857142857rem;
            top: -0.42857142857142855em;
          }
        }
      }
    }
  }
}
