.page-container {
	.main-content {
		.add-edit-fee {
			.member {
				.member-main {
					&.member-main--custom {
						&>.content {
							padding: 0.3rem 1.3rem;

							.collapse-promotion {
								border-bottom: none;

								&.collapse-saas {
									.collapsible {
										border-bottom: 1px solid var(--gray-color-2);
										padding-bottom: .7142857143rem;
										cursor: default;

										&::after {
											content: '';
										}
									}

									.content {
										&.open {
											max-height: 100%;
										}
									}

									.plan-availability-club {
										display: inline-block;

										.btn-add-new {
											float: right;

											&:hover {
												background-color: transparent;
												color: var(--primary-color) !important;
												border-color: var(--primary-color);
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}