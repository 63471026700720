.payment-email {
	display: flex;
	margin-left: 5.5em;
	padding-left: 0;
}

.payment-title {
	color: $primary-color;
}

.select-custom {
	margin-bottom: 1em;
}