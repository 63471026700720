.up-logo {
	&.system-config {
		display: flex;
		align-items: center;
		min-height: 26rem;
	}

	.box-img {
		margin-bottom: 45px !important;

		.change-img {
			bottom: -45px !important;
			z-index: 1;
		}
	}

	#img-store {
		display: none;
	}

	.holder {
		z-index: 1;
		position: absolute;

		&.invisible-holder {
			opacity: 0;
		}

		canvas {
			background-image: none !important;
		}
	}

	.uou-rangeslider {
		width: calc(100% - 90px);
		vertical-align: middle;
		margin: 0 auto;

		.rangeslider {
			box-shadow: none;
			background: rgba(0, 0, 0, 0.25);
		}

		.rangeslider-horizontal {
			height: 5px;

			.rangeslider__fill {
				background-color: $white;
				box-shadow: none;
			}

			.rangeslider__handle {
				width: 15px;
				height: 15px;
				box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
				border: none;

				&:after {
					display: none;
				}
			}
		}
	}

	.btn-zoom-in {
		float: left;
	}
}

@media screen and (max-width: 1366px) {
	.note-info {
		font-size: 0.8571428571428571em;
	}
}

.fs-i {
	font-style: italic;
}


.membersummary-page {
	.membersummary-list {
		.member-contact-detail {
			.row.promotion-addon-wrapper {
				margin-right: 0;
				margin-left: 0;
			}
		}
	}
}


.table-wrapper {
	margin-left: 0 !important;
	margin-right: 0 !important;

	.table-list {
		.table-filter {
			.left-filter {
				.filter-input-label {
					font-size: 0.85714rem;
					margin-right: 0.5em;
					margin-left: 0.5em;
				}

				.custom-select-filter,
				.custom-input-filter {
					width: 11em;
					margin-right: 1em;
				}
			}
		}

		.table-responsive {
			max-height: 300px;

			table {
				th {
					white-space: nowrap;

					>div {
						display: inline-block;
						margin-left: 0.5em;
					}

					&:last-child {
						right: unset;
					}
				}
			}
		}
	}
}

button[disabled] {
	cursor: default;
}

.swal2-modal {
	border-radius: 3px !important;

	.swal2-icon.swal2-error {
		border-color: $danger-color;

		[class^="swal2-x-mark-line"] {
			background-color: $danger-color;
		}
	}

	.swal2-icon.swal2-warning {
		border-color: $warning-color;
		color: $warning-color;
	}

	.swal2-icon.swal2-success {
		[class^="swal2-success-line"] {
			background-color: $green-color;
		}

		.swal2-success-ring {
			border-color: $green-color;
		}
	}

	.swal2-actions {
		button {
			min-width: 140px;
			margin: 0 10px;
		}
	}
}

.saveColor {
	.btn-secondary {
		margin-right: 2rem;
	}
}

.btn.btn-secondary {
	background-color: $primary-color-darken;
	border-color: $primary-color-darken;
}

.btn.btn-secondary:hover {
	background-color: white;
	border-color: $primary-color-darken !important;
	color: $primary-color-darken !important;
}

.header__logo img {
	height: 2.5rem;
	cursor: pointer;
}

.container-modal {
	.backdrop {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
	}

	z-index: 10000;
}

.sidebar-logo {
	.logo-mb {
		img {
			max-height: 48px;
		}
	}
}

.pagination {
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 0.25rem;

	.page-item {
		cursor: pointer;
		color: $base-color;
		background-color: transparent;
		border: 0.5px solid;
		border-color: $gray-color-3;
		border-radius: rem(3px);
		width: auto;
		height: 24px;

		.page-link {
			line-height: 1.5 !important;
			border: none;
			margin-left: 0;
			height: 23px !important;

			.icon {
				line-height: 1.3;
			}
		}

		&.disabled {
			background-color: $gray-color-2;
			opacity: 0.5;
		}

		&.active {
			background-color: $primary-color;
			border-color: $primary-color;

			#img-store {
				display: none;
			}
		}

		&:not(.disabled):not(.active) {
			&:hover {
				background: $gray-color-3;
				transition: 0.5s;
			}
		}
	}
}

div.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

.hidden {
	display: none;
}

.header__languages,
.header__account {
	cursor: pointer;

	.text-hidden-mb {
		white-space: nowrap;
	}

	.dropdown-content {
		li {
			a {
				white-space: nowrap;
			}
		}
	}
}

.modal-new-pass {
	.code-pass {
		cursor: pointer;
	}
}

.swal2-modal {
	.swal2-actions {
		button {
			text-transform: uppercase;
			font-weight: bold;
			min-width: 160px;
			width: auto;
		}
	}
}

.error-holder {
	.error {
		flex: 0 0 100%;
		color: var(--danger-color);
		font-size: 0.8571428571rem;
	}

	input,
	textarea,
	select,
	.icon-checked-ico:before,
	.select__control {
		border-color: var(--danger-color) !important;
	}
}

.page-container {
	header {
		z-index: 4;
	}

	.plan-edit {
		.member {
			.member-main.member-main--custom {
				>.content {
					.collapse-promotion.collapse-saas {
						width: 100%;
					}

					.content.open {
						overflow: visible;
					}

					.form-control:not(.datetime-control) {
						padding: 0.4285714286rem 1.4rem 0.4285714286rem 0.75rem;
					}

					.uppercase {
						text-transform: uppercase;
					}

					.react-datepicker-popper {
						z-index: 10;
					}

					.required {
						color: var(--danger-color);
					}

					.icon--calendar {
						top: 0.2em;
					}

					.line-date {
						vertical-align: text-top;
						display: table;
						padding-top: 5em;
					}

					.periods {
						.input-group {
							width: 5rem;
						}
					}

					padding: 0.3rem 1.3rem;

					.select-fluid {
						width: 100%;
					}

					.no-resize {
						resize: none;
					}

					.react-datepicker-wrapper {
						width: 100%;
						margin-right: 0;

						.react-datepicker__close-icon {
							display: none;
						}
					}

					.title-medium {
						font-weight: 500;
					}

					.selling-price {

						.priority,
						.is-club,
						.from-to,
						.inline-block {
							display: inline-block;
							width: unset;
						}

						.priority .select-custom,
						.is-club .select-custom,
						.from-to .input-group {
							margin-top: 0.7142857143rem;
						}

						.mrc-5 {
							margin-right: 5px;
						}

						.price-is {
							display: inline-block;

							.input-group {
								width: unset;

								.form-control {
									width: 10rem;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1680px) {
	.plan-edit {
		.payments .selling-price .span-3 {
			display: block;
			width: 100%;
		}
	}
}

@media (min-width: 1024px) and (max-width: 1680px) {
	.plan-edit {
		$price-width-at-1366: 7em !important;
		$other-input-width-at-1366: 12em !important;

		.payments .selling-price {
			.span-null {
				display: inline-block;
				width: 13.95em;
			}

			.price-is {
				.input-group input {
					width: $price-width-at-1366;
				}
			}

			.priority,
			.is-club {
				.select-custom {
					width: $other-input-width-at-1366;
				}
			}

			.react-datepicker-wrapper {
				width: $other-input-width-at-1366;
			}

			.span-4+.mrc-5 {
				width: 10.55em;
				display: inline-block;
				text-align: right;
			}

			.mrc-5 {
				.price-is {
					width: 3.65em !important;
					word-break: break-word;
					vertical-align: middle;
				}

				.priority {
					width: 3.65em !important;
					word-break: break-word;
					vertical-align: middle;
				}

				.is-club {
					width: 8.38em !important;
					word-break: break-word;
					vertical-align: middle;
				}

				.from-to {
					width: 2.55em !important;
					word-break: break-word;
					vertical-align: middle;
				}
			}
		}
	}
}

@media (max-width: 719px) {
	.plan-edit {
		$price-width-at-410: 15.05em !important;
		$other-input-width-at-410: 13em !important;

		.payments .selling-price {
			.mt-3 {
				clear: both;
				margin-bottom: 3em;

				.price-is {
					.input-group input {
						width: $price-width-at-410;
					}
				}

				.priority {
					.select-custom {
						width: $other-input-width-at-410;
						margin-right: 0;
					}
				}

				.react-datepicker-wrapper {
					width: $other-input-width-at-410;
				}

				.mrc-5 {
					+span {
						float: right;
					}

					>span {
						display: inline-block;
						padding-top: 0.85em;
					}

					span.price-is {
						padding-top: 0.15em;
					}
				}

				.span-1,
				.span-2,
				.span-3,
				.span-4,
				.span-5 {
					display: block;
					width: 100%;
					clear: both;
				}
			}
		}
	}
}

.swal2-container.swal2-fade {
	z-index: 10000;
}

.reset-password {
	.wraptext {
		display: inline-block;
		max-width: 300px;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

@media screen and (max-width: 768px) {
	.user-profile-picture {
		.up-logo {
			.box-img {
				width: 190px !important;
				height: 190px !important;
			}
		}

		.saveColor {
			display: flex;
			flex-direction: row-reverse;

			.btn {
				flex: 1;

				&.btn-primary {
					margin-right: 10px !important;
				}
			}
		}
	}

	.reset-password {
		.wraptext {
			max-width: 220px;
		}
	}

	.up-logo {
		&.system-config {
			min-height: 20rem;
		}

		.box-img {
			margin-bottom: 45px !important;
		}
	}

	.ReactPasswordStrength-strength-desc {
		padding: 8px 1% !important;
		right: 11% !important;
	}
}

@media screen and (min-width: 768px) {
	.user-profile-picture {
		.up-logo {
			.box-img {
				height: 17.43em !important;
				width: 20.714em !important;
			}
		}
	}

	.ReactPasswordStrength-strength-desc {
		padding: 8px 19% 8px 1% !important;
	}
}

.user-profile-picture {
	.modalSaas__dialog {
		max-width: 400px;
	}

	.holder {
		width: 100%;

		canvas {
			width: 100%;
		}
	}

	.saveColor {
		.btn-primary {
			float: left;
		}

		.btn-dark {
			margin-right: 0 !important;
		}
	}

	.description {
		text-align: center;
		margin: 10px 0;
		font-style: italic;
		font-size: 0.85714rem;

		p {
			margin: 0 -20px;
		}
	}
}

.info-acc {
	.border-tag {
		a {
			color: $primary-color !important;
		}
	}
}

.edit-acc {
	.tab-content {
		.tab-pane {
			display: block;

			.row-btn {
				margin-top: 1.4285714286rem;
				padding-bottom: 1.78571rem;
				border-top: none !important;
			}

			.list {
				padding-top: 1rem;
				padding-bottom: 1rem;
				border-top: 0.071em solid $gray-color-3;

				.title {
					padding-bottom: 0.2rem;
				}

				.item-holder {
					padding: 0;
					display: flex;
					flex-wrap: wrap;

					.item {
						background-color: $gray-color-1;
						padding: 0.143em 0.357em;
						margin-right: 0.357em;
						margin-bottom: 0.357em;
						border-radius: 1.071em;
						border: 0.071em solid $gray-color-3;
						min-width: 5%;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}
		}
	}
}

.logo-box {
	.logo-text {
		margin-left: 30px;
	}
}

.accordion {
	.dropdown-menu {
		li {
			padding-left: 2.14286rem !important;

			.dropdown-toggle {
				padding: 0;

				.icon-holder {
					padding-right: 0.71429rem;

					.icon {
						font-size: rem(18px);
						vertical-align: middle;
					}
				}
			}
		}
	}
}

.page-container {
	header {
		.header__left {
			.header__logo {
				border-right: 1px solid $gray-color-2;
				display: block;
			}
		}

		.header__right {
			.header__account {
				min-width: 10.714em;

				.box-account {
					align-items: center;

					.account-name {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						max-width: 14.285714285714286em;
					}

					.account-img {
						overflow: hidden;
						width: 3.4285714285714284em;
						height: 3.4285714285714284em;
						border-radius: 50%;

						img {
							width: 100%;
							height: 100%;
							vertical-align: baseline;
						}
					}
				}
			}
		}
	}

	.main-content {
		.edit-profile {
			.profile-content {
				.edit-avartar {
					background: white;

					img {
						background-color: white;
					}
				}
			}
		}

		.appearance {
			.title-content+.box-btn-filter {
				.select-form {
					margin-bottom: 0;
					vertical-align: text-top;
					display: inline-block;
					min-width: 12em;
					margin-left: 1em;
					margin-top: -0.25em;
				}
			}
		}
	}
}

.pickerColor {
	.popover {
		position: absolute;
		z-index: 2;
		top: unset;
		left: unset;
		margin-top: 40px;
	}

	.secondary {
		padding-left: 0;
	}

	.cover {
		position: fixed;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
	}
}

@media (max-width: 1200px) and (min-width: 320px) {
	.pickerColor {
		justify-content: center;
		display: flex !important;
	}
}

.bulk-import {
	.collapse-saas {
		.content {
			a {
				&:hover {
					color: $primary-color !important;
				}
			}
		}
	}

	.bulk-upload {
		.fileUpload {
			min-width: 140px;
			padding: 0.28571rem;
			text-transform: uppercase;
		}

		p {
			font-size: 14px !important;
		}
	}

	.list-account {
		table {
			thead {
				th {
					vertical-align: middle;
				}
			}
		}
	}
}

.component-list {
	margin-top: 0.7142857142857143em;

	.box-opt .items-opt {
		overflow-y: unset;
		max-height: 200px;
	}

	table {
		tbody {
			tr {
				.drop-box-arr {
					top: -1.3em;
					right: 2em;

					&:after {
						top: 1.1rem;
						right: -1.35em;
						transform: rotate(90deg);
					}

					&:before {
						top: 0.9rem;
						right: -1.8em;
						transform: rotate(90deg);
					}
				}
			}
		}
	}

	// list-component-upgrade-css
	.table-list {
		.table-responsive {
			max-height: none;
			overflow-y: visible;
			overflow-x: visible;
			height: unset !important;
		}

		.table {
			thead {
				th {
					vertical-align: middle;

					.box-opt {
						.items-opt {
							max-height: none;
						}
					}

					.drop-box-filter {
						max-height: calc(100vh - 50px);
						overflow-y: auto;
					}

					.th-filter {
						flex-direction: row;

						&>span {
							max-width: calc(100% - 20px);
							white-space: initial !important;
							overflow: initial !important;

							&:first-child {
								padding-right: rem(15px);
								position: relative;
							}

							&:before {
								position: absolute;
								top: 50%;
								right: rem(3px);
								transform: translateY(-50%);
							}
						}
					}
				}
			}

			th {
				padding: rem(8px);
			}

			td {
				padding: rem(8px);
			}
		}

		.th-col-opt {
			.drop-col-opt {
				max-height: none;
			}
		}

		.sort-control {
			display: flex;
			flex-direction: column;
			margin-right: 5px;

			&>span {
				line-height: 5px;

				&:first-child {
					padding-top: 5px;
					padding-bottom: 0;
				}

				&:last-child {
					padding-top: 0;
					padding-bottom: 5px;

					.icon.icon-desc-sort {
						transform: rotate(180deg) translateX(0.5px);
					}
				}
			}
		}
	}
}

.form-group {
	margin-bottom: 1.2rem;
}

.intl-tel-input {
	width: 100%;

	.selected-flag {
		outline: none;
	}
}

.input-error {
	font-style: italic;
	font-size: 0.85714rem;
	position: absolute;
}

.login {
	.container {
		.loginForm__logo {
			margin-bottom: 2rem !important;
		}

		.loginForm__desc {
			margin-bottom: 2.07143rem !important;
		}

		.loginForm {
			.btn-white {
				color: $primary-color !important;

				&:hover {
					color: white !important;
				}

				&:focus {
					color: white !important;
				}
			}

			.icon-hidden {
				position: absolute;
				right: 1.07143rem;
				top: 0.71429rem;
				z-index: 1111;
				cursor: pointer;

				&::before {
					font-size: 1.6428571429rem;
				}
			}

			.ReactPasswordStrength {
				padding: 0 !important;

				input {
					background-color: rgba(255, 255, 255, 0) !important;
				}

				.ReactPasswordStrength-strength-desc {
					right: 2.5rem;
				}
			}
		}

		.multi-box {
			li {
				cursor: pointer;
			}
		}
	}
}

/*  Add Role  */

.w-300 {
	width: 300px;
}

.label-select-switch {
	display: inline;
	padding: 5px 10px;
}

.label-select-club {
	display: inline;
	padding: 5px 20px 0 0;
}

.collapse-root {
	border-radius: 0px !important;

	.collapse-header {
		border-bottom: 1px solid #cccccc;
	}

	.collapse-detail {
		display: block !important;
	}
}

.feature-list {
	.accordion-group {
		position: relative;
	}

	dl {
		margin: 15px 0;

		dt {
			margin-bottom: 10px;
			font-weight: normal;
		}
	}

	.group-list,
	.resource-list {
		margin: 0px;
		padding: 0px;

		li {
			display: inline;

			&:last-child {
				margin-left: 5px;
				padding-left: 5px;
				border-left: 1px solid #ccc;
			}

			a {
				cursor: pointer;

				&:hover {
					color: $primary-color;
				}
			}
		}
	}

	.group-list {
		position: absolute;
		top: 16px;
		left: 170px;
		z-index: 2;
	}
}

.group-feature {
	width: 100%;
	margin: 10px 0;

	dt {
		width: 230px;
		float: left;
		font-size: 13px;
	}

	dd {
		padding: 0 0 0 250px;
	}

	.right-list {
		width: 100%;
		padding: 0px;

		li {
			display: inline-block;
			width: 33%;

			.checkbox-container {
				text-overflow: ellipsis;
				display: inline-block;
				vertical-align: middle;
				overflow-wrap: break-word;
			}
		}
	}
}

.accordion {
	.accordion-item {
		.group-panel {
			padding: 20px 50px;
			background-color: #fff;
			border-bottom: 1px solid #ccc;
		}

		.title {
			position: relative;
			border-radius: 0px !important;
			background-color: #fff !important;
			border-bottom: 1px solid #ccc !important;

			&:after {
				position: absolute;
				top: 16px;
				left: 15px;
			}

			h4 {
				padding-left: 25px;
			}
		}

		&.active {
			.panel {
				max-height: 1200px !important;
			}
		}
	}
}

.change-personal-detail-wrapper {
	.select-add-on-main {
		width: 100% !important;
	}
}

.select-add-on-main {
	.collapse-promotion {
		padding-bottom: 0.7142857143rem !important;

		.content {
			margin-top: 0 !important;

			.info-content {
				width: 100%;
				padding: 0 !important;

				.form-group {
					.input-error.long-text-error {
						display: contents !important;
					}
				}

				.align-self-end {
					@media (max-width: 767px) {
						min-height: 6rem;
					}
				}
			}
		}

		.active {
			overflow: visible !important;
		}
	}
}

.btn-tooltip {
	padding: 7px !important;
}

.infoIcon {
	color: $secondary-color;
	font-size: 20px !important;
}

.add-new-section {
	text-align: right;
	margin-bottom: 1rem;

	.box-btn-filter {
		justify-content: space-between;
	}

	.btn-add-new {
		margin-right: 0px;

		&:after {
			display: none;
		}

		&:hover {
			background-color: transparent;
			color: $primary-color !important;
			border-color: $primary-color;
		}
	}
}

.form-add-user {
	.form-group {
		display: inline-block;
		margin-bottom: 2rem;
	}

	.intl-tel-input {
		display: block;
	}
}

.profile-picture {
	text-align: center;

	.title {
		font-size: 16px;
	}
}

.sub-tenant-role {
	.form-group {
		display: block;
	}
}

.add-role-wrap {
	margin: 1rem 0;

	.form-group {
		margin: 1rem 0;
	}
}

#guideBlocksHelpBeacon {
	display: none;
}

#data-table {
	.section-filter {
		position: relative;
		top: -4px;
		height: 0;
	}

	.sort-control {
		display: flex;
		flex-direction: column;
		margin-right: 5px;

		&>span {
			line-height: 5px;

			&:first-child {
				padding-top: 5px;
				padding-bottom: 0;
			}

			&:last-child {
				padding-top: 0;
				padding-bottom: 5px;

				.icon.icon-desc-sort {
					transform: rotate(180deg) translateX(0.5px);
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.form-table {
		dt {
			width: 100%;
			float: left;
			padding-top: 5px;
		}

		dd {
			padding: 0px;
		}

		.form-group {
			display: inline-block;
		}
	}

	.sub-tenant-content {
		margin-left: 0px;
	}

	.feature-list {
		.group-list {
			top: 8px;

			li:last-child {
				display: block;
				border: none;
				padding-left: 0px;
				margin-left: 0px;
			}
		}
	}

	.group-feature {
		dd {
			padding: 0px;
		}

		.right-list {
			li {
				width: 100%;
			}
		}
	}

	.member {
		.list-action {
			text-align: left !important;

			li {
				display: block !important;
				margin: 0 !important;
			}
		}

		.list-btn-action {
			text-align: left !important;

			li {
				display: block !important;
				margin: 0 0 1rem 0 !important;

				a {
					display: block !important;
				}
			}
		}
	}
}

.list-btn-action {
	li {

		a,
		button {
			min-width: rem(114px);
		}
	}
}

.sidebar-menu {
	.accordion {
		border-radius: 0;
		border: none;
	}
}

.view-user-group {
	.btn-add-usergroup {
		border: 1px solid $gray-color-3;

		&:hover {
			background-color: $gray-color-1;
		}
	}
}

.page-container {
	.main-content {

		.title-content,
		.title-content-list {
			margin-bottom: 0.3571428571rem !important;
		}

		.btn-height-sm {
			&.no-after:after {
				display: none;
			}

			&.no-margin-right {
				margin-right: 0;
			}
		}

		.action-hint {
			font-size: 0.8571428571rem;
			margin-right: 1rem;
		}
	}
}

.add-member-page {
	.select-subtenant {
		min-height: calc(100vh - 200px);
	}

	.member-main {
		.list-page {
			display: flex;
		}

		.paging-steps .step.active.go-to-step .number-step {
			cursor: pointer;
		}

		.btn--apply {
			z-index: 0 !important;
		}

		.form-group {
			margin-bottom: 1.5rem !important;
		}

		.select-add-on-title--custom {
			font-size: 1.14286rem !important;
			font-weight: 500 !important;
		}

		.select__indicator-separator {
			background-color: transparent !important;
		}

		.icon-arrow-down-icon {
			position: absolute;
			font-size: 1.78571rem;
			right: 0;
			top: 8%;
			cursor: pointer;

			&::before {
				content: "\E901";
				color: $primary-color;
				background-color: transparent !important;
			}
		}

		.input-group {
			flex-wrap: inherit !important;

			.react-datepicker-wrapper {
				width: 100% !important;
			}

			.react-datepicker__close-icon {
				height: 16px !important;
				top: 25% !important;
				right: 7px !important;
			}
		}

		.content {
			.row-content.active {
				border: 1px solid $primary-color;
			}
		}

		.filter-sidebar {
			.filter-header {
				i.icon {
					font-size: 3em;
				}
			}
		}

		.tbody-height {
			height: auto !important;
			max-height: 21.428571428571427em !important;
		}
	}

	.box-nation {
		margin-left: -1rem;
		margin-right: -1rem;

		&.active {
			max-height: 20rem !important;
			transition: max-height 0.2s ease-out;
		}
	}

	.marketing-source {
		background-color: $gray-color-2;
	}

	.phone-require {
		margin-top: -1.45rem;
	}

	.debit-card-image {
		text-align: center;
		margin: 1rem 0;
		padding: 1rem 0;
		background-color: $gray-color-2;
		border-radius: 0.4rem;

		img {
			width: 8rem;
		}
	}

	.title-dupplicate {
		padding-bottom: 0em !important;
	}

	.icon-close-ico {
		font-size: 3em;
		cursor: pointer;
	}

	.duplicate-members-list {
		tbody {
			border: 0.1em solid $gray-color-1;

			tr {
				border-bottom: none;
			}

			td {
				border-bottom: none;
			}
		}
	}
}

.container-modal {
	.swal2-icon.swal2-warning.add-member-page {
		display: flex;
		border-color: $warning-color;
		color: $warning-color;
	}

	.content-center.add-member-page {
		text-align: center;
		color: #545454;
	}

	.modalSaas__row--btn {
		.btn {
			font-weight: 700;
		}
	}
}

.duplicate-member-popup {
	width: 35em !important;

	.close-button {
		color: $gray-color-2;
		background-color: $primary-color;
		border-radius: 50%;
		top: -1em;
		right: -1em;
		line-height: 0em;
		padding: 1.2em;
		font-size: 1em;
		font-weight: bold;

		&:hover {
			background-color: $primary-color;
			color: $gray-color-2;
		}
	}

	.memberName {
		color: $primary-color;
		font-weight: bold;
	}
}

.change-personal-details-page {
	.input-group {
		flex-wrap: inherit !important;

		.react-datepicker-wrapper {
			width: 100% !important;
		}

		.react-datepicker__close-icon {
			height: 16px !important;
			top: 25% !important;
			right: 7px !important;
		}
	}

	.box-nation {
		margin-left: -1rem;
		margin-right: -1rem;

		&.active {
			max-height: 20rem !important;
			transition: max-height 0.2s ease-out;
		}
	}

	.marketing-source {
		background-color: $gray-color-2;
	}

	.phone-require {
		margin-top: -1.45rem;
	}

	.noLongerInUse {
		display: flex;
		flex-direction: column-reverse;
		margin-top: -0.7em;
	}

	.multi-line {
		display: flex;
		flex-direction: column;
	}

	.data-has-changed {
		font-weight: bold;
		font-style: italic;
	}
}

// .plan-list-view-page.list-page {
//     display: block;

//     .box-btn-filter {
//         justify-content: space-between;
//     }
// }

.page-container {
	.main-content {
		.member {
			@media (height: 768px) {
				// padding-top: 6.785714285714286em !important;

				.member {
					.member-paging {
						.title-content {
							p {
								margin-bottom: 0 !important;
							}
						}
					}
				}
			}

			.member-main {
				.sticky-breadcrum {
					position: sticky;
					top: rem(60px);
					z-index: 2;
				}

				@media (min-width: 1024px) {

					.content.select-add-on {
						.sticky-aside {
							height: 8.714285714285714em !important;
							position: sticky !important;
							top: 13.5rem !important;

							.aside-summary {
								width: 100% !important;
							}
						}
					}
				}

				@media (min-width: 1024px) and (max-width: 1366px) {

					.sticky-breadcrum {
						.paging-steps {
							padding: 0.35714285714285715em 0;
							margin-bottom: 0.35714285714285715em !important;
						}
					}

					.content.select-add-on {
						.sticky-aside {
							height: 5.714285714285714em;
							position: sticky;
							top: 12.5rem;

							.aside-summary {
								width: 100%;
							}

							@media (height: 768px) {
								top: 13.5rem !important;

								.title-summary {
									padding: 0.21428571428571427em 0.7142857142857143em;
								}

								.pay-now {
									padding: 0.21428571428571427em 0.7142857142857143em;

									.number-pay-now {
										padding: 0;
										height: 1.142857142857143em;
									}
								}

								.section-billing {
									padding: 0.21428571428571427em 0.7142857142857143em;
								}

								.section-info {
									padding: 0.21428571428571427em 0.7142857142857143em;
								}

								.section-add-ons {
									padding: 0.21428571428571427em 0.7142857142857143em;

									.title-content {
										margin-bottom: 0 !important;
									}
								}

								.section-promotions {
									padding: 0.21428571428571427em 0.7142857142857143em;

									.title-content {
										margin-bottom: 0 !important;
									}
								}

								.box-btn {
									padding: 0.2857142857142857em 0.7142857142857143em;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 1024px) and (max-width: 1366px) {
	.page-container {
		.main-content {
			.member {
				.member-main {
					.content {
						.aside-summary {
							.row-span {
								.first-span {
									width: 35% !important;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 991px) {
	.plan-list-view-page.list-page {
		.list-page-title {
			width: 100%;
		}

		.box-btn-filter {
			flex-wrap: wrap;
			justify-content: flex-end;

			.plan-hint {
				width: 100%;
			}

			.plan-action {
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-end;

				.fm-success {
					width: 100%;
					text-align: right;

					.action-hint {
						margin-right: 0;
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.member {
		.general-info {
			justify-content: normal;
			flex-direction: column;

			.info-acc {
				max-width: none;
				margin-bottom: 2.1428571429rem;
			}

			.general-content {
				width: 100%;
			}
		}
	}
}

.package-detail {
	.table-above {
		border-bottom: none;
	}

	.grid-divider> :last-child:after {
		content: "";
		background-color: $gray-color-1;
		position: absolute;
		top: 0;
		bottom: 0;

		@media (min-width: 768px) {
			width: 1px;
			height: auto;
			left: -1px;
		}
	}
}

.member-config-page {
	.table-responsive {
		.checkbox-container {
			.checkmark.disabled {
				background-color: $gray-color-1 !important;
			}
		}
	}
}

.pd-left-35 {
	padding-left: 2.5em !important;
}

.btlr-0 {
	border-top-left-radius: 0 !important;
}

.bblr-0 {
	border-bottom-left-radius: 0 !important;
}

.select-async,
.select-fluid {
	&.select--is-disabled {
		.select__control {
			border: 1px solid var(--gray-color-3);
		}
	}

	.select__control {
		box-shadow: none !important;

		&.select__control--menu-is-open {
			border-color: $primary-color !important;
		}

		&.select__control--is-focused {
			border-color: $primary-color !important;
		}
	}

	.select__menu {
		.select__menu-list {
			.select__option--is-selected {
				background-color: $primary-color !important;
			}

			.select__option--is-focused {
				background-color: $primary-color-darken !important;
			}
		}
	}
}

.list-page {
	display: unset;
	justify-content: unset;
}

.select__indicator-separator {
	display: none;
}

.add-edit-fees {
	.row {
		width: 100%;
	}

	.collapse-saas {
		.collapsible:after {
			display: none;
		}

		.content {
			overflow: visible;

			.select--is-disabled {
				.select__indicators {
					display: none;
				}
			}
		}
	}
}

.iframe {
	width: 100%;
}