.search-wrapper {
	flex-wrap: wrap;
	width: 70%;

	.form-wrapper {
		display: flex;
		align-items: center;
		margin-bottom: 0.36em;

		.font-weight-bold {
			width: 6.071em;
		}

		.form-select {
			margin: 0 0.714em;
			flex: 1;

			.select__control {
				.select__value-container {
					padding: 0 0 0 24px;
				}
			}
		}
	}

	.btn-add {
		padding: 0.36em;
		margin-left: 6.625em;
		width: 7.14em;
		text-transform: uppercase;
		font-weight: $font-weight-bold;
	}
}

.ewh-autosuggest-container {
	border-radius: 0.357em;
	padding: 0.714em;
	margin-bottom: 1.071em;
	background-color: $gray-color-2;

	&.hideTag {
		border: none;
		background-color: inherit;
		padding: 0;
		box-shadow: none;
	}

	.autosugest-controller {
		display: flex;
		align-items: center;
		margin-bottom: 0.714em;
	}

	.tooltipText {
		color: $secondary-color;
		max-width: none;
	}

	.tag-container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		min-height: 3.571em;
		padding: 0.714em;
		background-color: $white-1;

		&.centerText {
			justify-content: center;
		}

		.tag-item {
			margin: 0.36em;
		}
	}

	.react-autosuggest__container {
		position: relative;
	}

	.autosuggest-input-container {
		position: relative;
		margin: 0 0.714em;
	}

	.autosuggest-search-icon {
		position: absolute;
		top: 0.357em;
		bottom: 0.357em;
		left: 0.714em;
	}

	.react-autosuggest__input {
		min-width: 17.143em;
		padding: 0.357em 1.071em 0.357em 2.857em;
		border: 0.071em solid #aaa;
		border-radius: 1.071em;
	}

	.react-autosuggest__input--focused {
		outline: none;
	}

	.react-autosuggest__suggestions-container {
		display: none;
	}

	.react-autosuggest__suggestions-container--open {
		display: block;
		position: absolute;
		margin-left: 0.714em;
		min-width: 17.143em;
		border: 0.071em solid #aaa;
		background-color: #fff;
		z-index: 2;
	}

	.react-autosuggest__suggestions-list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	.react-autosuggest__suggestion {
		cursor: pointer;
		padding: 0.357em 1.071em;
	}

	.react-autosuggest__suggestion--highlighted {
		background-color: #ddd;
	}
}

@media (max-width: 768px) {
	.ewh-autosuggest-container {
		.search-wrapper {
			width: 100%;
		}

		.tag-container {
			.MuiChip-root-457 {
				height: auto;

				.MuiChip-label-476 {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: normal;
				}
			}
		}
	}
}