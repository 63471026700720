.is-collapsed {
	
	.sidebar-demo {
		.page-container {
			padding-left: 60px;
			header {
				width: calc(100% - 60px);
			}
		}
		.sidebar {
			width: 70px;
		}
	}
}
.sidebar-demo {
	.page-container {
		padding-left: 260px;

		header {
			width: calc(100% - 260px);

			.header {
				padding-right: 30px;

				li {
					&.header__languages {
						font-size: rem(13px);

						.multi-box {
							.icon-global-ico {
								&:before {
									top: 0;
									font-size: rem(20px);
								}
							}
						}
					}

					&.header__account {
						.box-account {
							.account-name {
								font-size: rem(13px);
							}
						}
					}
				}
			}

			.header__right {
				li {
					&.header__notifications {
						padding: 0 30px;
					}
				}
			}
		}

		.main-content {
			padding: rem(115px 20px 1px 20px);
			min-height: calc(100vh - 40px);

			.title-content {
				font-size: rem(16px);
			}
		}
	}

	footer {
		height: 2.5714285714285716em;
		padding: rem(0 20px 0 30px);
		;
	}

	.sidebar {
		width: 260px;

		.dropdown-toggle {
			padding: rem(0 10px);

			&::after {
				right: rem(3px);
			}
		}

		.sidebar-inner {
			.sidebar-logo {
				padding: rem(0 18px);
			}

			.sidebar-menu {
				.nav-item {
					.dropdown-menu {
						font-size: rem(13px);

						li {
							height: 45px;
						}
					}

					>.dropdown-toggle {
						height: 45px;
						line-height: rem(45px);

						.title {
							font-size: rem(11.25px);
						}
					}
				}
			}
		}
	}

	.is-collapsed {
		.sidebar {
			width: 60px;

			&.sidebar-open {
				width: 260px;
			}
		}
	}

	@media (max-width: 767px) {
		body.app {
			nav.sidebar {
				.sidebar__icon {
					ul.sidebar__content {

						>li.dropdown,
						li.dropdown.active {
							.icon {
								height: 45px;
								line-height: rem(45px);

								>p.sidebar__title {
									font-size: rem(12px);
								}
							}

							>.dropdown-menu {
								.dropdown-item {
									height: 45px;
									line-height: rem(45px);
								}
							}
						}
					}
				}
			}
		}
	}
}