.member-ship-detail {
	.section-summary {
		margin-bottom: 2em;

		.summary-header {
			margin-bottom: 0.7142857142857143em;

			p {
				color: $base-color;
				text-transform: uppercase;
			}

			.list-action {
				.list-action__items {
					span {
						margin-right: 0;
					}
				}
			}
		}
	}

	hr {
		margin-bottom: 0.7142857142857143em;
	}

	.list-action {
		text-align: right;
		padding: 0;

		li {
			display: inline-block;
			margin: 0 0 0 1.5rem;

			a,
			span {
				color: $primary-color;
				cursor: pointer;
				font-size: 0.85714285714rem;

				&:hover {
					color: $primary-color-darken;
				}

				&:before {
					font-family: "icomoon";
					font-size: 1.8rem;
					content: "\e91e";
					vertical-align: middle;
				}
			}
		}
	}

	.content-wrapper {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		margin-bottom: 0.8571428571428571em;
		padding-bottom: 1em;

		&.no-border {
			border: none;
			padding-bottom: 0;
		}

		.content-header {
			display: flex;
			justify-content: space-between;

			.head-title {
				font-size: 1.1428571428571428em;
				text-transform: uppercase;
			}

			.list-action {
				margin-bottom: -1em;
			}
		}
	}



	.promotion-addon-wrapper {
		margin: 0;

		.promotion-addon {
			font-style: italic;
			background-color: $gray-color-2;
			border: 0.07142857142857142em solid $gray-color-2;
			border-radius: 0.21428571428571427em;
			padding: 0.5em;
		}
	}

	.package-period-wrapper {
		.package-detail {
			display: flex;
			justify-content: space-between;
			font-size: 1rem;

			.data-label {
				font-weight: 700;
			}
		}
	}

	.list-btn-action {
		display: flex;
		justify-content: flex-end;

		button {
			margin-left: 10px;
			width: 150px;
			font-weight: bold;
			text-transform: uppercase;
		}
	}
}

@media screen and (max-width: 1366px) {
	.member-ship-detail {
		.content-wrapper {
			.content-header {
				.head-title {
					font-size: 0.8571428571428571em;
				}
			}

			&.no-border {
				margin-bottom: 0;
			}
		}

		.promotion-addon-wrapper {
			.promotion-addon {
				font-size: 0.8571428571428571em;
			}
		}

		.package-period-wrapper {
			.package-detail {
				font-size: 0.7142857142857143em;
			}
		}

		.list-action {
			line-height: 1.2;

			li {
				span {
					font-size: 0.7142857142857143em;

					&:before {
						font-size: 1.4285714285714286em;
					}
				}
			}
		}
	}
}