.table-base {
    display: block;
    empty-cells: show;

    tr {
        width: 100%;
        display: flex;
    }

    td,
    th {
        border-right: unset !important;
        flex-basis: 100%;
        flex-grow: 2;
        display: block;
        border-color: transparent;
    }

    .align-center {
        text-align: center;
    }

    thead {
        display: block;
        width: 100%;

        th {
            padding: 0.75rem !important;
        }

    }

    tbody {
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        background-color: #fff;

        tr {
            vertical-align: baseline;
            background: transparent;

            border-bottom: 2px solid #fff;

            td {
                padding: 0.75rem;
            }
        }
    }
}