.membersummary-page {
	border-left: none !important;
	border-right: none !important;
	padding-left: 1.4285714285714286em;
	padding-right: 1.4285714285714286em;

	.color-red {
		color: $red;
	}

	.color-green {
		color: #36db1b;
	}

	.general-info,
	.general-content {
		p {
			font-size: 0.8571428571428571em;
		}

		.large-size {
			text-transform: uppercase;
		}
	}

	.general-info {
		background-color: white;
		justify-content: space-between;
		border-bottom: 0.07142857142857142em solid $gray-color-2;
		padding: 10px 20px 5px;
		margin: 0 -20px;

		p {
			margin-bottom: 0;
			line-height: 1.375rem;
		}

		.info-acc {
			display: flex;

			.color-text {
				color: $primary-color;
				font-weight: 700;
			}

			.box-avatar {
				width: 5.714285714285714em;
				height: 5.714285714285714em;
				border-radius: 50%;
				border: 0.2857142857142857em solid rgba(255, 255, 255, 0.5607843137254902);
				margin-left: -5px;

				.edit-avartar {
					width: 70px;
					height: 70px;
					border-radius: 50%;
					border: 0.21428571428571427em solid $gray-color-3;
					position: absolute;
					overflow: hidden;
					cursor: pointer;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}

					.icon-edit-ico {
						position: absolute;
						bottom: 0;
						display: block;
						width: 100%;
						padding: 0.35714285714285715em;
						background-color: rgba(33, 37, 41, 0.52);

						&::before {
							position: relative;
							top: 0;
							left: calc(50% - 7px);
							color: $white;
						}
					}
				}
			}
		}
	}

	.general-content {

		.positive,
		.smile {
			color: $positive-color;
			font-weight: 700;

			&::before {
				color: $positive-color;
			}
		}

		.neutral,
		.sad {
			color: $neutral-color;
			font-weight: 700;

			&::before {
				color: $neutral-color;
			}
		}

		.negative {
			color: $negative-color;
			font-weight: 700;

			&::before {
				color: $negative-color;
			}
		}

		.membership-stt,
		.payment-stt,
		.last-swipe-date-stt {
			color: unset;
			font-weight: unset;

			&:before {
				font-family: 'icomoon' !important;
				margin-right: 0.5714285714285714em;
				font-weight: 700;
			}

			&.positive {
				&::before {
					content: '\e95a';
					font-size: 0.8571428571428571em;
				}
			}

			&.neutral {
				&::before {
					content: '\e937';
					font-size: 1em;
				}
			}

			&.negative {
				&::before {
					content: '\e96c' !important;
					font-size: 0.8571428571428571em;
				}
			}

			&.smile {
				&::before {
					content: '\e96b';
					font-size: 0.8571428571428571em;
				}
			}

			&.sad {
				&::before {
					content: '\e96a';
					font-size: 0.8571428571428571em;
				}
			}
		}
	}

	.membersummary-list {
		.section-summary {
			padding: 7px 0;
		}

		.row {
			&:first-child {
				.section-summary {
					border-top: none;
				}
			}
		}

		.section-line-between {
			position: relative;

			&::before {
				content: "";
				position: absolute;
				display: block;
				width: 0.07142857142857142em;
				top: 10px;
				left: 0;
				bottom: 15px;
				background-color: #f2f2f2;
			}
		}

		.section-line-row {
			border-top: 1px solid #f2f2f2;
		}

		margin-top: 0.28571428571rem;

		.list-action {
			display: block;
			text-align: right;

			li {
				display: inline-block;

				a,
				span {
					cursor: pointer;

					&:hover {
						color: $primary-color-darken;
					}
				}
			}
		}

		dl {
			font-size: 11px;
			display: flex;
			justify-content: space-between;
			margin-bottom: 0;
			line-height: 1.7;

			dt {
				font-weight: 600;
			}

			dd {
				margin-bottom: 0;
				text-align: right;
			}
		}

		.summary-header {
			display: flex;
			justify-content: space-between;

			&.member-account-header,
			&.membership-header {
				display: unset;

				.list-action {
					padding-left: 0;
				}
			}

			p {
				margin-bottom: 0;
				font-size: 0.8571428571428571em;
				color: $primary-color;
				font-weight: 700;
				text-transform: uppercase;
				cursor: pointer;
			}

			.list-action {
				margin-bottom: 0;
				position: relative;

				li.list-no-action {
					padding-right: 0;
				}

				.show-box {
					display: flex;
					flex-direction: column;
					position: absolute;
					z-index: 10;
					background-color: $white;
					border: 0.07142857142857142em solid $gray-color-1;
					box-shadow: 0px 0.07142857142857142em 0.07142857142857142em 0px $gray-color-1;
					right: rem(-15px);
					top: 2.542857142857143em;
					padding-left: 0;
					width: auto;

					li {
						padding: 0.5714285714285714em 1.2142857142857142em;
						font-size: 0.8571428571428571em;
						cursor: pointer;

						span {
							white-space: nowrap;
						}

						&:hover {
							background-color: #f2f2f2;
							color: $primary-color;
						}

						a {
							color: $dark-color;

							&:hover {
								color: $primary-color;
							}
						}
					}

					font-size: 0.8571428571428571em;

					&::after {
						content: '';
						position: absolute;
						width: 0;
						height: 0;
						border-left: 0.6em solid transparent;
						border-right: 0.6em solid transparent;
						top: -0.8142857142857143em;
						border-bottom: 0.8em solid $gray-color-1;
						right: 1em;
					}

					&::before {
						content: '';
						position: absolute;
						width: 0;
						height: 0;
						border-left: 0.6em solid transparent;
						border-right: 0.6em solid transparent;
						top: -0.6714285714285714em;
						border-bottom: 0.8em solid $white;
						right: 1em;
						z-index: 1;
					}
				}

				.action {
					cursor: pointer;
					color: $primary-color;
					position: relative;
					font-size: 0.7142857142857143em;

					a,
					span {
						&:after {
							content: '\e901';
							position: absolute;
							font-family: 'icomoon' !important;
							border: none;
							font-size: 1.78571rem;
							right: -0.3em;
							top: -10px;
						}

						padding-right: 1.7142857142857143em;
					}
				}

				.list-action__items {
					cursor: pointer;

					a,
					span {
						color: $primary-color;
						position: relative;
						font-size: 0.7142857142857143em;
						margin-right: 1.4285714285714286em;

						&:hover {
							color: $primary-color-darken;
						}

						&:before {
							content: '\e91e';
							position: absolute;
							font-family: 'icomoon' !important;
							border: none;
							font-size: 1.4285714285714286em;
							left: -1em;
							top: -0.14285714285714285em;
						}
					}
				}

				.list-action__items:not(:last-child) {
					margin-right: 1.4285714285714286em;
				}
			}
		}

		.debt-summary,
		.member-account,
		.membership,
		.personal-detail,
		.activity {
			margin-bottom: 0;
		}
	}

	.row-btn {
		margin-top: 0.3142857142857143em;

		.list-btn-action {
			display: flex;
			justify-content: flex-end;
			margin-bottom: 0;

			li {
				color: white;
			}

			.btn {
				margin-right: 0.7142857142857143em;
				font-size: 0.8571428571428571em;
				text-transform: uppercase;
				font-weight: $font-weight-bold;
				padding: 11px 10px !important;

				&.btn-dark {
					margin-right: 0;
				}
			}
		}
	}
}

.member-summary-page footer {
	h3 {
		padding-right: 1.42857142857rem;
	}
}

.page-container .main-content {
	padding-top: 5.5em;

}

.page-container {
	.member-summary-main-content {
		padding: 4em 0 0 0 !important;
	}
}

.change-summary {
	.section-email {
		.col-12 {
			padding-top: 10px;
			background: var(--gray-color-2);
		}

		.payment-email {
			margin-left: 3em;
			flex-direction: column;

			label {
				padding-left: 0;
			}

			.col-5 {
				margin-left: 0 !important;
				margin-bottom: 0 !important;
				padding-left: 0;
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.membersummary-page {
		.select-add-on-main {
			&.no-debt-section {
				min-height: 490px;
			}
		}

		.membersummary-list {
			dl {
				dd {
					max-width: 58%;
				}
			}

			.summary-header {
				.list-action {
					span {
						&:last-child {
							.list-action__items {

								a,
								span {
									margin-right: 0;
								}
							}
						}
					}
				}
			}
		}

		.row-btn {
			.list-btn-action {

				.btn-primary,
				.btn-dark {
					padding: 6px;
					font-size: 10px;
				}
			}
		}
	}
}

@media only screen and (max-width: 950px) {
	.page-container header {
		.header {
			padding-right: 1.5rem;
			padding-left: 3rem;
		}

		.header__right .header__account {
			min-width: 7em;
		}
	}

	.membersummary-page {
		border: none;

		.table-wrapper {
			.table-filter {
				.left-filter {
					justify-content: left;
					flex-wrap: wrap;

					>* {
						margin-bottom: 0.5em;
					}
				}
			}
		}

		.general-info {
			p {
				font-size: 0.8571428571428571em;
			}

			.info-acc {
				.col-md-9 {
					padding-left: 0;
				}
			}
		}

		.row-btn {
			.list-btn-action {
				padding-left: 0;

				.btn {
					font-size: 0.7857142857142857em;
					white-space: normal;
				}
			}
		}
	}

	footer {
		padding-right: 1.1428571428571428em;
	}
}

@media only screen and (max-width: 540px) {
	.membersummary-page {
		.general-info {
			flex-direction: column;

			.info-acc {
				flex-direction: column;

				.box-avatar {
					margin: 0 auto;
				}
			}
		}

		.member-contact-detail,
		.member-account-detail {
			.list-btn-action {
				flex-wrap: wrap;
				padding: 0;
				flex-direction: unset !important;

				button {
					margin-left: 0.5em !important;
					margin-right: 0 !important;
					width: 135px !important;
					margin-bottom: 0.5em;
				}
			}
		}

		.membersummary-list {
			.summary-header {
				flex-direction: column;

				ul {
					flex-direction: column;
					padding-left: 0.7142857142857143em;
				}
			}
		}

		.row-btn {
			.list-btn-action {
				flex-direction: column;

				.btn {
					width: 100%;
					margin-right: 0;
					margin-bottom: 0.7142857142857143em;

					&.btn-dark {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

@media only screen and (min-device-width: 1600px) {
	.membersummary-page {

		.general-info,
		.general-content {
			p {
				font-size: 1rem;
			}
		}

		.membersummary-list {
			.summary-header {
				margin-top: 0 !important;

				p {
					font-size: 1.1428571428571428em;
				}

				.list-action {
					span {
						&:last-child {
							.list-action__items {
								padding-right: 0;
							}
						}
					}

					.list-action__items {
						cursor: pointer;
						padding-right: 1.4285714285714286em;

						a,
						span {
							color: $primary-color;
							position: relative;
							font-size: 0.85714285714rem;
							margin-right: 1.42857142857rem;

							&:last-child {
								margin-right: 0;
							}

							&:before {
								content: '\e91e';
								position: absolute;
								font-family: 'icomoon' !important;
								border: none;
								font-size: 1.78571rem;
								left: -1.42857142857rem;
								top: -0.35714285714285715em;
							}
						}
					}

					.action {
						cursor: pointer;
						padding-right: 0.71428571428rem;
						color: $primary-color;
						position: relative;
						font-size: 0.85714285714rem;

						a,
						span {
							&:after {
								right: -0.1em;
								top: -0.71428571428rem;
							}

							padding-right: 0.7142857142857143em;
						}
					}

					.show-box {
						font-size: 1rem;
					}
				}
			}

			dl {
				font-size: 1rem;
				line-height: 2;
			}
		}
	}
}