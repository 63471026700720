.sidebar {
    .sidebar-inner {
        .sidebar-menu {
            &::-webkit-scrollbar {
                width: 0.5em !important;
            }
        }

        ul {
            cursor: unset;

            .dropdown-toggle {
                cursor: pointer;
            }

            .dropdown-menu {
                li.nav-item {
                    cursor: pointer;

                    &.active {
                        .dropdown-toggle {
                            background-color: transparent;

                            .icon-holder {
                                i.icon {
                                    &::before {
                                        color: $primary-color !important;
                                    }
                                }
                            }

                            .title {
                                color: $primary-color;
                            }
                        }
                    }

                    .sidebar-link {
                        &.active {
                            .icon-holder {
                                i.icon {
                                    &::before {
                                        color: $primary-color !important;
                                    }
                                }
                            }

                            .title {
                                color: $primary-color;
                            }
                        }
                    }
                }
            }

        }
    }
}

.is-collapsed {
    .sidebar {
        .sidebar-inner {
            .sidebar-menu {
                .nav-item {
                    background-color: $primary-color !important;
                    &.dropdown {
                        background-color: $primary-color !important;
                        
                        .dropdown-toggle:hover {
                            background-color: $primary-color-darken !important;
                        }
                        .dropdown-menu {
                            li {
                                background-color: $primary-color !important;
                                color: $base-color;
                            }
                        }
                    }
                }
            }
        }
        
    }
}