.membersummary-page {
	.membersummary-list {
		.member-activity-detail {
			.section-summary {
				padding: rem(10px) 0;
				.btn-add-new {
					margin-right: 0;
					&:after {
						display: none;
					}
				}
				.summary-header {
					align-items: center;
					p {
						color: var(--base-color);
						font-weight: 700;
						cursor: unset;
					}

					.show-dropdown {
						display: flex;

						label {
							margin-right: rem(10px);
							display: flex;
							align-items: center;
						}

						.select-custom {
							width: 200px;
							max-width: 100%;
							margin-right: 0;
							margin-bottom: rem(7px);
						}
					}

				}
			}
			.desc-list {
				font-size: rem(12px);
				.left-content {
					font-weight: 700;
				}
			}
		}
	}
}

@media only screen and (max-width: 540px) {
	.membersummary-page {
		.membersummary-list {
			.member-activity-detail {
				.list-btn-action {
					padding: 0;
					flex-wrap: wrap;
					.btn {
						margin-bottom: 0.5em;
						width: 150px;
						padding: 0.71429rem;
						font-size: 1rem;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.membersummary-page {
		.membersummary-list {
			.member-activity-detail {
				.section-summary {
					padding: rem(8px) 0;
					font-size: rem(12px);

					.summary-header {
						p {
							font-size: rem(12px);
						}

						.list-action {

							.list-action__items,
							.action,
							.show-box {
								span {
									font-size: rem(12px);
								}
							}
						}
					}
				}
			}
		}
	}

}