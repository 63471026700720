.membersummary-page {
	.membersummary-list {
		.member-account-detail {

			.row-btn {
				.list-btn-action {
					.btn {
						width: 150px;
						padding: 0.71429rem;
						font-size: 1rem;
					}
				}
			}
			hr {
				margin-top: 0.25em;
				margin-bottom: 0.25em;
			}
			.member-account-top {
				.section-summary {
					.summary-header {
						p {
							font-weight: 700;
						}
						.list-action {
							padding-left: 1em;
							.action {
								left: 1em;
								margin-right: 0.5em;
								> span{
									padding-right: 1.2em;
									&:after {
										right: -0.2em;
									}
								}
							}
							> span {
								position: relative;
							}
						}
					}
				}
			}
			.section-summary {
				padding: rem(10px) 0;
				.summary-header {
					align-items: center;
					p {
						color: var(--base-color);
						font-weight: 400;
						cursor: unset;
					}

					.show-dropdown {
						display: flex;

						label {
							margin-right: rem(10px);
							display: flex;
							align-items: center;
						}

						.select-custom {
							width: 200px;
							max-width: 100%;
							margin-right: 0;
							margin-bottom: rem(7px);
						}
					}

				}

				.left-content {
					font-weight: 700;
				}
			}
		}
	}
}

@media only screen and (min-device-width: 1600px) {
	.membersummary-page {
		.membersummary-list {
			.member-account-detail {
				.member-account-top {
					.section-summary {
						.summary-header {
							.list-action {
								padding-left: 1em;
								.action {
									left: 1em;
									margin-right: 0em;
									> span{
										padding-right: 1.2em;
										&:after {
											right: 0.15em;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.membersummary-page {
		.membersummary-list {
			.member-account-detail {
				.section-summary {
					padding: rem(8px) 0;
					font-size: rem(12px);

					.summary-header {
						p {
							font-size: rem(12px);
						}

						.list-action {

							.list-action__items,
							.action,
							.show-box {
								span {
									font-size: rem(12px);
								}
							}
						}
					}
				}
			}
		}
	}

}