.plan-edit-availability {
    .member {
        .member-main.member-main--custom {
            >.content {
                .collapse-saas .collapsible::after {
                    display: none;
                }

                .collapse-promotion button.collapsible {
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }
        }
    }
}

.plan-edit {
    .form-check {
        label {
            span {
                line-height: 1.714em;
            }
        }
    }

    .member {
        .member-main.member-main--custom {
            >.content {
                .item-count {
                    font-weight: 400;
                    margin-left: 5px;
                    font-size: 0.8em;
                }

                .table-list {
                    box-shadow: none;
                    border: none;
                    padding: 0;
                    max-width: 100%;
                    width: 100%;

                    .table-responsive {
                        min-height: 0;
                        max-height: 100%;
                    }
                }

                .select-custom {
                    .form-control:not(:disabled) {
                        z-index: 0;
                        position: relative;
                        background-color: transparent;
                    }
                }

                .component-list {
                    max-width: 100%;
                    width: 100%;

                    .icon-arrow-down-icon {
                        position: relative;

                        &:before {
                            color: var(--white);
                        }
                    }
                }

                .table-paging .form-group {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
}